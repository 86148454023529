import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {
  ButtonSwitch,
  ButtonSwitchOption,
  Control,
} from "@sokigo/components-react-bootstrap";
import {CaseWorkerPicker} from "./CaseWorkerPicker";
import {CaseWorkerSelection} from "./CaseWorkerSelection";

export function CaseWorkerSelectionSwitch({
  onSelectionChange,
  onSpecificCaseWorkerIdChange,
  selection,
  specificCaseWorkerId,
  canSearch,
}) {
  const t = useAppTranslation();
  return (
    <>
      <Control labelText={t("caseWorkerSelection")}>
        <>
          <ButtonSwitch value={selection} onChange={onSelectionChange}>
            <ButtonSwitchOption value={CaseWorkerSelection.Me}>
              {t("caseWorkerSelectionMe")}
            </ButtonSwitchOption>
            <ButtonSwitchOption value={CaseWorkerSelection.Any}>
              {t("caseWorkerSelectionAny")}
            </ButtonSwitchOption>
            <ButtonSwitchOption value={CaseWorkerSelection.Specific}>
              {t("caseWorkerSelectionSpecific")}
            </ButtonSwitchOption>
          </ButtonSwitch>
          {!canSearch && (
            <div className="invalid-feedback" style={{display: "block"}}>
              {t("searchErrorMessage")}
            </div>
          )}
        </>
      </Control>
      {selection === CaseWorkerSelection.Specific && (
        <CaseWorkerPicker
          value={specificCaseWorkerId}
          onChange={onSpecificCaseWorkerIdChange}
        />
      )}
    </>
  );
}
