import {
  faEdit,
  faEllipsisV,
  faPlus,
  faTrashAlt,
  faInfoCircle,
} from "@fortawesome/pro-regular-svg-icons";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {
  ActionsDropdown,
  ActionsDropdownItem,
  Alert,
  Panel,
  Table,
  TableBody,
  TableCell,
  TableExpandedRow,
  TableExpandRowCell,
  TableHead,
  TableHeader,
  TableHeaderRow,
  TableRow,
  TableRowActionsCell,
  TextArea,
  Tooltip,
} from "@sokigo/components-react-bootstrap";
import {Fragment, useMemo, useState} from "react";
import {FillTableCellWithTruncatedText} from "../../../../../common-components/FillTableCellWithTruncatedText";
import {InspectionAssessment} from "../../Common/InspectionAssessment";
import {Assessment} from "../information/historicVisits/Assessment";
import {
  useCurrentVisitInput,
  useMergeCurrentVisitInput,
} from "../InspectionVisitRoute";
import {FoodControlAreaNavigationMenuButton} from "./FoodControlAreaNavigationMenuButton";
import styles from "./FoodInspectionControlForm.module.scss";
import {useAvailableFoodControlAreas} from "./useAvailableFoodControlAreas";
import {useCreateSupportQuestion} from "./useCreateSupportQuestion";
import {useEditSupportQuestion} from "./useEditSupportQuestion";
import {useFoodControlAreasValidation} from "./useFoodControlAreasValidation";
import {useRemoveFoodControlSupportQuestion} from "./useRemoveFoodControlSupportQuestion";
import {useRemoveFoodInspectionQuestion} from "./useRemoveFoodInspectionQuestion";
import {AssessmentSwitch} from "../AssessmentSwitch";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function TextareaWithFoodPhrasePicker({className, value, question, onChange}) {
  const t = useAppTranslation();
  return (
    <div className={className}>
      {question.phrases.length > 0 && (
        <ActionsDropdown
          dropdownText={t("selectPhrase")}
          kind="control"
          className="mb-2"
          menuAlign="start"
        >
          {question.phrases.map((p) => (
            <ActionsDropdownItem
              key={p.name}
              onClick={() =>
                onChange((value ?? "") + (value ? "\n" : "") + p.phraseText)
              }
              itemText={
                <div className={styles.phrase + " text-truncate"}>
                  {p.name}: {p.phraseText}
                </div>
              }
            />
          ))}
        </ActionsDropdown>
      )}
      <TextArea value={value ?? ""} onChange={(x) => onChange(x)} rows={4} />
    </div>
  );
}

function SupportQuestion({supportQuestion, note, onNoteChange}) {
  return (
    <div className="row p-3">
      <div className="col-6">
        <TextArea value={note ?? ""} onChange={(note) => onNoteChange(note)} />
      </div>
      {supportQuestion.helpText?.length && (
        <div className={"col-md-6 mt-2 mt-md-0"}>
          <p className={"mb-0 " + styles.helpText}>
            {supportQuestion.helpText}
          </p>
        </div>
      )}
    </div>
  );
}

function FoodQuestion({area, question}) {
  const t = useAppTranslation();
  const {
    foodControlQuestionNoteByQuestionId,
    foodControlQuestionAssessmentByQuestionId,
    foodControlSupportQuestionNoteBySupportQuestionId,
    foodControlSupportQuestionAssessmentBySupportQuestionId,
    completingInformation,
    completingInformation: {foodInspectionActionsModel},
  } = useCurrentVisitInput();
  const merge = useMergeCurrentVisitInput();
  const note = foodControlQuestionNoteByQuestionId?.[question.id];
  const assessment = foodControlQuestionAssessmentByQuestionId?.[question.id];

  const columns = [
    {
      key: "title",
      header: t("title"),
      renderCell: ({row}) => (
        <FillTableCellWithTruncatedText>
          {row.title}
        </FillTableCellWithTruncatedText>
      ),
      className: "w-66",
    },
    {
      key: "note",
      header: t("note"),
      renderCell: ({row}) => (
        <FillTableCellWithTruncatedText>
          {foodControlSupportQuestionNoteBySupportQuestionId?.[row.id]}
        </FillTableCellWithTruncatedText>
      ),
      className: "w-33",
    },
  ];
  const [createSupportQuestion, supportQuestionCreation] =
    useCreateSupportQuestion(question.id);
  const [editSupportQuestion, supportQuestionEditing] = useEditSupportQuestion(
    question.id
  );
  const removeSupportQuestion = useRemoveFoodControlSupportQuestion();
  const removeQuestion = useRemoveFoodInspectionQuestion(area.id, question.id);

  function setNote(note) {
    merge({
      foodControlQuestionNoteByQuestionId: {
        ...(foodControlQuestionNoteByQuestionId ?? {}),
        [question.id]: note,
      },
    });
  }

  function setAssessment(assessment) {
    const oldAssessment =
      foodControlQuestionAssessmentByQuestionId?.[question.id];
    let updatedCompletingInformation = {...completingInformation};
    if (
      oldAssessment === InspectionAssessment.NotAcceptable &&
      assessment !== InspectionAssessment.NotAcceptable &&
      foodInspectionActionsModel?.length > 0
    ) {
      //Control information tab/ foodInspectionActionsModel/ have property called questionIds (multi).
      //questionIds represents assessments that are NotAcceptable.
      //If assessment is changed from NotAcceptable to anything else,
      //all foodInspectionActionsModel needs to reduce questionIds for that assessment,
      //if foodInspectionActionsModel does not have any questionIds, remove it completly.
      const editedActions = foodInspectionActionsModel
        .map((action) => {
          const filteredControlPoints = action.questionIds.filter(
            (cpId) => cpId !== question.id
          );
          return {
            ...action,
            questionIds: filteredControlPoints,
          };
        })
        .filter((x) => x.questionIds.length > 0);

      updatedCompletingInformation = {
        ...completingInformation,
        foodInspectionActionsModel: [...editedActions],
      };
    }
    merge({
      foodControlQuestionAssessmentByQuestionId: {
        ...(foodControlQuestionAssessmentByQuestionId ?? {}),
        [question.id]: assessment,
      },
      completingInformation: updatedCompletingInformation,
    });
  }

  function setSupportQuestionNote(supportQuestionId, note) {
    merge({
      foodControlSupportQuestionNoteBySupportQuestionId: {
        ...(foodControlSupportQuestionNoteBySupportQuestionId ?? {}),
        [supportQuestionId]: note,
      },
    });
  }

  function setSupportQuestionAssessment(supportQuestionId, assessment) {
    merge({
      foodControlSupportQuestionAssessmentBySupportQuestionId: {
        ...(foodControlSupportQuestionAssessmentBySupportQuestionId ?? {}),
        [supportQuestionId]: assessment,
      },
    });
  }

  return (
    <>
      <Panel className="mb-4 p-3">
        <div className="d-flex flex-row justify-content-between align-items-center mb-2">
          <Tooltip text={question.title}>
            <h4 className="text-truncate">
              {question.number}: {question.title}
            </h4>
          </Tooltip>
          <div className="d-flex align-items-center ml-2">
            <AssessmentSwitch
              value={assessment}
              onChange={(x) => setAssessment(x)}
            />
            <ActionsDropdown
              icon={faEllipsisV}
              kind="ghost"
              className="ml-2"
              menuSize="sm"
            >
              <ActionsDropdownItem
                itemText={t("remove")}
                onClick={removeQuestion}
                icon={faTrashAlt}
                kind="danger-hover-ghost"
              />
              <ActionsDropdownItem
                itemText={t("addSupportQuestion")}
                onClick={createSupportQuestion}
                icon={faPlus}
              />
            </ActionsDropdown>
          </div>
        </div>
        <TextareaWithFoodPhrasePicker
          value={note}
          onChange={(text) => setNote(text)}
          question={question}
        />
        {question.supportQuestions.length > 0 && (
          <div>
            <h4 className="mt-4 mb-2">
              {t("mySupportQuestionsForQ", {q: question.number})}{" "}
              <Tooltip
                side="left"
                text={t("supportQuestionsAreClearedAfterVisitFinished")}
              >
                <span tabIndex="0" className="align-start">
                  <FontAwesomeIcon icon={faInfoCircle} />
                </span>
              </Tooltip>
            </h4>
            <Table
              className={""}
              rows={question.supportQuestions}
              columns={columns}
              getRowKey={(x) => x.id}
            >
              <TableHead>
                <TableHeaderRow colSpan={columns.length + 2}>
                  {(columns) => (
                    <>
                      <TableHeader className="" />
                      {columns.map((x) => (
                        <TableHeader
                          key={x.key}
                          column={x}
                          className={x.className}
                        >
                          {x.header}
                        </TableHeader>
                      ))}
                      <TableHeader />
                    </>
                  )}
                </TableHeaderRow>
              </TableHead>
              <TableBody>
                {(rows) =>
                  rows.map((r) => (
                    <Fragment key={r.key}>
                      <TableRow key={r.key} row={r}>
                        <TableExpandRowCell row={r} />
                        {r.cells.map((c) => (
                          <TableCell key={c.key}>{c.value}</TableCell>
                        ))}
                        <TableRowActionsCell>
                          <div className="d-flex align-items-center">
                            <AssessmentSwitch
                              size="sm"
                              value={
                                foodControlSupportQuestionAssessmentBySupportQuestionId?.[
                                  r.row.id
                                ] ?? Assessment.NotAssessed
                              }
                              onChange={(assessment) =>
                                setSupportQuestionAssessment(
                                  r.row.id,
                                  assessment
                                )
                              }
                            />
                            <ActionsDropdown
                              size="sm"
                              icon={faEllipsisV}
                              kind="ghost"
                              className="ml-2"
                            >
                              <ActionsDropdownItem
                                itemText={t("remove")}
                                onClick={() =>
                                  removeSupportQuestion(question.id, r.row.id)
                                }
                                icon={faTrashAlt}
                                kind="danger-hover-ghost"
                              />

                              <ActionsDropdownItem
                                itemText={t("edit")}
                                onClick={() => editSupportQuestion(r.row.id)}
                                icon={faEdit}
                              />
                            </ActionsDropdown>
                          </div>
                        </TableRowActionsCell>
                      </TableRow>
                      <TableExpandedRow
                        row={r}
                        colSpan={columns.length + 2}
                        indent
                      >
                        <SupportQuestion
                          supportQuestion={r.row}
                          note={
                            foodControlSupportQuestionNoteBySupportQuestionId?.[
                              r.row.id
                            ]
                          }
                          onNoteChange={(note) =>
                            setSupportQuestionNote(r.row.id, note)
                          }
                        />
                      </TableExpandedRow>
                    </Fragment>
                  ))
                }
              </TableBody>
            </Table>
          </div>
        )}
      </Panel>
      {supportQuestionEditing}
      {supportQuestionCreation}
    </>
  );
}

export function FoodInspectionControlForm() {
  const availableAreas = useAvailableFoodControlAreas();
  const t = useAppTranslation();
  const isValid = useFoodControlAreasValidation();
  const [selected, setSelected] = useState({
    foodControlAreaId: availableAreas[0]?.id,
    questionIds: availableAreas[0] ? [availableAreas[0].questions[0].id] : [],
  });
  const currentArea = availableAreas.find(
    (x) => x.id === selected.foodControlAreaId
  );
  const currentQuestions = useMemo(
    () =>
      currentArea?.questions.filter((q) => selected.questionIds.includes(q.id)),
    [currentArea, selected]
  );

  return (
    <div>
      <div className="d-flex flex-row align-items-center rounded bg-normal my-3">
        <FoodControlAreaNavigationMenuButton onSelect={setSelected} />
        {currentArea && (
          <Tooltip text={currentArea.title} side="right">
            <h3 className="ml-2 text-truncate">
              {currentArea.number}: {currentArea.title}
            </h3>
          </Tooltip>
        )}
      </div>
      {!isValid && (
        <Alert kind="warning" inline hideCloseButton contrast>
          {t("operativeGoalsErrorMessage")}
        </Alert>
      )}
      {currentQuestions?.map((q) => (
        <FoodQuestion area={currentArea} key={q.id} question={q} />
      ))}
    </div>
  );
}
