import {faTimes} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {createContext, useContext, useState} from "react";
import styles from "./InputWithTags.module.scss";

const InputWithTagsContext = createContext(null);
export function InputWithTagsProvider({
  separator,
  value,
  onChange,
  validator,
  children,
}) {
  const [pendingValue, setPendingValue] = useState("");
  const addTag = () => {
    if (validator(pendingValue)) {
      onChange([...(value ?? []), pendingValue]);
      setPendingValue("");
    }
  };
  const removeTag = (index) => {
    if (!(value?.length > 0)) {
      return;
    }
    const newTags = [...value];
    newTags.splice(index, 1);
    onChange(newTags);
  };
  const v = {
    separator,
    addTag,
    removeTag,
    validator,
    pendingValue,
    setPendingValue,
    value: value ?? [],
  };
  return (
    <InputWithTagsContext.Provider value={v}>
      {children(pendingValue && !validator(pendingValue))}
    </InputWithTagsContext.Provider>
  );
}

export function InputWithTags({className}) {
  const {separator, addTag, removeTag, pendingValue, setPendingValue, value} =
    useContext(InputWithTagsContext);

  const inputBlur = () => addTag();
  const inputChange = (evt) => {
    setPendingValue(evt.target.value);
  };
  const inputKeyDown = (evt) => {
    if (evt.key === "Backspace" && !pendingValue) {
      removeTag(value.length - 1);
      evt.stopPropagation();
      evt.preventDefault();
    } else if (evt.key === "Enter" || (separator && evt.key === separator)) {
      addTag();
      evt.stopPropagation();
      evt.preventDefault();
    }
  };

  return (
    <div className={className + " " + styles.inputTag}>
      <ul className={styles.inputTagTags}>
        {value.map((tag, i) => (
          <li key={tag}>
            {tag}
            <FontAwesomeIcon
              className={styles.closeIcon}
              icon={faTimes}
              onClick={() => {
                removeTag(i);
              }}
            />
          </li>
        ))}
        <li className={styles.inputTagTagsInput}>
          <input
            type="text"
            onKeyDown={inputKeyDown}
            value={pendingValue ?? ""}
            onChange={inputChange}
            onBlur={inputBlur}
          />
        </li>
      </ul>
    </div>
  );
}
