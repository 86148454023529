import {useMemo} from "react";
import {useServiceWorkerRepository} from "../useServiceWorkerRepository";

export function useServerVisitRepository() {
  const {items: serverVisits, refresh: refreshServerVisits} =
    useServiceWorkerRepository("server-visit");
  const v = useMemo(
    () => ({serverVisits, refreshServerVisits}),
    [serverVisits, refreshServerVisits]
  );
  return v;
}
