import {Fragment} from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableExpandedRow,
  TableExpandRowCell,
  TableHead,
  TableHeader,
  TableHeaderRow,
  TableRow,
  TableRowActionsCell,
  Switch,
} from "@sokigo/components-react-bootstrap";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {useServerState} from "../../../../../../ServerStateProvider";

export function FoodInspectionControlAreas({
  selectedControlAreas,
  toggleControlAreaQuestion,
  toggleAllControlAreaQuestions,
}) {
  const {
    baseData: {foodInspectionControlAreas},
  } = useServerState();
  const t = useAppTranslation();

  const columns = [
    {
      key: "selectControlAreas",
      header: t("selectControlAreas"),
      renderCell: ({row}) => row.number + ". " + row.title,
    },
  ];

  return (
    <>
      <Table
        rows={foodInspectionControlAreas}
        columns={columns}
        getRowKey={(x) => x.id}
      >
        <TableHead>
          <TableHeaderRow>
            {(columns) => (
              <>
                <TableHeader />
                {columns.map((x) => (
                  <TableHeader key={x.key} column={x}>
                    {x.header}
                  </TableHeader>
                ))}
                <TableHeader />
              </>
            )}
          </TableHeaderRow>
        </TableHead>
        <TableBody>
          {(rows) =>
            rows.map((r) => (
              <ControlAreaQuestionExpandableContent
                key={r.key}
                r={r}
                columns={columns}
                selected={selectedControlAreas}
                toggle={toggleControlAreaQuestion}
                toggleAll={toggleAllControlAreaQuestions}
              />
            ))
          }
        </TableBody>
      </Table>
    </>
  );
}

function ControlAreaQuestionExpandableContent({
  r,
  toggle,
  toggleAll,
  selected,
}) {
  const currentSelected = selected.find((x) => x.id === r.row.id);

  const allSelected =
    currentSelected?.questions.length === r.row.questions.length;

  return (
    <>
      <TableRow row={r} key={r.key}>
        <TableExpandRowCell />
        {r.cells.map((c) => (
          <TableCell key={c.key}>{c.value}</TableCell>
        ))}
        <TableRowActionsCell>
          <div className="d-flex flex-row justify-content-end align-items-center">
            <Switch
              checked={allSelected}
              onChange={() => toggleAll(r.row.id)}
              indeterminate={
                currentSelected?.questions.length > 0 && !allSelected
              }
            />
          </div>
        </TableRowActionsCell>
      </TableRow>
      <TableExpandedRow row={r} indent={true} colSpan={r.cells.length + 2}>
        <ControlAreaQuestionsTable
          questions={r.row.questions}
          selected={currentSelected?.questions}
          toggleSingle={(questionId) => toggle(r.row.id, questionId)}
        />
      </TableExpandedRow>
    </>
  );
}

function ControlAreaQuestionsTable({questions, selected, toggleSingle}) {
  const columns = [
    {
      key: "title",
      renderCell: ({row}) => row.number + ". " + row.title,
    },
  ];

  return (
    <Table
      rows={questions}
      columns={columns}
      getRowKey={(x) => x.id}
      borderless
    >
      <TableBody>
        {(rows) =>
          rows.map((r) => {
            return (
              <Fragment key={r.key}>
                <TableRow key={r.key} row={r} invalid={!r.row.title}>
                  {r.cells.map((c) => (
                    <TableCell key={c.key}>{c.value}</TableCell>
                  ))}
                  <TableRowActionsCell>
                    <Switch
                      checked={selected?.includes(r.row.id)}
                      onChange={() => toggleSingle(r.row.id)}
                    />
                  </TableRowActionsCell>
                </TableRow>
              </Fragment>
            );
          })
        }
      </TableBody>
    </Table>
  );
}
