import {useMemo} from "react";
import {
  useCurrentVisit,
  useMergeCurrentVisitInput,
} from "../InspectionVisitRoute";
import {ControlCause} from "../../Common/ControlCause";
import {FollowUpAssessment} from "../../Common/FollowUpAssessment";
import {FollowUpAssessmentItem} from "./FollowUpAssessmentItem";
import {Panel, Tooltip} from "@sokigo/components-react-bootstrap";
import {v4 as uuid} from "uuid";

export function FollowUpControlForm() {
  const {
    visit: {
      input: {foodControlOccasion2024},
      baseDataSnapshot: {foodInspectionControlAreas},
      foodInspection24Options: {foodFacilityTypes},
    },
  } = useCurrentVisit();
  const merge = useMergeCurrentVisitInput();

  const irregularities = useMemo(() => {
    const foodFacilityTypeIds = foodControlOccasion2024.controls
      .filter((c) => c.cause === ControlCause.FollowUp)
      .map((c) => c.foodFacilityTypeId);

    const irregularities = [];
    foodFacilityTypes
      .filter((f) => foodFacilityTypeIds.includes(f.id))
      .forEach((f) => {
        f.irregularities.forEach((i) => {
          const followUp = foodControlOccasion2024.controls
            .find(
              (c) =>
                c.cause === ControlCause.FollowUp &&
                c.foodFacilityTypeId === f.id
            )
            .followUps?.find((followUp) => followUp.irregularityId === i.id);

          irregularities.push({
            ...i,
            value: followUp?.value ?? FollowUpAssessment.NotAssessed,
            followUpId: followUp?.followUpId,
            foodFacilityTypeName: f.name,
            foodFacilityTypeId: f.id,
          });
        });
      });
    return irregularities;
  }, [foodControlOccasion2024.controls, foodFacilityTypes]);

  const currentQuestions = useMemo(
    () =>
      foodInspectionControlAreas
        .flatMap((a) => a.questions)
        .filter((q) => irregularities.map((i) => i.questionId).includes(q.id)),
    [foodInspectionControlAreas, irregularities]
  );

  function onChange(irregularity) {
    const followUp = {
      followUpId: irregularity.followUpId ?? uuid(),
      irregularityId: irregularity.id,
      questionId: irregularity.questionId,
      value: irregularity.value,
    };
    const control = foodControlOccasion2024.controls.find(
      (c) =>
        c.cause === ControlCause.FollowUp &&
        c.foodFacilityTypeId === irregularity.foodFacilityTypeId
    );

    if (!control.followUps) {
      control.followUps = [];
    }
    if (
      control.followUps.find((a) => a.followUpId === followUp.followUpId) !==
      undefined
    ) {
      control.followUps = control.followUps.map((x) =>
        x.followUpId === followUp.followUpId ? followUp : x
      );
    } else {
      control.followUps.push(followUp);
    }
    merge({
      foodControlOccasion2024: {
        ...foodControlOccasion2024,
        controls: foodControlOccasion2024.controls.map((x) =>
          x.id === control.id ? control : x
        ),
      },
    });
  }

  return (
    <>
      {currentQuestions?.map((question) => (
        <div
          key={question.id}
          className="d-flex flex-row align-items-center rounded bg-normal my-3"
        >
          <Panel className="p-3">
            <div
              key={question.id}
              className="d-flex flex-row justify-content-between align-items-center mb-4"
            >
              <Tooltip text={question.title}>
                <h4 className="text-truncate">
                  {question.number}: {question.title}
                </h4>
              </Tooltip>
            </div>
            <div>
              {irregularities
                ?.filter((i) => i.questionId === question.id)
                .map((irr, i, arr) => (
                  <FollowUpAssessmentItem
                    key={irr.id}
                    irregularity={irr}
                    isLast={i === arr.length - 1}
                    onChange={onChange}
                  />
                ))}
            </div>
          </Panel>
        </div>
      ))}
    </>
  );
}
