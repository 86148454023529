import {
  faCheckDouble,
  faInfoCircle,
  faStickyNote,
  faTasks,
} from "@fortawesome/pro-regular-svg-icons";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {getValidationErrors} from "@sokigo-sbwebb/validation";
import {Tab, Tabs} from "@sokigo/components-react-bootstrap";
import {useState} from "react";
import {Controls} from "./VisitFormEditor/Controls/Controls";
import {General} from "./VisitFormEditor/General";
import {Sections} from "./VisitFormEditor/Sections";
import {ProtocolTexts} from "./VisitFormEditor/ProtocolTexts/ProtocolTexts";

const TabContent = ({
  selected,
  visitForm,
  validationErrors,
  onChange,
  selectedControlAreas,
  toggleControlAreaQuestion,
  toggleAllControlAreaQuestions,
}) => {
  switch (selected) {
    case "general":
      return (
        <General
          visitForm={visitForm}
          validationErrors={validationErrors}
          onChange={onChange}
        />
      );
    case "sections":
      return (
        <Sections
          visitForm={visitForm}
          validationErrors={validationErrors}
          onChange={(sections) => onChange({...visitForm, sections})}
        />
      );
    case "controls":
      return (
        <Controls
          visitForm={visitForm}
          validationErrors={validationErrors}
          onChange={onChange}
          selectedControlAreas={selectedControlAreas}
          toggleControlAreaQuestion={toggleControlAreaQuestion}
          toggleAllControlAreaQuestions={toggleAllControlAreaQuestions}
        />
      );
    case "protocolTexts":
      return (
        <ProtocolTexts
          protocolTexts={visitForm.protocolTexts || {}}
          onChange={(protocolTexts) => onChange({...visitForm, protocolTexts})}
          validationErrors={getValidationErrors(
            validationErrors,
            "protocolTexts"
          )}
        />
      );
    default:
      return (
        <General
          visitForm={visitForm}
          validationErrors={validationErrors}
          onChange={onChange}
        />
      );
  }
};

const EditVisitFormTabs = ({selected, onSelect, visitForm}) => {
  const t = useAppTranslation();
  return (
    <>
      <Tabs vertical square selected={selected} onSelect={onSelect}>
        <Tab
          id="general"
          headerText={t("visitFormGeneralTab")}
          icon={faInfoCircle}
        />
        <Tab
          id="sections"
          headerText={t("visitFormSectionsTab")}
          icon={faTasks}
        />
        {visitForm?.sections?.controlAreas && (
          <Tab
            id="controls"
            headerText={t("visitFormControlsTab")}
            icon={faCheckDouble}
          />
        )}
        {visitForm?.sections?.notes && (
          <Tab
            id="protocolTexts"
            headerText={t("visitFormProtocolTextsTab")}
            icon={faStickyNote}
          />
        )}
      </Tabs>
    </>
  );
};

export const EditVisitForm = ({
  visitForm,
  validationErrors,
  onChange,
  selectedControlAreas,
  toggleControlAreaQuestion,
  toggleAllControlAreaQuestions,
}) => {
  const [selected, setSelected] = useState("general");

  return (
    <div className="row">
      <div className="col-md-3 col-xl-2">
        <EditVisitFormTabs
          selected={selected}
          onSelect={setSelected}
          visitForm={visitForm}
        />
      </div>
      <div className="col-md-9">
        <TabContent
          selected={selected}
          visitForm={visitForm}
          validationErrors={validationErrors}
          onChange={onChange}
          selectedControlAreas={selectedControlAreas}
          toggleControlAreaQuestion={toggleControlAreaQuestion}
          toggleAllControlAreaQuestions={toggleAllControlAreaQuestions}
        />
      </div>
    </div>
  );
};
