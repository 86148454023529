import {faBars} from "@fortawesome/pro-regular-svg-icons";
import {Button} from "@sokigo/components-react-bootstrap";
import {useCallback, useState} from "react";
import styles from "./HamburgerMenu.module.scss";

export function HamburgerMenu({children, tooltipText}) {
  const [showing, setShowing] = useState(false);
  const onClose = useCallback(() => setShowing(false), [setShowing]);
  return (
    <div
      className="position-relative"
      style={{
        width: "fit-content",
        whiteSpace: "nowrap",
      }}
    >
      <Button
        icon={faBars}
        kind={showing ? "selected" : "ghost"}
        onClick={() => setShowing((x) => !x)}
        tooltipText={tooltipText}
        tooltipSide="right"
        size="lg"
      />
      <div
        className={[
          "position-absolute p-3 bg-normal rounded mt-1 shadow",
          styles.picker,
          !showing && "d-none",
        ]
          .filter((x) => x)
          .join(" ")}
        //style={{zIndex: 10000}}
      >
        {showing && children(onClose)}
      </div>
    </div>
  );
}
