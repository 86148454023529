import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {
  Table,
  TableBody,
  TableCell,
  TableExpandedRow,
  TableExpandRowCell,
  TableHead,
  TableHeader,
  TableHeaderRow,
  TableRow,
} from "@sokigo/components-react-bootstrap";
import {Fragment} from "react";
import {FillTableCellWithTruncatedText} from "../../../../../../common-components/FillTableCellWithTruncatedText";
import {DocumentsTable} from "../documents/DocumentsTable";
import {Assessment, getAssessment} from "./Assessment";
import styles from "./HistoricVisit.module.scss";

export function GenericInspectionInformation({visit}) {
  const t = useAppTranslation();
  return (
    <div className="d-flex flex-column p-4">
      <h5>{t("documents")}</h5>
      {visit.documents.length === 0 && <div>{t("noDocuments")}</div>}
      {visit.documents.length > 0 && (
        <DocumentsTable documents={visit.documents} size="sm" />
      )}
      <GenericInspectionResult
        result={visit.genericInspectionResult}
        note={visit.note}
      />
    </div>
  );
}

function GenericInspectionResult({
  result: {inspectionItems, totalAssessment, inspectionItemTitleHeader},
  note,
}) {
  const t = useAppTranslation();

  return (
    <>
      <h5 className="pt-4">{t("inspectionResult")}</h5>
      {inspectionItems.length === 0 && (
        <div className="ml-3">{t("noInspectionResults")}</div>
      )}
      {inspectionItems.length > 0 && (
        <GenericInspectionTable
          inspectionItems={inspectionItems}
          inspectionItemTitleHeader={inspectionItemTitleHeader}
        />
      )}
      <h5 className="pt-4">{t("assessment")}</h5>
      <Assessment assessment={totalAssessment} />
      {note && (
        <>
          <h5 className="pt-4">{t("note")}</h5>
          <div className={styles.note}>{note}</div>
        </>
      )}
    </>
  );
}

function GenericInspectionTable({inspectionItems, inspectionItemTitleHeader}) {
  const t = useAppTranslation();

  const columns = [
    {
      key: "title",
      field: "title",
      header: inspectionItemTitleHeader,
    },
    {
      key: "assessment",
      renderCell: (r) => getAssessment(r.row.assessment, t),
      header: t("assessment"),
    },
    {
      key: "note",
      renderCell: (r) => (
        <FillTableCellWithTruncatedText>
          {r.row.note}
        </FillTableCellWithTruncatedText>
      ),
      header: t("note"),
    },
  ];

  return (
    <Table
      rows={inspectionItems}
      columns={columns}
      getRowKey={(x) => x.rowId}
      size="sm"
    >
      <TableHead>
        <TableHeaderRow>
          {(columns) => (
            <>
              <TableHeader />
              {columns.map((x) => (
                <TableHeader key={x.key} column={x}>
                  {x.header}
                </TableHeader>
              ))}
            </>
          )}
        </TableHeaderRow>
      </TableHead>
      <TableBody>
        {(rows) =>
          rows.map((r) => (
            <Fragment key={r.key}>
              <TableRow row={r}>
                <TableExpandRowCell disabled={!r.row.note} single={true} />
                {r.cells.map((c) => (
                  <TableCell key={c.key}>{c.value}</TableCell>
                ))}
              </TableRow>
              <TableExpandedRow row={r}>
                <div className={"p-2 " + styles.note}>{r.row.note}</div>
              </TableExpandedRow>
            </Fragment>
          ))
        }
      </TableBody>
    </Table>
  );
}
