import {useState} from "react";

const useToggleFoodInspectionControlAreas = (options, defaultValue) => {
  const [selectedControlAreas, setSelected] = useState(defaultValue || []);

  const toggleControlAreaQuestion = (controlAreaId, questionId) => {
    const isInSelected = selectedControlAreas.find(
      (x) => x.id === controlAreaId
    );
    if (isInSelected) {
      setSelected((cur) =>
        cur.map((ca) =>
          ca.id === controlAreaId
            ? {
                ...ca,
                questions: ca.questions.includes(questionId)
                  ? ca.questions.filter((x) => x !== questionId)
                  : [...ca.questions, questionId],
              }
            : ca
        )
      );
    } else {
      setSelected((cur) => [
        ...cur,
        {
          id: controlAreaId,
          questions: [questionId],
        },
      ]);
    }
  };

  const toggleAllControlAreaQuestions = (controlAreaId) => {
    const isInSelected = selectedControlAreas.find(
      (x) => x.id === controlAreaId
    );
    if (isInSelected) {
      setSelected((cur) =>
        cur
          .map((ca) =>
            ca.id === controlAreaId
              ? ca.questions.length ===
                options.find((x) => x.id === controlAreaId).questions.length
                ? null
                : {
                    ...ca,
                    questions: options
                      .find((x) => x.id === controlAreaId)
                      .questions.slice(0),
                  }
              : ca
          )
          .filter((x) => x)
      );
    } else {
      setSelected((cur) => [
        ...cur,
        {
          id: controlAreaId,
          questions: options
            .find((x) => x.id === controlAreaId)
            .questions.slice(0),
        },
      ]);
    }
  };

  const clear = () => setSelected([]);
  return [
    selectedControlAreas,
    toggleControlAreaQuestion,
    toggleAllControlAreaQuestions,
    clear,
  ];
};

export default useToggleFoodInspectionControlAreas;
