import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {Select, Control} from "@sokigo/components-react-bootstrap";
import {useServerState} from "../../../../ServerStateProvider";

export function CaseWorkerPicker({value, onChange}) {
  const t = useAppTranslation();
  const {
    baseData: {caseWorkers},
  } = useServerState();
  return (
    <Control className="mb-3">
      <Select
        onChange={(v) => onChange(v)}
        options={caseWorkers}
        value={value}
        placeholder={t("selectCaseWorker")}
        getOptionKey={(x) => x.id}
        getOptionValue={(x) => x.id}
        getOptionText={(x) => x.name}
      />
    </Control>
  );
}
