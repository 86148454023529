import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {Control, Panel, TextArea} from "@sokigo/components-react-bootstrap";

import {useCompletingInformationProvider} from "../CompletingInformationTab";
import {AssessmentSwitch} from "../../../AssessmentSwitch";
import {InspectionAssessment} from "../../../../Common/InspectionAssessment";

export function OverallAssessmentSection() {
  const {onChange, value} = useCompletingInformationProvider();

  const t = useAppTranslation();
  return (
    <Panel className="mt-3 pt-3 pb-1">
      <h4 className="px-3 ">{t("assessment")}</h4>
      <div className="m-3">
        <div className="mb-2">
          <Control labelText={t("assessment")} horizontal>
            <AssessmentSwitch
              value={
                value?.overallAssessment || InspectionAssessment.NotAssessed
              }
              onChange={(overallAssessment) =>
                onChange({...value, overallAssessment})
              }
            />
          </Control>
        </div>
        <Control labelText={t("note")} horizontal>
          <TextArea
            value={value?.occurrenceNote ?? ""}
            onChange={(occurrenceNote) => onChange({...value, occurrenceNote})}
          />
        </Control>
      </div>
    </Panel>
  );
}
