import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {Tab, Tabs} from "@sokigo/components-react-bootstrap";
import {useCurrentVisit} from "../InspectionVisitRoute";
import {FacilityDetailsTab} from "./FacilityDetailsTab";
import {InformationTab} from "./InformationTab";

export function InformationRoute() {
  const {
    visit: {
      visitMetadata: {
        visitTargetInformation: {infoSections},
      },
    },
  } = useCurrentVisit();
  const t = useAppTranslation();

  return (
    <>
      {Array.isArray(infoSections) && infoSections.length > 0 ? (
        <Tabs justified rounded className="mt-3 bg-normal">
          <Tab headerText={t("infoTab")} id="info">
            <InformationTab />
          </Tab>
          <Tab headerText={t("facilityDetailsTab")} id="facilityDetails">
            <FacilityDetailsTab />
          </Tab>
        </Tabs>
      ) : (
        <InformationTab />
      )}
    </>
  );
}
