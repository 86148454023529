import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {
  ButtonSwitch,
  ButtonSwitchOption,
} from "@sokigo/components-react-bootstrap";
import {FollowUpAssessment} from "../../Common/FollowUpAssessment";

export function FollowUpAssessmentSwitch({value, onChange, size}) {
  const t = useAppTranslation();
  return (
    <>
      <ButtonSwitch
        value={value ?? FollowUpAssessment.NotAssessed}
        onChange={(x) => onChange(x)}
        size={size}
      >
        <ButtonSwitchOption
          value={FollowUpAssessment.Remaining}
          tooltipText={t("remains")}
        >
          {t("remains")}
        </ButtonSwitchOption>
        <ButtonSwitchOption
          value={FollowUpAssessment.Resolved}
          tooltipText={t("fixed")}
        >
          {t("fixed")}
        </ButtonSwitchOption>
        <ButtonSwitchOption
          value={FollowUpAssessment.Dismissed}
          tooltipText={t("removed")}
        >
          {t("removed")}
        </ButtonSwitchOption>
        <ButtonSwitchOption
          value={FollowUpAssessment.NotAssessed}
          tooltipText={t("notAssessed")}
        >
          {t("notAssessed")}
        </ButtonSwitchOption>
      </ButtonSwitch>
    </>
  );
}
