import {Accordion, AccordionSection} from "@sokigo/components-react-bootstrap";
import {Fields} from "./Fields";

export function Items({items}) {
  return (
    <Accordion className="mt-3" border borderBetween square>
      {items.map(({title, fields, rowId}) => {
        return (
          <AccordionSection key={rowId} headerText={title}>
            <div className="py-3">
              <Fields fields={fields} />
            </div>
          </AccordionSection>
        );
      })}
    </Accordion>
  );
}
