import {
  Accordion,
  AccordionSection,
  Label,
} from "@sokigo/components-react-bootstrap";
import {FollowUpAssessmentSwitch} from "./FollowUpAssessmentSwitch";
import {ConfirmAsssessmentActionModal} from "./ConfirmAssessmentActionModal";
import {useState} from "react";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {FollowUpAssessment} from "../../Common/FollowUpAssessment";

export function FollowUpAssessmentItem({irregularity, isLast, onChange}) {
  const t = useAppTranslation();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationText, setConfirmationText] = useState([]);
  const [onConfirmationOkay, setOnConfirmationOkay] = useState();
  const [expanded, setExpanded] = useState([true]);

  function setFollowUpAssessment(assessment) {
    irregularity.value = assessment;
    onChange(irregularity);
    setShowConfirmation(false);
  }

  function openConfirmationAssessmentChanged(assessment) {
    if (
      assessment === irregularity.value ||
      irregularity.value === FollowUpAssessment.NotAssessed
    ) {
      setFollowUpAssessment(assessment);
    } else {
      if (assessment === FollowUpAssessment.NotAssessed && irregularity.value) {
        setConfirmationText([t("confirmRemoveAssessment")]);
      } else {
        setConfirmationText([t("confirmChangeAssessment")]);
      }

      setOnConfirmationOkay(() => () => {
        setFollowUpAssessment(assessment);
      });
      setShowConfirmation(true);
    }
  }

  return (
    <>
      <div key={irregularity.id}>
        <Accordion
          side={"left"}
          expanded={expanded}
          onExpand={(x) => {
            setExpanded(x);
          }}
        >
          <AccordionSection
            headerContent={
              <>
                <FollowUpAssessmentSwitch
                  value={irregularity.value}
                  onChange={openConfirmationAssessmentChanged}
                  size={"sm"}
                ></FollowUpAssessmentSwitch>
                <Label className="ml-2" style={{verticalAlign: "middle"}}>
                  {irregularity.foodFacilityTypeName}
                </Label>
              </>
            }
          >
            <div
              className="mt-3 ml-7 mr-7"
              style={{whiteSpace: "pre-line", paddingLeft: "3px"}}
            >
              {irregularity.note}
            </div>
          </AccordionSection>
        </Accordion>

        {!isLast && (
          <hr style={{borderStyle: "dashed"}} className="mt-4 mb-4" />
        )}
      </div>
      <ConfirmAsssessmentActionModal
        show={showConfirmation}
        header={t("confirm")}
        text={confirmationText}
        onOkay={onConfirmationOkay}
        onCancel={() => setShowConfirmation(false)}
      />
    </>
  );
}
