import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {DeviceVisitCheckinStatus} from "../../sw-comms/device-inspections-repo/DeviceVisitCheckinStatus";

export function CheckinStatusIndicator({checkinStatus}) {
  const t = useAppTranslation();

  switch (checkinStatus) {
    case DeviceVisitCheckinStatus.CheckedOut:
      return t("checkedOut");
    case DeviceVisitCheckinStatus.CheckingIn:
      return t("checkingIn");
    case DeviceVisitCheckinStatus.Finishing:
      return t("finishing");
    case DeviceVisitCheckinStatus.DeletedOnServer:
      return t("deleted");
    case DeviceVisitCheckinStatus.Hijacked:
      return t("hijacked");
    case DeviceVisitCheckinStatus.OwnerChanged:
      return t("ownerChanged");
  }
  return <>Unknwon status: {checkinStatus}</>;
}
