import {Control, Select} from "@sokigo/components-react-bootstrap";
import {useServerState} from "../../../ServerStateProvider";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";

export function RolePicker({value, onChange, errors, label}) {
  const {
    baseData: {roles},
  } = useServerState();
  const t = useAppTranslation();

  return (
    <Control labelText={label} errors={errors}>
      <Select
        options={roles.sort((a, b) =>
          a.name.localeCompare(b.name, t("locale"))
        )}
        value={value}
        onChange={(id) => onChange(id)}
        getOptionKey={(x) => x.id}
        getOptionValue={(x) => x.id}
        getOptionText={(x) => x.name}
      />
    </Control>
  );
}
