import {faCheck, faPause, faTimes} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Spinner} from "@sokigo/components-react-bootstrap";
import {OfflineDocumentStatus} from "./OfflineDocumentStatus";

export function OfflineDocumentStatusIndicator({status}) {
  switch (status) {
    case OfflineDocumentStatus.Downloading:
      return <Spinner small inline />;
    case OfflineDocumentStatus.Available:
      return <FontAwesomeIcon icon={faCheck} className="text-success-alt" />;
    case OfflineDocumentStatus.Failed:
      return <FontAwesomeIcon icon={faTimes} className="text-error" />;
    case OfflineDocumentStatus.Pending:
    default:
      return <FontAwesomeIcon icon={faPause} className="text-info" />;
  }
}
