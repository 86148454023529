import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {
  Panel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableHeaderRow,
  TableRow,
  TableRowActionsCell,
} from "@sokigo/components-react-bootstrap";
import {Fragment} from "react";
import {useCurrentVisit} from "../InspectionVisitRoute";
import {AddCasePartyRoleButton} from "./AddCasePartyRoleButton";

export function CasePartiesTable() {
  const t = useAppTranslation();
  const {
    visit: {
      visitMetadata: {
        visitTargetInformation: {casePartyRoles},
      },
    },
  } = useCurrentVisit();

  const columns = [
    {header: t("name"), key: "name", renderCell: ({row}) => row.partyName},
    {
      header: t("nationalIdentityNumberShort"),
      key: "nationalIdentityNumber",
      renderCell: ({row}) => row.nationalIdentityNumber,
    },
    {
      header: t("role"),
      key: "role",
      renderCell: ({row}) => row.roleName,
    },
    {
      header: t("contact"),
      key: "contact",
      renderCell: ({row}) => row.contactName,
    },
    {
      header: t("email"),
      key: "email",
      renderCell: ({row}) => (
        <>
          {row.emailAddresses.map(
            ({emailAddress, flaggedAsRelevantForCasePartyRole}, i) => (
              <Fragment key={emailAddress}>
                {i !== 0 && <>, </>}{" "}
                {flaggedAsRelevantForCasePartyRole ? (
                  <strong>{emailAddress}</strong>
                ) : (
                  emailAddress
                )}
              </Fragment>
            )
          )}
        </>
      ),
    },
  ];

  return (
    <Panel className="mt-3 py-3">
      <h4 className="px-3 mb-3">{t("caseParties")}</h4>
      {!casePartyRoles || casePartyRoles?.length === 0 ? (
        <div className="px-3">{t("caseHasNoParties")}</div>
      ) : (
        <Table
          rows={casePartyRoles}
          columns={columns}
          getRowKey={(x) =>
            [x.partyId, x.contactId ?? "null", x.roleId].join("_")
          }
        >
          <TableHead>
            <TableHeaderRow>
              {(columns) => (
                <>
                  {columns.map((x) => (
                    <TableHeader key={x.key} column={x}>
                      {x.header}
                    </TableHeader>
                  ))}
                  <TableHeader />
                </>
              )}
            </TableHeaderRow>
          </TableHead>
          <TableBody>
            {(rows) =>
              rows.map((r) => (
                <Fragment key={r.key}>
                  <TableRow row={r}>
                    {r.cells.map((c) => (
                      <TableCell key={c.key}>{c.value}</TableCell>
                    ))}
                    <TableRowActionsCell>
                      <AddCasePartyRoleButton casePartyRole={r.row} />
                    </TableRowActionsCell>
                  </TableRow>
                </Fragment>
              ))
            }
          </TableBody>
        </Table>
      )}
    </Panel>
  );
}
