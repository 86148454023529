import {faCheck, faMinus, faTimes} from "@fortawesome/pro-regular-svg-icons";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {InspectionAssessment} from "../../Common/InspectionAssessment";

export function useGetAssessmentIcon() {
  return function (assessmentId, controlQuestionAssessmentByQuestionId) {
    if (controlQuestionAssessmentByQuestionId) {
      const assessmentValue =
        controlQuestionAssessmentByQuestionId[assessmentId];
      let icon;
      let className;
      switch (assessmentValue) {
        case InspectionAssessment.Acceptable:
          icon = faCheck;
          className = "text-success-alt";
          break;
        case InspectionAssessment.NotAcceptable:
          icon = faTimes;
          className = "text-danger";
          break;
        default:
          icon = faMinus;
          className = "text-muted";
      }
      return (
        <div className="d-flex align-items-center">
          <FontAwesomeIcon icon={icon} className={className} fixedWidth />
        </div>
      );
    }
    return (
      <div className="d-flex align-items-center">
        <FontAwesomeIcon icon={faMinus} className="text-muted" fixedWidth />
      </div>
    );
  };
}
