import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {
  Table,
  TableBody,
  TableCell,
  TableExpandedRow,
  TableExpandRowCell,
  TableRow,
} from "@sokigo/components-react-bootstrap";
import {Fragment} from "react";
import {
  useCurrentVisit,
  useCurrentVisitInput,
  useMergeCurrentVisitInput,
} from "../InspectionVisitRoute";
import {TextAreaSection} from "./TextAreaSection";

export function StaticParagraphs() {
  const t = useAppTranslation();
  const {
    visit: {
      visitFormSnapshot: {protocolTexts},
    },
  } = useCurrentVisit();

  const {staticParagraphValues} = useCurrentVisitInput();
  const merge = useMergeCurrentVisitInput();

  if (!protocolTexts?.staticParagraphs?.length > 0) {
    return null;
  }

  const {staticParagraphs} = protocolTexts;

  return (
    <>
      {staticParagraphs?.length > 0 && (
        <div className="mb-2">
          <h4>{t("staticParagraphs")}</h4>
          <Table
            rows={staticParagraphs}
            columns={[
              {
                key: "title",
                renderCell: ({row}) => row.title,
              },
            ]}
            getRowKey={(x) => x.id}
            borderless
          >
            <TableBody>
              {(rows) =>
                rows.map((r) => (
                  <Fragment key={r.key}>
                    <TableRow key={r.key} row={r}>
                      <TableExpandRowCell row={r} />
                      {r.cells.map((c) => (
                        <TableCell key={c.key}>{c.value}</TableCell>
                      ))}
                    </TableRow>
                    <TableExpandedRow row={r} colSpan={2}>
                      <TextAreaSection
                        value={staticParagraphValues[r.row.id] ?? ""}
                        onChange={(value) =>
                          merge({
                            staticParagraphValues: {
                              ...staticParagraphValues,
                              [r.row.id]: value,
                            },
                          })
                        }
                        phraseGroupIds={r.row.phraseGroupIds}
                      />
                    </TableExpandedRow>
                  </Fragment>
                ))
              }
            </TableBody>
          </Table>
        </div>
      )}
    </>
  );
}
