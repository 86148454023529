import {
  useApplicationBusy,
  useNotifications,
} from "@sokigo-sbwebb/default-core";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {getJson} from "@sokigo-sbwebb/fetch";
import {register} from "ol/proj/proj4";
import proj4 from "proj4";
import {createContext, useContext, useEffect, useMemo, useState} from "react";
import {useIsOnline} from "@sokigo-sbwebb/serviceworker";

proj4.defs(
  "EPSG:3006",
  "+proj=utm +zone=33 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +axis=neu"
);
proj4.defs(
  "EPSG:3007",
  "+proj=tmerc +lat_0=0 +lon_0=12 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"
);
proj4.defs(
  "EPSG:3008",
  "+proj=tmerc +lat_0=0 +lon_0=13.5 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"
);
proj4.defs(
  "EPSG:3009",
  "+proj=tmerc +lat_0=0 +lon_0=15 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"
);
proj4.defs(
  "EPSG:3010",
  "+proj=tmerc +lat_0=0 +lon_0=16.5 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"
);
proj4.defs(
  "EPSG:3011",
  "+proj=tmerc +lat_0=0 +lon_0=18 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"
);
proj4.defs(
  "EPSG:3012",
  "+proj=tmerc +lat_0=0 +lon_0=14.25 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"
);
proj4.defs(
  "EPSG:3013",
  "+proj=tmerc +lat_0=0 +lon_0=15.75 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"
);
proj4.defs(
  "EPSG:3014",
  "+proj=tmerc +lat_0=0 +lon_0=17.25 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"
);
proj4.defs(
  "EPSG:3015",
  "+proj=tmerc +lat_0=0 +lon_0=18.75 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"
);
proj4.defs(
  "EPSG:3016",
  "+proj=tmerc +lat_0=0 +lon_0=20.25 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"
);
proj4.defs(
  "EPSG:3017",
  "+proj=tmerc +lat_0=0 +lon_0=21.75 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"
);
proj4.defs(
  "EPSG:3018",
  "+proj=tmerc +lat_0=0 +lon_0=23.25 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"
);
proj4.defs(
  "EPSG:3019",
  "+proj=tmerc +lat_0=0 +lon_0=11.30827777777778 +k=1 +x_0=1500000 +y_0=0 +ellps=bessel +towgs84=414.1,41.3,603.1,-0.855,2.141,-7.023,0 +units=m +no_defs"
);

register(proj4); // register proj4 instance with ol

const MapSettingsContext = createContext(null);

export function MapSettingsProvider({children}) {
  const t = useAppTranslation();
  const {addErrorNotification} = useNotifications();
  const applicationBusy = useApplicationBusy();

  const isOnline = useIsOnline();
  const [mapSettings, setMapSettings] = useState(null);
  useEffect(() => {
    if (mapSettings || !isOnline) {
      return;
    }

    const ac = new AbortController();
    (async function () {
      try {
        applicationBusy(true);
        const result = await getJson("api/inspection/mapSettings", {
          signal: ac.sign,
        });
        setMapSettings(result);
      } catch (e) {
        addErrorNotification(t("couldNotGetMapInformation"));
      } finally {
        applicationBusy(false);
      }
    })();
    return () => ac.abort();
  }, [addErrorNotification, applicationBusy, t, mapSettings, isOnline]);

  const wmsSettings = useMemo(
    () => ({
      url:
        mapSettings &&
        (mapSettings.wmsUrl ?? document.baseURI + "api/inspection/mapProxy"),
      projection: mapSettings?.projection,
      params: {
        LAYERS: mapSettings && mapSettings.wmsLayers,
        VERSION: mapSettings && mapSettings.wmsVersion,
        STYLES: mapSettings && mapSettings.wmsStyles,
      },
    }),
    [mapSettings]
  );

  const ret = useMemo(
    () => ({mapSettings, wmsSettings}),
    [mapSettings, wmsSettings]
  );

  return (
    <MapSettingsContext.Provider value={ret}>
      {children}
    </MapSettingsContext.Provider>
  );
}

export function useMapSettings() {
  return useContext(MapSettingsContext);
}
