import {
  faCheck,
  faDumpsterFire,
  faEllipsisV,
  faQuestion,
  faTimes,
} from "@fortawesome/pro-regular-svg-icons";
import {useApplicationPermissions} from "@sokigo-sbwebb/application-services";
import {
  AccessDenied,
  ApplicationView,
  useConfirmDialog,
} from "@sokigo-sbwebb/default-components";
import {
  useApplicationBusy,
  useNotifications,
} from "@sokigo-sbwebb/default-core";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {deleteJson, putJson} from "@sokigo-sbwebb/fetch";
import {
  ActionsDropdown,
  ActionsDropdownItem,
  Alert,
  Button,
  Control,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Panel,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableHeaderRow,
  TableRow,
  TableRowActionsCell,
  Tooltip,
} from "@sokigo/components-react-bootstrap";
import moment from "moment";
import {Fragment, useState} from "react";
import useWindowDimensions from "../../../../common-components/useWindowDimensions";
import {useServerState} from "../../ServerStateProvider";
import {SaveToEcosStatus} from "../Common/SaveToEcosStatus";
import {
  EcosSaveOccurrenceStatusIndicator,
  EcosSaveProtocolStatusIndicator,
} from "../Home/EcosSaveStatusIndicator";
import {ShowVisitIdModal} from "../Common/ShowVisitIdModal";

function ChangeOwnerModal({visit, onSave, onClose}) {
  const t = useAppTranslation();
  const [saving, setSaving] = useState(false);

  const {
    baseData: {caseWorkers},
  } = useServerState();

  const [selectedCaseworkerId, setSelectedCaseworkerId] = useState(
    visit.ownerId
  );

  async function onOk() {
    setSaving(true);
    await onSave(selectedCaseworkerId);
    setSaving(false);
  }

  return (
    <Modal show onClose={onClose} static>
      <fieldset disabled={saving}>
        <ModalHeader>{t("changeVisitOwner")}</ModalHeader>
        <ModalBody>
          <Alert kind="warning" hideCloseButton className="mb-4" light>
            <h5>{t("changeOwnerWarningHeader")}</h5>
            {t("changeOwnerWarningBody")}
          </Alert>
          <Control labelText={t("newVisitOwner")}>
            <Select
              options={caseWorkers}
              getOptionValue={(x) => x.id}
              getOptionKey={(x) => x.id}
              getOptionText={(x) => x.name}
              value={selectedCaseworkerId}
              onChange={(x) => setSelectedCaseworkerId(x)}
            />
          </Control>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} className="mr-2" icon={faTimes}>
            {t("cancel")}
          </Button>
          <Button
            kind="primary"
            onClick={onOk}
            icon={faCheck}
            disabled={selectedCaseworkerId === visit.ownerId}
          >
            {t("ok")}
          </Button>
        </ModalFooter>
      </fieldset>
    </Modal>
  );
}

function useChangeVisitOwner() {
  const t = useAppTranslation();
  const [changingVisit, setChangingVisit] = useState(null);
  const {addErrorNotification} = useNotifications();
  const applicationBusy = useApplicationBusy();

  const {serverVisits, refreshServerVisits} = useServerState();

  function changeOwner(visitId) {
    setChangingVisit(serverVisits[visitId]);
  }

  async function onSave(newOwnerId) {
    try {
      applicationBusy(true);
      await putJson(
        "api/inspection/visit/" + changingVisit.id + "/owner",
        newOwnerId
      );
      await refreshServerVisits();
      setChangingVisit(null);
    } catch {
      addErrorNotification(t("couldNotChangeVisitOwner"));
    } finally {
      applicationBusy(false);
    }
  }

  const modal = !changingVisit ? null : (
    <ChangeOwnerModal
      visit={changingVisit}
      onSave={onSave}
      onClose={() => setChangingVisit(null)}
    />
  );

  return [changeOwner, modal];
}

export function InspectionManageAllVisitsRoute() {
  const t = useAppTranslation();
  const {hasPermission} = useApplicationPermissions();
  const canAccessPage = hasPermission("manageAllVisits");
  const applicationBusy = useApplicationBusy();
  const {addErrorNotification} = useNotifications();
  const [ConfirmModal, confirmDelete] = useConfirmDialog();
  const [showingVisitId, setShowingVisitId] = useState(null);

  const {windowWidth} = useWindowDimensions();
  const cellHeaderMaxWidth =
    windowWidth < 576
      ? `20px` //xs
      : windowWidth < 769
      ? `30px` //sm
      : windowWidth < 992
      ? `70px` //md
      : `300px`; //lg , xl

  const {
    baseData: {caseWorkers},
    serverVisits,
    refreshServerVisits,
  } = useServerState();
  const serverVisitsList = Object.keys(serverVisits).map(
    (x) => serverVisits[x]
  );

  const [changeVisitOwner, changeVisitOwnerModal] = useChangeVisitOwner();

  if (!canAccessPage) {
    return <AccessDenied />;
  }

  async function clearSuccessfullyFinishedVisitsAsAdmin() {
    try {
      applicationBusy(true);
      await deleteJson(
        "api/inspection/visit/clearSuccessfullyFinishedVisitsAsAdmin"
      );
      refreshServerVisits();
      return true;
    } catch (err) {
      addErrorNotification(
        t("successfullyFinishedVisitsCouldNotBeClearedHeader")
      );
      return false;
    } finally {
      applicationBusy(false);
    }
  }

  async function removeUnsuccessfullyFinishedVisitAsAdmin(id) {
    try {
      if (!(await confirmDelete())) {
        return;
      }
      applicationBusy(true);
      await deleteJson(
        `api/inspection/visit/removeUnsuccessfullyFinishedVisitAsAdmin/${id}`
      );
      refreshServerVisits();
      return true;
    } catch (err) {
      addErrorNotification(t("visitCouldNotBeDeletedHeader"));
      return false;
    } finally {
      applicationBusy(false);
    }
  }

  const columns = [
    {
      key: "createdDate",
      header: t("date"),
      field: "createdDate",
    },
    {
      key: "visitTarget",
      header: t("description"),
      renderCell: ({row}) => (
        <Tooltip text={row.visitTargetDescription}>
          <div
            style={{
              maxWidth: cellHeaderMaxWidth,
              minWidth: "20px",
            }}
          >
            <div className="text-truncate">{row.visitTargetDescription}</div>
          </div>
        </Tooltip>
      ),
    },
    {
      key: "user",
      header: t("user"),
      field: "user",
    },
    {
      key: "checkedOut",
      header: t("checkedOut"),
      field: "checkedOut",
    },
    {
      key: "lastSaved",
      header: t("lastSaved"),
      field: "lastSaved",
    },
    {
      key: "saveStatusOccurrence",
      header: t("occurrence"),
      renderCell: ({row}) =>
        row.saveToEcosStatus !== SaveToEcosStatus.VisitNotFinished &&
        row.saveToEcosStatus !==
          SaveToEcosStatus.VisitWithValidationErrorsReopened && (
          <EcosSaveOccurrenceStatusIndicator
            saveToEcosStatus={row.saveToEcosStatus}
          />
        ),
    },
    {
      key: "saveStatusProtocol",
      header: t("protocol"),
      renderCell: ({row}) =>
        row.saveToEcosStatus !== SaveToEcosStatus.VisitNotFinished &&
        row.saveToEcosStatus !==
          SaveToEcosStatus.VisitWithValidationErrorsReopened && (
          <EcosSaveProtocolStatusIndicator
            saveToEcosStatus={row.saveToEcosStatus}
          />
        ),
    },
  ];

  const serverVisitsListWithParsedData = serverVisitsList.map((x) => ({
    ...x,
    createdDate: moment(x.createdDate).format("YYYY-MM-DD"),
    user:
      caseWorkers.find((cw) => cw.id === x.ownerId)?.name ?? t("unknownUser"),
    checkedOut: x.checkoutId ? t("yes") : t("no"),
    lastSaved: t("lastSavedDateAndDevice", {
      date: moment(x.lastSavedDate).format("YYYY-MM-DD"),
      device: x.lastSavedDevice,
    }),
  }));

  return (
    <ApplicationView className="p-3" style={{maxWidth: 1440}}>
      <Panel className="py-3">
        <div className="d-flex justify-content-between">
          <h3 className="mb-3 px-3">{t("manageAllVisits")}</h3>
          <div>
            <ActionsDropdown
              icon={faEllipsisV}
              kind="ghost"
              size="sm"
              className="mr-2"
            >
              <ActionsDropdownItem
                kind="danger-hover-ghost"
                onClick={() => clearSuccessfullyFinishedVisitsAsAdmin()}
                itemText={t("clearSuccessfullySavedVisits")}
                icon={faDumpsterFire}
              />
            </ActionsDropdown>
          </div>
        </div>

        <Table
          rows={serverVisitsListWithParsedData}
          columns={columns}
          getRowKey={(x) => x.id}
          sortable
        >
          <TableHead>
            <TableHeaderRow>
              {(columns) => (
                <>
                  {columns.map((headerColumn) => (
                    <TableHeader key={headerColumn.key} column={headerColumn}>
                      <div
                        key={headerColumn.key}
                        style={{maxWidth: cellHeaderMaxWidth, minWidth: "15px"}}
                      >
                        <div className="text-truncate">
                          {headerColumn.header}
                        </div>
                      </div>
                    </TableHeader>
                  ))}
                  <TableHeader />
                </>
              )}
            </TableHeaderRow>
          </TableHead>
          <TableBody>
            {(rows) =>
              rows.map((r) => (
                <Fragment key={r.key}>
                  <TableRow key={r.key} row={r}>
                    {r.cells.map((c) => (
                      <TableCell key={c.key}>{c.value}</TableCell>
                    ))}
                    <TableRowActionsCell>
                      <ActionsDropdown
                        size="sm"
                        icon={faEllipsisV}
                        kind="ghost"
                        className="ml-2"
                      >
                        {r.row.saveToEcosStatus !==
                          SaveToEcosStatus.Success && (
                          <>
                            <ActionsDropdownItem
                              itemText={t("remove")}
                              onClick={() =>
                                removeUnsuccessfullyFinishedVisitAsAdmin(
                                  r.row.id
                                )
                              }
                            />
                            {(r.row.saveToEcosStatus ===
                              SaveToEcosStatus.VisitNotFinished ||
                              r.row.saveToEcosStatus ===
                                SaveToEcosStatus.VisitWithValidationErrorsReopened ||
                              r.row.saveToEcosStatus ===
                                SaveToEcosStatus.CreateOccurrenceValidationError) && (
                              <ActionsDropdownItem
                                itemText={t("changeVisitOwner")}
                                onClick={() => changeVisitOwner(r.row.id)}
                              />
                            )}
                          </>
                        )}
                        <ActionsDropdownItem
                          itemText={t("showVisitId")}
                          onClick={() => setShowingVisitId(r.row.id)}
                          icon={faQuestion}
                        />
                      </ActionsDropdown>
                    </TableRowActionsCell>
                  </TableRow>
                </Fragment>
              ))
            }
          </TableBody>
        </Table>
      </Panel>
      {changeVisitOwnerModal}
      <ConfirmModal key="deleteVisitConfirmationMessage">
        {t("deleteVisitConfirmationMessage")}
      </ConfirmModal>
      <ShowVisitIdModal
        visitId={showingVisitId}
        onClose={() => setShowingVisitId(null)}
      />
    </ApplicationView>
  );
}
