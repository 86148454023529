import {useMemo} from "react";
import {useServiceWorkerRepository} from "../useServiceWorkerRepository";

export function useVisitFormsRepository() {
  const {items, createItem, deleteItem, updateItem, updateItems, refresh} =
    useServiceWorkerRepository("inspection-types");

  const v = useMemo(
    () => ({
      visitForms: items,
      addVisitForm: createItem,
      removeVisitForm: deleteItem,
      updateVisitForm: updateItem,
      setVisitForms: updateItems,
      refreshVisitForms: refresh,
    }),
    [items, createItem, deleteItem, updateItem, updateItems, refresh]
  );

  return v;
}
