import {SaveAsType} from "../SaveAsType";
import {FoodInspectionControlAreas} from "./FoodInspectionControlAreas";
import {NonFoodInspectionControlAreas} from "./NonFoodInspectionControlAreas";

export function Controls({
  visitForm,
  onChange,
  selectedControlAreas,
  toggleControlAreaQuestion,
  toggleAllControlAreaQuestions,
}) {
  return (
    <>
      {(visitForm.saveAsType === SaveAsType.FoodInspection ||
        visitForm.saveAsType === SaveAsType.FoodInspection24) && (
        <FoodInspectionControlAreas
          visitForm={visitForm}
          onChange={(foodInspectionControlAreas) =>
            onChange({...visitForm, foodInspectionControlAreas})
          }
          selectedControlAreas={selectedControlAreas}
          toggleControlAreaQuestion={toggleControlAreaQuestion}
          toggleAllControlAreaQuestions={toggleAllControlAreaQuestions}
        />
      )}
      {visitForm.saveAsType !== SaveAsType.FoodInspection &&
        visitForm.saveAsType !== SaveAsType.FoodInspection24 && (
          <NonFoodInspectionControlAreas
            visitForm={visitForm}
            onChange={(controlAreas) => onChange({...visitForm, controlAreas})}
          />
        )}
    </>
  );
}
