import {createContext, useContext} from "react";

const AttachmentsContext = createContext({
  newAttachments: [],
  allAttachments: [],
  deleteAttachment: () => {},
  changeAttachment: () => {},
  removePosition: () => {},
});

export function AttachmentsProvider({children, value}) {
  return (
    <AttachmentsContext.Provider value={value}>
      {children}
    </AttachmentsContext.Provider>
  );
}

export function useAttachments() {
  return useContext(AttachmentsContext);
}
