import {
  faCheck,
  faEdit,
  faPlus,
  faTimes,
  faTrashAlt,
} from "@fortawesome/pro-regular-svg-icons";
import {HelpLink, useConfirmDialog} from "@sokigo-sbwebb/default-components";
import {useNotifications} from "@sokigo-sbwebb/default-core";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {
  getValidationErrorMessages,
  getValidationErrors,
  Validation,
} from "@sokigo-sbwebb/validation";
import {
  Button,
  Control,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Panel,
  Table,
  TableBody,
  TableCell,
  TableExpandedRow,
  TableExpandRowCell,
  TableReorderableRowCell,
  TableRow,
  TableRowActionsCell,
  TextArea,
  TextBox,
} from "@sokigo/components-react-bootstrap";
import {Fragment, useMemo, useState} from "react";
import {v4 as uuid} from "uuid";
import {useServerState} from "../../../ServerStateProvider";
import PhraseGroupSchema from "./PhraseGroupSchema.json";

function EditPhraseExpandedRow({phrase, updatePhrase, validationErrors}) {
  const t = useAppTranslation();
  return (
    <div className="p-3 mb-0">
      <Control
        labelText={t("phraseCode")}
        errors={getValidationErrorMessages(validationErrors, "code")}
      >
        <TextBox
          value={phrase.code ?? ""}
          onChange={(code) =>
            updatePhrase({
              ...phrase,
              code,
            })
          }
        />
      </Control>

      <Control
        labelText={t("phraseText")}
        errors={getValidationErrorMessages(validationErrors, "text")}
      >
        <TextArea
          rows={5}
          value={phrase.text ?? ""}
          onChange={(text) =>
            updatePhrase({
              ...phrase,
              text,
            })
          }
        />
      </Control>
    </div>
  );
}

function EditPhraseGroupModal({
  onClose,
  isCreation,
  defaultValue,
  onSave,
  disabled,
}) {
  const t = useAppTranslation();
  const [value, setValue] = useState(
    () => defaultValue ?? {title: "", id: uuid()}
  );
  const [expandedRowId, setExpandedRowId] = useState(null);
  const validation = useMemo(() => new Validation(PhraseGroupSchema), []);
  const validationErrors = useMemo(
    () => value && validation.validate(value, t),
    [value, validation, t]
  );

  function addPhrase() {
    const id = uuid();
    setValue((v) => ({
      ...v,
      phrases: [...(v.phrases ?? []), {id, code: "", text: ""}],
    }));
    setExpandedRowId(id);
  }
  function updatePhrase(phrase) {
    setValue((v) => ({
      ...v,
      phrases: v.phrases.map((p) => (p.id === phrase.id ? phrase : p)),
    }));
  }
  function removePhrase(id) {
    setValue((v) => ({
      ...v,
      phrases: v.phrases.filter((x) => x.id !== id),
    }));
  }

  const columns = [
    {
      key: "code",
      renderCell: ({row}) => <>{row.code}</>,
    },
  ];

  const canSave = validationErrors.length === 0;

  return (
    <Modal show onClose={onClose} size="xl" static>
      <fieldset disabled={disabled}>
        <ModalHeader>
          {isCreation ? t("editPhraseGroup") : t("addPhraseGroup")}
        </ModalHeader>
        <ModalBody className="px-0">
          <div className="row px-3" style={{width: "100%"}}>
            <div className="col">
              <Control
                labelText={t("title")}
                errors={getValidationErrorMessages(validationErrors, "title")}
              >
                <TextBox
                  value={value.title}
                  onChange={(title) => setValue((v) => ({...v, title}))}
                />
              </Control>
            </div>
          </div>
          <div className="d-flex flex-row justify-content-between align-items-center mb-1 px-3">
            <h4>{t("phrases")}</h4>
            <Button icon={faPlus} onClick={addPhrase} kind="primary">
              {t("addPhrase")}
            </Button>
          </div>
          <Table
            rows={value.phrases ?? []}
            columns={columns}
            onChange={(phrases) => setValue((v) => ({...v, phrases}))}
            getRowKey={(x) => x.id}
            expanded={expandedRowId ? [expandedRowId] : []}
            onExpand={(ids) => setExpandedRowId(ids.length > 0 ? ids[0] : null)}
          >
            <TableBody>
              {(rows) =>
                rows.map((r) => (
                  <Fragment key={r.key}>
                    <TableRow
                      key={r.key}
                      row={r}
                      invalid={
                        getValidationErrors(
                          validationErrors,
                          "phrases",
                          r.rowIndex
                        ).length > 0
                      }
                    >
                      <TableExpandRowCell row={r} single />
                      <TableReorderableRowCell />
                      {r.cells.map((c) => (
                        <TableCell key={c.key}>{c.value}</TableCell>
                      ))}
                      <TableRowActionsCell>
                        <Button
                          kind="danger-hover-ghost"
                          icon={faTrashAlt}
                          tooltipText={t("remove")}
                          size="sm"
                          onClick={() => removePhrase(r.key)}
                        />
                      </TableRowActionsCell>
                    </TableRow>
                    <TableExpandedRow
                      row={r}
                      colSpan={columns.length + 3}
                      indent
                    >
                      <EditPhraseExpandedRow
                        validationErrors={getValidationErrors(
                          validationErrors,
                          "phrases",
                          r.rowIndex
                        )}
                        phrase={r.row}
                        updatePhrase={updatePhrase}
                      />
                    </TableExpandedRow>
                  </Fragment>
                ))
              }
            </TableBody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} className="mr-2" icon={faTimes}>
            {t("cancel")}
          </Button>
          <Button
            kind="primary"
            onClick={() => onSave(value)}
            icon={faCheck}
            disabled={!canSave}
          >
            {t("ok")}
          </Button>
        </ModalFooter>
      </fieldset>
    </Modal>
  );
}

function CreatePhraseGroupButton() {
  const t = useAppTranslation();
  const [show, setShow] = useState(false);
  const {addPhraseGroup} = useServerState();
  const {addErrorNotification} = useNotifications();
  const [saving, setSaving] = useState(false);

  async function savePhraseGroup(phraseGroup) {
    try {
      setSaving(true);
      await addPhraseGroup(phraseGroup.id, phraseGroup);
      setShow(false);
    } catch (err) {
      addErrorNotification(t("savePhraseGroupFailed"));
      throw err;
    } finally {
      setSaving(false);
    }
  }
  return (
    <>
      {show && (
        <EditPhraseGroupModal
          onClose={() => setShow(false)}
          defaultValue={null}
          onSave={savePhraseGroup}
          disabled={saving}
        />
      )}
      <Button
        icon={faPlus}
        onClick={() => setShow(true)}
        kind="primary"
        className="ml-1"
      >
        {t("addPhraseGroup")}
      </Button>
    </>
  );
}

function EditPhraseGroupButton({phraseGroup}) {
  const {updatePhraseGroup} = useServerState();
  const t = useAppTranslation();
  const [show, setShow] = useState(false);
  const [saving, setSaving] = useState(false);
  const {addErrorNotification} = useNotifications();

  async function savePhraseGroup(phraseGroup) {
    try {
      setSaving(true);
      await updatePhraseGroup(phraseGroup.id, phraseGroup);
      setShow(false);
    } catch (err) {
      addErrorNotification(t("savePhraseGroupFailed"));
      throw err;
    } finally {
      setSaving(false);
    }
  }
  return (
    <>
      <Button
        kind="ghost"
        icon={faEdit}
        tooltipText={t("edit")}
        size="sm"
        onClick={(e) => {
          e.stopPropagation();
          setShow(true);
        }}
      />
      {show && (
        <EditPhraseGroupModal
          defaultValue={phraseGroup}
          onClose={() => setShow(false)}
          onSave={savePhraseGroup}
          disabled={saving}
        />
      )}
    </>
  );
}
function DeletePhraseGroupButton({phraseGroupId}) {
  const {removePhraseGroup} = useServerState();
  const t = useAppTranslation();
  async function remove() {
    const r = await confirmDelete();
    if (r) {
      await removePhraseGroup(phraseGroupId);
    }
  }
  const [ConfirmModal, confirmDelete] = useConfirmDialog();

  return (
    <>
      <Button
        kind="danger-hover-ghost"
        icon={faTrashAlt}
        tooltipText={t("remove")}
        size="sm"
        onClick={remove}
      />
      <ConfirmModal />
    </>
  );
}

function PhraseGroups() {
  const t = useAppTranslation();
  const {phraseGroups: phraseGroupsById} = useServerState();
  const phraseGroups = useMemo(
    () =>
      phraseGroupsById &&
      Object.keys(phraseGroupsById)
        .map((x) => phraseGroupsById[x])
        .sort((a, b) => a.title.localeCompare(b.title, t("locale"))),
    [phraseGroupsById, t]
  );

  if (!phraseGroups) {
    return null;
  }

  return (
    <>
      <Table
        columns={[{key: "title", renderCell: ({row}) => row.title}]}
        rows={phraseGroups}
        getRowKey={(x) => x.id}
      >
        <TableBody>
          {(rows) =>
            rows.map((r) => (
              <Fragment key={r.key}>
                <TableRow key={r.key} row={r}>
                  {r.cells.map((c) => (
                    <TableCell key={c.key}>{c.value}</TableCell>
                  ))}
                  <TableRowActionsCell>
                    <EditPhraseGroupButton
                      phraseGroup={phraseGroupsById[r.key]}
                    />
                    <DeletePhraseGroupButton phraseGroupId={r.key} />
                  </TableRowActionsCell>
                </TableRow>
              </Fragment>
            ))
          }
        </TableBody>
      </Table>
    </>
  );
}

export function AdminPhrasesView() {
  const t = useAppTranslation();
  return (
    <Panel className="mx-3 py-3">
      <div className="d-flex flex-row justify-content-between align-items-center mb-1 px-3">
        <h4>{t("phraseGroups")}</h4>
        <div>
          <HelpLink section="phraseGroups" />
          <CreatePhraseGroupButton />
        </div>
      </div>
      <PhraseGroups />
    </Panel>
  );
}
