import {faPlus} from "@fortawesome/pro-regular-svg-icons";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {
  ButtonSwitch,
  ButtonSwitchOption,
  Control,
  Button,
} from "@sokigo/components-react-bootstrap";
import {useState} from "react";
import {v4 as uuid} from "uuid";
import {DynamicParagraphsTable} from "./DynamicParagraphsTable";

export function DynamicParagraphsSection({
  onChange,
  protocolTexts: {dynamicParagraphs, allowDynamicParagraphs},
  validationErrors,
}) {
  const t = useAppTranslation();

  const [expandedParagraphId, setExpandedParagraphId] = useState(null);

  const addDynamicParagraph = () => {
    const newDynamicParagraph = {
      id: uuid(),
      title: t("newDynamicParagraph"),
      phraseGroupIds: [],
    };
    onChange({
      dynamicParagraphs: [...(dynamicParagraphs ?? []), newDynamicParagraph],
    });
    setExpandedParagraphId(newDynamicParagraph.id);
  };

  return (
    <>
      <div className="d-flex flex-column flex-sm-row justify-content-between mt-4 align-items-md-center">
        <h4>{t("dynamicParagraphs")}</h4>
        {allowDynamicParagraphs && (
          <Button icon={faPlus} onClick={addDynamicParagraph} kind="primary">
            {t("addDynamicParagraph")}
          </Button>
        )}
      </div>
      <div>
        <Control
          className="mt-4"
          labelText={t("allowDynamicParagraphs")}
          helpText={t("requiresTagInProtocol")}
        >
          <ButtonSwitch
            value={allowDynamicParagraphs || false}
            onChange={(allowDynamicParagraphs) => {
              onChange({allowDynamicParagraphs});
            }}
          >
            <ButtonSwitchOption value={true}>{t("yes")}</ButtonSwitchOption>
            <ButtonSwitchOption value={false}>{t("no")}</ButtonSwitchOption>
          </ButtonSwitch>
        </Control>
      </div>
      {allowDynamicParagraphs && (
        <div>
          <DynamicParagraphsTable
            onChange={(dynamicParagraphs) =>
              onChange({
                dynamicParagraphs,
              })
            }
            dynamicParagraphs={dynamicParagraphs}
            validationErrors={validationErrors}
            expandedParagraphId={expandedParagraphId}
            onExpand={setExpandedParagraphId}
          />
        </div>
      )}
    </>
  );
}
