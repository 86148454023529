import {faArrowUp} from "@fortawesome/pro-solid-svg-icons/faArrowUp";
import {faCamera} from "@fortawesome/pro-solid-svg-icons/faCamera";
import {keyBy} from "lodash-es";
import Collection from "ol/Collection";
import Feature from "ol/Feature";
import GeoJSON from "ol/format/GeoJSON";
import Point from "ol/geom/Point";
import Translate from "ol/interaction/Translate";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Fill from "ol/style/Fill";
import Icon from "ol/style/Icon";
import Stroke from "ol/style/Stroke";
import Style from "ol/style/Style";
import Text from "ol/style/Text";
import {useEffect} from "react";
import {useGeoLocation} from "../../../services/useGeoLocation";
import {useMapSettings} from "../../../services/useMapSettings";
import {CurrentPositionLayer} from "../../mapping/Mapping/CurrentPositionLayer";
import {DrawInteraction} from "../../mapping/Mapping/DrawInteraction";
import {MapContainer} from "../../mapping/Mapping/MapContainer";
import {MapProvider, useMap} from "../../mapping/Mapping/MapProvider";
import {TileWMSLayer} from "../../mapping/Mapping/TileWMSLayer";
import {useAttachments} from "./AttachmentsProvider";
import {getWgs84PositionInCoordinateSystemWithCoordinateOrderXY} from "../../mapping/Mapping/GetWgs84PositionInCoordinateSystemWithCoordinateOrderXY";

const rotateIconDefinition = faArrowUp.icon;
const rotateW = rotateIconDefinition[0];
const rotateH = rotateIconDefinition[1];
const rotatePath = rotateIconDefinition[4];
const rotateIcon =
  "data:image/svg+xml;utf8," +
  `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="${rotateW}" height="${rotateH}" viewBox="0 0 ${rotateW} ${rotateH}"><path d="${rotatePath}"/></svg>`;
const rotateIconTransparent =
  "data:image/svg+xml;utf8," +
  `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" fill="rgba(0, 0, 0, 0.5)" width="${rotateW}" height="${rotateH}" viewBox="0 0 ${rotateW} ${rotateH}"><path d="${rotatePath}"/></svg>`;
const moveIconDefinition = faCamera.icon;
const moveW = moveIconDefinition[0];
const moveH = moveIconDefinition[1];
const movePath = moveIconDefinition[4];
const moveIcon =
  "data:image/svg+xml;utf8," +
  `<svg version="1.1" xmlns="http://www.w3.org/2000/svg"  width="${moveW}" height="${moveH}" viewBox="0 0 ${moveW} ${moveH}"><path d="${movePath}"/></svg>`;

const moveIconTransparent =
  "data:image/svg+xml;utf8," +
  `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" fill="rgba(0, 0, 0, 0.5)" width="${moveW}" height="${moveH}" viewBox="0 0 ${moveW} ${moveH}"><path d="${movePath}"/></svg>`;

function RotateAttachmentsLayer({opaqueAttachment}) {
  const {
    newAttachments,
    changeAttachment,
    allAttachments: savedAttachments,
  } = useAttachments();
  const map = useMap();
  useEffect(() => {
    if (!map) {
      return;
    }

    const allAttachments = [...savedAttachments, ...newAttachments];
    const allAttachmentsById = keyBy(allAttachments, (x) => x.attachmentId);
    const geoJson = new GeoJSON();
    const source = new VectorSource();
    const layer = new VectorLayer({
      source,
      style: (feature) =>
        [
          new Style({
            image: new Icon({
              scale: 0.1,
              src:
                feature.get("attachmentId") === opaqueAttachment?.attachmentId
                  ? moveIcon
                  : moveIconTransparent,
              size: [moveW, moveH],
            }),
            text: new Text({
              text: "" + (feature.get("index") + 1),

              font: "12px sans-serif",
              fill: new Fill({
                color: "#000",
              }),
              stroke: new Stroke({
                color: "#fff",
                width: 3,
              }),
              offsetY: 4,
            }),
          }),
        ].filter((x) => x),
    });
    map.addLayer(layer);

    const allAttachmentFeatures = allAttachments
      .map(({position, heading, attachmentId}, index) => {
        if (!position) {
          return null;
        }
        const geometry = geoJson.readGeometry(position);
        const feature = new Feature({
          geometry,
          attachmentId,
          heading,
          index,
          type: "translate",
        });
        return feature;
      })
      .filter((x) => x);

    const allAttachmentFeaturesByAttachmentId = keyBy(
      allAttachmentFeatures,
      (x) => x.get("attachmentId")
    );

    source.addFeatures(allAttachmentFeatures);

    const moveInteractions = allAttachmentFeatures
      .filter((f) => {
        const attachmentId = f.get("attachmentId");
        return attachmentId === opaqueAttachment?.attachmentId;
      })
      .map((f) => {
        const translateInteraction = new Translate({
          features: new Collection([f]),
          hitTolerance: 7,
        });
        translateInteraction.on("translating", (evt) => {
          const translatedFeature = evt.features.getArray()[0];
          const attachmentId = translatedFeature.get("attachmentId");

          const geometry = translatedFeature.getGeometry();
          rotateHandleFeaturesByAttachmentId[attachmentId].setGeometry(
            geometry
          );
        });
        translateInteraction.on("translateend", (evt) => {
          const translatedFeature = evt.features.getArray()[0];
          const attachmentId = translatedFeature.get("attachmentId");
          const position = JSON.parse(
            geoJson.writeGeometry(translatedFeature.getGeometry())
          );
          const attachment = allAttachmentsById[attachmentId];
          if (newAttachments.includes(attachment)) {
            changeAttachment({...attachment, position});
          } else if (savedAttachments.includes(attachment)) {
            changeAttachment({...attachment, position});
          }
        });
        return translateInteraction;
      });

    moveInteractions.forEach((i) => map.addInteraction(i));

    const rotateSource = new VectorSource();
    const rotateLayer = new VectorLayer({
      source: rotateSource,
      style: (feature) => {
        return typeof feature.get("heading") !== "number"
          ? []
          : [
              new Style({
                image: new Icon({
                  scale: 0.1,
                  rotation: (feature.get("heading") * Math.PI) / 180,
                  src:
                    feature.get("attachmentId") ===
                    opaqueAttachment?.attachmentId
                      ? rotateIcon
                      : rotateIconTransparent,
                  size: [rotateW, rotateH],
                  anchor: feature.get("dragging") ? undefined : [0.5, 1.5],
                  color: "#BADA55",
                }),
              }),
            ];
      },
    });

    const rotateHandleFeatures = allAttachments
      .map(({position, heading, attachmentId}, index) => {
        if (!position) {
          return null;
        }
        const geometry = geoJson.readGeometry(position);
        const feature = new Feature({
          geometry,
          attachmentId,
          heading,
          index,
          type: "rotate",
        });
        return feature;
      })
      .filter((x) => x);
    rotateSource.addFeatures(rotateHandleFeatures);
    map.addLayer(rotateLayer);
    const rotateHandleFeaturesByAttachmentId = keyBy(
      rotateHandleFeatures,
      (x) => x.get("attachmentId")
    );

    const rotateInteractions = rotateHandleFeatures
      .filter((f) => {
        const attachmentId = f.get("attachmentId");
        return attachmentId === opaqueAttachment?.attachmentId;
      })
      .map((f) => {
        const rotateInteraction = new Translate({
          features: new Collection([f]),
          hitTolerance: 7,
        });
        function getHeading(rotatedFeature) {
          const attachmentId = rotatedFeature.get("attachmentId");
          const attachment = allAttachmentsById[attachmentId];
          const geometry = rotatedFeature.getGeometry();
          const coordinates = geometry.getCoordinates();
          const dx = coordinates[0] - attachment["position"].coordinates[0];
          const dy = coordinates[1] - attachment["position"].coordinates[1];
          const angle = Math.atan2(dx, dy);
          const heading = (angle * 180) / Math.PI;
          return Math.round(heading * 1) / 1;
        }
        rotateInteraction.on("translatestart", (evt) => {
          const f = evt.features.getArray()[0];
          f.set("dragging", true);
          // move feature to drag start location, since offset is disabled during move
          const startGeom = new Point(evt.startCoordinate);
          f.setGeometry(startGeom);
        });
        rotateInteraction.on("translating", (evt) => {
          const rotatedFeature = evt.features.getArray()[0];
          const heading = getHeading(rotatedFeature);
          const translateFeature =
            allAttachmentFeaturesByAttachmentId[
              rotatedFeature.get("attachmentId")
            ];
          translateFeature.set("heading", heading);
          rotatedFeature.set("heading", heading);
        });
        rotateInteraction.on("translateend", (evt) => {
          evt.features.getArray()[0].set("dragging", false);
          const rotatedFeature = evt.features.getArray()[0];

          const heading = getHeading(rotatedFeature);
          const attachmentId = rotatedFeature.get("attachmentId");
          const attachment = allAttachmentsById[attachmentId];
          if (newAttachments.includes(attachment)) {
            changeAttachment({...attachment, heading});
          } else if (savedAttachments.includes(attachment)) {
            changeAttachment({...attachment, heading});
          }
        });
        return rotateInteraction;
      });
    rotateInteractions.forEach((i) => map.addInteraction(i));

    return () => {
      rotateInteractions.forEach((x) => map.removeInteraction(x));
      moveInteractions.forEach((x) => map.removeInteraction(x));
      map.removeLayer(rotateLayer);
      map.removeLayer(layer);
    };
  }, [
    map,
    changeAttachment,
    newAttachments,
    savedAttachments,
    opaqueAttachment,
  ]);

  return null;
}

export function AttachmentsMap({onClick, opaqueAttachment}) {
  const {mapSettings, wmsSettings} = useMapSettings();
  const geoLocation = useGeoLocation();

  if (!mapSettings) {
    return null;
  }

  function getCurrentPosition() {
    if (!geoLocation?.longitude && !geoLocation?.latitude) {
      return null;
    }
    return {
      type: "Point",
      coordinates: getWgs84PositionInCoordinateSystemWithCoordinateOrderXY(
        [geoLocation?.longitude, geoLocation?.latitude],
        mapSettings.projection
      ),
    };
  }

  return (
    <>
      <MapProvider
        viewProjection={mapSettings?.projection}
        initialExtent={[
          mapSettings.extentW,
          mapSettings.extentS,
          mapSettings.extentE,
          mapSettings.extentN,
        ]}
        center={
          opaqueAttachment.position?.coordinates ??
          getCurrentPosition()?.coordinates
        }
        initialZoom={17}
      >
        {onClick && (
          <DrawInteraction
            options={{type: "Point"}}
            onDrawEnd={({feature}) => {
              const geometry = feature.getGeometry();
              const geoJson = new GeoJSON().writeGeometry(geometry);
              onClick(JSON.parse(geoJson));
            }}
          />
        )}

        <div
          className="d-flex"
          style={{
            minHeight: 400,
            position: "relative",
            touchAction: "none",
          }}
        >
          <MapContainer className="flex-grow-1" />
        </div>
        <TileWMSLayer options={wmsSettings} visible />
        <CurrentPositionLayer />
        <RotateAttachmentsLayer opaqueAttachment={opaqueAttachment} />
      </MapProvider>
    </>
  );
}
