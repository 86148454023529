import {createContext, useContext} from "react";
import {useServerState} from "../ServerStateProvider";

const PhraseGroupContext = createContext(null);
export function PhraseGroupProvider({children}) {
  // TODO: use useServerState directly instead
  const {phraseGroups} = useServerState();
  return (
    <PhraseGroupContext.Provider value={{phraseGroups: phraseGroups ?? {}}}>
      {children}
    </PhraseGroupContext.Provider>
  );
}

export function usePhraseGroups() {
  return useContext(PhraseGroupContext);
}
