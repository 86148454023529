import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {Control, Select} from "@sokigo/components-react-bootstrap";
import {useServerState} from "../../../../ServerStateProvider";

export function FacilityTypePicker({value, onChange, ...props}) {
  const t = useAppTranslation();
  const {baseData: {facilityTypes = []} = {}} = useServerState();
  const sorted = [...facilityTypes].sort((a, b) =>
    a.title.localeCompare(b.title)
  );

  return (
    <Control labelText={t("facilityType")} {...props}>
      <Select
        options={sorted}
        value={value}
        onChange={(v) => onChange(v)}
        getOptionKey={(x) => x.id}
        getOptionValue={(x) => x.id}
        getOptionText={(x) => x.title}
        placeholder={t("selectFacilityType")}
        clearable
      />
    </Control>
  );
}
