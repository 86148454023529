import {ApplicationView} from "@sokigo-sbwebb/default-components";
import {DeviceVisits} from "./DeviceVisits";
import {FinishedVisits} from "./FinishedVisits";
import {HomeSearch} from "./Search/HomeSearch";
import {ServerVisits} from "./ServerVisits";
import {VisitsOnOtherDevices} from "./VisitsOnOtherDevices";

export function Home() {
  return (
    <ApplicationView className="bg-container" style={{maxWidth: 1440}}>
      <div className="p-3">
        <HomeSearch />
        <DeviceVisits />
        <ServerVisits />
        <VisitsOnOtherDevices />
        <FinishedVisits />
      </div>
    </ApplicationView>
  );
}
