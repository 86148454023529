import {useCallback, useMemo} from "react";
import {
  useCurrentVisitInput,
  useMergeCurrentVisitInput,
} from "../../InspectionVisitRoute";

export function useCurrentOfflineDocumentIds() {
  const input = useCurrentVisitInput();

  const offlineDocumentIds = useMemo(
    () => input.offlineDocumentIds ?? [],
    [input]
  );

  const mergeCurrentVisitInput = useMergeCurrentVisitInput();
  const onChangeOfflineDocumentIds = useCallback(
    (offlineDocumentIds) => mergeCurrentVisitInput({offlineDocumentIds}),
    [mergeCurrentVisitInput]
  );

  return {
    offlineDocumentIds,
    onChangeOfflineDocumentIds,
  };
}
