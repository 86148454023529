export enum RepoMessageTypes {
  Items,
  GetItems,
  Refresh,
  DeleteItem,
  UpdateItem,
  UpdateItems,
  CreateItem,
  CreateItems,
}

export abstract class RepoMessage {
  protected constructor(type: RepoMessageTypes, repoName: string) {
    this.type = type;
    this.repoName = repoName;
    this.isRepoMessage = true;
  }
  isRepoMessage: true;
  repoName: string;
  type: RepoMessageTypes;
}
export abstract class RepoResponseMessage extends RepoMessage {
  protected constructor(
    type: RepoMessageTypes,
    repoName: string,
    success: boolean
  ) {
    super(type, repoName);
    this.success = success;
  }
  success: boolean;
}

export class RepoItemsBroadcastMessage<TValue> extends RepoMessage {
  constructor(items: {[id: string]: TValue}, repoName: string) {
    super(RepoMessageTypes.Items, repoName);
    this.items = items;
  }
  items: {[id: string]: TValue};
}

export class RepoRefreshRequest extends RepoMessage {
  constructor(repoName: string) {
    super(RepoMessageTypes.Refresh, repoName);
  }
}
export class RepoRefreshResponse extends RepoResponseMessage {
  constructor(repoName: string, success: boolean) {
    super(RepoMessageTypes.Refresh, repoName, success);
  }
}

export class RepoGetItemsRequest extends RepoMessage {
  constructor(repoName: string) {
    super(RepoMessageTypes.GetItems, repoName);
  }
}
export class RepoGetItemsResponse<TValue> extends RepoResponseMessage {
  constructor(
    success: boolean,
    items: {[id: string]: TValue},
    repoName: string
  ) {
    super(RepoMessageTypes.GetItems, repoName, success);
    this.items = items;
  }
  items: {[id: string]: TValue};
}

export class RepoDeleteItemRequest extends RepoMessage {
  constructor(id: string, repoName: string) {
    super(RepoMessageTypes.DeleteItem, repoName);
    this.id = id;
  }
  id: string;
}
export class RepoDeleteItemResponse extends RepoResponseMessage {
  constructor(repoName: string, success: boolean) {
    super(RepoMessageTypes.DeleteItem, repoName, success);
  }
}

export class RepoUpdateItemRequest<TValue> extends RepoMessage {
  constructor(id: string, item: TValue, repoName: string) {
    super(RepoMessageTypes.UpdateItem, repoName);
    this.id = id;
    this.item = item;
  }
  id: string;
  item: TValue;
}
export class RepoUpdateItemResponse extends RepoResponseMessage {
  constructor(repoName: string, success: boolean) {
    super(RepoMessageTypes.UpdateItem, repoName, success);
  }
}

export class RepoUpdateItemsRequest<TValue> extends RepoMessage {
  constructor(items: {id: string; item: TValue}[], repoName: string) {
    super(RepoMessageTypes.UpdateItems, repoName);
    this.items = items;
  }
  items: {id: string; item: TValue}[];
}
export class RepoUpdateItemsResponse extends RepoResponseMessage {
  constructor(repoName: string, success: boolean) {
    super(RepoMessageTypes.UpdateItems, repoName, success);
  }
}

export class RepoCreateItemRequest<TValue> extends RepoMessage {
  constructor(id: string, item: TValue, repoName: string) {
    super(RepoMessageTypes.CreateItem, repoName);
    this.id = id;
    this.item = item;
  }
  id: string;
  item: TValue;
}
export class RepoCreateItemResponse extends RepoResponseMessage {
  constructor(repoName: string, success: boolean) {
    super(RepoMessageTypes.CreateItem, repoName, success);
  }
}

export class RepoCreateItemsRequest<TValue> extends RepoMessage {
  constructor(items: {id: string; item: TValue}[], repoName: string) {
    super(RepoMessageTypes.CreateItems, repoName);
    this.items = items;
  }
  items: {id: string; item: TValue}[];
}
export class RepoCreateItemsResponse extends RepoResponseMessage {
  constructor(repoName: string, success: boolean) {
    super(RepoMessageTypes.CreateItems, repoName, success);
  }
}
