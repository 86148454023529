import {faCheck, faMinus, faTimes} from "@fortawesome/pro-regular-svg-icons";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {
  ButtonSwitch,
  ButtonSwitchOption,
} from "@sokigo/components-react-bootstrap";
import {InspectionAssessment} from "../Common/InspectionAssessment";

export function AssessmentSwitch({value, onChange, size}) {
  const t = useAppTranslation();
  return (
    <>
      <ButtonSwitch
        value={value ?? InspectionAssessment.NotAssessed}
        onChange={(x) => onChange(x)}
        size={size}
      >
        <ButtonSwitchOption
          value={InspectionAssessment.Acceptable}
          icon={faCheck}
          tooltipText={t("assessmentAcceptable")}
        />
        <ButtonSwitchOption
          value={InspectionAssessment.NotAcceptable}
          icon={faTimes}
          tooltipText={t("assessmentNotAcceptable")}
        />
        <ButtonSwitchOption
          value={InspectionAssessment.NotAssessed}
          icon={faMinus}
          tooltipText={t("assessmentNotAssessed")}
        />
      </ButtonSwitch>
    </>
  );
}
