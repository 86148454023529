import {faCheck, faEdit, faTimes} from "@fortawesome/pro-regular-svg-icons";
import {XTreeView} from "@sokigo-sbwebb/default-components";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@sokigo/components-react-bootstrap";
import {useCallback, useEffect, useMemo, useState} from "react";
import {useAvailableControlAreaGroups} from "../../../../../Visit/InspectionVisitRoute";

//default value represents VisitFormControlArea[]
export function ControlsModal({onChange, defaultValue, buttonKind, onClose}) {
  const t = useAppTranslation();
  const [show, setShow] = useState();
  const [selected, setSelected] = useState([]);

  const controlAreaGroups = useAvailableControlAreaGroups();

  const getControlAreaGroupsForDefaultValues = useCallback(() => {
    const areaIds = defaultValue.map((x) => x.id);
    const itemIds = defaultValue.map((x) => x.controlPoints).flat();
    const groups = Object.values(controlAreaGroups).reduce(
      (accGroups, currGroup) => {
        const controlAreas = currGroup.controlAreas
          ? currGroup.controlAreas.reduce((accAreas, currArea) => {
              if (areaIds.includes(currArea.id)) {
                const controlPoints = currArea.controlPoints
                  ? currArea.controlPoints.reduce((accItems, currItem) => {
                      if (itemIds.includes(currItem.id)) {
                        accItems = [...accItems, currItem];
                      }
                      return accItems;
                    }, [])
                  : [];
                accAreas = [
                  ...accAreas,
                  {...currArea, controlPoints: controlPoints},
                ];
              }
              return accAreas;
            }, [])
          : [];
        if (controlAreas?.length > 0) {
          accGroups = [
            ...accGroups,
            {...currGroup, controlAreas: controlAreas},
          ];
        }
        return accGroups;
      },
      []
    );

    return groups;
  }, [controlAreaGroups, defaultValue]);

  useEffect(
    () => setSelected(getControlAreaGroupsForDefaultValues()),
    [getControlAreaGroupsForDefaultValues, controlAreaGroups]
  );

  const options = useMemo(
    () =>
      Object.keys(controlAreaGroups)
        .map((key) => controlAreaGroups[key])
        .map((cag) => ({
          key: cag.id,
          label: cag.title,
          children:
            cag.controlAreas?.map((ca) => ({
              key: ca.id,
              label: ca.title,
              children:
                ca.controlPoints?.map((ci) => ({
                  key: ci.id,
                  label: ci.title,
                })) ?? [],
            })) ?? [],
        }))
        .sort((a, b) => a.label.localeCompare(b.label, t("locale"))),
    [controlAreaGroups, t]
  );

  const mappedValue = useMemo(
    () =>
      selected.map((cag) => ({
        key: cag.id,
        children:
          cag.controlAreas?.map((ca) => ({
            key: ca.id,
            children:
              ca.controlPoints?.map((ci) => ({
                key: ci.id,
              })) ?? [],
          })) ?? [],
      })),
    [selected]
  );

  const onChangeHelper = (value) => {
    const updatedValue = value?.map((cag) => {
      const previouslySelectedControlAreaGroup = selected.find(
        (x) => x.id === cag.key
      );
      const controlAreas = options.find((x) => x.key === cag.key)?.children;
      const cagChildren = cag.children;
      return {
        id: cag.key,
        controlAreas: (!previouslySelectedControlAreaGroup &&
        (!cag.children || cag.children?.length === 0)
          ? selectAll(controlAreas)
          : cag.children || []
        )
          .map((ca) => {
            const controlArea = cagChildren.find((x) => x.key === ca.key);
            const previouslySelectedControlArea = selected
              .find((x) => x.id === cag.key)
              ?.controlAreas.find((x) => x.id === controlArea.key);
            const controlPoints = options
              .find((x) => x.key === cag.key)
              ?.children.find((x) => x.key === ca.key)?.children;
            return controlArea
              ? {
                  id: controlArea.key,
                  controlPoints: (!previouslySelectedControlArea &&
                  (!controlArea.children || controlArea.children?.length === 0)
                    ? selectAll(controlPoints)
                    : controlArea?.children || []
                  ).map((ci) => ({
                    id: ci.key,
                  })),
                }
              : {
                  id: ca.key,
                  controlPoints: (!previouslySelectedControlAreaGroup
                    ? selectAll(controlPoints)
                    : ca.children || []
                  ).map((ci) => ({
                    id: ci.key,
                  })),
                };
          })
          .filter((x) => x),
      };
    });
    const updatedValueWithCleanup = updatedValue
      .map((cag) => {
        if (cag.controlAreas.length === 0) {
          return null;
        } else {
          return {
            ...cag,
            controlAreas: cag.controlAreas
              .map((ca) => {
                if (ca.controlPoints.length === 0) {
                  return null;
                } else {
                  return {
                    ...ca,
                    controlPoints: ca.controlPoints.map((ci) => ci),
                  };
                }
              })
              .filter((x) => x),
          };
        }
      })
      .filter((x) => x);
    setSelected(updatedValueWithCleanup);
  };

  function selectAll(nodes) {
    return nodes.map((x) => ({
      key: x.key,
      children: x.children ? selectAll(x.children) : [],
    }));
  }

  const onSave = () => {
    const visitFormControlAreas = selected
      .map((x) => {
        const controlAreas = x.controlAreas.map((y) => y);
        const controlAreasWithGroupId = controlAreas.map((y) => ({
          controlAreaGroupId: x.id,
          ...y,
        }));
        return controlAreasWithGroupId;
      })
      .flat()
      .map((controlArea) => {
        const controlPoints = controlArea.controlPoints.map((x) => x.id);
        return {
          controlAreaGroupId: controlArea.controlAreaGroupId,
          id: controlArea.id,
          controlPoints: controlPoints,
        };
      });
    onChange(visitFormControlAreas);
  };

  return (
    <>
      <Button
        kind={buttonKind ?? "ghost"}
        icon={faEdit}
        onClick={() => setShow(!show)}
      >
        {t("selectControlAreas")}
      </Button>
      <Modal show={show} onClose={() => setShow(false)} static>
        <ModalHeader>{t("selectControlAreas")}</ModalHeader>
        <ModalBody className="px-0">
          <XTreeView
            style={{maxHeight: "100%"}}
            label={t("controlAreas")}
            options={options}
            value={mappedValue}
            onChange={onChangeHelper}
            searchable={true}
            multi={true}
            validationErrors={[]}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            kind="secondary"
            onClick={() => {
              setShow(false);
              setSelected(getControlAreaGroupsForDefaultValues());
              if (onClose) {
                onClose();
              }
            }}
            className="w-25 mr-2"
            icon={faTimes}
          >
            {t("cancel")}
          </Button>
          <Button
            onClick={() => {
              setShow(false);
              onSave();
              if (onClose) {
                onClose();
              }
            }}
            kind="primary"
            className="w-25"
            icon={faCheck}
          >
            {t("ok")}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
