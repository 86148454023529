import {parseISO} from "date-fns";
import {useServerState} from "../../ServerStateProvider";
import {
  useSaveVisitToServerImmediately,
  useSyncDeviceVisitToServiceWorkerImmediately,
} from "../../sw-comms/device-inspections-repo/useDeviceVisits";
import {useCurrentVisit} from "./InspectionVisitRoute";

export function useSyncVisit() {
  const {serverVisits} = useServerState();
  const syncDeviceVisitToServiceWorkerImmediately =
    useSyncDeviceVisitToServiceWorkerImmediately();
  const saveVisitToServerImmediately = useSaveVisitToServerImmediately();

  const {
    visit: {id},
    changeDate,
  } = useCurrentVisit();

  const serverVisit = serverVisits[id];
  if (!serverVisit && process.env.NODE_ENV === "development") {
    // TODO why does this sometimes fail? useSyncVisit should never be called in a context where serverVisits[id] is not available...
    // eslint-disable-next-line no-debugger
    debugger;
  }
  const lastSavedDate = serverVisit?.lastSavedDate;
  const lastSaveTime = parseISO(lastSavedDate).getTime();
  const hasUnsavedChanges = !!changeDate && lastSaveTime < changeDate;

  async function doSync() {
    // save any pending changes to the service worker
    await syncDeviceVisitToServiceWorkerImmediately(id);

    // let the service worker send any local changes to server
    await saveVisitToServerImmediately(id);
  }

  return [hasUnsavedChanges, doSync];
}
