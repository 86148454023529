import {Tab, Tabs} from "@sokigo/components-react-bootstrap";
import {useCurrentVisit} from "../InspectionVisitRoute";
import {useMemo, useState} from "react";
import {StandardControlForm} from "./StandardControlForm";
import {FollowUpControlForm} from "./FollowUpControlForm";
import {ControlCause} from "../../Common/ControlCause";
import {SelectControlAreasWithoutModal} from "../foodInspectionControl/SelectControlAreasWithoutModal";

export function ControlFormTab() {
  const {
    visit: {
      input: {
        foodControlOccasion2024: {controls},
        foodControlQuestionsByFoodControlAreaId,
      },
      foodInspection24Options: {foodFacilityTypes},
    },
  } = useCurrentVisit();

  const controlTypes = useMemo(() => {
    const controlTypes = [];
    for (let i = 0; i < (controls || []).length; i++)
      if (!controlTypes.some((c) => c.cause === controls[i].cause)) {
        const possibleCauses = foodFacilityTypes
          .find((f) => f.id === controls[i].foodFacilityTypeId)
          .possibleCauses.find((p) => p.id === controls[i].cause);
        controlTypes.push({
          cause: controls[i].cause,
          name: possibleCauses ? possibleCauses.name : "",
        });
      }
    return controlTypes;
  }, [controls, foodFacilityTypes]);

  const [selectedTab, setSelectedTab] = useState(
    controlTypes.length > 0 && controlTypes[0].cause
  );

  if (
    selectedTab !== ControlCause.FollowUp &&
    !(Object.keys(foodControlQuestionsByFoodControlAreaId ?? {}).length > 0)
  ) {
    return <SelectControlAreasWithoutModal />;
  }

  return (
    <>
      {controlTypes.length > 0 && (
        <div>
          <div className="d-flex flex-column overflow-auto">
            <div className="flex-grow-0 flex-shrink-0 mt-3">
              <Tabs
                selected={selectedTab}
                justified
                rounded
                tabsSquare
                className="bg-normal"
                onSelect={setSelectedTab}
              >
                {controlTypes.map((c) => (
                  <Tab
                    key={c.cause}
                    id={c.cause}
                    headerText={c.name}
                    // warning={completingInformationErrors.length > 0}
                  />
                ))}
              </Tabs>
            </div>
          </div>
          {selectedTab === ControlCause.FollowUp ? (
            <FollowUpControlForm />
          ) : (
            <StandardControlForm causeId={selectedTab} key={selectedTab} />
          )}
        </div>
      )}
    </>
  );
}
