import {Table} from "@sokigo/components-react-bootstrap";
import {useMemo, useState} from "react";
import {useCurrentVisitInput} from "../../Visit/InspectionVisitRoute";
import {useAttachments} from "./AttachmentsProvider";
import {AttachmentsTableBody} from "./AttachmentsTableBody";
import {AttachmentsTableHead} from "./AttachmentsTableHead";
import {useAttachmentsTableColumns} from "./useAttachmentsTableColumns";

export function Attachments() {
  const input = useCurrentVisitInput();
  const oldAttachments = useMemo(() => input.attachments ?? [], [input]);
  const {newAttachments} = useAttachments();
  const [expanded, setExpanded] = useState([]);
  const tableHeaderColumns = useAttachmentsTableColumns(oldAttachments.length);

  const expandRow = (rowKey) => {
    if (!expanded.includes(rowKey)) setExpanded([rowKey]);
  };

  return (
    <Table
      columns={tableHeaderColumns}
      rows={newAttachments}
      getRowKey={(row) => row.attachmentId}
      expanded={expanded}
      onExpand={setExpanded}
    >
      <AttachmentsTableHead />
      <AttachmentsTableBody expandRow={expandRow} />
    </Table>
  );
}
