import {XTreeView} from "@sokigo-sbwebb/default-components";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {getValidationErrorMessages} from "@sokigo-sbwebb/validation";
import {Control, Select, TextBox} from "@sokigo/components-react-bootstrap";
import {useEffect, useState} from "react";
import {useServerState} from "../../../../ServerStateProvider";

export function NewCaseParameters({
  value,
  onChange,
  validationErrors,
  selectedDiary,
  setSelectedDiary,
  selectedDiaryValidationErrors,
  diaryPlanOptions,
  getSelectedDiaryId,
}) {
  const t = useAppTranslation();
  const [diaryPlanTreeViewLabel, setDiaryPlanTreeViewLabel] = useState();
  const {
    baseData: {processes, selectableMunicipalities, caseBillingModels} = {},
  } = useServerState();

  useEffect(() => {
    if (selectableMunicipalities?.length === 1) {
      onChange({
        ...value,
        municipalityCode: selectableMunicipalities[0].municipalityCode,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectedProcess =
    value.processId && processes.find((x) => x.id === value.processId);

  const availableBillingModels =
    selectedProcess &&
    value.municipalityCode &&
    (selectedProcess.caseBillingModelByMunicipalityCode[
      value.municipalityCode
    ]?.map((x) => caseBillingModels.find((m) => m.id === x)) ??
      caseBillingModels);

  function findSelectedDiaryItem(diaryOptions, key) {
    let ret = null;
    traverse(diaryOptions);

    function traverse(nodes) {
      nodes.forEach((x) => {
        if (x.key === key) {
          ret = x;
        } else {
          traverse(x.children);
        }
      });
    }

    return ret;
  }

  function onChangeProcessId(processId) {
    const process = processId && processes.find((x) => x.id === processId);

    const billingModels =
      process &&
      value.municipalityCode &&
      (process.caseBillingModelByMunicipalityCode[value.municipalityCode]?.map(
        (x) => caseBillingModels.find((m) => m.id === x)
      ) ??
        caseBillingModels);

    onChange({
      ...value,
      processId,
      predefinedSubtitleId: null,
      caseBillingModelId:
        billingModels?.length === 1 ? billingModels[0].id : null,
    });
  }

  const sortedSubTitleOptions =
    selectedProcess?.predefinedSubtitles.sort((a, b) =>
      a.title.localeCompare(b.title, t("locale"))
    ) ?? [];

  return (
    <>
      <h4 className="mb-2">{t("newCase")}</h4>
      <div className="row">
        <div className="col">
          <Control
            labelText={t("title")}
            errors={getValidationErrorMessages(validationErrors, "processId")}
          >
            <Select
              options={processes}
              getOptionValue={(x) => x.id}
              getOptionKey={(x) => x.id}
              getOptionText={(x) => x.title}
              value={value.processId ?? null}
              onChange={(processId) => onChangeProcessId(processId)}
            />
          </Control>
        </div>
        <div className="col">
          <Control labelText={t("subtitle")}>
            <Select
              key={value.predefinedSubtitleId}
              disabled={!selectedProcess}
              options={sortedSubTitleOptions}
              getOptionValue={(x) => x.id}
              getOptionKey={(x) => x.id}
              getOptionText={(x) => x.title}
              value={value.predefinedSubtitleId ?? null}
              onChange={(predefinedSubtitleId) =>
                onChange({...value, predefinedSubtitleId})
              }
              clearable
            />
          </Control>
        </div>
        <div className="col">
          <Control labelText={t("customSubtitle")}>
            <TextBox
              value={value.customSubTitle ?? ""}
              onChange={(customSubTitle) =>
                onChange({...value, customSubTitle})
              }
            />
          </Control>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <Control labelText={t("diaryPlanSpecification")}>
            <XTreeView
              style={{maxHeight: 310}}
              label={diaryPlanTreeViewLabel}
              options={diaryPlanOptions}
              value={selectedDiary}
              onChange={(selectedDiaryTree) => {
                setSelectedDiary(selectedDiaryTree);
                const diaryPlanItemId = getSelectedDiaryId(selectedDiaryTree);
                const selectedDiaryPlanItem = findSelectedDiaryItem(
                  diaryPlanOptions,
                  diaryPlanItemId
                );
                setDiaryPlanTreeViewLabel(selectedDiaryPlanItem?.label);
                onChange({...value, diaryPlanItemId});
              }}
              searchable={true}
              multi={false}
              validationErrors={selectedDiaryValidationErrors}
            />
          </Control>
        </div>
        <div className="col">
          <Control
            labelText={t("municipality")}
            errors={getValidationErrorMessages(
              validationErrors,
              "municipalityCode"
            )}
          >
            <Select
              options={selectableMunicipalities ?? []}
              getOptionValue={(x) => x.municipalityCode}
              getOptionKey={(x) => x.municipalityCode}
              getOptionText={(x) => x.name}
              value={value.municipalityCode}
              onChange={(municipalityCode) =>
                onChange({...value, municipalityCode})
              }
            />
          </Control>
        </div>
        <div className="col">
          <Control
            labelText={t("billingModel")}
            errors={getValidationErrorMessages(
              validationErrors,
              "caseBillingModelId"
            )}
          >
            <Select
              disabled={!(availableBillingModels?.length > 0)}
              options={availableBillingModels ?? []}
              getOptionValue={(x) => x.id}
              getOptionKey={(x) => x.id}
              getOptionText={(x) => x.title}
              value={value.caseBillingModelId}
              onChange={(caseBillingModelId) =>
                onChange({...value, caseBillingModelId})
              }
            />
          </Control>
        </div>
      </div>
    </>
  );
}
