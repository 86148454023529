import {useMemo} from "react";
import {Panel, Alert, Button} from "@sokigo/components-react-bootstrap";
import {faCheck} from "@fortawesome/pro-regular-svg-icons";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {getValidationErrors, Validation} from "@sokigo-sbwebb/validation";
import {useIsOnline} from "@sokigo-sbwebb/serviceworker";
import {
  useCurrentVisit,
  useCurrentVisitInput,
  useMergeCurrentVisitInput,
} from "../../Visit/InspectionVisitRoute";
import {useFinishVisit} from "../../../sw-comms/device-inspections-repo/useDeviceVisits";
import {SaveAsSection} from "../../../components/Visit/finish/FinishVisit/FinishVisitSections/SaveAsSection";
import {SendByEmailSwitch} from "../../../components/Visit/finish/FinishVisit/FinishVisitSections/SendByEmailSwitch";
import {InspectionProtocol} from "../../Visit/finish/CompletingInformation/Sections/InspectionProtocol";
import {CompletingInformationProvider} from "../finish/CompletingInformation/CompletingInformationTab";
import {EmailSection} from "../finish/FinishVisit/FinishVisitSections/EmailSection";
import {SaveDocumentAs} from "../finish/FinishVisit/SaveDocumentAs";
import {InputWithTagsProvider} from "../finish/FinishVisit/InputWithTags/InputWithTags";
import {isValidEmailAddress} from "../presentParties/isValidEmailAddress";
import FinishVisitSchema from "../finish/FinishVisit/FinishVisitSchema.json";
import DocumentTemplateSchema from "../finish/FinishVisit/DocumentTemplateSchema.json";
import {
  useFoodAssessmentValidation,
  useFoodControlAreas24Validation,
  useFoodObligatoryValuesValidation,
  useInspectionDateValidation,
  useObligatoryBevaWhen92ControlValidation,
  useFoodFacilityTypeValidation,
  useFinish24TabHasObligatoryValuesMissing,
  useControlOccasionTabHasObligatoryValuesMissing,
} from "../foodInspection24Validator/useFoodInspection24Validation";
import {useAttachmentSizeValidation} from "../attachments/useAttachmentTabValidation";

export function Finish24Tab() {
  const t = useAppTranslation();
  const input = useCurrentVisitInput();
  const {finishVisitInfo, completingInformation} = input;
  const visit = useCurrentVisit();
  const {
    visit: {id: currentVisitId},
  } = visit;

  const finishVisit = useFinishVisit();

  const onFinishVisitClicked = () => {
    finishVisit(currentVisitId);
  };

  const merge = useMergeCurrentVisitInput();
  function onChange(changes) {
    if (changes.saveAs === 0) {
      changes.sendByEmail = false;
    }
    merge({finishVisitInfo: {...finishVisitInfo, ...changes}});
  }

  const finishVisitInfoValidation = useMemo(
    () => new Validation(FinishVisitSchema),
    []
  );
  const templateValidation = useMemo(
    () => new Validation(DocumentTemplateSchema),
    []
  );
  const validationErrors = useMemo(
    () => finishVisitInfoValidation.validate(finishVisitInfo, t),
    [finishVisitInfoValidation, finishVisitInfo, t]
  );
  const templateValidationErrors = useMemo(
    () => templateValidation.validate(completingInformation, t),
    [templateValidation, completingInformation, t]
  );
  const isInspectionDateValid = useInspectionDateValidation();
  const hasObligatoryBevaWhen92ControlValid =
    useObligatoryBevaWhen92ControlValidation();

  const foodFacilityTypesValid = useFoodFacilityTypeValidation();

  const noEmailAddressesSpecified =
    finishVisitInfo.sendByEmail &&
    finishVisitInfo.emailInfo.sendToAdditionalEmailAddresses.length === 0 &&
    finishVisitInfo.emailInfo.sendToPresentPartyIds.length === 0 &&
    finishVisitInfo.emailInfo.sendToCasePartyContactDetails.length === 0;

  const isAttachmentTotalSizeToLarge = useAttachmentSizeValidation();

  const hasFoodControlAreaValidationErrors = !useFoodControlAreas24Validation();
  const hasFoodControlAssessmentValidationErrors =
    !useFoodAssessmentValidation();

  const finishTabHasRequiredValues =
    !useFinish24TabHasObligatoryValuesMissing();
  const controlOccasionTabHasRequiredValues =
    !useControlOccasionTabHasObligatoryValuesMissing();
  const selectControlsTabHasObligatoryValues =
    useFoodObligatoryValuesValidation();

  const validationErrorMessages = [];

  if (isAttachmentTotalSizeToLarge) {
    validationErrorMessages.push(t("attachmentSizeToLarge"));
  }
  if (!isInspectionDateValid.minMax) {
    validationErrorMessages.push(t("minMaxInspectionDateValidationMessage"));
  }
  if (isInspectionDateValid.unallowed2023cretaion) {
    validationErrorMessages.push(t("datesBefore2024notAllowed"));
  }
  if (hasFoodControlAssessmentValidationErrors) {
    validationErrorMessages.push(t("noQuestionSelectedForFoodFacility"));
  }
  if (hasFoodControlAreaValidationErrors) {
    validationErrorMessages.push(t("operativeGoalsErrorMessage"));
  }
  if (!hasObligatoryBevaWhen92ControlValid) {
    validationErrorMessages.push(t("missingObligatoryBevaWhen92Control"));
  }
  if (!foodFacilityTypesValid) {
    validationErrorMessages.push(t("foodFacilityTypeNotValidForInspection"));
  }
  if (
    !selectControlsTabHasObligatoryValues ||
    !finishTabHasRequiredValues ||
    !controlOccasionTabHasRequiredValues ||
    validationErrors.length > 0
  ) {
    validationErrorMessages.push(t("mandatoryFieldsAreNotCompleted"));
  }

  const isOnline = useIsOnline();
  const canFinish = isOnline && validationErrorMessages.length === 0;

  return (
    <>
      <div>
        {validationErrorMessages.length > 0 &&
          validationErrorMessages.map((validationMessage) => (
            <Alert
              key={validationMessage}
              kind="warning"
              inline
              hideCloseButton
              contrast
              className="mt-3"
            >
              {validationMessage}
            </Alert>
          ))}
        <Panel className="my-3 p-3">
          <div className="justify-content-between">
            <CompletingInformationProvider>
              <InspectionProtocol
                canPreview={canFinish}
                validationErrors={templateValidationErrors}
              />
            </CompletingInformationProvider>
            <div className="p-3">
              <SaveAsSection
                value={finishVisitInfo.saveAs}
                onChange={onChange}
              />
              <br></br>
              {finishVisitInfo.saveAs === SaveDocumentAs.Completed && (
                <SendByEmailSwitch
                  value={finishVisitInfo.sendByEmail}
                  onChange={onChange}
                  validationErrors={validationErrors}
                />
              )}
              {finishVisitInfo.sendByEmail && (
                <InputWithTagsProvider
                  value={
                    finishVisitInfo.emailInfo.sendToAdditionalEmailAddresses ??
                    []
                  }
                  onChange={(sendToAdditionalEmailAddresses) =>
                    merge({
                      finishVisitInfo: {
                        ...finishVisitInfo,
                        emailInfo: {
                          ...finishVisitInfo.emailInfo,
                          sendToAdditionalEmailAddresses,
                        },
                      },
                    })
                  }
                  separator={","}
                  validator={(x) => isValidEmailAddress(x)}
                >
                  {(pendingAdditionalEmailAddressIsInvalid) => (
                    <EmailSection
                      onChange={onChange}
                      validationErrors={getValidationErrors(
                        validationErrors,
                        "emailInfo"
                      )}
                      pendingAdditionalEmailAddressIsInvalid={
                        pendingAdditionalEmailAddressIsInvalid
                      }
                      noEmailAddressesSpecified={noEmailAddressesSpecified}
                    />
                  )}
                </InputWithTagsProvider>
              )}
            </div>
          </div>
          <div className="d-flex justify-content-end my-3">
            <Button
              kind="primary"
              className="ml-2"
              icon={faCheck}
              disabled={!canFinish}
              onClick={onFinishVisitClicked}
            >
              {finishVisitInfo.sendByEmail
                ? t("finishVisitAndEmail")
                : t("finishTab")}
            </Button>
          </div>
        </Panel>
      </div>
    </>
  );
}
