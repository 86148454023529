import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableHeaderRow,
  TableRow,
  TableRowActionsCell,
} from "@sokigo/components-react-bootstrap";
import {Fragment, useContext} from "react";
import {
  SelectedCaseContext,
  SetSelectedCaseContext,
} from "./EstateSearchResults";
import {CreateNewVisitButton} from "./CreateNewVisitButton";

export function ConnectedCases({connectedCases, estateRowId, facilityRowId}) {
  const selectedCase = useContext(SelectedCaseContext);
  const setSelectedCase = useContext(SetSelectedCaseContext);

  const t = useAppTranslation();
  const columns = [
    {
      key: "caseNumber",
      header: t("caseNumber"),
      renderCell: ({row}) => (row.isNewCaseRow ? t("newCase") : row.caseNumber),
    },
    {
      key: "caseTitle",
      header: t("caseTitle"),
      renderCell: ({row}) => (row.isNewCaseRow ? "" : row.caseTitle),
    },
    {
      key: "caseSubTitle",
      header: t("caseSubTitle"),
      renderCell: ({row}) => (row.isNewCaseRow ? "" : row.subTitle),
    },
  ];

  return (
    <>
      <Table
        className="my-2"
        rows={connectedCases}
        columns={columns}
        getRowKey={(x) => x.rowId}
        size="sm"
        borderless
        selected={selectedCase ? [selectedCase.rowId] : []}
      >
        <TableHead>
          <TableHeaderRow colSpan={columns.length + 1}>
            {(columns) => (
              <>
                {columns.map((x) => (
                  <TableHeader key={x.key} column={x}>
                    {x.header}
                  </TableHeader>
                ))}
                <TableHeader />
              </>
            )}
          </TableHeaderRow>
        </TableHead>
        <TableBody>
          {(rows) => (
            <>
              {rows.map((r) => (
                <Fragment key={r.key}>
                  <TableRow key={r.key} row={r}>
                    {r.cells.map((c) => (
                      <TableCell key={c.key}>{c.value}</TableCell>
                    ))}
                    <TableRowActionsCell>
                      <CreateNewVisitButton
                        key={r.row.rowId}
                        possibleVisits={r.row.possibleVisits}
                        isNewCase={r.row.isNewCaseRow ? true : false}
                        caseNumber={r.row.isNewCaseRow ? "" : r.row.caseNumber}
                        onClick={() =>
                          setSelectedCase({
                            estateRowId,
                            facilityRowId,
                            caseRowId: r.row.rowId,
                          })
                        }
                      />
                    </TableRowActionsCell>
                  </TableRow>
                </Fragment>
              ))}
            </>
          )}
        </TableBody>
      </Table>
    </>
  );
}
