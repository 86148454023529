import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {
  ButtonSwitch,
  Control,
  ButtonSwitchOption,
} from "@sokigo/components-react-bootstrap";
import {SaveAsType} from "./SaveAsType";

export const Sections = ({visitForm, onChange}) => {
  const t = useAppTranslation();
  const {sections, saveAsType} = visitForm;
  const isFoodVisitForm =
    saveAsType === SaveAsType.FoodInspection ||
    saveAsType === SaveAsType.FoodInspection24;

  return (
    <>
      <Control labelText={t("presentParties")} horizontal>
        <ButtonSwitch
          value={sections?.presentParties}
          onChange={(presentParties) => {
            onChange({...sections, presentParties});
          }}
        >
          <ButtonSwitchOption value={true}>{t("yes")}</ButtonSwitchOption>
          <ButtonSwitchOption value={false}>{t("no")}</ButtonSwitchOption>
        </ButtonSwitch>
      </Control>
      <Control
        labelText={isFoodVisitForm ? t("controlAreasSLV") : t("control")}
        horizontal
      >
        <ButtonSwitch
          value={isFoodVisitForm ? true : sections?.controlAreas}
          onChange={(controlAreas) => {
            onChange({...sections, controlAreas});
          }}
        >
          <ButtonSwitchOption value={true} disabled={isFoodVisitForm}>
            {t("yes")}
          </ButtonSwitchOption>
          <ButtonSwitchOption value={false} disabled={isFoodVisitForm}>
            {t("no")}
          </ButtonSwitchOption>
        </ButtonSwitch>
      </Control>
      <Control labelText={t("attachments")} horizontal>
        <ButtonSwitch
          value={sections?.documents}
          onChange={(documents) => {
            onChange({...sections, documents});
          }}
        >
          <ButtonSwitchOption value={true}>{t("yes")}</ButtonSwitchOption>
          <ButtonSwitchOption value={false}>{t("no")}</ButtonSwitchOption>
        </ButtonSwitch>
      </Control>
      <Control labelText={t("protocolTexts")} horizontal>
        <ButtonSwitch
          value={sections?.notes}
          onChange={(notes) => {
            onChange({...sections, notes});
          }}
        >
          <ButtonSwitchOption value={true}>{t("yes")}</ButtonSwitchOption>
          <ButtonSwitchOption value={false}>{t("no")}</ButtonSwitchOption>
        </ButtonSwitch>
      </Control>
    </>
  );
};
