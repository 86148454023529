import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {getValidationErrorMessages} from "@sokigo-sbwebb/validation";
import {
  Control,
  DatePicker,
  Label,
  Panel,
  Switch,
  TextArea,
} from "@sokigo/components-react-bootstrap";
import {useCompletingInformationProvider} from "../CompletingInformationTab";
import moment from "moment";

export function VisitOccurrenceFields({validationErrors}) {
  const t = useAppTranslation();

  const {onChange, value} = useCompletingInformationProvider();

  const onOccurrenceInformationChange = (change) => {
    onChange({...value, ...change});
  };

  return (
    <Panel className="mt-3 pt-3 pb-1">
      <h4 className="px-3 mb-3">{t("occurrenceInformation")}</h4>
      <div className="m-3" style={{display: "flex", flexWrap: "wrap"}}>
        <Control
          labelText={t("occurrenceDate")}
          errors={getValidationErrorMessages(
            validationErrors,
            "occurrenceDate"
          )}
        >
          <DatePicker
            format="yyyy-MM-dd"
            value={value?.occurrenceDate}
            onChange={(occurrenceDate) =>
              onOccurrenceInformationChange({
                occurrenceDate,
              })
            }
            max={moment().format("YYYY-MM-DD")}
            inline
            locale={t("locale")}
          />
        </Control>
        <div className="form-group col-xs-12 col-sm-6 col-md-5">
          <Switch
            className="mt-3 mr-2"
            size="lg"
            kind="success"
            checked={value?.saveScratchPadNote || false}
            onChange={(saveScratchPadNote) =>
              onOccurrenceInformationChange({saveScratchPadNote})
            }
          />
          <Label className="mt-5">{t("saveScratchPadNote")}</Label>
        </div>
      </div>

      <Control labelText={t("note")}>
        <TextArea
          value={value?.occurrenceNote ?? ""}
          onChange={(occurrenceNote) =>
            onOccurrenceInformationChange({
              occurrenceNote,
            })
          }
          row={5}
        />
      </Control>
    </Panel>
  );
}
