import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {TextBoxControl} from "../../../../../../common-components/TextBoxControl";

export function CaseSearchForm({value = {}, onChange}) {
  const t = useAppTranslation();
  const c = (changes) => onChange({...value, ...changes});
  return (
    <>
      <div className="row">
        <div className="col-md">
          <TextBoxControl
            label={t("caseNumber")}
            value={value.caseNumber}
            onChange={(caseNumber) => c({caseNumber})}
            placeholder={t("caseNumberPlaceholder")}
            className="mb-md-0"
            name="casesearch-casenumber"
          />
        </div>
        <div className="col-md">
          <TextBoxControl
            label={t("caseTitle")}
            value={value.caseTitle}
            onChange={(caseTitle) => c({caseTitle})}
            placeholder={t("caseTitleOrSubtitlePlaceholder")}
            className="mb-0"
            name="casesearch-casetitle"
          />
        </div>
      </div>
    </>
  );
}
