import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {
  ButtonSwitchOption,
  ButtonSwitch,
  Control,
} from "@sokigo/components-react-bootstrap";
import {getValidationErrorMessages} from "@sokigo-sbwebb/validation";

export function SendByEmailSwitch({value, onChange, validationErrors}) {
  const t = useAppTranslation();
  return (
    <Control
      errors={getValidationErrorMessages(validationErrors, "sendByEmail")}
      labelText={t("sendByEmail")}
      horizontal
    >
      <ButtonSwitch
        value={value}
        onChange={(sendByEmail) => onChange({sendByEmail})}
      >
        <ButtonSwitchOption value={true}>{t("yes")}</ButtonSwitchOption>
        <ButtonSwitchOption value={false}>{t("no")}</ButtonSwitchOption>
      </ButtonSwitch>
    </Control>
  );
}
