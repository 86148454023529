import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {Control, Panel, TextArea} from "@sokigo/components-react-bootstrap";
import {useCurrentVisit} from "../../../InspectionVisitRoute";
import {useCompletingInformationProvider} from "../CompletingInformationTab";
import {AssessmentSwitch} from "../../../AssessmentSwitch";

export function AdditionalAssessmentsSection() {
  const {onChange, value} = useCompletingInformationProvider();

  const {
    visit: {
      visitMetadata: {inspectionItems, visitTargetDescription},
    },
  } = useCurrentVisit();

  const t = useAppTranslation();

  if (inspectionItems?.length === 0) {
    return null;
  }

  const onAssessmentChange = (assessmentId, change) => {
    const additionalAssessments = value?.additionalAssessments
      ? {...value?.additionalAssessments}
      : {};
    additionalAssessments[assessmentId] = {
      ...additionalAssessments[assessmentId],
      ...change,
    };

    onChange({...value, additionalAssessments: additionalAssessments});
  };

  return (
    <Panel className="mt-3 pt-3 pb-1">
      <h4 className="px-3 ">{visitTargetDescription}</h4>
      {inspectionItems?.map((x) => {
        return (
          <div key={x.itemId} className="m-3">
            <div className="mb-2">
              <Control labelText={x.title} horizontal>
                <AssessmentSwitch
                  value={
                    value?.additionalAssessments?.[x.itemId]?.assessmentValue
                  }
                  onChange={(assessmentValue) =>
                    onAssessmentChange(x.itemId, {
                      assessmentValue: assessmentValue,
                    })
                  }
                />
              </Control>
            </div>
            <Control labelText={t("note")} horizontal>
              <TextArea
                value={
                  value?.additionalAssessments?.[x.itemId]?.assessmentNote || ""
                }
                onChange={(assessmentNote) =>
                  onAssessmentChange(x.itemId, {assessmentNote})
                }
              />
            </Control>
          </div>
        );
      })}
    </Panel>
  );
}
