import {
  faCalendarCheck,
  faCamera,
  faCheckDouble,
  faInfoCircle,
  faRectanglesMixed,
  faStickyNote,
  faTrophy,
  faUsers,
} from "@fortawesome/pro-regular-svg-icons";
import {ApplicationView} from "@sokigo-sbwebb/default-components";
import {useSetApplicationSubTitle} from "@sokigo-sbwebb/default-core";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {Tab, Tabs} from "@sokigo/components-react-bootstrap";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {generatePath, Link, matchPath, useHistory} from "react-router-dom";
import {
  useDeviceVisits,
  useUpdateDeviceVisit,
} from "../../sw-comms/device-inspections-repo/useDeviceVisits";
import {SaveAsType} from "../AdminRoutes/AdminVisitForms/EditVisitForm/VisitFormEditor/SaveAsType";
import {AttachmentsTab} from "./attachments/AttachmentsTab";
import {FinishTab} from "./finish/FinishTab";
import {FoodInspectionControlTab} from "./foodInspectionControl/FoodInspectionControlTab";
import {useFoodControlAreasValidation} from "./foodInspectionControl/useFoodControlAreasValidation";
import {
  useInspectionDateValidation,
  useFoodAssessmentValidation,
  useObligatoryBevaWhen92ControlValidation,
  useFoodFacilityTypeValidation,
  useFoodObligatoryValuesValidation,
  useControlOccasionTabHasObligatoryValuesMissing,
  useFinish24TabHasObligatoryValuesMissing,
  useFoodControlAreas24Validation,
} from "./foodInspection24Validator/useFoodInspection24Validation";
import {Footer} from "./footer/Footer";
import {InformationRoute} from "./information/InformationRoute";
import {NonFoodInspectionControlTab} from "./nonFoodInspectionControl/NonFoodInspectionControlTab";
import {PresentPartiesTab} from "./presentParties/PresentPartiesTab";
import {ProtocolTextsTab} from "./protocolTexts/ProtocolTextsTab";
import {useSyncVisit} from "./useSyncVisit";
import {DeviceVisitCheckinStatus} from "../../sw-comms/device-inspections-repo/DeviceVisitCheckinStatus";
import {useServerState} from "../../ServerStateProvider";
import {ControlOccasionTab} from "./controlOccasion/ControlOccasionTab";
import {Finish24Tab} from "./finish24/finish24Tab";
import {SelectControlsTab} from "./selectControls/SelectControlsTab";
import {ControlFormTab} from "./controlForm/ControlFormTab";
import {InformationRoute24} from "./information/InformationRoute24";
import {useAttachmentSizeValidation} from "./attachments/useAttachmentTabValidation";

function DebugTab() {
  const currentVisit = useCurrentVisit();
  return (
    <div>
      <h4>Debug debug debug</h4>
      <pre>{JSON.stringify(currentVisit, null, 2)}</pre>
    </div>
  );
}

const CurrentVisitContext = createContext();
const SelectedFoodAreaAndQuestionsContext = createContext();

export function useSelectedFoodAreaAndQuestions() {
  return useContext(SelectedFoodAreaAndQuestionsContext);
}
export function useCurrentVisit() {
  return useContext(CurrentVisitContext);
}
export function useCurrentVisitInput() {
  return useCurrentVisit().visit.input ?? {};
}
export function useMergeCurrentVisitInput() {
  const visitWithStatus = useCurrentVisit();
  const updateVisit = useUpdateDeviceVisit();

  return useCallback(
    (changes, newAttachments) =>
      updateVisit(
        {
          ...visitWithStatus,
          visit: {
            ...visitWithStatus.visit,
            input: {...(visitWithStatus.visit.input ?? {}), ...changes},
          },
        },
        newAttachments
      ),
    [visitWithStatus, updateVisit]
  );
}

function CurrentVisitProvider({children, visitId}) {
  const {serverVisits} = useServerState();
  const deviceVisits = useDeviceVisits();
  const currentVisit = deviceVisits?.[visitId];

  const [
    selectedFoodControlAreaAndQuestions,
    setSelectedFoodControlAreaAndQuestions,
  ] = useState({
    foodControlAreaId: undefined,
    questionIds: [],
  });
  // deviceVisits will only contain visits owned by the current user
  if (
    !serverVisits[currentVisit?.visit?.id] ||
    !currentVisit ||
    currentVisit.checkinStatus === DeviceVisitCheckinStatus.OwnerChanged ||
    currentVisit.checkinStatus === DeviceVisitCheckinStatus.Hijacked ||
    currentVisit.checkinStatus === DeviceVisitCheckinStatus.DeletedOnServer
  ) {
    return <VisitNotAvailable />;
  }

  return (
    <CurrentVisitContext.Provider value={currentVisit}>
      <SelectedFoodAreaAndQuestionsContext.Provider
        value={{
          selectedFoodControlAreaAndQuestions,
          setSelectedFoodControlAreaAndQuestions,
        }}
      >
        {children}
      </SelectedFoodAreaAndQuestionsContext.Provider>
    </CurrentVisitContext.Provider>
  );
}

function VisitMainLayoutTabs({path, section}) {
  const t = useAppTranslation();
  const footerRef = useRef();
  const {push, listen} = useHistory();
  const {
    visit: {
      id: visitId,
      visitMetadata: {saveAsType, visitTargetDescription},
      visitFormSnapshot: {sections},
    },
    checkinStatus,
  } = useCurrentVisit();

  const [hasUnsavedChanges, doSync] = useSyncVisit();
  const [isFinish24TabSelected, setIsFinish24TabSelected] = useState(
    section === "finish24"
  );

  useEffect(() => {
    if (section === "finish24") {
      setIsFinish24TabSelected(true);
    }
  }, [section]);

  const setApplicationSubtitle = useSetApplicationSubTitle();
  useEffect(
    () => setApplicationSubtitle(visitTargetDescription),
    [setApplicationSubtitle, visitTargetDescription]
  );

  useEffect(() => {
    const handleBeforeUnload = (ev) => {
      ev.preventDefault();
      if (
        hasUnsavedChanges &&
        // Visits that are being checked in or finished will already have triggered a sync
        checkinStatus === DeviceVisitCheckinStatus.CheckedOut
      ) {
        // noinspection JSIgnoredPromiseFromCall
        doSync();
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [hasUnsavedChanges, doSync, checkinStatus]);

  useEffect(() => {
    return listen(({pathname: newPath}) => {
      const match = matchPath(newPath, {
        path,
        exact: true,
      });
      if (match?.params?.visitId !== visitId) {
        // navigating away from the visit
        if (hasUnsavedChanges) {
          // noinspection JSIgnoredPromiseFromCall
          doSync();
        }
      }
    });
  });

  const controlAreasValid = useFoodControlAreasValidation();

  const customValidationTabWarnings = (TabComponent) => {
    let error = false;
    switch (TabComponent) {
      case FoodInspectionControlTab: {
        error = !controlAreasValid;
        break;
      }
    }
    return error;
  };

  const tabs = [
    saveAsType !== SaveAsType.FoodInspection24 && {
      title: t("informationTab"),
      path: "information",
      icon: faInfoCircle,
      component: InformationRoute,
    },
    saveAsType === SaveAsType.FoodInspection24 && {
      title: t("informationTab"),
      path: "information",
      icon: faInfoCircle,
      component: InformationRoute24,
    },
    sections.presentParties && {
      title: t("presentPartiesTab"),
      path: "presentParties",
      icon: faUsers,
      component: PresentPartiesTab,
    },
    saveAsType === SaveAsType.FoodInspection24 && {
      title: t("selectControlsTab"),
      path: "selectControls",
      icon: faRectanglesMixed,
      component: SelectControlsTab,
    },
    saveAsType === SaveAsType.FoodInspection24 && {
      title: t("controlFormTab"),
      path: "controlForm",
      icon: faCheckDouble,
      component: ControlFormTab,
    },
    saveAsType !== SaveAsType.FoodInspection &&
      saveAsType !== SaveAsType.FoodInspection24 &&
      sections.controlAreas && {
        title: t("control"),
        path: "control",
        icon: faCheckDouble,
        component: NonFoodInspectionControlTab,
      },
    saveAsType === SaveAsType.FoodInspection &&
      sections.controlAreas && {
        title: t("control"),
        path: "control",
        icon: faCheckDouble,
        component: FoodInspectionControlTab,
      },
    sections.documents && {
      title: t("attachmentsTab"),
      path: "attachments",
      icon: faCamera,
      component: AttachmentsTab,
    },
    sections.notes && {
      title: t("protocolTextsTab"),
      path: "protocolTexts",
      icon: faStickyNote,
      component: ProtocolTextsTab,
    },
    saveAsType === SaveAsType.FoodInspection24 && {
      title: t("controlOccasion"),
      path: "controlOccasion",
      icon: faCalendarCheck,
      component: ControlOccasionTab,
    },
    saveAsType === SaveAsType.FoodInspection24 && {
      title: t("finishTab"),
      path: "finish24",
      icon: faTrophy,
      component: Finish24Tab,
    },
    //note = protocolTexts
    saveAsType !== SaveAsType.FoodInspection24 && {
      title: t("finishTab"),
      path: "finish",
      icon: faTrophy,
      component: FinishTab,
    },
    process.env.NODE_ENV === "development" && {
      title: "debug 🐛",
      path: "debug",
      component: DebugTab,
    },
  ].filter((x) => x);
  const currentSection = section ?? tabs[0].path;
  return (
    <>
      <ApplicationView
        className="p-3"
        style={{
          height: "100%",
          maxHeight:
            "calc(100% - " + (footerRef.current?.offsetHeight ?? 0) + "px)",
          overflow: "auto",
          maxWidth: 1440,
        }}
      >
        {saveAsType === SaveAsType.FoodInspection24 && isFinish24TabSelected ? (
          <TabWith24Validation
            path={path}
            section={section}
            tabs={tabs}
            push={push}
            visitId={visitId}
          />
        ) : (
          <Tabs
            selected={section ?? tabs[0].path}
            onSelect={(newSection) =>
              newSection !== section &&
              push(generatePath(path, {visitId, section: newSection}))
            }
            justified
            size="lg"
            rounded
            tabsSquare
            className="bg-normal"
          >
            {tabs.map(({path, title, icon, component: TabComponent}) => (
              <Tab
                key={path}
                headerText={t(title)}
                id={path}
                icon={icon}
                warning={customValidationTabWarnings(TabComponent)}
              >
                {/*Skip rendering non-selected sections?*/}
                {currentSection === path ? <TabComponent /> : null}
              </Tab>
            ))}
          </Tabs>
        )}
      </ApplicationView>
      <Footer ref={footerRef} />
    </>
  );
}

function TabWith24Validation({path, section, tabs, push, visitId}) {
  const t = useAppTranslation();
  const dateValid = useInspectionDateValidation();
  const attachmentValid = useAttachmentSizeValidation();
  const bevaValid = useObligatoryBevaWhen92ControlValidation();
  const fftValid = useFoodFacilityTypeValidation();
  const controlAreasValid = useFoodControlAreas24Validation();
  const assessmentValid = useFoodAssessmentValidation();
  const selectControlObligatory = useFoodObligatoryValuesValidation();
  const controlOccasionObligatory =
    useControlOccasionTabHasObligatoryValuesMissing();

  const isSelectControlTabInvalid =
    !bevaValid || !fftValid || !selectControlObligatory;
  const isControlFormTabValid = assessmentValid && controlAreasValid;
  const isControlOccasionTabValid =
    dateValid.minMax && !controlOccasionObligatory;
  const isAttachmentTabInvalid = attachmentValid;
  const isFinishTabValid = !useFinish24TabHasObligatoryValuesMissing();

  const customValidationTabInvalid = (TabComponent) => {
    let error = false;
    switch (TabComponent) {
      case SelectControlsTab: {
        error = isSelectControlTabInvalid;
        break;
      }
      case ControlFormTab: {
        error = !isControlFormTabValid;
        break;
      }
      case AttachmentsTab: {
        error = isAttachmentTabInvalid;
        break;
      }
      case ControlOccasionTab: {
        error = !isControlOccasionTabValid;
        break;
      }
      case Finish24Tab: {
        error = !isFinishTabValid;
        break;
      }
    }
    return error;
  };

  const currentSection = section ?? tabs[0].path;
  return (
    <Tabs
      selected={section ?? tabs[0].path}
      onSelect={(newSection) =>
        newSection !== section &&
        push(generatePath(path, {visitId, section: newSection}))
      }
      justified
      size="lg"
      rounded
      tabsSquare
      className="bg-normal"
    >
      {tabs.map(({path, title, icon, component: TabComponent}) => (
        <Tab
          key={path}
          headerText={t(title)}
          id={path}
          icon={icon}
          invalid={customValidationTabInvalid(TabComponent)}
        >
          {currentSection === path ? <TabComponent /> : null}
        </Tab>
      ))}
    </Tabs>
  );
}

export function useAvailableControlAreaGroups() {
  const currentVisit = useContext(CurrentVisitContext);
  const {controlAreaGroups} = useServerState();

  return currentVisit?.visit.controlAreaGroupsSnapshot ?? controlAreaGroups;
}

export function InspectionVisitRoute({
  match: {
    params: {visitId, section},
    path,
  },
}) {
  return (
    <CurrentVisitProvider visitId={visitId}>
      <VisitMainLayoutTabs section={section} path={path} />
    </CurrentVisitProvider>
  );
}

function VisitNotAvailable() {
  const t = useAppTranslation();

  return (
    <div className="flex-fill d-flex flex-column overflow-hidden p-3">
      <ApplicationView style={{maxWidth: 1300}}>
        <p>{t("visitNotAvailable")}</p>
        <Link to="/inspection">{t("backToStartPage")}</Link>
      </ApplicationView>
    </div>
  );
}
