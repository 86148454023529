import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {
  Alert,
  AlertAction,
  Select,
  Control,
  TextBox,
} from "@sokigo/components-react-bootstrap";
import {useCallback, useEffect} from "react";
import {useCurrentVisit} from "../../Visit/InspectionVisitRoute";
import {AttachmentsMap} from "./AttachmentsMap";
import {useAttachments} from "./AttachmentsProvider";
import {useIsOnline} from "@sokigo-sbwebb/serviceworker";

export function AttachmentExpandedContent({
  attachment,
  setEditingPosition,
  isEditingPosition,
}) {
  const isOnline = useIsOnline();
  const {
    visit: {
      baseDataSnapshot: {documentTypes},
    },
  } = useCurrentVisit();
  const t = useAppTranslation();
  const {changeAttachment} = useAttachments();

  const onPointDrawn = useCallback(
    (position) => {
      if (!isEditingPosition) {
        return;
      }
      changeAttachment({...attachment, position});
      setEditingPosition(false);
    },
    [attachment, isEditingPosition, changeAttachment, setEditingPosition]
  );

  useEffect(() => {
    if (isOnline) {
      return;
    }
    // stop editing when going offline
    if (isEditingPosition) {
      setEditingPosition(false);
    }
  }, [isOnline, isEditingPosition, setEditingPosition]);

  return (
    <div className="my-3 px-3">
      <Control labelText={t("type")} horizontal>
        <Select
          onChange={(type) => {
            changeAttachment({...attachment, documentType: type});
          }}
          value={attachment.documentType}
          options={documentTypes.sort((dt1, dt2) =>
            dt1.name.localeCompare(dt2.name)
          )}
          getOptionKey={(docType) => docType.id}
          getOptionText={(docType) => docType.name}
        />
      </Control>
      <Control labelText={t("note")} horizontal>
        <TextBox
          value={attachment.note}
          onChange={(value) => changeAttachment({...attachment, note: value})}
        />
      </Control>
      {isEditingPosition && (
        <Alert
          kind="info"
          inline
          hideCloseButton
          actions={
            <AlertAction onClick={() => setEditingPosition(false)}>
              {t("cancel")}
            </AlertAction>
          }
        >
          {t("pointToSpecifyPositionForAttachment")}
        </Alert>
      )}
      <div className="mt-2">
        {isOnline && (
          <AttachmentsMap
            onClick={isEditingPosition ? onPointDrawn : null}
            opaqueAttachment={attachment}
          />
        )}
      </div>
    </div>
  );
}
