import {useMemo} from "react";
import {useServiceWorkerRepository} from "../useServiceWorkerRepository";

export function usePhraseGroupsRepository() {
  const {items, createItem, deleteItem, updateItem} =
    useServiceWorkerRepository("phrase-groups");

  const v = useMemo(
    () => ({
      phraseGroups: items,
      addPhraseGroup: createItem,
      removePhraseGroup: deleteItem,
      updatePhraseGroup: updateItem,
    }),
    [items, createItem, deleteItem, updateItem]
  );

  return v;
}
