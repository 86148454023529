import {useCallback, useMemo} from "react";
import {ControlsModal} from "../../AdminRoutes/AdminVisitForms/EditVisitForm/VisitFormEditor/Controls/ControlsModal";
import {useCurrentVisit, useCurrentVisitInput} from "../InspectionVisitRoute";
import {useAvailableNonFoodControlAreas} from "./useAvailableNonFoodControlAreas";

// Since the common ControlModal component only works with IDs, but the visit needs the full areas+points, the value must be mapped when it's changed
function useMapSelectedControlAreas(flatAreasFromSnapshot, visitControlAreas) {
  return useCallback(
    (selectedControlAreas) => {
      return selectedControlAreas.map((selectedArea) => {
        const snapshotArea = flatAreasFromSnapshot.find(
          (x) => x.id === selectedArea.id
        );
        const visitArea = visitControlAreas.find(
          (x) => x.id === selectedArea.id
        );
        const selectedcontrolPoints = selectedArea.controlPoints.map(
          (controlPointId) => {
            // get item from visit if it is already there in case title or helpText has been changed, otherwise get it from the snapshot
            return (
              visitArea?.controlPoints.find((i) => i.id === controlPointId) ??
              snapshotArea.controlPoints.find((i) => i.id === controlPointId)
            );
          }
        );
        // keep the rest of the area, since it cannot be manipulated anyway
        return {...snapshotArea, controlPoints: selectedcontrolPoints};
      });
    },
    [flatAreasFromSnapshot, visitControlAreas]
  );
}

export function SelectControlAreasButton({onClose}) {
  const {onControlAreaGroupsChange} = useAvailableNonFoodControlAreas();
  const {visitControlAreas} = useCurrentVisitInput();
  const {
    visit: {controlAreaGroupsSnapshot},
  } = useCurrentVisit();

  const flatAreasFromSnapshot = useMemo(
    () => controlAreaGroupsSnapshot.map((x) => x.controlAreas).flat(),
    [controlAreaGroupsSnapshot]
  );

  function controlAreaFromSnapshot(controlArea) {
    return flatAreasFromSnapshot.find((x) => x.id === controlArea.id);
  }

  const controlAreasWithCustomControlPoints = (() => {
    const returnAreas = visitControlAreas.filter((ca) => {
      return !ca.controlPoints?.every((cp) =>
        controlAreaFromSnapshot(ca).controlPoints.some(
          (cp2) => cp.id === cp2.id
        )
      );
    });

    return returnAreas.map((ca) => ({
      ...ca,
      // return only custom control points
      controlPoints: ca.controlPoints.filter(
        (cp) =>
          !controlAreaFromSnapshot(ca).controlPoints.some(
            (cp2) => cp.id === cp2.id
          )
      ),
    }));
  })();

  // map current visit control areas to the same format as visit form control areas, as expected by ControlsModal
  const controlAreas = visitControlAreas.map((controlArea) => ({
    id: controlArea.id,
    controlPoints: controlArea.controlPoints.map((x) => x.id),
  }));

  const getMappedControlAreaGroups = useMapSelectedControlAreas(
    flatAreasFromSnapshot,
    visitControlAreas
  );

  function onChange(selectedControlAreas) {
    const visitControlAreas = getMappedControlAreaGroups(selectedControlAreas);

    if (controlAreasWithCustomControlPoints?.length > 0) {
      // add custom control points to mapped selection from ControlsModal
      controlAreasWithCustomControlPoints.map((ca) => {
        const selectedControlArea = visitControlAreas.find(
          (ca2) => ca.id === ca2.id
        );
        if (selectedControlArea) {
          ca.controlPoints.map((cp) =>
            selectedControlArea.controlPoints.push(cp)
          );
        } else {
          // area doesn't exist in selected control areas, so it only contains custom control points => add whole area
          visitControlAreas.push(ca);
        }
      });
    }

    onControlAreaGroupsChange(visitControlAreas);
  }

  return (
    <>
      <ControlsModal
        onChange={onChange}
        defaultValue={controlAreas}
        buttonKind="primary"
        onClose={onClose}
      />
    </>
  );
}
