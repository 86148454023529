import {useState} from "react";
import {
  Accordion,
  AccordionSection,
  ActionsDropdown,
  ActionsDropdownItem,
  Button,
  Label,
  TextArea,
} from "@sokigo/components-react-bootstrap";
import {InspectionAssessment} from "../../Common/InspectionAssessment";
import {FoodControl24AssessmentSwitch} from "./FoodControl24AssessmentSwitch";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {ConfirmAsssessmentActionModal} from "./ConfirmAssessmentActionModal";

function TextareaWithFoodPhrasePicker({
  className,
  value,
  question,
  onChange,
  disabled,
}) {
  const t = useAppTranslation();
  const cmp = (a, b) => (a > b) - (a < b);
  const sortedPhrases = question.phrases.sort(function (a, b) {
    return cmp(a.name, b.name);
  });
  return (
    <div className={className}>
      {question.phrases.length > 0 && !disabled && (
        <ActionsDropdown
          dropdownText={t("selectPhrase")}
          kind="control"
          className="mb-2"
          menuAlign="start"
        >
          {sortedPhrases.map((p) => (
            <ActionsDropdownItem
              key={p.name}
              onClick={() =>
                onChange((value ?? "") + (value ? "\n" : "") + p.phraseText)
              }
              itemText={
                <div className={"text-truncate"} style={{maxWidth: "30rem"}}>
                  {p.name}: {p.phraseText}
                </div>
              }
            />
          ))}
        </ActionsDropdown>
      )}
      <TextArea
        disabled={disabled}
        value={value ?? ""}
        onChange={(x) => onChange(x)}
        rows={4}
      />
    </div>
  );
}

export function ControlAssessment({
  question,
  currentAssessment,
  onChangeAssessment,
  addAssessment,
  removeAssessment,
  foodFacilityTypeName,
  isMultipleAssessed,
  isLast,
}) {
  const t = useAppTranslation();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationText, setConfirmationText] = useState([]);
  const [onConfirmationOkay, setOnConfirmationOkay] = useState();
  const [expanded, setExpanded] = useState([false]);

  function setNote(note) {
    currentAssessment.note = note;
    onChangeAssessment(currentAssessment);
  }

  function setBeva() {
    currentAssessment.beva = !currentAssessment.beva;
    onChangeAssessment(currentAssessment);
  }

  function setAssessment(assessment) {
    currentAssessment.valid = assessment;
    onChangeAssessment(currentAssessment);
    setShowConfirmation(false);
  }

  function openConfirmationAssessmentChanged(assessment) {
    if (
      !!currentAssessment.valid &&
      assessment !== 0 &&
      currentAssessment.valid !== assessment
    ) {
      setConfirmationText([t("confirmChangeAssessment")]);
      setOnConfirmationOkay(() => () => {
        setAssessment(assessment);
        setExpanded([true]);
      });
      setShowConfirmation(true);
    } else if (!!currentAssessment.valid && assessment === 0) {
      openConfirmationAssessmentDeleted(currentAssessment.id);
    } else {
      setAssessment(assessment);
      if (assessment === 1 || assessment === 2) {
        setExpanded([true]);
      }
    }
  }

  function openConfirmationAssessmentDeleted(assessmentId) {
    setConfirmationText([
      t("confirmRemoveAssessment"),
      t("confirmNoteAndBevaRemove"),
    ]);
    setOnConfirmationOkay(() => () => {
      removeAssessment(assessmentId);
      setExpanded([false]);
    });
    setShowConfirmation(true);
  }

  return (
    <>
      <Accordion
        side={"left"}
        expanded={expanded}
        onExpand={(x) => {
          setExpanded(x);
        }}
      >
        <AccordionSection
          headerContent={
            <div className="mb-2 mt-2">
              <FoodControl24AssessmentSwitch
                value={currentAssessment.valid}
                onChange={openConfirmationAssessmentChanged}
                onRemove={(assessmentId) =>
                  openConfirmationAssessmentDeleted(assessmentId)
                }
                isMultipleAssessed={isMultipleAssessed}
                assessmentId={currentAssessment.id}
              />
              <Button
                kind={
                  currentAssessment.beva === true ? "secondary" : "tertiary"
                }
                onClick={() => {
                  setBeva();
                }}
                style={{
                  marginLeft: "5px",
                  marginTop: "-4px",
                  borderWidth: "1px",
                }}
                className="mr-2"
                fontNormal
                disabled={!currentAssessment.valid}
              >
                {t("beva")}
              </Button>
              <Label style={{verticalAlign: "middle"}}>
                {foodFacilityTypeName}
              </Label>
            </div>
          }
        >
          <TextareaWithFoodPhrasePicker
            value={currentAssessment.note}
            onChange={(text) => setNote(text)}
            question={question}
            disabled={!currentAssessment.valid}
          />
        </AccordionSection>
      </Accordion>

      {currentAssessment.valid === InspectionAssessment.NotAcceptable &&
        isLast && (
          <Button
            kind="primary"
            className="mt-2"
            onClick={() => {
              addAssessment();
            }}
          >
            {t("addIrregularity")}
          </Button>
        )}
      <ConfirmAsssessmentActionModal
        show={showConfirmation}
        header={t("confirm")}
        text={confirmationText}
        onOkay={onConfirmationOkay}
        onCancel={() => setShowConfirmation(false)}
      />
    </>
  );
}
