import {Label} from "@sokigo/components-react-bootstrap";

export function Fields({fields}) {
  return (
    <div className="d-flex flex-row flex-wrap">
      {fields.map(({title, value, rowId}) => {
        return (
          <div key={rowId} className="flex-col px-3 mt-3">
            <div className="d-flex flex-row">
              <div className="flex-col">
                <Label>{title}</Label>
              </div>
            </div>
            <div className="d-flex flex-row">
              <div style={{whiteSpace: "pre-wrap"}} className="flex-col">
                {value}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
