import {
  faArrowRight,
  faDumpsterFire,
  faEllipsisV,
  faQuestion,
  faSync,
  faTrashAlt,
} from "@fortawesome/pro-regular-svg-icons";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {
  ActionsDropdown,
  ActionsDropdownItem,
  Button,
  Panel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableHeaderRow,
  TableRow,
  TableRowActionsCell,
} from "@sokigo/components-react-bootstrap";
import moment from "moment";
import {Fragment, useState} from "react";
import {Link} from "react-router-dom";
import {DeviceVisitCheckinStatus} from "../../sw-comms/device-inspections-repo/DeviceVisitCheckinStatus";
import {
  useCheckInVisit,
  useDeleteDeviceVisit,
  useDeleteServerVisit,
  useDeviceVisits,
} from "../../sw-comms/device-inspections-repo/useDeviceVisits";
import {useCurrentEcosUserId} from "../../useCurrentEcosUserId";
import {CheckinStatusIndicator} from "./CheckinStatusIndicator";
import {SyncStatusIndicator} from "./SyncStatusIndicator";
import {useIsOnline} from "@sokigo-sbwebb/serviceworker";
import {ShowVisitIdModal} from "../Common/ShowVisitIdModal";

export function DeviceVisits() {
  const currentEcosUserId = useCurrentEcosUserId();
  const t = useAppTranslation();
  const deviceVisits = useDeviceVisits();
  const isOnline = useIsOnline();

  const deviceVisitsList = Object.keys(deviceVisits ?? {}).map(
    (x) => deviceVisits[x]
  );

  // Only show visits owned by the current user (in case the device is shared)
  const visibleVisits = deviceVisitsList.filter(
    (x) => x.visit.ownerId === currentEcosUserId
  );

  const deleteDeviceVisit = useDeleteDeviceVisit();
  const deleteServerVisit = useDeleteServerVisit();
  const checkInVisit = useCheckInVisit();

  const [showingVisitId, setShowingVisitId] = useState(null);

  const columns = [
    {
      key: "visitTargetDescription",
      header: t("description"),
      renderCell: ({row}) => row.visit.visitMetadata.visitTargetDescription,
    },
    {
      key: "date",
      header: t("date"),
      renderCell: ({row}) => moment(row.visit.createdDate).format("YYYY-MM-DD"),
    },
    {
      key: "checkinStatus",
      header: t("status"),
      renderCell: ({row}) => (
        <CheckinStatusIndicator checkinStatus={row.checkinStatus} />
      ),
    },
    {
      key: "syncStatus",
      header: t("syncStatus"),
      renderCell: ({row}) => <SyncStatusIndicator visitWithStatus={row} />,
    },
    {
      key: "lastSaved",
      header: t("lastSaved"),
      renderCell: ({row}) =>
        t("lastSavedDateAndDevice", {
          date: moment(row.visit.lastSavedDate).format("YYYY-MM-DD"),
          device: row.visit.lastSavedDevice,
        }),
    },
  ];

  return (
    <Panel className="my-3 p-3" highlightColor="#12bec4">
      <h3 className="mb-3">{t("availableOffline")}</h3>
      {(!visibleVisits || visibleVisits.length === 0) && (
        <div>{t("noVisitsAvailable")}</div>
      )}
      {visibleVisits?.length > 0 && (
        <Table
          rows={visibleVisits}
          columns={columns}
          getRowKey={(x) => x.visit.id}
        >
          <TableHead>
            <TableHeaderRow>
              {(columns) => (
                <>
                  {columns.map((x) => (
                    <TableHeader key={x.key} column={x}>
                      {x.header}
                    </TableHeader>
                  ))}
                  <TableHeader />
                </>
              )}
            </TableHeaderRow>
          </TableHead>
          <TableBody>
            {(rows) =>
              rows.map((r) => {
                // deleted or hijacked => only valid move is to clear?
                const onlyLocal =
                  r.row.checkinStatus ===
                    DeviceVisitCheckinStatus.DeletedOnServer ||
                  r.row.checkinStatus === DeviceVisitCheckinStatus.Hijacked;
                const isCheckingIn =
                  r.row.checkinStatus === DeviceVisitCheckinStatus.CheckingIn;
                const isOwnerChanged =
                  r.row.checkinStatus === DeviceVisitCheckinStatus.OwnerChanged;

                return (
                  <Fragment key={r.key}>
                    <TableRow key={r.key} row={r}>
                      {r.cells.map((c) => (
                        <TableCell key={c.key}>{c.value}</TableCell>
                      ))}
                      <TableRowActionsCell>
                        <ActionsDropdown
                          icon={faEllipsisV}
                          kind="ghost"
                          size="sm"
                        >
                          {!onlyLocal && !isCheckingIn && !isOwnerChanged && (
                            <>
                              <ActionsDropdownItem
                                kind="danger-hover-ghost"
                                onClick={() =>
                                  deleteServerVisit(r.row.visit.id)
                                }
                                itemText={t("remove")}
                                icon={faTrashAlt}
                                disabled={!isOnline}
                              />
                              <ActionsDropdownItem
                                icon={faSync}
                                onClick={() => checkInVisit(r.row.visit.id)}
                                itemText={t("checkIn")}
                                disabled={!isOnline}
                              />
                            </>
                          )}
                          {((onlyLocal && !isCheckingIn) || isOwnerChanged) && (
                            <ActionsDropdownItem
                              kind="danger-hover-ghost"
                              onClick={() => deleteDeviceVisit(r.row.visit.id)}
                              itemText={t("removeVisitFromDevice")}
                              icon={faDumpsterFire}
                            />
                          )}
                          <ActionsDropdownItem
                            onClick={() => setShowingVisitId(r.row.visit.id)}
                            itemText={t("showVisitId")}
                            icon={faQuestion}
                          />
                        </ActionsDropdown>
                        {!isCheckingIn && (
                          <Button
                            size="sm"
                            kind="ghost"
                            tag={Link}
                            to={"/inspection/visit/" + r.row.visit.id}
                            icon={faArrowRight}
                            disabled={onlyLocal || isOwnerChanged}
                          />
                        )}
                      </TableRowActionsCell>
                    </TableRow>
                  </Fragment>
                );
              })
            }
          </TableBody>
        </Table>
      )}
      <ShowVisitIdModal
        visitId={showingVisitId}
        onClose={() => setShowingVisitId(null)}
      />
    </Panel>
  );
}
