import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {DocumentsTable} from "../documents/DocumentsTable";

export function FoodInspectionInformation24({visit}) {
  const t = useAppTranslation();
  return (
    <div className="d-flex flex-column p-4">
      <h5>{t("documents")}</h5>
      {visit.documents.length === 0 && <div>{t("noDocuments")}</div>}
      {visit.documents.length > 0 && (
        <DocumentsTable documents={visit.documents} size="sm" />
      )}
      <div style={{marginTop: "8px", whiteSpace: "pre-wrap"}}>{visit.note}</div>
    </div>
  );
}
