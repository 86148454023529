import {useState} from "react";
import {
  useCurrentVisitInput,
  useMergeCurrentVisitInput,
} from "../InspectionVisitRoute";
import {EditSupportQuestionModal} from "../../Common/EditSupportQuestionModal";

export function useEditSupportQuestion(foodControlQuestionId) {
  const {foodControlSupportQuestionsByFoodControlQuestionId} =
    useCurrentVisitInput();
  const merge = useMergeCurrentVisitInput();
  const [currentSupportQuestionId, setCurrentSupportQuestionId] =
    useState(null);
  const currentSupportQuestion =
    currentSupportQuestionId &&
    foodControlSupportQuestionsByFoodControlQuestionId[
      foodControlQuestionId
    ].find((x) => x.id === currentSupportQuestionId);

  function save(updatedQuestion) {
    const nv = {
      foodControlSupportQuestionsByFoodControlQuestionId: {
        ...foodControlSupportQuestionsByFoodControlQuestionId,
        [foodControlQuestionId]:
          foodControlSupportQuestionsByFoodControlQuestionId[
            foodControlQuestionId
          ].map((sq) =>
            sq.id === currentSupportQuestionId ? updatedQuestion : sq
          ),
      },
    };
    merge(nv);
    setCurrentSupportQuestionId(null);
  }

  const ui = currentSupportQuestion ? (
    <EditSupportQuestionModal
      defaultValue={currentSupportQuestion}
      onCancel={() => setCurrentSupportQuestionId(null)}
      key={foodControlQuestionId}
      onSave={save}
    />
  ) : null;

  return [setCurrentSupportQuestionId, ui];
}
