import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@sokigo/components-react-bootstrap";
import {faCheck} from "@fortawesome/pro-regular-svg-icons";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";

export function BusyFailedModal({onDismiss, header, body}) {
  const t = useAppTranslation();
  return (
    <Modal show centered onClose={onDismiss}>
      <ModalHeader>{header}</ModalHeader>
      <ModalBody>{body}</ModalBody>
      <ModalFooter>
        <Button kind="primary" icon={faCheck} onClick={onDismiss}>
          {t("ok")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
