import {useNotifications} from "@sokigo-sbwebb/default-core";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {createContext, useContext, useEffect, useState} from "react";

const GeoLocationContext = createContext(null);
export function GeoLocationProvider({children}) {
  const t = useAppTranslation();
  const [location, setLocation] = useState(null);
  const {addErrorNotification} = useNotifications();
  useEffect(() => {
    const id = navigator.geolocation.watchPosition(
      ({coords: {latitude, longitude, heading, accuracy}}) => {
        setLocation({
          latitude,
          longitude,
          heading,
          accuracy,
        });
      },
      (err) => {
        if (err?.length > 0) {
          addErrorNotification(
            t("couldNotGetGeoLocationBecause", {reason: err.message})
          );
        }
      },
      {
        enableHighAccuracy: true,
        maximumAge: 0,
      }
    );
    return () => {
      navigator.geolocation.clearWatch(id);
    };
  }, [t, addErrorNotification]);

  return (
    <GeoLocationContext.Provider value={location}>
      {children}
    </GeoLocationContext.Provider>
  );
}

export function useGeoLocation() {
  return useContext(GeoLocationContext);
}
