import {
  TableHead,
  TableHeader,
  TableHeaderRow,
} from "@sokigo/components-react-bootstrap";

export function AttachmentsTableHead() {
  return (
    <TableHead>
      <TableHeaderRow>
        {(columns) => (
          <>
            <TableHeader />
            {columns.map((headerColumn) => (
              <TableHeader key={headerColumn.key} column={headerColumn}>
                {headerColumn.header}
              </TableHeader>
            ))}
            <TableHeader />
          </>
        )}
      </TableHeaderRow>
    </TableHead>
  );
}
