import {useMemo} from "react";
import {useServiceWorkerRepository} from "../useServiceWorkerRepository";

export function useCommonDocumentsMetadataRepository() {
  const {items, createItem, deleteItem, updateItem, createItems} =
    useServiceWorkerRepository("common-documents-metadata");

  const v = useMemo(
    () => ({
      commonDocuments: items,
      addCommonDocument: createItem,
      addCommonDocuments: createItems,
      removeCommonDocument: deleteItem,
      updateCommonDocument: updateItem,
    }),
    [items, createItem, createItems, deleteItem, updateItem]
  );

  return v;
}
