import {createContext, useContext} from "react";
import {useServerState} from "../ServerStateProvider";

const CaseWorkersContext = createContext();

export function CaseWorkersProvider({children}) {
  // todo use useServerState directly
  const {baseData} = useServerState();
  const {caseWorkers} = baseData ?? {};

  return (
    <CaseWorkersContext.Provider value={{caseWorkers}}>
      {children}
    </CaseWorkersContext.Provider>
  );
}

export function useCaseWorkers() {
  return useContext(CaseWorkersContext);
}
