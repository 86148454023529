import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {createContext, useContext} from "react";
import {SaveAsType} from "../../../AdminRoutes/AdminVisitForms/EditVisitForm/VisitFormEditor/SaveAsType";
import {
  useCurrentVisit,
  useCurrentVisitInput,
  useMergeCurrentVisitInput,
} from "../../InspectionVisitRoute";
import {ActionPenaltyTypes} from "./Sections/ActionPenaltyTypes";
import {Actions} from "./Sections/Actions";
import {AdditionalAssessmentsSection} from "./Sections/AdditionalAssessmentsSection";
import {OverallAssessmentSection} from "./Sections/OverallAssessmentSection";
import {InspectionFields} from "./Sections/InspectionFields";
import {VisitOccurrenceFields} from "./Sections/VisitOccurrenceFields";
import {TimeRegistration} from "./Sections/TimeRegistration";

const CompletingInformationContext = createContext();

export function CompletingInformationProvider({children}) {
  const t = useAppTranslation();
  const merge = useMergeCurrentVisitInput();
  const input = useCurrentVisitInput();

  const onChange = (change) => {
    merge({completingInformation: {...change}});
  };

  const penaltyTypes = [
    {id: ActionPenaltyTypes.NoPenalty, title: t("noPenalty")},
    {id: ActionPenaltyTypes.OneTimePenalty, title: t("oneTimePenalty")},
    {id: ActionPenaltyTypes.OnGoingPerDay, title: t("onGoingPerDay")},
    {id: ActionPenaltyTypes.OnGoingPerWeek, title: t("onGoingPerWeek")},
    {id: ActionPenaltyTypes.OnGoingPerMonth, title: t("onGoingPerMonth")},
    {id: ActionPenaltyTypes.OnGoingPerYear, title: t("onGoingPerYear")},
    {
      id: ActionPenaltyTypes.OnGoingPerViolation,
      title: t("onGoingPerViolation"),
    },
  ];
  const notBillableArticle = {
    // TODO this should really be null, but Dropdown doesn't seem to support null values??
    articleId: "00000000-0000-0000-0000-000000000000",
    title: t("notBillable"),
  };

  return (
    <CompletingInformationContext.Provider
      value={{
        onChange,
        value: input?.completingInformation ?? {},
        penaltyTypes: penaltyTypes,
        notBillableArticle,
      }}
    >
      {children}
    </CompletingInformationContext.Provider>
  );
}

export function useCompletingInformationProvider() {
  return useContext(CompletingInformationContext);
}

export function CompletingInformationTab({validationErrors}) {
  const {
    visit: {
      visitMetadata: {saveAsType},
    },
  } = useCurrentVisit();

  return (
    <CompletingInformationProvider>
      {saveAsType !== SaveAsType.VisitOccurrence && (
        <InspectionFields validationErrors={validationErrors} />
      )}
      {saveAsType === SaveAsType.VisitOccurrence && (
        <VisitOccurrenceFields validationErrors={validationErrors} />
      )}
      <TimeRegistration />
      {saveAsType === SaveAsType.FoodInspection && <Actions />}
      {saveAsType !== SaveAsType.VisitOccurrence && (
        <OverallAssessmentSection />
      )}
      {saveAsType === SaveAsType.GenericInspection && (
        <AdditionalAssessmentsSection />
      )}
      {/* <InspectionProtocol validationErrors={validationErrors} /> */}
    </CompletingInformationProvider>
  );
}
