import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {getValidationErrorMessages} from "@sokigo-sbwebb/validation";
import {Control, TextBox, Select} from "@sokigo/components-react-bootstrap";
import {usePhraseGroups} from "src/apps/sokigo-sbwebb-inspection/services/usePhraseGroups";

export function DynamicParagraphSettings({
  onChange,
  dynamicParagraph,
  validationErrors,
}) {
  const t = useAppTranslation();
  const {phraseGroups} = usePhraseGroups();

  const phraseOptions = Object.keys(phraseGroups).map(function (key) {
    return phraseGroups[key];
  });

  return (
    <div className="d-flex flex-column mt-2">
      <Control
        labelText={t("title")}
        horizontal
        errors={getValidationErrorMessages(validationErrors, "title")}
      >
        <TextBox
          value={dynamicParagraph?.title}
          onChange={(title) =>
            onChange({
              ...dynamicParagraph,
              title,
            })
          }
        />
      </Control>
      <Control labelText={t("phrases")} horizontal>
        <Select
          multiple
          withCheckboxes
          options={phraseOptions}
          value={dynamicParagraph?.phraseGroupIds ?? []}
          getOptionValue={(x) => x.id}
          getOptionText={(x) => x.title}
          getOptionKey={(x) => x.id}
          onChange={(phraseGroupIds) =>
            onChange({...dynamicParagraph, phraseGroupIds})
          }
        />
      </Control>
    </div>
  );
}
