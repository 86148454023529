import {useMapEffect} from "./useMapEffect";
import Draw from "ol/interaction/Draw";
import VectorSource from "ol/source/Vector";

export const DrawInteraction = ({options, onDrawEnd}) => {
  useMapEffect(
    (map) => {
      const interaction = new Draw({
        ...options,
        source: options.source || new VectorSource(),
      });
      map.addInteraction(interaction);
      if (onDrawEnd) {
        interaction.on("drawend", onDrawEnd);
      }
      return () => {
        map.removeInteraction(interaction);
      };
    },
    [options]
  );
  return null;
};
