import {
  Table,
  TableSelectRow,
  ActionsDropdown,
  TableRowActionsCell,
  ActionsDropdownItem,
  TableBody,
  TableReorderableRowCell,
  TableCell,
} from "@sokigo/components-react-bootstrap";
import {faEllipsisV, faTrashAlt} from "@fortawesome/pro-regular-svg-icons";
import {useServerState} from "../../../../../../ServerStateProvider";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";

export function ControlAreasTable({
  onChange,
  savedControlAreas,
  setSelectedControlAreaId,
  removeControlArea,
}) {
  const {controlAreaGroups} = useServerState();
  const t = useAppTranslation();

  const columns = [
    {
      key: "key1",
      field: "title",
    },
  ];

  const rows =
    savedControlAreas?.map((x) => ({
      ...x,
      title: Object.keys(controlAreaGroups)
        .map((key) => controlAreaGroups[key])
        .map((x) => x.controlAreas)
        .flat()
        .find((y) => y.id === x.id)?.title,
    })) || [];

  return (
    <Table
      rows={rows}
      columns={columns}
      getRowKey={(x) => x.id}
      onChange={onChange}
      onSelect={(x) => setSelectedControlAreaId(x[0])}
    >
      <TableBody>
        {(rows) =>
          rows.map((r) => (
            <TableSelectRow key={r.key} row={r} single>
              <TableReorderableRowCell />
              {r.cells.map((c) => (
                <TableCell key={c.key}>{c.value}</TableCell>
              ))}
              <TableRowActionsCell>
                <ActionsDropdown
                  icon={faEllipsisV}
                  kind="ghost"
                  size="sm"
                  onClick={(e) => e.stopPropagation()}
                >
                  <ActionsDropdownItem
                    onClick={() => removeControlArea(r.key)}
                    itemText={t("remove")}
                    icon={faTrashAlt}
                    danger
                  />
                </ActionsDropdown>
              </TableRowActionsCell>
            </TableSelectRow>
          ))
        }
      </TableBody>
    </Table>
  );
}
