import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@sokigo/components-react-bootstrap";
import {useState} from "react";

function Thumbnail({fileName, url}) {
  const t = useAppTranslation();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <img
        src={url}
        alt={fileName}
        style={{maxHeight: "2rem"}}
        onClick={() => setIsOpen(true)}
      />
      <Modal show={isOpen} size="xl" static onClose={() => setIsOpen(false)}>
        <ModalHeader>{fileName}</ModalHeader>
        <ModalBody className="d-flex justify-content-center">
          <img src={url} style={{maxWidth: "100%"}} />
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              setIsOpen(false);
            }}
          >
            {t("ok")}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export function useAttachmentsTableColumns(startIndex) {
  const t = useAppTranslation();

  return [
    {
      key: "attachmentNumber",
      field: "attachmentNumber",
      header: "#",
      renderCell: ({index}) => (startIndex ?? 0) + index + 1,
    },
    {
      key: "thumbnail",
      field: "thumbnail",
      header: "",
      renderCell: ({row}) =>
        row.mimeType.match(/^image\//) !== null ? (
          <>
            <Thumbnail url={row.fileUrl} fileName={row.fileName} />
          </>
        ) : null,
    },
    {
      key: "file",
      field: "fileName",
      header: t("file"),
      renderCell: ({row}) => {
        return (
          <a
            style={{maxWidth: "80px", marginTop: "1em"}}
            target="_blank"
            href={row.fileUrl}
            rel="noopener noreferrer"
          >
            {row.fileName}
          </a>
        );
      },
    },
    {
      key: "type",
      field: "documentType",
      header: t("type"),
      renderCell: ({row}) => {
        return row?.documentType?.name;
      },
    },
    {
      key: "note",
      field: "note",
      header: t("note"),
      renderCell: ({row}) => (
        <div style={{maxWidth: "80px", minWidth: "40px"}}>
          <div className="text-truncate">{row.note}</div>
        </div>
      ),
    },
    {
      key: "file",
      field: "fileSize",
      header: t("fileSize"),
      renderCell: ({row}) => {
        return (
          <p
            style={{maxWidth: "80px", marginTop: "1em"}}
            className="d-inline-block text-truncate"
            target="_blank"
            rel="noopener noreferrer"
          >
            {(row.size / 1048576).toLocaleString("sv-SE", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) + " MB"}
          </p>
        );
      },
    },
  ];
}
