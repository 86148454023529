import {useCallback} from "react";
import {
  useCurrentVisitInput,
  useMergeCurrentVisitInput,
} from "../InspectionVisitRoute";

export function useAvailableNonFoodControlAreas() {
  const mergeCurrentVisitInput = useMergeCurrentVisitInput();
  const {visitControlAreas} = useCurrentVisitInput();

  const onControlAreaGroupsChange = useCallback(
    (visitControlAreas) => {
      mergeCurrentVisitInput({visitControlAreas});
    },
    [mergeCurrentVisitInput]
  );

  const addNewControlPoint = useCallback(
    (controlArea, newControlPoint) =>
      mergeCurrentVisitInput({
        visitControlAreas: visitControlAreas.map((x) =>
          x.id !== controlArea.id
            ? x
            : {...x, controlPoints: [...x.controlPoints, newControlPoint]}
        ),
      }),
    [mergeCurrentVisitInput, visitControlAreas]
  );

  const updateControlPoint = useCallback(
    (controlArea, updatedControlItem) =>
      mergeCurrentVisitInput({
        visitControlAreas: visitControlAreas.map((originalArea) =>
          originalArea.id !== controlArea.id
            ? originalArea
            : {
                ...originalArea,
                controlPoints: originalArea.controlPoints.map(
                  (originalControlItem) =>
                    originalControlItem.id !== updatedControlItem.id
                      ? originalControlItem
                      : updatedControlItem
                ),
              }
        ),
      }),
    [mergeCurrentVisitInput, visitControlAreas]
  );

  const removeControlPoint = useCallback(
    (controlAreaId, controlItemId) => {
      const newVisitControlAreas = visitControlAreas.map((x) =>
        x.id !== controlAreaId
          ? x
          : {
              ...x,
              controlPoints: x.controlPoints.filter(
                (ci) => ci.id !== controlItemId
              ),
            }
      );
      mergeCurrentVisitInput({visitControlAreas: newVisitControlAreas});
    },
    [mergeCurrentVisitInput, visitControlAreas]
  );

  return {
    onControlAreaGroupsChange,
    addNewControlPoint,
    updateControlPoint,
    removeControlPoint,
    controlAreas: visitControlAreas,
  };
}
