import {
  Table,
  ActionsDropdown,
  TableRowActionsCell,
  ActionsDropdownItem,
  TableBody,
  TableReorderableRowCell,
  TableCell,
  TableRow,
} from "@sokigo/components-react-bootstrap";
import {faEllipsisV, faTrashAlt} from "@fortawesome/pro-regular-svg-icons";
import {useServerState} from "../../../../../../ServerStateProvider";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";

export function ControlPointsTable({
  onChange,
  selectedControlArea,
  removeControlPoint,
}) {
  const {controlAreaGroups} = useServerState();
  const t = useAppTranslation();
  const columns = [
    {
      key: "key1",
      field: "title",
    },
  ];

  const rows =
    selectedControlArea.controlPoints?.map((pointId) => ({
      id: pointId,
      title: Object.values(controlAreaGroups)
        .map((x) => x.controlAreas)
        .flat()
        .find((y) => y.id === selectedControlArea.id)
        ?.controlPoints?.find((y) => y.id === pointId)?.title,
    })) || [];

  return (
    <Table
      rows={rows}
      columns={columns}
      getRowKey={(x) => x.id}
      onChange={onChange}
    >
      <TableBody>
        {(rows) =>
          rows.map((r) => (
            <TableRow key={r.key} row={r}>
              <TableReorderableRowCell />
              {r.cells.map((c) => (
                <TableCell key={c.key}>{c.value}</TableCell>
              ))}
              <TableRowActionsCell>
                <ActionsDropdown
                  icon={faEllipsisV}
                  kind="ghost"
                  size="sm"
                  onClick={(e) => e.stopPropagation()}
                >
                  <ActionsDropdownItem
                    onClick={() => removeControlPoint(r.key)}
                    itemText={t("remove")}
                    icon={faTrashAlt}
                    danger
                  />
                </ActionsDropdown>
              </TableRowActionsCell>
            </TableRow>
          ))
        }
      </TableBody>
    </Table>
  );
}
