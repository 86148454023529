import {faPlus} from "@fortawesome/pro-regular-svg-icons";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {Button} from "@sokigo/components-react-bootstrap";
import {useState} from "react";
import {
  useCurrentVisitInput,
  useMergeCurrentVisitInput,
} from "../InspectionVisitRoute";
import {EditCasePartyRolePresentPartyModal} from "./EditCasePartyRolePresentPartyModal";

export function AddCasePartyRoleButton({casePartyRole}) {
  const t = useAppTranslation();
  const [showing, setShowing] = useState(false);

  const {presentParties, finishVisitInfo} = useCurrentVisitInput();
  const merge = useMergeCurrentVisitInput();

  function onSave(addedParty) {
    merge({
      presentParties: [...(presentParties ?? []), addedParty],
      finishVisitInfo: {
        ...finishVisitInfo,
        emailInfo: {
          ...finishVisitInfo.emailInfo,
          // add to selectedPresentPartyIds if email address specified
          sendToPresentPartyIds:
            addedParty.emailAddressForDispatch ||
            addedParty.contactDetailIdForDispatch
              ? [
                  ...(finishVisitInfo.emailInfo.sendToPresentPartyIds ?? []),
                  addedParty.id,
                ]
              : finishVisitInfo.emailInfo.sendToPresentPartyIds,
        },
      },
    });
    setShowing(false);
  }

  const alreadyAdded = presentParties?.some(
    (x) =>
      x.partyId === casePartyRole.partyId &&
      x.contactId === casePartyRole.contactId
  );

  return (
    <>
      <Button
        disabled={alreadyAdded}
        kind="ghost"
        size="sm"
        icon={faPlus}
        onClick={() => setShowing(true)}
        tooltipText={t("addToPresentParties")}
      />
      {showing && (
        <EditCasePartyRolePresentPartyModal
          casePartyRole={casePartyRole}
          onClose={() => setShowing(false)}
          onSave={onSave}
          title={t("addToPresentParties")}
        />
      )}
    </>
  );
}
