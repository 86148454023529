import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {
  ButtonSwitch,
  ButtonSwitchOption,
  Control,
} from "@sokigo/components-react-bootstrap";
import {SearchType} from "./SearchType";

export function SearchTypeSwitch({value, onChange}) {
  const t = useAppTranslation();
  return (
    <Control labelText={t("searchType")}>
      <ButtonSwitch value={value} onChange={onChange}>
        <ButtonSwitchOption value={SearchType.Estate}>
          {t("searchTypeEstate")}
        </ButtonSwitchOption>
        <ButtonSwitchOption value={SearchType.Case}>
          {t("searchTypeCase")}
        </ButtonSwitchOption>
      </ButtonSwitch>
    </Control>
  );
}
