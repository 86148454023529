import {
  faCheck,
  faPlus,
  faTimes,
  faTrashAlt,
} from "@fortawesome/pro-regular-svg-icons";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {
  getValidationErrorMessages,
  getValidationErrors,
  Validation,
} from "@sokigo-sbwebb/validation";
import {TextBox} from "@sokigo/components-react-bootstrap";
import {
  Button,
  Control,
  Modal,
  Select,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  TableBody,
  TableCell,
  TableExpandedRow,
  TableExpandRowCell,
  TableReorderableRowCell,
  TableRow,
  TableRowActionsCell,
  TextArea,
} from "@sokigo/components-react-bootstrap";

import {Fragment, useMemo, useState} from "react";
import {v4 as uuid} from "uuid";
import {useServerState} from "../../../ServerStateProvider";
import validationSchema from "./GenericControlAreaGroupsSchema.json";

function ControlPoints({controlPoints, onChange}) {
  const t = useAppTranslation();
  const [selectedControlItemId, setSelectedControlItemId] = useState(null);

  const createNewControlItem = () => {
    const newControlItem = {id: uuid(), phraseGroups: []};
    onChange([...controlPoints, newControlItem]);
    setSelectedControlItemId(newControlItem.id);
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="py-2 mb-2">{t("controlPoints")}</h4>
        <Button
          icon={faPlus}
          onClick={() => createNewControlItem()}
          kind="primary"
        >
          {t("addControlPoint")}
        </Button>
      </div>
      <Table
        rows={controlPoints}
        onChange={onChange}
        columns={[
          {
            key: 1,
            renderCell: ({row, onRowChange}) => {
              return (
                <div className="form-control-wrapper">
                  <TextBox
                    size="sm"
                    value={row.title ?? ""}
                    onChange={(title) => onRowChange({...row, title})}
                    invalid={!row.title}
                  />
                </div>
              );
            },
          },
        ]}
        getRowKey={(x) => x.id}
        expanded={selectedControlItemId ? [selectedControlItemId] : []}
        onExpand={(ids) =>
          setSelectedControlItemId(ids.length === 1 ? ids[0] : null)
        }
      >
        <TableBody>
          {(rows) =>
            rows.map((r) => (
              <Fragment key={r.key}>
                <TableRow key={r.key} row={r} invalid={!r.row.title}>
                  <TableExpandRowCell single />
                  <TableReorderableRowCell />
                  {r.cells.map((c) => (
                    <TableCell key={c.key}>{c.value}</TableCell>
                  ))}
                  <TableRowActionsCell>
                    <Button
                      size="sm"
                      kind="danger-hover-ghost"
                      icon={faTrashAlt}
                      onClick={() =>
                        onChange(controlPoints.filter((x) => x.id !== r.row.id))
                      }
                      tooltipText={t("remove")}
                    />
                  </TableRowActionsCell>
                </TableRow>
                <TableExpandedRow row={r} colSpan={r.cells.length + 3}>
                  <Control labelText={t("phraseGroups")} className="pt-2 ml-3">
                    <PhraseGroupsPicker
                      value={r.row.phraseGroups}
                      onChange={(phraseGroups) =>
                        r.onRowChange({...r.row, phraseGroups})
                      }
                    />
                  </Control>
                  <Control labelText={t("helpText")} className="ml-3">
                    <TextArea
                      name="name"
                      defaultValue={r.row.helpText}
                      onChange={(helpText) =>
                        r.onRowChange({...r.row, helpText})
                      }
                      rows={3}
                    />
                  </Control>
                </TableExpandedRow>
              </Fragment>
            ))
          }
        </TableBody>
      </Table>
    </>
  );
}

function ControlAreas({
  controlAreas,
  onChange,
  selectedControlAreaId,
  setSelectedControlAreaId,
  controlAreasWithErrors,
  validationErrors,
}) {
  const t = useAppTranslation();

  function createNewControlArea() {
    const newControlArea = {
      id: uuid(),
      phraseGroups: [],
    };
    setSelectedControlAreaId(newControlArea.id);
    onChange([...controlAreas, newControlArea]);
  }

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="py-2 mb-2">{t("controlAreas")}</h4>
        <Button
          icon={faPlus}
          onClick={() => createNewControlArea()}
          kind="primary"
        >
          {t("addControlArea")}
        </Button>
      </div>
      <Table
        rows={controlAreas}
        onChange={onChange}
        columns={[
          {
            key: 1,
            renderCell: ({row, onRowChange, index}) => {
              const errs = getValidationErrors(
                validationErrors,
                index,
                "title"
              );
              return (
                <div className="form-control-wrapper">
                  <TextBox
                    size="sm"
                    value={row.title ?? ""}
                    onChange={(title) => onRowChange({...row, title})}
                    invalid={errs.length > 0}
                  />
                </div>
              );
            },
          },
        ]}
        getRowKey={(x) => x.id}
        expanded={selectedControlAreaId ? [selectedControlAreaId] : []}
        onExpand={(ids) =>
          setSelectedControlAreaId(ids.length === 1 ? ids[0] : null)
        }
      >
        <TableBody>
          {(rows) =>
            rows.map((r) => (
              <Fragment key={r.key}>
                <TableRow
                  key={r.key}
                  row={r}
                  invalid={
                    !r.row.title || controlAreasWithErrors.includes(r.row.id)
                  }
                >
                  <TableExpandRowCell single />
                  <TableReorderableRowCell />
                  {r.cells.map((c) => (
                    <TableCell key={c.key}>{c.value}</TableCell>
                  ))}
                  <TableRowActionsCell>
                    <Button
                      size="sm"
                      kind="danger-hover-ghost"
                      icon={faTrashAlt}
                      onClick={() =>
                        onChange(controlAreas.filter((x) => x.id !== r.row.id))
                      }
                      tooltipText={t("remove")}
                    />
                  </TableRowActionsCell>
                </TableRow>
                <TableExpandedRow row={r} colSpan={r.cells.length + 3}>
                  <Control labelText={t("phraseGroups")} className="ml-3 pt-2">
                    <PhraseGroupsPicker
                      value={r.row.phraseGroups}
                      onChange={(phraseGroups) =>
                        r.onRowChange({...r.row, phraseGroups})
                      }
                    />
                  </Control>
                </TableExpandedRow>
              </Fragment>
            ))
          }
        </TableBody>
      </Table>
    </>
  );
}

function PhraseGroupsPicker({value, onChange}) {
  const t = useAppTranslation();
  const {phraseGroups} = useServerState();

  const phraseGroupsList = Object.keys(phraseGroups)
    .map((x) => phraseGroups[x])
    .sort((a, b) => a.title.localeCompare(b.title, t("locale")));

  return (
    <Select
      options={phraseGroupsList}
      value={value}
      onChange={(phraseGroups) => onChange(phraseGroups)}
      getOptionValue={(x) => x.id}
      getOptionText={(x) => x.title}
      getOptionKey={(x) => x.id}
      multiple
      withCheckboxes
    />
  );
}

export function ManageGenericControlAreaGroupsModal({
  controlAreaGroup,
  onClose,
  show,
  onSave,
  headerTitle,
}) {
  const t = useAppTranslation();
  const [value, setValue] = useState(() => controlAreaGroup ?? {id: uuid()});
  const [saving, setSaving] = useState(false);
  const [selectedControlAreaId, setSelectedControlAreaId] = useState(null);
  const selectedControlArea = value.controlAreas?.find(
    (x) => x.id === selectedControlAreaId
  );

  const validation = useMemo(() => new Validation(validationSchema), []);
  const validationErrors = validation.validate(value, t);

  async function save() {
    setSaving(true);
    try {
      await onSave(value);
    } finally {
      setSaving(false);
    }
  }

  const controlAreasWithErrors = value.controlAreas
    ?.filter(
      (ca) =>
        !ca.title ||
        !ca.controlPoints ||
        ca.controlPoints.length === 0 ||
        ca.controlPoints.some((x) => !x.title)
    )
    .map((x) => x.id);

  return (
    <Modal size="xl" show={show} onClose={onClose} scrollable={true} static>
      <fieldset disabled={saving}>
        <ModalHeader>{headerTitle}</ModalHeader>
        <ModalBody>
          <Control
            className="col-5"
            labelText={t("title")}
            errors={getValidationErrorMessages(validationErrors, "title")}
          >
            <TextBox
              value={value.title ?? ""}
              onChange={(title) => setValue({...value, title})}
            />
          </Control>
          <div className="row" style={{maxHeight: "calc(100vh  - 20rem)"}}>
            <div className="col">
              <ControlAreas
                controlAreas={value.controlAreas ?? []}
                onChange={(controlAreas) =>
                  setValue((v) => ({...v, controlAreas}))
                }
                selectedControlAreaId={selectedControlAreaId}
                setSelectedControlAreaId={setSelectedControlAreaId}
                controlAreasWithErrors={controlAreasWithErrors}
              />
            </div>
            <div className="col">
              <div className="d-flex flex-column">
                {selectedControlAreaId && (
                  <ControlPoints
                    controlPoints={selectedControlArea?.controlPoints ?? []}
                    onChange={(controlPoints) => {
                      const updatedControlArea = {
                        ...selectedControlArea,
                        controlPoints,
                      };
                      const updatedControlAreas = value.controlAreas.map((x) =>
                        x.id === selectedControlAreaId ? updatedControlArea : x
                      );
                      setValue((v) => ({
                        ...v,
                        controlAreas: updatedControlAreas,
                      }));
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            kind="secondary"
            onClick={onClose}
            className="mr-2"
            icon={faTimes}
          >
            {t("cancel")}
          </Button>
          <Button
            onClick={save}
            icon={faCheck}
            disabled={
              getValidationErrors(validationErrors).length > 0 ||
              !value.controlAreas ||
              value.controlAreas?.length === 0 ||
              controlAreasWithErrors?.length > 0
            }
            kind="primary"
          >
            {t("ok")}
          </Button>
        </ModalFooter>
      </fieldset>
    </Modal>
  );
}
