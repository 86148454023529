import {
  faCheck,
  faEllipsisV,
  faPlus,
  faTimes,
  faTrashAlt,
} from "@fortawesome/pro-regular-svg-icons";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {
  getValidationErrorMessages,
  Validation,
} from "@sokigo-sbwebb/validation";
import {
  ActionsDropdown,
  ActionsDropdownItem,
  Button,
  Control,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  TableBody,
  TableCell,
  TableExpandedRow,
  TableExpandRowCell,
  TableReorderableRowCell,
  TableRow,
  TableRowActionsCell,
  TextBox,
} from "@sokigo/components-react-bootstrap";
import {Fragment, useMemo, useState} from "react";
import {v4 as uuid} from "uuid";
import {
  useCurrentVisit,
  useCurrentVisitInput,
  useMergeCurrentVisitInput,
} from "../InspectionVisitRoute";
import ParagraphTitleSchema from "./ParagraphTitleSchema.json";
import {TextAreaSection} from "./TextAreaSection";

function AddDynamicParagraphButton() {
  const t = useAppTranslation();
  const [adding, setAdding] = useState(false);
  const {dynamicParagraphs} = useCurrentVisitInput();
  const merge = useMergeCurrentVisitInput();

  function save(title) {
    merge({dynamicParagraphs: [...dynamicParagraphs, {id: uuid(), title}]});
    setAdding(false);
  }

  return (
    <>
      <Button
        className="ml-1"
        kind="primary"
        icon={faPlus}
        onClick={() => setAdding(true)}
      >
        {t("addDynamicParagraph")}
      </Button>
      <ParagraphTitleModal
        defaultValue={""}
        onClose={() => setAdding(false)}
        onSave={(title) => save(title)}
        show={adding}
      />
    </>
  );
}

export function DynamicParagraphs() {
  const t = useAppTranslation();
  const {
    visit: {
      visitFormSnapshot: {protocolTexts},
    },
  } = useCurrentVisit();
  const {dynamicParagraphs, dynamicParagraphValues} = useCurrentVisitInput();
  const merge = useMergeCurrentVisitInput();

  if (!protocolTexts?.allowDynamicParagraphs) {
    return null;
  }

  return (
    <div>
      <div className="d-flex flex-row justify-content-between align-items-center">
        <h4>{t("dynamicParagraphs")}</h4>
        <AddDynamicParagraphButton />
      </div>
      <Table
        rows={dynamicParagraphs}
        onChange={(dynamicParagraphs) => merge({dynamicParagraphs})}
        columns={[
          {
            key: "title",
            renderCell: ({row}) => row.title,
          },
        ]}
        getRowKey={(x) => x.id}
        borderless
      >
        <TableBody>
          {(rows) =>
            rows.map((r) => (
              <Fragment key={r.key}>
                <TableRow key={r.key} row={r}>
                  <TableExpandRowCell row={r} />
                  <TableReorderableRowCell />
                  {r.cells.map((c) => (
                    <TableCell key={c.key}>{c.value}</TableCell>
                  ))}
                  <TableRowActionsCell className="w-0 text-nowrap">
                    <DynamicParagraphActions dynamicParagraph={r.row} />
                  </TableRowActionsCell>
                </TableRow>
                <TableExpandedRow row={r} colSpan={4}>
                  <TextAreaSection
                    value={dynamicParagraphValues[r.row.id] ?? ""}
                    onChange={(value) =>
                      merge({
                        dynamicParagraphValues: {
                          ...dynamicParagraphValues,
                          [r.row.id]: value,
                        },
                      })
                    }
                    phraseGroupIds={r.row.phraseGroupIds}
                  />
                </TableExpandedRow>
              </Fragment>
            ))
          }
        </TableBody>
      </Table>
    </div>
  );
}

function DynamicParagraphActions({dynamicParagraph: {id, title}}) {
  const t = useAppTranslation();
  const [showEditTitleModal, setShowEditTitleModal] = useState();
  const {dynamicParagraphs, dynamicParagraphValues} = useCurrentVisitInput();
  const mergeCurrentVisitInput = useMergeCurrentVisitInput();

  function remove() {
    const nv = {...dynamicParagraphValues};
    delete nv[id];
    mergeCurrentVisitInput({
      dynamicParagraphValues,
      dynamicParagraphs: dynamicParagraphs.filter((x) => x.id !== id),
    });
  }

  return (
    <>
      <ActionsDropdown icon={faEllipsisV} kind="ghost" size="sm" key={id}>
        <ActionsDropdownItem
          onClick={remove}
          itemText={t("remove")}
          icon={faTrashAlt}
          danger
        />
        <ActionsDropdownItem
          onClick={() => setShowEditTitleModal(true)}
          itemText={t("changeTitle")}
        />
      </ActionsDropdown>
      <ParagraphTitleModal
        onSave={(title) => {
          mergeCurrentVisitInput({
            dynamicParagraphs: dynamicParagraphs.map((x) =>
              x.id === id ? {...x, title} : x
            ),
          });
          setShowEditTitleModal(false);
        }}
        defaultValue={title}
        show={showEditTitleModal}
        onClose={() => setShowEditTitleModal(false)}
      />
    </>
  );
}

function ParagraphTitleModal({onSave, defaultValue, onClose, show}) {
  const t = useAppTranslation();
  const [localTitle, setLocalTitle] = useState(defaultValue);

  const validation = useMemo(() => new Validation(ParagraphTitleSchema), []);
  const validationErrors = useMemo(
    () => validation.validate({title: localTitle}, t),
    [localTitle, validation, t]
  );

  function save() {
    onSave(localTitle);
  }

  const errors = getValidationErrorMessages(validationErrors, "title");
  const canSave = errors.length === 0;

  return (
    <Modal size="xl" show={show} onClose={onClose} static>
      <ModalHeader>{t("changeTitle")}</ModalHeader>
      <ModalBody>
        <Control errors={errors}>
          <TextBox
            value={localTitle}
            onChange={(title) => setLocalTitle(title)}
          />
        </Control>
      </ModalBody>
      <ModalFooter>
        <Button
          kind="secondary"
          onClick={onClose}
          className="mr-2"
          icon={faTimes}
        >
          {t("cancel")}
        </Button>
        <Button
          onClick={save}
          disabled={!canSave}
          kind="primary"
          icon={faCheck}
        >
          {t("ok")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
