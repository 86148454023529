import {Tooltip} from "@sokigo/components-react-bootstrap";
import {useState} from "react";
import {useCurrentVisitInput} from "../InspectionVisitRoute";
import ControlAreaPoints from "./ControlAreaPoints";
import {NonFoodControlAreaNavigationMenuButton} from "./NonFoodControlAreaNavigationMenuButton";
import {useAvailableNonFoodControlAreas} from "./useAvailableNonFoodControlAreas";

export function NonFoodInspectionControlTab() {
  const {controlAreas} = useAvailableNonFoodControlAreas();
  const [selectedControlAreaId, setSelectedControlAreaId] = useState(
    () => controlAreas?.[0]?.id ?? null
  );
  const {visitControlAreas} = useCurrentVisitInput();
  const currentArea = visitControlAreas?.find(
    (x) => x.id === selectedControlAreaId
  );

  return (
    <div>
      <div className="d-flex flex-row align-items-center rounded bg-normal my-3">
        <NonFoodControlAreaNavigationMenuButton
          onSelect={(controlAreaId) => setSelectedControlAreaId(controlAreaId)}
        />
        {currentArea && (
          <Tooltip text={currentArea.title} side="right">
            <h3 className="ml-2 text-truncate">{currentArea.title}</h3>
          </Tooltip>
        )}
      </div>
      {currentArea && <ControlAreaPoints controlArea={currentArea} />}
    </div>
  );
}
