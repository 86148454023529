import {Fields} from "./Fields";
import {Items} from "./Items";
import {Panel} from "@sokigo/components-react-bootstrap";

export function Section({section: {title, fields, items}}) {
  return (
    <Panel className="py-3 mt-3">
      <h4 className="mx-3">{title}</h4>
      {fields && <Fields fields={fields} />}
      {items && items.length > 0 && <Items items={items} />}
    </Panel>
  );
}
