export default function getPrettyFileSize(n) {
  const units = ["B", "KiB", "MiB", "GiB", "TiB"];
  let i = 0;
  while (n > 1024 && i < units.length - 1) {
    i++;
    n /= 1024;
  }
  n = Math.round((n + Number.EPSILON) * 100) / 100;
  return n + " " + units[i];
}
