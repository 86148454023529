import {useCurrentVisitInput} from "../InspectionVisitRoute";
import {useServerState} from "src/apps/sokigo-sbwebb-inspection/ServerStateProvider";

export function useAttachmentSizeValidation() {
  const {attachments} = useCurrentVisitInput();
  const {baseData: {attachmentSizeLimit} = {}} = useServerState();
  const sizecollection = attachments.map((x) => x.size);
  const totalsize = sizecollection.reduce((a, b) => (a = a + b), 0);
  const totalInMb = (totalsize / 1048576).toFixed(2);

  return totalInMb >= attachmentSizeLimit ? true : false;
}
