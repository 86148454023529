import {useMemo, useState} from "react";
import {v4 as uuid} from "uuid";
import {ClaimTransformation} from "./ClaimTransformation";
import {ClaimTransformSourceValueMode} from "./ClaimTransformSourceValueMode";
import {ClaimTransformTargetValueMode} from "./ClaimTransformTargetValueMode";
import {faPlus, faTrashAlt} from "@fortawesome/pro-regular-svg-icons";
import {
  Button,
  Col,
  Row,
  Table,
  TableBody,
  TableCell,
  TableReorderableRowCell,
  TableSelectRow,
} from "@sokigo/components-react-bootstrap";

export function ClaimTransformations({claimTypes, value, onChange}) {
  const [selectedTransformId, setSelectedTransformId] = useState(null);
  const selectedTransform = useMemo(
    () => value?.find((x) => x.id === selectedTransformId),
    [value, selectedTransformId]
  );

  const listItems =
    value?.map((x) => ({
      id: x.id,
      title:
        x.title ||
        [
          claimTypes?.find((t) => t.type === x.sourceClaimType)?.description ??
            x.sourceClaimType ??
            "<unknown source type>",
          x.sourceClaimValueMode ===
            ClaimTransformSourceValueMode.SpecificValue &&
            '"' + x.sourceClaimValue + '"',
          " → ",
          claimTypes?.find((t) => t.type === x.targetClaimType)?.description ??
            x.targetClaimType ??
            "<unknown target type>",
          x.targetClaimValueMode ===
            ClaimTransformTargetValueMode.SpecificValue &&
            '"' + x.targetClaimValue + '"',
        ]
          .filter((x) => x)
          .join(" "),
    })) ?? [];

  return (
    <>
      <Button
        className="mb-3"
        kind="primary"
        icon={faPlus}
        onClick={() => {
          const id = uuid();
          onChange([...value, {id, title: "New transform"}]);
          setSelectedTransformId(id);
        }}
      >
        Lägg till
      </Button>
      <Row>
        <Col sm={9}>
          <Table
            rows={listItems}
            columns={[{key: "title", field: "title"}]}
            getRowKey={(x) => x.id}
            onChange={(sortedItems) =>
              onChange(sortedItems.map((x) => value.find((v) => v.id === x.id)))
            }
            selected={[selectedTransformId]}
            onSelect={(ids) =>
              setSelectedTransformId((x) => (x === ids[0] ? null : ids[0]))
            }
          >
            <TableBody>
              {(rows) =>
                rows.map((r) => (
                  <TableSelectRow key={r.key} row={r} single>
                    <TableReorderableRowCell />
                    {r.cells.map((c) => (
                      <TableCell className="pl-0" key={c.key}>
                        {c.value}
                      </TableCell>
                    ))}
                    <TableCell className="pl-0 w-1px">
                      <Button
                        size="sm"
                        kind="danger-hover-ghost"
                        icon={faTrashAlt}
                        onClick={(e) => {
                          e.stopPropagation();
                          onChange(value.filter((x) => x.id !== r.row.id));
                        }}
                      />
                    </TableCell>
                  </TableSelectRow>
                ))
              }
            </TableBody>
          </Table>
        </Col>
        <Col sm={9}>
          {selectedTransform && (
            <>
              <hr />
              <ClaimTransformation
                claimTypes={claimTypes}
                value={selectedTransform}
                onChange={(transform) =>
                  onChange(
                    value.map((x) => (x.id === transform.id ? transform : x))
                  )
                }
              />
            </>
          )}
        </Col>
      </Row>
    </>
  );

  // return (
  //   <Expander title="Claim Transformations" initiallyExpanded>
  //     <Button
  //       className="mb-1"
  //       color="success"
  //       icon="plus"
  //       onClick={() => {
  //         const id = uuid();
  //         onChange([...value, {id, title: "New transform"}]);
  //         setSelectedTransformId(id);
  //       }}
  //     >
  //       Lägg till
  //     </Button>

  //     <SortableList
  //       items={listItems}
  //       renderItem={(x) => x.sourceType}
  //       select={(id) => setSelectedTransformId((x) => (x === id ? null : id))}
  //       selectedId={selectedTransformId}
  //       actions={[
  //         {
  //           icon: faTrashAlt,
  //           onClick: (x) => onChange(value.filter((t) => t.id !== x.id)),
  //         },
  //       ]}
  //       onChange={(sortedItems) =>
  //         onChange(sortedItems.map((x) => value.find((v) => v.id === x.id)))
  //       }
  //     />

  //     {selectedTransform && (
  //       <ClaimTransformation
  //         claimTypes={claimTypes}
  //         value={selectedTransform}
  //         onChange={(transform) =>
  //           onChange(value.map((x) => (x.id === transform.id ? transform : x)))
  //         }
  //       />
  //     )}
  //   </Expander>
  // );
}
