import {Icon} from "@sokigo-sbwebb/default-components";
import {ClaimTransformConcurrencyMode} from "./ClaimTransformConcurrencyMode";
import {ClaimTransformSourceSelection} from "./ClaimTransformSourceSelection";
import {ClaimTransformSourceValueMode} from "./ClaimTransformSourceValueMode";
import {ClaimTransformTargetValueMode} from "./ClaimTransformTargetValueMode";
import {
  ButtonSwitch,
  ButtonSwitchOption,
  Control,
  Select,
  TextBox,
} from "@sokigo/components-react-bootstrap";
import {faArrowRight} from "@fortawesome/pro-regular-svg-icons";

export function ClaimTransformation({value, onChange, claimTypes}) {
  function c(change) {
    onChange({...value, ...change});
  }

  return (
    <div>
      <Control labelText="Title">
        <TextBox value={value.title} onChange={(title) => c({title})} />
      </Control>
      <Control labelText="Concurrency">
        <ButtonSwitch
          value={value.concurrencyMode || ClaimTransformConcurrencyMode.Add}
          onChange={(value) => c({concurrencyMode: value})}
        >
          <ButtonSwitchOption value={ClaimTransformConcurrencyMode.Add}>
            Add
          </ButtonSwitchOption>
          {/* Not relevant for Ecos integration settings
          <ButtonSwitchOption value={ClaimTransformConcurrencyMode.Replace}>
            Replace
          </ButtonSwitchOption>
          */}
          <ButtonSwitchOption value={ClaimTransformConcurrencyMode.Skip}>
            Skip
          </ButtonSwitchOption>
        </ButtonSwitch>
      </Control>
      <Control labelText="Source">
        <ButtonSwitch
          value={
            value.sourceSelection || ClaimTransformSourceSelection.Incoming
          }
          onChange={(value) => c({sourceSelection: value})}
        >
          <ButtonSwitchOption value={ClaimTransformSourceSelection.Incoming}>
            Incoming claims
          </ButtonSwitchOption>
          <ButtonSwitchOption value={ClaimTransformSourceSelection.Transformed}>
            Transformed claims
          </ButtonSwitchOption>
          <ButtonSwitchOption value={ClaimTransformSourceSelection.All}>
            All claims
          </ButtonSwitchOption>
        </ButtonSwitch>
      </Control>
      <Control labelText="Source claim type">
        <Select
          options={claimTypes}
          getOptionText={(t) => t.description}
          getOptionValue={(c) => c.type}
          getOptionKey={(c) => c.type}
          value={value.sourceClaimType}
          onChange={(type) => c({sourceClaimType: type})}
          placeholder="Source claim type..."
        />
      </Control>
      <Control labelText="Source claim value">
        <ButtonSwitch
          value={
            value.sourceClaimValueMode || ClaimTransformSourceValueMode.AnyValue
          }
          onChange={(sourceClaimValueMode) => c({sourceClaimValueMode})}
        >
          <ButtonSwitchOption value={ClaimTransformSourceValueMode.AnyValue}>
            Any value
          </ButtonSwitchOption>
          <ButtonSwitchOption
            value={ClaimTransformSourceValueMode.SpecificValue}
          >
            Specific value
          </ButtonSwitchOption>
        </ButtonSwitch>
      </Control>
      {value.sourceClaimValueMode ===
        ClaimTransformSourceValueMode.SpecificValue && (
        <Control labelText="Source claim specific value">
          <TextBox
            label=""
            value={value.sourceClaimValue}
            onChange={(sourceClaimValue) => c({sourceClaimValue})}
          />
        </Control>
      )}
      <Icon icon={faArrowRight} className="mb-3" />
      <Control labelText="Target claim type">
        <Select
          options={claimTypes}
          getOptionText={(t) => t.description}
          getOptionValue={(c) => c.type}
          getOptionKey={(c) => c.type}
          value={value.targetClaimType}
          onChange={(type) => c({targetClaimType: type})}
          placeholder="Target claim type..."
        />
      </Control>
      <Control labelText="Target claim value">
        <ButtonSwitch
          value={
            value.targetClaimValueMode ||
            ClaimTransformTargetValueMode.SourceValue
          }
          onChange={(targetClaimValueMode) => c({targetClaimValueMode})}
        >
          <ButtonSwitchOption value={ClaimTransformTargetValueMode.SourceValue}>
            Any value
          </ButtonSwitchOption>
          <ButtonSwitchOption
            value={ClaimTransformTargetValueMode.SpecificValue}
          >
            Specific value
          </ButtonSwitchOption>
        </ButtonSwitch>
      </Control>
      {value.targetClaimValueMode ===
        ClaimTransformTargetValueMode.SpecificValue && (
        <Control labelText="Target claim specific value">
          <TextBox
            value={value.targetClaimValue}
            onChange={(targetClaimValue) => c({targetClaimValue})}
          />
        </Control>
      )}
    </div>
  );
}
