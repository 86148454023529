import {Fragment} from "react";
import styles from "./VisitInformationHeader24.module.scss";

function Columns({array}) {
  return array
    .filter((x) => x.value)
    .map((object) => {
      return (
        <Fragment key={object.title}>
          <div className={styles.flexItemHeader}>{object.title}:</div>
          <div className={styles.flexItemOrientationRow}>
            {object.value
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((type) => (
                <Fragment key={type.slvCode}>
                  <div>
                    {type.name} {" - "} {type.slvCode}
                  </div>
                  <div>
                    {type.activities
                      .filter((a) => a.endDate === null)
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((act) => (
                        <div key={act}>
                          <div
                            style={{
                              fontWeight: "lighter",
                              paddingLeft: "1em",
                            }}
                          >
                            {" "}
                            {act.name} {" - "} {act.slvCode}{" "}
                          </div>
                        </div>
                      ))}
                  </div>
                </Fragment>
              ))}
          </div>
        </Fragment>
      );
    });
}

export const InfoRowOrientationType = ({array}) => {
  return (
    <div className={styles.flexContainer}>
      <Columns array={array} />
    </div>
  );
};
