import {faCheck} from "@fortawesome/pro-regular-svg-icons";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {Button, Panel} from "@sokigo/components-react-bootstrap";
import {useState} from "react";
import {useCurrentVisitInput} from "../InspectionVisitRoute";
import {SelectFoodControlAreas} from "./SelectFoodControlAreas";
import {useSaveSelectedFoodControlQuestions} from "./useSaveSelectedFoodControlQuestions";

export function SelectControlAreasWithoutModal() {
  const t = useAppTranslation();

  const {foodControlQuestionsByFoodControlAreaId} = useCurrentVisitInput();

  const [selected, setSelected] = useState(
    foodControlQuestionsByFoodControlAreaId ?? {}
  );

  const saveSelectedFoodControlQuestions =
    useSaveSelectedFoodControlQuestions();
  async function save() {
    await saveSelectedFoodControlQuestions(selected);
  }

  const canSave = Object.keys(selected).length > 0;

  return (
    <Panel className="py-3 mt-3">
      <h3 className="mx-3 mb-3">{t("selectFoodControlAreas")}</h3>
      <SelectFoodControlAreas value={selected} onChange={setSelected} />
      <div className="mx-3 text-right">
        <Button
          className="mt-4"
          onClick={save}
          disabled={!canSave}
          icon={faCheck}
          kind="primary"
        >
          {t("ok")}
        </Button>
      </div>
    </Panel>
  );
}
