import "@sokigo/components-react-bootstrap";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {
  useCurrentVisitInput,
  useMergeCurrentVisitInput,
} from "../InspectionVisitRoute";
import {v4 as uuid} from "uuid";
import {ControlListItem} from "./ControlListItem";
import {Button} from "@sokigo/components-react-bootstrap";
import {faPlus} from "@fortawesome/pro-regular-svg-icons";

export function ControlList({foodFacilityTypes}) {
  const t = useAppTranslation();
  const {foodControlOccasion2024} = useCurrentVisitInput();
  const controls = foodControlOccasion2024.controls ?? [];

  const merge = useMergeCurrentVisitInput();

  function onChange(control) {
    merge({
      foodControlOccasion2024: {
        ...foodControlOccasion2024,
        controls:
          controls.find((x) => x.id === control.id) === undefined
            ? [...controls, control]
            : controls.map((x) => (x.id === control.id ? control : x)),
      },
    });
  }

  function onRemove(controlId) {
    merge({
      foodControlOccasion2024: {
        ...foodControlOccasion2024,
        controls: controls.filter((x) => x.id !== controlId),
      },
    });
  }

  function onAdd(foodFacilityTypeId) {
    onChange({id: uuid(), foodFacilityTypeId});
  }

  const rows = foodFacilityTypes.map((x) => {
    const selectedCauses = controls
      .filter((y) => y.foodFacilityTypeId === x.id)
      .map((y) => y.cause);
    const data = {
      id: x.id,
      name: x.name,
      startDate: x.startDate,
      endDate: x.endDate,
      controlReason: x.possibleCauses.map((y) => ({id: y.id, text: y.name})),
      type: ["Föranmäld", "Oanmäld"],
      controls:
        controls.filter((y) => y.foodFacilityTypeId === x.id).length > 0
          ? controls.filter((y) => y.foodFacilityTypeId === x.id)
          : [{id: uuid(), foodFacilityTypeId: x.id}],
    };
    const lastControl = data.controls.at(-1);
    const firstControlId = data.controls[0].id;
    const selectedControlReasons = data.controlReason.filter((y) =>
      selectedCauses.includes(y.id)
    );
    const availableControlReasons = data.controlReason.filter(
      (y) => !selectedCauses.includes(y.id)
    );
    return {
      ...data,
      lastControl,
      firstControlId,
      selectedControlReasons,
      availableControlReasons,
    };
  });

  return rows.map((fft, idx) => (
    <div
      key={fft.id}
      className={`d-flex flex-row justify-content-start py-1 ${
        idx > 0 && "border-top"
      }`}
    >
      <div className="col-3">
        <div>
          <b>{t("foodFacilityType")}</b>
        </div>
        <div className="mt-3">
          <b>{t("startDate")}</b>
          <div>{fft.startDate}</div>
        </div>
        <div className="mt-3">
          <b>{t("endDate")}</b>
          <div>{fft.endDate}</div>
        </div>
      </div>
      <div>
        <div style={{maxWidth: "24rem"}}>{fft.name}</div>
        {fft.controls.map((control) => (
          <ControlListItem
            key={control.id}
            control={control}
            onChange={onChange}
            onRemove={onRemove}
            controlReason={fft.controlReason}
            selectedControlReasons={fft.selectedControlReasons}
          />
        ))}
        {fft.lastControl.cause && fft.availableControlReasons.length > 0 && (
          <div className="d-flex flex-row-reverse pr-6">
            <Button
              align="right"
              kind="primary"
              icon={faPlus}
              onClick={() => {
                onAdd(fft.id);
              }}
            >
              {t("addNewControl")}
            </Button>
          </div>
        )}
      </div>
    </div>
  ));
}
