import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableHeaderRow,
} from "@sokigo/components-react-bootstrap";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {ControlItem} from "./ControlItem";
import {Fragment} from "react";
import {
  useCurrentVisitInput,
  useMergeCurrentVisitInput,
} from "../InspectionVisitRoute";
import {v4 as uuid} from "uuid";

export function ControlItemTable({foodFacilityTypes}) {
  const t = useAppTranslation();
  const columns = [
    {
      key: "bussinessType",
      field: "businessType",
      header: t("foodFacilityType"),
    },
    {
      key: "startDate",
      field: "startDate",
      header: t("startDate"),
    },
    {
      key: "endDate",
      field: "endDate",
      header: t("endDate"),
    },
    {
      key: "validationError",
      field: "validationError",
      header: "",
    },
    {
      key: "controlReason",
      field: "controlReason",
      header: t("controlCause"),
    },
    {
      key: "type",
      field: "type",
      header: t("controlType"),
    },
    {
      key: "92",
      field: "92",
      header: t("92control"),
    },
    {
      key: "distance",
      field: "distance",
      header: t("controlOnSite"),
    },
    {
      key: "sampling",
      field: "sampling",
      header: t("controlSampling"),
    },
    {
      key: "revision",
      field: "revision",
      header: t("controlRevision"),
    },
    {
      key: "remove",
      field: "remove",
      header: "",
    },
  ];
  const {foodControlOccasion2024} = useCurrentVisitInput();
  const controls = foodControlOccasion2024.controls ?? [];

  const merge = useMergeCurrentVisitInput();

  function onChange(control) {
    merge({
      foodControlOccasion2024: {
        ...foodControlOccasion2024,
        controls:
          controls.find((x) => x.id === control.id) === undefined
            ? [...controls, control]
            : controls.map((x) => (x.id === control.id ? control : x)),
      },
    });
  }

  function onRemove(controlId) {
    merge({
      foodControlOccasion2024: {
        ...foodControlOccasion2024,
        controls: controls.filter((x) => x.id !== controlId),
      },
    });
  }

  const rows = foodFacilityTypes.map((x) => {
    const selectedCauses = controls
      .filter((y) => y.foodFacilityTypeId === x.id)
      .map((y) => y.cause);
    const data = {
      name: x.name,
      startDate: x.startDate,
      endDate: x.endDate,
      controlReason: x.possibleCauses.map((y) => ({id: y.id, text: y.name})),
      type: ["Föranmäld", "Oanmäld"],
      controls:
        controls.filter((y) => y.foodFacilityTypeId === x.id).length > 0
          ? controls.filter((y) => y.foodFacilityTypeId === x.id)
          : [{id: uuid(), foodFacilityTypeId: x.id}],
    };
    const lastControl = data.controls.at(-1);
    const firstControlId = data.controls[0].id;
    const selectedControlReasons = data.controlReason.filter((y) =>
      selectedCauses.includes(y.id)
    );
    return {data, lastControl, firstControlId, selectedControlReasons};
  });

  const mappedControls = rows.flatMap((fft) =>
    fft.data.controls.map((c) => ({
      control: c,
      foodFacilityType: fft.data,
      lastControl: fft.lastControl,
      firstControlId: fft.firstControlId,
      selectedControlReasons: fft.selectedControlReasons,
    }))
  );
  return (
    <Table columns={columns} rows={mappedControls} getRowKey={(x) => x.id}>
      <TableHead>
        <TableHeaderRow>
          {(columns) =>
            columns.map((x) => (
              <TableHeader key={x.key} column={x}>
                {x.header}
              </TableHeader>
            ))
          }
        </TableHeaderRow>
      </TableHead>
      <TableBody>
        {(rows) =>
          rows.map((f) => (
            <Fragment key={f.key}>
              <ControlItem
                row={f.row}
                onChange={onChange}
                onRemove={onRemove}
              ></ControlItem>
            </Fragment>
          ))
        }
      </TableBody>
    </Table>
  );
}
