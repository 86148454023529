import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {getValidationErrorMessages} from "@sokigo-sbwebb/validation";
import {
  Control,
  Select,
  Panel,
  Button,
} from "@sokigo/components-react-bootstrap";
import {useCallback, useEffect, useMemo, useState} from "react";
import {useServerState} from "../../../../../ServerStateProvider";
import {useCurrentVisit} from "../../../InspectionVisitRoute";
import {useCompletingInformationProvider} from "../CompletingInformationTab";
import {useSyncVisit} from "../../../useSyncVisit";
import {useHistory} from "react-router-dom";
import {faFileSearch} from "@fortawesome/pro-regular-svg-icons";

function PreviewProtocolButton({disabled}) {
  const t = useAppTranslation();
  const {
    visit: {id: currentVisitId},
  } = useCurrentVisit();

  const [hasUnsavedChanges, doSync] = useSyncVisit();

  const [syncing, setSyncing] = useState(false);
  const history = useHistory();

  async function onClick() {
    if (hasUnsavedChanges) {
      setSyncing(true);
      try {
        await doSync();
      } finally {
        setSyncing(false);
      }
    }
    window.open(
      history.createHref({
        pathname: "/inspection/protocolPreview/" + currentVisitId,
      })
    );
  }

  return (
    <Button
      kind="secondary"
      disabled={disabled || syncing}
      onClick={onClick}
      icon={faFileSearch}
    >
      {t("previewProtocol")}
    </Button>
  );
}

export function InspectionProtocol({validationErrors, canPreview}) {
  const {onChange, value} = useCompletingInformationProvider();
  const onInspectionControlProtocolChange = useCallback(
    (change) => {
      onChange({...value, ...change});
    },
    [onChange, value]
  );

  const {
    visit: {
      visitFormSnapshot: {id},
      visitMetadata: {
        visitTargetInformation: {casePartyRoles},
      },
    },
  } = useCurrentVisit();
  const {
    baseData: {availableTemplates},
    visitForms,
  } = useServerState();
  const usedVisitForm = visitForms[id];
  const reportTemplateIds = usedVisitForm.reportTemplateIds;
  // reportTemplateIds = ids for Visit Form selected templates
  // availableTemplates = templates in Ecos
  const mappedTemplates = reportTemplateIds
    .map((id) => availableTemplates?.find((t) => t.id === id))
    .filter((x) => x);

  const allSelectedTemplatesExist =
    reportTemplateIds.length > 0 &&
    reportTemplateIds.length === mappedTemplates.length;

  // if all reportTemplateIds exists in availableTemplates: return mappedTemplates
  // else (selected template has been deleted in Ecos): return availableTemplates
  const templateOptions = allSelectedTemplatesExist
    ? mappedTemplates
    : availableTemplates;

  useEffect(() => {
    if (
      allSelectedTemplatesExist &&
      value.protocolTemplateId &&
      !reportTemplateIds.some((id) => id === value.protocolTemplateId)
    ) {
      onInspectionControlProtocolChange({protocolTemplateId: null});
    }
  }, [
    allSelectedTemplatesExist,
    onInspectionControlProtocolChange,
    reportTemplateIds,
    value.protocolTemplateId,
  ]);

  useEffect(() => {
    if (
      Array.isArray(templateOptions) &&
      templateOptions.length === 1 &&
      value.protocolTemplateId === null
    ) {
      onInspectionControlProtocolChange({
        protocolTemplateId: templateOptions[0].id,
      });
    }
  }, [
    onInspectionControlProtocolChange,
    templateOptions,
    value.protocolTemplateId,
  ]);

  const casePartyRolesReduced = useMemo(
    () =>
      casePartyRoles.reduce((acc, curr) => {
        return acc.some((x) => x.partyId === curr.partyId)
          ? acc
          : [...acc, curr];
      }, []),
    [casePartyRoles]
  );

  const t = useAppTranslation();
  return (
    <Panel className="mt-3 py-3">
      <div className="d-flex justify-content-between">
        <h4 className="px-3 mb-3">{t("inspectionProtocol")}</h4>
        <PreviewProtocolButton disabled={!canPreview} />
      </div>
      <div className="m-3">
        <Control
          labelText={t("template")}
          horizontal
          errors={getValidationErrorMessages(
            validationErrors,
            "protocolTemplateId"
          )}
        >
          <Select
            className="mb-3"
            onChange={(protocolTemplateId) =>
              onInspectionControlProtocolChange({protocolTemplateId})
            }
            options={templateOptions}
            value={value.protocolTemplateId}
            getOptionKey={(x) => x.id}
            getOptionValue={(x) => x.id}
            getOptionText={(x) => x.title}
          />
        </Control>
        {casePartyRolesReduced.length > 0 && (
          <Control
            labelText={t("addressee")}
            horizontal
            errors={getValidationErrorMessages(
              validationErrors,
              "casePartyAddressee"
            )}
          >
            <Select
              className="mb-3"
              onChange={(casePartyAddressee) =>
                onInspectionControlProtocolChange({casePartyAddressee})
              }
              options={casePartyRolesReduced}
              value={value.casePartyAddressee}
              placeholder={t("addressee")}
              getOptionKey={(x) => x.partyId}
              getOptionValue={(x) => x.partyId}
              getOptionText={(x) => x.partyName}
            />
          </Control>
        )}
      </div>
    </Panel>
  );
}
