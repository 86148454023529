import {useGeoLocation} from "../../../services/useGeoLocation";
import {useMapSettings} from "../../../services/useMapSettings";
import {useMap} from "./MapProvider";
import {useEffect, useState} from "react";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import Point from "ol/geom/Point";
import Feature from "ol/Feature";
import Circle from "ol/geom/Circle";
import {getWgs84PositionInCoordinateSystemWithCoordinateOrderXY} from "./GetWgs84PositionInCoordinateSystemWithCoordinateOrderXY";

export function CurrentPositionLayer() {
  const {latitude, longitude, accuracy} = useGeoLocation() ?? {};
  const {mapSettings} = useMapSettings();
  const projection = mapSettings?.projection;
  const map = useMap();
  const [source, setSource] = useState(null);

  useEffect(() => {
    if (!map) {
      return;
    }
    const source = new VectorSource();
    const layer = new VectorLayer({source});
    setSource(source);
    map.addLayer(layer);
    return () => {
      map.removeLayer(layer);
    };
  }, [map]);

  useEffect(() => {
    if (!source) {
      return;
    }
    if (!latitude || !longitude) {
      return;
    }

    const point = getWgs84PositionInCoordinateSystemWithCoordinateOrderXY(
      [latitude, longitude],
      projection
    );
    const geometry = new Point(point);
    const feature = new Feature(geometry);
    source.addFeature(feature);

    let accuracyFeature = null;
    if (accuracy) {
      const accuracyGeom = new Circle(point, accuracy);
      accuracyFeature = new Feature(accuracyGeom);
      source.addFeature(accuracyFeature);
    }

    return () => {
      source.removeFeature(feature);
      if (accuracyFeature) {
        source.removeFeature(accuracyFeature);
      }
    };
  }, [latitude, longitude, projection, accuracy, source]);

  return null;
}
