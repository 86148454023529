import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {getValidationErrorMessages} from "@sokigo-sbwebb/validation";
import {
  ButtonSwitch,
  ButtonSwitchOption,
  Control,
  DatePicker,
  Select,
  Panel,
  Switch,
  Label,
} from "@sokigo/components-react-bootstrap";
import {SaveAsType} from "../../../../AdminRoutes/AdminVisitForms/EditVisitForm/VisitFormEditor/SaveAsType";
import {useCurrentVisit} from "../../../InspectionVisitRoute";
import {useCompletingInformationProvider} from "../CompletingInformationTab";
import {useFoodInspection2013DateValidation} from "../../../foodInspection24Validator/useFoodInspection24Validation";
import {NotifiedTypes} from "../NotifiedTypes";
import moment from "moment";

export function InspectionFields({validationErrors}) {
  const t = useAppTranslation();
  const {
    visit: {
      visitMetadata: {saveAsType},
      baseDataSnapshot: {inspectionCauses},
    },
  } = useCurrentVisit();

  const {onChange, value} = useCompletingInformationProvider();

  const onControlInformationChange = (change) => {
    onChange({...value, ...change});
  };

  const dateValidation = useFoodInspection2013DateValidation();
  const dateError = [];
  if (dateValidation.unallowed2024creation) {
    dateError.push(t("datesAfter2023NotAllowed"));
  }

  return (
    <Panel className="mt-3 pt-3" shadow>
      <h4 className="px-3 mb-3">{t("controlInformation")}</h4>
      <div className="m-3">
        <div className="d-flex flex-wrap">
          <Control
            className="mr-5"
            labelText={t("occurrenceDate")}
            errors={!value?.occurrenceDate ? [t("mandatory")] : dateError}
          >
            <DatePicker
              format="yyyy-MM-dd"
              value={
                value?.occurrenceDate || ""
                  ? moment(value?.occurrenceDate).format("YYYY-MM-DD")
                  : ""
              }
              onChange={(occurrenceDate) =>
                onControlInformationChange({
                  occurrenceDate,
                })
              }
              inline
              locale={t("locale")}
            />
          </Control>
          <Switch
            id="saveScratchPadNote"
            className="mt-3 mr-2"
            checked={value?.saveScratchPadNote || false}
            onChange={(saveScratchPadNote) =>
              onControlInformationChange({saveScratchPadNote})
            }
          />
          <Label htmlFor="saveScratchPadNote" className="mt-5">
            {t("saveScratchPadNote")}
          </Label>
        </div>
        <Control
          labelText={t("cause")}
          errors={getValidationErrorMessages(validationErrors, "causeId")}
        >
          <Select
            className="mb-3"
            onChange={(causeId) => onControlInformationChange({causeId})}
            options={inspectionCauses}
            value={value?.causeId}
            getOptionKey={(x) => x.id}
            getOptionValue={(x) => x.id}
            getOptionText={(x) => x.title}
          />
        </Control>
        <div className="d-flex flex-wrap">
          <Control
            className="mr-5"
            labelText={t("type")}
            errors={getValidationErrorMessages(validationErrors, "type")}
          >
            <ButtonSwitch
              value={value?.type}
              onChange={(type) => onControlInformationChange({type})}
            >
              <ButtonSwitchOption value={NotifiedTypes.PreNotified}>
                {t("preNotified")}
              </ButtonSwitchOption>
              <ButtonSwitchOption value={NotifiedTypes.NotNotified}>
                {t("notNotified")}
              </ButtonSwitchOption>
            </ButtonSwitch>
          </Control>
          <Control
            className="mr-5"
            labelText={t("revision")}
            errors={getValidationErrorMessages(validationErrors, "revision")}
          >
            <ButtonSwitch
              value={value?.revision}
              onChange={(revision) => {
                onControlInformationChange({revision});
              }}
            >
              <ButtonSwitchOption value={true}>{t("yes")}</ButtonSwitchOption>
              <ButtonSwitchOption value={false}>{t("no")}</ButtonSwitchOption>
            </ButtonSwitch>
          </Control>
          {saveAsType === SaveAsType.GenericInspection && (
            <Control
              className="mr-5"
              labelText={t("visitPerformedOnLocation")}
              errors={getValidationErrorMessages(
                validationErrors,
                "visitPerformedOnLocation"
              )}
            >
              <ButtonSwitch
                value={value?.visitPerformedOnLocation}
                onChange={(visitPerformedOnLocation) => {
                  onControlInformationChange({
                    visitPerformedOnLocation,
                  });
                }}
              >
                <ButtonSwitchOption value={true}>{t("yes")}</ButtonSwitchOption>
                <ButtonSwitchOption value={false}>{t("no")}</ButtonSwitchOption>
              </ButtonSwitch>
            </Control>
          )}
        </div>
      </div>
    </Panel>
  );
}
