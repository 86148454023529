import {
  faCheck,
  faPlus,
  faTimes,
  faTrashAlt,
} from "@fortawesome/pro-regular-svg-icons";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {
  Button,
  Card,
  CardBody,
  Control,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  TableBody,
  TableCell,
  TableExpandedRow,
  TableExpandRowCell,
  TableReorderableRowCell,
  TableRow,
  TableRowActionsCell,
  TableSelectRow,
  TextArea,
  TextBox,
  Select,
} from "@sokigo/components-react-bootstrap";
import {Fragment, useMemo, useState} from "react";
import {v4 as uuid} from "uuid";
import {useServerState} from "../../../ServerStateProvider";

function SupportQuestionEditForm({value, onChange}) {
  const t = useAppTranslation();

  const {
    baseData: {foodFacilityOrientations},
  } = useServerState();
  const foodFacilityOrientationOptionsAvailableForInclusion = useMemo(
    () =>
      foodFacilityOrientations.filter(
        (o) => !value.notRelevantForFoodFacilityOrientationIds.includes(o.id)
      ),
    [foodFacilityOrientations, value.notRelevantForFoodFacilityOrientationIds]
  );
  const foodFacilityOrientationOptionsAvailableForExclusion = useMemo(
    () =>
      foodFacilityOrientations.filter(
        (o) => !value.relevantForFoodFacilityOrientationIds.includes(o.id)
      ),
    [foodFacilityOrientations, value.relevantForFoodFacilityOrientationIds]
  );

  return (
    <Card>
      <CardBody>
        <Control labelText={t("relevantForFoodFacilityOrientations")}>
          <Select
            options={foodFacilityOrientationOptionsAvailableForInclusion}
            getOptionValue={(x) => x.id}
            getOptionText={(x) => x.description}
            getOptionKey={(x) => x.id}
            onChange={(relevantForFoodFacilityOrientationIds) =>
              onChange({...value, relevantForFoodFacilityOrientationIds})
            }
            value={value.relevantForFoodFacilityOrientationIds}
            multiple
            withCheckboxes
          />
        </Control>
        <Control labelText={t("notRelevantForFoodFacilityOrientations")}>
          <Select
            options={foodFacilityOrientationOptionsAvailableForExclusion}
            getOptionValue={(x) => x.id}
            getOptionText={(x) => x.description}
            getOptionKey={(x) => x.id}
            onChange={(notRelevantForFoodFacilityOrientationIds) => {
              onChange({...value, notRelevantForFoodFacilityOrientationIds});
            }}
            value={value.notRelevantForFoodFacilityOrientationIds}
            multiple
            withCheckboxes
          />
        </Control>
        <Control labelText={t("helpText")}>
          <TextArea
            defaultValue={value.helpText}
            onChange={(helpText) => onChange({...value, helpText})}
            rows={3}
          />
        </Control>
      </CardBody>
    </Card>
  );
}

function SupportQuestions({onChange, currentControlAreaQuestion, value}) {
  const [selectedSupportQuestionId, setSelectedSupportQuestionId] =
    useState(null);
  const t = useAppTranslation();

  const createNewQuestion = () => {
    const question = {
      id: uuid(),
      relevantForFoodFacilityOrientationIds: [],
      notRelevantForFoodFacilityOrientationIds: [],
    };
    onChange([...value, question]);
    setSelectedSupportQuestionId(question.id);
  };

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="py-2 mb-2">
          {t("supportQuestionsForQuestionNumber", {
            questionNumber: currentControlAreaQuestion.number,
          })}
        </h4>
        <Button
          icon={faPlus}
          onClick={() => createNewQuestion()}
          kind="primary"
        >
          {t("addSupportQuestion")}
        </Button>
      </div>
      <Table
        rows={value}
        onChange={onChange}
        columns={[
          {
            key: 1,
            renderCell: ({row, onRowChange}) => (
              <div className="form-control-wrapper">
                <TextBox
                  size="sm"
                  value={row.title ?? ""}
                  onChange={(title) => onRowChange({...row, title})}
                  invalid={!row.title}
                />
              </div>
            ),
          },
        ]}
        getRowKey={(x) => x.id}
        expanded={selectedSupportQuestionId ? [selectedSupportQuestionId] : []}
        onExpand={(ids) =>
          setSelectedSupportQuestionId(ids.length === 1 ? ids[0] : null)
        }
      >
        <TableBody>
          {(rows) =>
            rows.map((r) => (
              <Fragment key={r.key}>
                <TableRow key={r.key} row={r} invalid={!r.row.title}>
                  <TableExpandRowCell single />
                  <TableReorderableRowCell />
                  {r.cells.map((c) => (
                    <TableCell key={c.key}>{c.value}</TableCell>
                  ))}
                  <TableRowActionsCell>
                    <Button
                      size="sm"
                      kind="danger-hover-ghost"
                      icon={faTrashAlt}
                      onClick={() =>
                        onChange(value.filter((x) => x.id !== r.row.id))
                      }
                    />
                  </TableRowActionsCell>
                </TableRow>
                <TableExpandedRow row={r} colSpan={r.cells.length + 3}>
                  <SupportQuestionEditForm
                    value={r.row}
                    onChange={r.onRowChange}
                  />
                </TableExpandedRow>
              </Fragment>
            ))
          }
        </TableBody>
      </Table>
    </div>
  );
}

function ControlAreaQuestions({
  onSelect,
  questions,
  selectedQuestionId,
  supportQuestionsWithErrors,
}) {
  const t = useAppTranslation();

  return (
    <>
      <h4 className="py-2 mb-2">{t("controlArea")}</h4>
      <Table
        onSelect={(ids) => onSelect(ids.length === 1 ? ids[0] : null)}
        selected={selectedQuestionId ? [selectedQuestionId] : []}
        rows={questions}
        columns={[
          {
            key: 1,
            renderCell: ({row}) => `${row.number}. ${row.title}`,
          },
        ]}
        getRowKey={(x) => x.id}
      >
        <TableBody>
          {(rows) =>
            rows.map((r) => (
              <TableSelectRow
                single
                key={r.key}
                row={r}
                invalid={supportQuestionsWithErrors.includes(r.row.id)}
              >
                {r.cells.map((c) => (
                  <TableCell key={c.key}>{c.value}</TableCell>
                ))}
              </TableSelectRow>
            ))
          }
        </TableBody>
      </Table>
    </>
  );
}

export function ManageFoodControlAreaSupportQuestionsModal({
  show,
  foodInspectionArea,
  onClose,
  onSave,
  defaultValue,
}) {
  const t = useAppTranslation();
  const [value, setValue] = useState(() => defaultValue ?? []);
  const [saving, setSaving] = useState(false);

  async function save() {
    setSaving(true);
    try {
      await onSave(value);
    } finally {
      setSaving(false);
    }
  }

  const [
    selectedFoodInspectionControlAreaQuestionId,
    setSelectedFoodInspectionControlAreaQuestionId,
  ] = useState(null);
  const selectedFoodInspectionControlAreaQuestion = useMemo(
    () =>
      foodInspectionArea.questions.find(
        (x) => x.id === selectedFoodInspectionControlAreaQuestionId
      ),
    [foodInspectionArea, selectedFoodInspectionControlAreaQuestionId]
  );
  const selectedFoodInspectionControlAreaQuestionSupportQuestions = useMemo(
    () => value[selectedFoodInspectionControlAreaQuestionId] ?? [],
    [value, selectedFoodInspectionControlAreaQuestionId]
  );

  // can not save if any help questions are missing title
  const supportQuestionsWithErrors = foodInspectionArea.questions
    .map((q) => q.id)
    .filter((id) => value[id]?.some((sq) => !sq.title));
  const canSave = supportQuestionsWithErrors.length === 0;

  return (
    <Modal size="xl" show={show} onClose={onClose} static scrollable={true}>
      <ModalHeader>{t("manageFoodInspectionControlArea")}</ModalHeader>
      <ModalBody>
        <fieldset disabled={saving}>
          <div className="row">
            <div className="col">
              <ControlAreaQuestions
                questions={foodInspectionArea.questions}
                selectedQuestionId={selectedFoodInspectionControlAreaQuestionId}
                onSelect={(id) =>
                  setSelectedFoodInspectionControlAreaQuestionId(id)
                }
                supportQuestionsWithErrors={supportQuestionsWithErrors}
              />
            </div>
            <div className="col">
              <div className="d-flex flex-column">
                {selectedFoodInspectionControlAreaQuestion && (
                  <SupportQuestions
                    key={selectedFoodInspectionControlAreaQuestionId}
                    currentControlAreaQuestion={
                      selectedFoodInspectionControlAreaQuestion
                    }
                    value={
                      selectedFoodInspectionControlAreaQuestionSupportQuestions
                    }
                    onChange={(supportQuestions) =>
                      setValue((v) => ({
                        ...v,
                        [selectedFoodInspectionControlAreaQuestionId]:
                          supportQuestions,
                      }))
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </fieldset>
      </ModalBody>
      <ModalFooter>
        <Button
          kind="secondary"
          onClick={onClose}
          className="mr-2"
          icon={faTimes}
          disabled={saving}
        >
          {t("cancel")}
        </Button>
        <Button
          onClick={save}
          icon={faCheck}
          disabled={!canSave || saving}
          kind="primary"
        >
          {t("ok")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
