import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@sokigo/components-react-bootstrap";
import {useState} from "react";
import {useCurrentVisitInput} from "../InspectionVisitRoute";
import {SelectFoodControlAreas} from "./SelectFoodControlAreas";
import {useSaveSelectedFoodControlQuestions} from "./useSaveSelectedFoodControlQuestions";
import {faCheck, faEdit} from "@fortawesome/pro-regular-svg-icons";

function SelectFoodControlAreasModal({onClose}) {
  const t = useAppTranslation();
  const {foodControlQuestionsByFoodControlAreaId} = useCurrentVisitInput();
  const saveSelectedFoodControlQuestions =
    useSaveSelectedFoodControlQuestions();
  const [selected, setSelected] = useState(
    () => foodControlQuestionsByFoodControlAreaId ?? {}
  );

  async function save() {
    await saveSelectedFoodControlQuestions(selected);
    onClose();
  }

  const canSave = Object.keys(selected).length > 0;
  return (
    <Modal scrollable onClose={onClose} size="lg" show static>
      <ModalHeader>{t("selectControlAreas")}</ModalHeader>
      <ModalBody>
        <SelectFoodControlAreas
          value={selected}
          onChange={setSelected}
          defaultValue={foodControlQuestionsByFoodControlAreaId ?? {}}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          disabled={!canSave}
          onClick={save}
          kind="primary"
          icon={faCheck}
        >
          {t("ok")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export function SelectFoodControlAreasButton() {
  const t = useAppTranslation();
  const [showing, setShowing] = useState(false);

  return (
    <>
      <Button kind="primary" icon={faEdit} onClick={() => setShowing(true)}>
        {t("selectFoodControlAreas")}
      </Button>
      {showing && (
        <SelectFoodControlAreasModal onClose={() => setShowing(false)} />
      )}
    </>
  );
}
