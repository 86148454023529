import {Modal, ModalBody, Spinner} from "@sokigo/components-react-bootstrap";

export function BusyModal({text}) {
  return (
    <Modal show centered hideClose size="sm">
      <ModalBody>
        <div className="d-flex align-content-center justify-content-center">
          <Spinner>
            <h6>{text}</h6>
          </Spinner>
        </div>
      </ModalBody>
    </Modal>
  );
}
