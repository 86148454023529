import {faFilePdf} from "@fortawesome/pro-regular-svg-icons";
import settings from "./settings.json";
import settingsi18n from "./settingsi18n.json";

export default {
  name: "sokigo-sbwebb-documentconverter-service",
  title: "Dokumentkonverterare",
  icon: faFilePdf,
  settings: {
    schema: settings,
    group: "services",
    translations: settingsi18n,
  },
};
