import {Label} from "@sokigo/components-react-bootstrap";

function Columns({array}) {
  return array
    .filter((x) => x.value)
    .map((object) => {
      return (
        <div key={object.title} className="col-xs-12 col-sm-4 mb-3">
          <Label>{object.title}</Label>
          <div>{object.value}</div>
        </div>
      );
    });
}

export const InfoRow = ({array}) => {
  return (
    <div className="row">
      <Columns array={array} />
    </div>
  );
};
