import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableHeaderRow,
  TableRow,
} from "@sokigo/components-react-bootstrap";
import {Fragment} from "react";
import {v4 as uuid} from "uuid";

export function ContactDetailsTable({emailAddresses, phoneNumbers}) {
  const t = useAppTranslation();

  const getPhoneNumbers = () => {
    return (
      phoneNumbers?.map((x) => ({
        id: uuid(),
        flaggedAsRelevantForCasePartyRole: x.flaggedAsRelevantForCasePartyRole,
        type: x.phoneNumberType,
        contactDetail: x.phoneNumber,
        contactMethod: "Telefon",
      })) || []
    );
  };

  const getEmailAddresses = () => {
    return (
      emailAddresses?.map((x) => ({
        id: uuid(),
        flaggedAsRelevantForCasePartyRole: x.flaggedAsRelevantForCasePartyRole,
        type: x.emailAddressType,
        contactDetail: x.emailAddress,
        contactMethod: "E-post",
      })) || []
    );
  };

  const contactDetails = getEmailAddresses().concat(getPhoneNumbers());

  const columns = [
    {
      key: "contactMethod",
      field: "contactMethod",
      header: t("contactMethod"),
    },
    {
      key: "type",
      field: "type",
      header: t("type"),
    },
    {
      key: "contactDetail",
      field: "contactDetail",
      header: t("contactDetail"),
    },
  ];

  return (
    <div className="p-4">
      {(!contactDetails || contactDetails?.length === 0) && (
        <div>{t("partyHasNoContactDetails")}</div>
      )}
      <Table rows={contactDetails} columns={columns} getRowKey={(x) => x.id}>
        <TableHead>
          <TableHeaderRow>
            {(columns) => (
              <>
                {columns.map((x) => (
                  <TableHeader key={x.key} column={x}>
                    {x.header}
                  </TableHeader>
                ))}
              </>
            )}
          </TableHeaderRow>
        </TableHead>
        <TableBody>
          {(rows) =>
            rows.map((r) => (
              <Fragment key={r.key}>
                <TableRow row={r}>
                  {r.cells.map((c) => (
                    <TableCell key={c.key}>
                      {r.row.flaggedAsRelevantForCasePartyRole ? (
                        <div className="font-weight-bold">{c.value}</div>
                      ) : (
                        c.value
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </Fragment>
            ))
          }
        </TableBody>
      </Table>
    </div>
  );
}
