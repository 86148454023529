import {CaseParties} from "./caseParties/CaseParties";
import {Documents} from "./documents/Documents";
import {VisitInformationHeader} from "./header/VisitInformationHeader";
import {HistoricVisits} from "./historicVisits/HistoricVisits";

export function InformationTab() {
  return (
    <>
      <VisitInformationHeader />
      <CaseParties />
      <Documents />
      <HistoricVisits />
    </>
  );
}
