import {getValidationErrors} from "@sokigo-sbwebb/validation";
import {DynamicParagraphsSection} from "./DynamicParagraphsSection";
import {StaticParagraphsSection} from "./StaticParagraphsSection";
import {Alert} from "@sokigo/components-react-bootstrap";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";

export function ProtocolTexts({
  protocolTexts = {},
  onChange,
  validationErrors,
}) {
  const t = useAppTranslation();

  function c(change) {
    onChange({
      ...(protocolTexts || {}),
      ...change,
    });
  }

  return (
    <>
      {validationErrors.length > 0 && (
        <Alert kind="warning" contrast inline hideCloseButton className="mb-3">
          {t("protocolTextsValidationErrorAlertMessage")}
        </Alert>
      )}
      <StaticParagraphsSection
        onChange={(staticParagraphs) =>
          onChange({...protocolTexts, staticParagraphs})
        }
        staticParagraphs={protocolTexts.staticParagraphs}
        validationErrors={getValidationErrors(
          validationErrors,
          "staticParagraphs"
        )}
      />
      <DynamicParagraphsSection
        onChange={c}
        protocolTexts={protocolTexts}
        validationErrors={getValidationErrors(
          validationErrors,
          "dynamicParagraphs"
        )}
      />
    </>
  );
}
