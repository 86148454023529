import {Section} from "./Section";

export function Sections({infoSections}) {
  return (
    <>
      {infoSections.map((section) => {
        return <Section key={section.rowId} section={section} />;
      })}
    </>
  );
}
