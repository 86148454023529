import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {
  Table,
  TableBody,
  TableCell,
  TableExpandedRow,
  TableExpandRowCell,
  TableHead,
  TableHeader,
  TableHeaderRow,
  TableRow,
  TableRowActionsCell,
} from "@sokigo/components-react-bootstrap";
import {Fragment} from "react";
import {CreateNewVisitButton} from "./CreateNewVisitButton";

function CaseConnectedFacilities({connectedFacilities}) {
  const t = useAppTranslation();
  const columns = [
    {
      key: "facilityType",
      header: t("facilityType"),
      renderCell: ({row}) => row.facilityType,
    },
    {
      key: "facilityCollectionNameOrFacilityName",
      header: t("facilityCollectionNameOrFacilityName"),
      renderCell: ({row}) =>
        [row.facilityCollectionName, row.facilityName].find((x) => x),
    },
    {
      key: "operator",
      header: t("operator"),
      renderCell: ({row}) => row.operatorName,
    },
    {key: "estate", header: t("estate"), renderCell: ({row}) => row.estate},
    {
      key: "address",
      header: t("address"),
      renderCell: ({row}) => row.address,
    },
  ];
  return (
    <div className="p-2 m-2">
      <Table
        rows={connectedFacilities}
        columns={columns}
        getRowKey={(x) => x.rowId}
      >
        <TableHead>
          <TableHeaderRow>
            {(columns) => (
              <>
                {columns.map((x) => (
                  <TableHeader key={x.key} column={x}>
                    {x.header}
                  </TableHeader>
                ))}
              </>
            )}
          </TableHeaderRow>
        </TableHead>
        <TableBody>
          {(rows) =>
            rows.map((r) => (
              <TableRow key={r.key} row={r}>
                {r.cells.map((c) => (
                  <TableCell key={c.key}>{c.value}</TableCell>
                ))}
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
    </div>
  );
}

function CaseConnectedEstates({connectedEstates}) {
  const t = useAppTranslation();
  const columns = [
    {
      key: "estate",
      header: t("estate"),
      renderCell: ({row}) => row.estate,
    },
    {
      key: "address",
      header: t("address"),
      renderCell: ({row}) => row.address,
    },
    {
      key: "estateOwner",
      header: t("estateOwner"),
      renderCell: ({row}) => row.estateOwnerNames.join(", "),
    },
  ];
  return (
    <div className="p-2 m-2">
      <Table
        rows={connectedEstates}
        columns={columns}
        getRowKey={(x) => x.rowId}
      >
        <TableHead>
          <TableHeaderRow>
            {(columns) => (
              <>
                {columns.map((x) => (
                  <TableHeader key={x.key} column={x}>
                    {x.header}
                  </TableHeader>
                ))}
              </>
            )}
          </TableHeaderRow>
        </TableHead>
        <TableBody>
          {(rows) =>
            rows.map((r) => (
              <Fragment key={r.key}>
                <TableRow key={r.key} row={r}>
                  {r.cells.map((c) => (
                    <TableCell key={c.key}>{c.value}</TableCell>
                  ))}
                </TableRow>
              </Fragment>
            ))
          }
        </TableBody>
      </Table>
    </div>
  );
}

function CaseSearchResultExpandedRow({result}) {
  const {connectedFacilities, connectedEstates} = result;
  return (
    <>
      {connectedFacilities?.length > 0 && (
        <CaseConnectedFacilities connectedFacilities={connectedFacilities} />
      )}
      {connectedEstates?.length > 0 && (
        <CaseConnectedEstates connectedEstates={connectedEstates} />
      )}
    </>
  );
}

export function CaseSearchResults({results}) {
  const t = useAppTranslation();
  const columns = [
    {
      key: "caseNumber",
      header: t("caseNumber"),
      renderCell: ({row}) => row.caseNumber,
    },
    {
      key: "caseTitle",
      header: t("caseTitle"),
      renderCell: ({row}) => row.caseTitle,
    },
    {
      key: "subTitle",
      header: t("subTitle"),
      renderCell: ({row}) => row.subTitle,
    },
  ];

  return (
    <div className="mt-4">
      <Table rows={results} columns={columns} getRowKey={(x) => x.rowId}>
        <TableHead>
          <TableHeaderRow colSpan={columns.length + 2}>
            {(columns) => (
              <>
                <TableHeader />
                {columns.map((x) => (
                  <TableHeader key={x.key} column={x}>
                    {x.header}
                  </TableHeader>
                ))}
                <TableHeader />
              </>
            )}
          </TableHeaderRow>
        </TableHead>
        <TableBody>
          {(rows) =>
            rows.map((r) => (
              <Fragment key={r.key}>
                <TableRow key={r.key} row={r}>
                  <TableExpandRowCell
                    row={r}
                    single
                    disabled={
                      !(
                        r.row.connectedFacilities.length > 0 ||
                        r.row.connectedEstates.length > 0
                      )
                    }
                  />
                  {r.cells.map((c) => (
                    <TableCell key={c.key}>{c.value}</TableCell>
                  ))}
                  <TableRowActionsCell>
                    <CreateNewVisitButton
                      key={r.row.key}
                      isNewCase={false}
                      caseNumber={r.row.caseNumber}
                      possibleVisits={r.row.possibleVisits}
                    />
                  </TableRowActionsCell>
                </TableRow>
                <TableExpandedRow row={r} colSpan={columns.length + 2}>
                  <CaseSearchResultExpandedRow result={r.row} />
                </TableExpandedRow>
              </Fragment>
            ))
          }
        </TableBody>
      </Table>
    </div>
  );
}
