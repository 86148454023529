import {Switch} from "@sokigo/components-react-bootstrap";
import {useState} from "react";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";

export function SelectFoodControlAreasSwitch({
  foodInspectionControlAreas,
  controlAreaId,
  assessedQuestionIds,
  onChange,
  selectedQuestions,
  controlAreasSwitchIsChanged,
}) {
  const t = useAppTranslation();

  const [assessedIsDeselected, setAssessedIsDeselected] = useState(false);

  function showIndeterminate() {
    if (
      !allQuestionsAreSelected(controlAreaId) &&
      !noQuestionsAreSelected(controlAreaId)
    ) {
      return true;
    } else {
      return false;
    }
  }

  function setAssessedIsDeselectedFunction() {
    const selectedQuestions = getSelectedQuestions();
    const deselectedAssessedQ = assessedQuestionIds.filter((assessed) =>
      selectedQuestions?.map((q) => q.id).includes(assessed)
    );
    const assessedDeselected = deselectedAssessedQ.length > 0 ? true : false;
    setAssessedIsDeselected(assessedDeselected);
  }

  function allQuestionsAreSelected() {
    const controlAreaQuestionsCount = foodInspectionControlAreas.filter(
      (area) => area.id === controlAreaId
    )[0]?.questions?.length;

    return controlAreaQuestionsCount === selectedQuestions?.length
      ? true
      : false;
  }

  function noQuestionsAreSelected() {
    return selectedQuestions.length === 0;
  }

  function onChangeFunction(selected) {
    const questionIds = getSelectedQuestionIds();

    if (selected) {
      onChange(questionIds, true);
    } else {
      setAssessedIsDeselectedFunction();
      onChange(questionIds, false);
    }
  }

  function getSelectedQuestionIds() {
    const selectedQuestions = getSelectedQuestions();
    return selectedQuestions.map((q) => q.id);
  }

  function getSelectedQuestions() {
    return foodInspectionControlAreas.filter(
      (area) => area.id === controlAreaId
    )[0]?.questions;
  }

  return (
    <>
      <Switch
        checked={allQuestionsAreSelected()}
        indeterminate={showIndeterminate()}
        warning={
          !allQuestionsAreSelected() &&
          assessedIsDeselected &&
          controlAreasSwitchIsChanged
        }
        onChange={(selected) => onChangeFunction(selected)}
      />

      {!allQuestionsAreSelected() &&
        assessedIsDeselected &&
        controlAreasSwitchIsChanged && (
          <div className="text-warning text-nowrap mb-2">
            {t("controlAreaHasAssessedQuestions")}
          </div>
        )}
    </>
  );
}
