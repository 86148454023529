import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {DocumentsTable} from "../documents/DocumentsTable";
import styles from "./HistoricVisit.module.scss";

export function VisitOccurrenceInformation({visit}) {
  const t = useAppTranslation();
  return (
    <div className="d-flex flex-column p-4">
      <h5>{t("documents")}</h5>
      {visit.documents.length === 0 && <div>{t("noDocuments")}</div>}
      {visit.documents.length > 0 && (
        <DocumentsTable documents={visit.documents} size="sm" />
      )}
      <h5 className="pt-4">{t("note")}</h5>
      <div className={styles.note}>{visit.note}</div>
    </div>
  );
}
