export const SaveToEcosStatus = {
  VisitNotFinished: 0,
  VisitFinishedSaveToEcosQueued: 1,
  CreateOccurrenceReadyToStart: 2,
  CreateOccurrenceInProgress: 3,
  CreateOccurrenceFailed: 4,
  CreateOccurrenceAborted: 5,
  SaveProtocolReadyToStart: 6,
  SaveProtocolInProgress: 7,
  SaveProtocolFailed: 8,
  SaveProtocolAborted: 9,
  Success: 10,
  CreateOccurrenceValidationError: 11,
  VisitWithValidationErrorsReopened: 12,
  CreateOccurrenceError: 13,
};
