import {
  ButtonSwitch,
  ButtonSwitchOption,
  Control,
} from "@sokigo/components-react-bootstrap";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {getValidationErrorMessages} from "@sokigo-sbwebb/validation";
import {SaveDocumentAs} from "../SaveDocumentAs";

export function SaveAsSection({value, onChange, validationErrors}) {
  const t = useAppTranslation();

  return (
    <Control
      errors={getValidationErrorMessages(validationErrors, "saveAs")}
      labelText={t("saveAs")}
      horizontal
    >
      <ButtonSwitch value={value} onChange={(saveAs) => onChange({saveAs})}>
        <ButtonSwitchOption value={SaveDocumentAs.WorkMaterial}>
          {t("workMaterial")}
        </ButtonSwitchOption>
        <ButtonSwitchOption value={SaveDocumentAs.Completed}>
          {t("completed")}
        </ButtonSwitchOption>
      </ButtonSwitch>
    </Control>
  );
}
