import {CasePartiesTable} from "./CasePartiesTable";
import {PresentPartiesTable} from "./PresentPartiesTable";

export function PresentPartiesTab() {
  return (
    <>
      <CasePartiesTable />
      <PresentPartiesTable />
    </>
  );
}
