import {TextArea} from "@sokigo/components-react-bootstrap";
import {PhrasePicker} from "../../../../../common-components/PhrasePicker";

export function TextAreaSection({value, onChange, phraseGroupIds}) {
  return (
    <div className="mt-2">
      <PhrasePicker
        value={value}
        onChange={(value) => onChange(value)}
        availablePhraseGroupIds={phraseGroupIds}
      />
      <TextArea
        required
        multiline
        rows={3}
        value={value}
        onChange={(value) => onChange(value)}
      />
    </div>
  );
}
