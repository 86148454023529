import {Fragment} from "react";
import styles from "./VisitInformationHeader24.module.scss";

function Columns({array}) {
  return array
    .filter((x) => x.value)
    .map((object) => {
      return (
        <Fragment key={object.title}>
          <div className={styles.flexItemFullRowSmallScreen}>
            <h4>{object.title}</h4>
            {object.value}
          </div>
        </Fragment>
      );
    });
}

export const InfoRowEstate = ({array}) => {
  return (
    <div className={styles.flexContainerFullRowSmallScreen}>
      <Columns array={array} />
    </div>
  );
};
