import {Table} from "@sokigo/components-react-bootstrap";
import {useState} from "react";
import {useAttachments} from "./AttachmentsProvider";
import {AttachmentsTableBody} from "./AttachmentsTableBody";
import {AttachmentsTableHead} from "./AttachmentsTableHead";
import {useAttachmentsTableColumns} from "./useAttachmentsTableColumns";

export function Attachments() {
  const {allAttachments} = useAttachments();
  const [expanded, setExpanded] = useState([]);

  const tableHeaderColumns = useAttachmentsTableColumns();

  const expandRow = (rowKey) => {
    if (!expanded.includes(rowKey)) setExpanded([rowKey]);
  };

  return (
    <Table
      columns={tableHeaderColumns}
      rows={allAttachments}
      getRowKey={(row) => row.attachmentId}
      expanded={expanded}
      onExpand={setExpanded}
    >
      <AttachmentsTableHead />
      <AttachmentsTableBody expandRow={expandRow} />
    </Table>
  );
}
