import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {
  Panel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableHeaderRow,
  TableRow,
  TableRowActionsCell,
} from "@sokigo/components-react-bootstrap";
import {Fragment} from "react";
import {useServerState} from "../../../ServerStateProvider";
import {useCurrentVisit} from "../InspectionVisitRoute";
import {EditPresentPartyButton} from "./EditPresentPartyButton";
import {RemovePresentPartyButton} from "./RemovePresentPartyButton";
import {AddCustomPresentPartyButton} from "./AddCustomPresentPartyButton";

export function PresentPartiesTable() {
  const t = useAppTranslation();
  const {
    baseData: {roles},
  } = useServerState();
  const {
    visit: {
      visitMetadata: {
        visitTargetInformation: {casePartyRoles},
      },
      input: {presentParties},
    },
  } = useCurrentVisit();

  const columns = [
    {
      header: t("nameForProtocol"),
      key: "nameForProtocol",
      renderCell: ({row}) => row.nameForProtocol,
    },
    {
      header: t("roleForProtocol"),
      key: "roleForProtocol",
      renderCell: ({row}) =>
        roles.find((r) => r.id === row.protocolRoleId).name,
    },
    {
      header: t("emailAddressForDispatch"),
      key: "emailAddressForDispatch",
      renderCell: ({row}) =>
        row.emailAddressForDispatch ??
        (row.contactDetailIdForDispatch
          ? casePartyRoles
              .find(
                (cpr) =>
                  cpr.partyId === row.partyId && cpr.contactId === row.contactId
              )
              .emailAddresses.find(
                (e) => e.contactDetailId === row.contactDetailIdForDispatch
              ).emailAddress
          : null),
    },
  ];

  return (
    <Panel className="mt-3 py-3">
      <h4 className="px-3 mb-3">{t("presentParties")}</h4>
      {!(presentParties?.length > 0) && (
        <div className="px-3">{t("noPresentParties")}</div>
      )}
      {presentParties?.length > 0 && (
        <Table rows={presentParties} columns={columns} getRowKey={(x) => x.id}>
          <TableHead>
            <TableHeaderRow>
              {(columns) => (
                <>
                  {columns.map((x) => (
                    <TableHeader key={x.key} column={x}>
                      {x.header}
                    </TableHeader>
                  ))}
                  <TableHeader />
                </>
              )}
            </TableHeaderRow>
          </TableHead>
          <TableBody>
            {(rows) =>
              rows.map((r) => (
                <Fragment key={r.key}>
                  <TableRow row={r}>
                    {r.cells.map((c) => (
                      <TableCell key={c.key}>{c.value}</TableCell>
                    ))}
                    <TableRowActionsCell>
                      <EditPresentPartyButton presentParty={r.row} />
                      <RemovePresentPartyButton id={r.row.id} />
                    </TableRowActionsCell>
                  </TableRow>
                </Fragment>
              ))
            }
          </TableBody>
        </Table>
      )}
      <div className="px-3 mt-3 text-right">
        <AddCustomPresentPartyButton />
      </div>
    </Panel>
  );
}
