import {faCheck, faTimes} from "@fortawesome/pro-regular-svg-icons";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@sokigo/components-react-bootstrap";
import {useState} from "react";
import {useAttachments} from "./AttachmentsProvider";
import {Attachments} from "./ModalAttachments";

export function AttachmentModal({show, onClose, onOk}) {
  const t = useAppTranslation();
  const {newAttachments: attachments} = useAttachments();
  const [disableOk, setDisableOk] = useState(false);

  return (
    <Modal show={show} onClose={onClose} size="xl" scrollable static>
      <ModalHeader>
        {attachments && attachments.length > 1
          ? t("addAttachments")
          : t("addAttachment")}
      </ModalHeader>
      <ModalBody>
        <Attachments />
      </ModalBody>
      <ModalFooter className="mt-3">
        <Button className="mr-2" onClick={onClose} icon={faTimes}>
          {t("cancel")}
        </Button>
        <Button
          kind="primary"
          onClick={() => {
            setDisableOk(true);
            onOk(setDisableOk);
          }}
          icon={faCheck}
          disabled={disableOk}
        >
          {t("ok")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
