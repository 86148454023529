import {ControlAssessment} from "./ControlAssessment";
import {useMemo} from "react";
import {v4 as uuid} from "uuid";
import {useCurrentVisit} from "../InspectionVisitRoute";
import {InspectionAssessment} from "../../Common/InspectionAssessment";

export function ControlAssessments({control, question, onChangeControl}) {
  const {
    visit: {
      foodInspection24Options: {foodFacilityTypes},
    },
  } = useCurrentVisit();

  const assessments = useMemo(() => {
    return (
      (control.assessments ?? []).reduce((acc, cur) => {
        if (cur.questionId === question.id) {
          if (!acc) acc = [];
          acc.push(cur);
        }
        return acc;
      }, null) ?? [{id: uuid(), questionId: question.id}]
    );
  }, [control.assessments, question]);

  const foodFacilityTypeName = useMemo(
    () =>
      foodFacilityTypes.find((f) => f.id === control.foodFacilityTypeId).name,
    [control.foodFacilityTypeId, foodFacilityTypes]
  );

  function onChangeAssessment(assessment) {
    if (!control.assessments) {
      control.assessments = [];
    }
    if (control.assessments.find((a) => a.id === assessment.id) !== undefined) {
      control.assessments = control.assessments.map((x) =>
        x.id === assessment.id ? assessment : x
      );
    } else {
      control.assessments.push(assessment);
    }
    onChangeControl(control);
  }

  function addAssessment() {
    const controlAssessments = control.assessments.concat([
      {
        id: uuid(),
        questionId: question.id,
        valid: InspectionAssessment.NotAcceptable,
      },
    ]);

    control.assessments = controlAssessments;
    onChangeControl(control);
  }

  function removeAssessment(assessmentId) {
    control.assessments = control.assessments.filter(
      (a) => a.id !== assessmentId
    );
    onChangeControl(control);
  }

  return (
    <>
      {assessments.map((a, i) => (
        <ControlAssessment
          key={a.id}
          question={question}
          currentAssessment={a}
          onChangeAssessment={onChangeAssessment}
          addAssessment={addAssessment}
          removeAssessment={removeAssessment}
          foodFacilityTypeName={foodFacilityTypeName}
          isMultipleAssessed={assessments.length > 1}
          isLast={i === assessments.length - 1}
        />
      ))}
    </>
  );
}
