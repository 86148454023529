import {faCheck, faTimes} from "@fortawesome/pro-regular-svg-icons";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {
  Button,
  Control,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Select,
} from "@sokigo/components-react-bootstrap";
import {useMemo, useState} from "react";
import {v4 as uuid} from "uuid";
import {TextBoxControl} from "../../../../../common-components/TextBoxControl";
import {isValidEmailAddress} from "./isValidEmailAddress";
import {RolePicker} from "./RolePicker";

export function EditCasePartyRolePresentPartyModal({
  onClose,
  onSave,
  defaultValue,
  casePartyRole,
  title,
}) {
  const t = useAppTranslation();
  const [saving, setSaving] = useState(false);

  const [value, setValue] = useState(
    () =>
      defaultValue ?? {
        id: uuid(),
        partyId: casePartyRole.partyId,
        contactId: casePartyRole.contactId,
        protocolRoleId: casePartyRole.roleId,
        nameForProtocol: casePartyRole.contactName
          ? `${casePartyRole.contactName} (${casePartyRole.partyName})`
          : casePartyRole.partyName,
        contactDetailIdForDispatch:
          casePartyRole.emailAddresses.length === 1
            ? casePartyRole.emailAddresses[0].contactDetailId
            : casePartyRole.emailAddresses.find(
                (e) => e.flaggedAsRelevantForCasePartyRole
              )?.contactDetailId ?? null,
        emailAddressForDispatch: null,
      }
  );

  async function save() {
    setSaving(true);
    await onSave(value);
    setSaving(false);
  }

  const [emailAddressSelection, setEmailAddressSelection] = useState(() => {
    return (
      value.contactDetailIdForDispatch ??
      (value.emailAddressForDispatch ? "custom" : "null")
    );
  });

  const emailAddressOptions = useMemo(
    () => [
      {rowId: "null", label: t("noEmail")},
      {rowId: "custom", label: t("otherEmail")},
      ...casePartyRole.emailAddresses.map(
        ({contactDetailId, emailAddress, emailAddressType}) => ({
          rowId: contactDetailId,
          label: emailAddress + emailAddressType,
        })
      ),
    ],
    [casePartyRole, t]
  );

  // eslint-disable-next-line no-console
  console.log(casePartyRole.emailAddresses);

  const {protocolRoleId, nameForProtocol, emailAddressForDispatch} = value;
  const emailAddressValid =
    !emailAddressForDispatch || isValidEmailAddress(emailAddressForDispatch);
  const canSave = protocolRoleId && emailAddressValid;

  const setEmailLabel = (x) => {
    const flaggedAsRelevantForCasePartyRole =
      x.rowId === "null" || x.rowId === "custom"
        ? false
        : casePartyRole.emailAddresses.find(
            (y) => y.contactDetailId === x.rowId
          ).flaggedAsRelevantForCasePartyRole;
    return (
      <>
        {flaggedAsRelevantForCasePartyRole ? (
          <strong>{x.label}</strong>
        ) : (
          <>{x.label}</>
        )}
      </>
    );
  };

  return (
    <Modal show onClose={onClose} static>
      <fieldset disabled={saving}>
        <ModalHeader onClose={onClose}>{title}</ModalHeader>
        <ModalBody>
          <TextBoxControl
            label={t("nameForProtocol")}
            value={nameForProtocol}
            onChange={(nameForProtocol) =>
              setValue((v) => ({...v, nameForProtocol}))
            }
            placeholder={t("nameForProtocol")}
            readOnly
          />
          <Control labelText={t("emailAddressForDispatch")}>
            <Select
              value={emailAddressSelection}
              onChange={(selection) => {
                setEmailAddressSelection(selection);
                setValue((v) => ({
                  ...v,
                  emailAddressForDispatch: null,
                  contactDetailIdForDispatch:
                    (selection !== "null" &&
                      selection !== "custom" &&
                      selection) ||
                    null,
                }));
              }}
              options={emailAddressOptions}
              getOptionValue={(x) => x.rowId}
              getValueText={(x) => x.label}
              getOptionKey={(x) => x.rowId}
              getOptionText={(x) => setEmailLabel(x)}
            />
          </Control>
          {emailAddressSelection === "custom" && (
            <TextBoxControl
              label={t("otherEmail")}
              value={emailAddressForDispatch}
              onChange={(emailAddressForDispatch) =>
                setValue((v) => ({...v, emailAddressForDispatch}))
              }
              placeholder={t("emailAddressForDispatch")}
              errors={!emailAddressValid ? [t("invalidEmailAddress")] : []}
            />
          )}
          <RolePicker
            label={t("roleForProtocol")}
            value={protocolRoleId}
            onChange={(protocolRoleId) =>
              setValue((v) => ({...v, protocolRoleId}))
            }
            errors={protocolRoleId ? [] : [t("mandatory")]}
          />
        </ModalBody>
        <ModalFooter>
          <Button icon={faTimes} onClick={onClose} className="w-25">
            {t("cancel")}
          </Button>
          <Button
            kind="primary"
            icon={faCheck}
            disabled={!canSave}
            onClick={save}
            className="ml-2 w-25"
          >
            {t("ok")}
          </Button>
        </ModalFooter>
      </fieldset>
    </Modal>
  );
}
