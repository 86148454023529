import {Fragment} from "react";
import styles from "./VisitInformationHeader24.module.scss";

function Columns({array}) {
  return array
    .filter((x) => x.value)
    .map((object) => {
      return (
        <Fragment key={object.title}>
          <div className={styles.flexItemHeader}>{object.title}:</div>
          <div className={styles.flexItem}>{object.value}</div>
        </Fragment>
      );
    });
}

export const InfoRowSingleLine = ({array}) => {
  return (
    <div className={styles.flexContainer}>
      <Columns array={array} />
    </div>
  );
};
