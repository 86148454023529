import {faCheck, faFileSearch} from "@fortawesome/pro-regular-svg-icons";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {getValidationErrorMessages} from "@sokigo-sbwebb/validation";
import {getValidationErrors} from "@sokigo-sbwebb/validation";
import {
  Alert,
  Button,
  Control,
  Panel,
  Select,
} from "@sokigo/components-react-bootstrap";
import {useState, useCallback, useMemo} from "react";
import {useHistory} from "react-router-dom";
import {useFinishVisit} from "../../../../sw-comms/device-inspections-repo/useDeviceVisits";
import {
  useFoodControlAreasValidation,
  useFoodControlQuestionsValidation,
} from "../../foodInspectionControl/useFoodControlAreasValidation";
import {
  useCurrentVisit,
  useCurrentVisitInput,
  useMergeCurrentVisitInput,
} from "../../InspectionVisitRoute";
import {useSyncVisit} from "../../useSyncVisit";
import {EmailSection} from "./FinishVisitSections/EmailSection";
import {SaveAsSection} from "./FinishVisitSections/SaveAsSection";
import {SendByEmailSwitch} from "./FinishVisitSections/SendByEmailSwitch";
import {SaveDocumentAs} from "./SaveDocumentAs";
import {SaveAsType} from "../../../AdminRoutes/AdminVisitForms/EditVisitForm/VisitFormEditor/SaveAsType";
import {useServerState} from "src/apps/sokigo-sbwebb-inspection/ServerStateProvider";
import {useCompletingInformationProvider} from "../CompletingInformation/CompletingInformationTab";
import {useFoodInspection2013DateValidation} from "../../foodInspection24Validator/useFoodInspection24Validation";
import {useAttachmentSizeValidation} from "../../attachments/useAttachmentTabValidation";

function PreviewProtocolButton({disabled}) {
  const t = useAppTranslation();
  const {
    visit: {id: currentVisitId},
  } = useCurrentVisit();

  const [hasUnsavedChanges, doSync] = useSyncVisit();

  const [syncing, setSyncing] = useState(false);
  const history = useHistory();

  async function onClick() {
    if (hasUnsavedChanges) {
      setSyncing(true);
      try {
        await doSync();
      } finally {
        setSyncing(false);
      }
    }
    window.open(
      history.createHref({
        pathname: "/inspection/protocolPreview/" + currentVisitId,
      })
    );
  }

  return (
    <Button
      kind="secondary"
      disabled={disabled || syncing}
      onClick={onClick}
      icon={faFileSearch}
    >
      {t("previewProtocol")}
    </Button>
  );
}

export function FinishVisitTab({
  validationErrors,
  pendingAdditionalEmailAddressIsInvalid,
  noEmailAddressesSpecified,
  hasCompletingInfoValidationErrors,
  templateValidationErrors,
  canFinish,
}) {
  const t = useAppTranslation();
  const hasFoodControlValidationErrors = !useFoodControlAreasValidation();
  const isAttachmentTotalSizeToLarge = useAttachmentSizeValidation();
  const foodQuestionValidation = useFoodControlQuestionsValidation();
  const input = useCurrentVisitInput();
  const {finishVisitInfo} = input;
  const visit = useCurrentVisit();
  const {onChange, value} = useCompletingInformationProvider();
  const onInspectionControlProtocolChange = useCallback(
    (change) => {
      onChange({...value, ...change});
    },
    [onChange, value]
  );

  // const value = input?.completingInformation ?? {};
  const {
    visit: {
      id: currentVisitId,
      visitFormSnapshot: {
        id,
        emailDispatch: {allow: allowEmail},
      },
      visitMetadata: {
        saveAsType: saveAsType,
        visitTargetInformation: {casePartyRoles},
      },
    },
  } = visit;
  const {
    baseData: {availableTemplates},
    visitForms,
  } = useServerState();
  const usedVisitForm = visitForms[id];
  const reportTemplateIds = usedVisitForm.reportTemplateIds;
  const mappedTemplates = reportTemplateIds
    .map((id) => availableTemplates?.find((t) => t.id === id))
    .filter((x) => x);

  const allSelectedTemplatesExist =
    reportTemplateIds.length > 0 &&
    reportTemplateIds.length === mappedTemplates.length;

  const casePartyRolesReduced = useMemo(
    () =>
      casePartyRoles.reduce((acc, curr) => {
        return acc.some((x) => x.partyId === curr.partyId)
          ? acc
          : [...acc, curr];
      }, []),
    [casePartyRoles]
  );

  const templateOptions = allSelectedTemplatesExist
    ? mappedTemplates
    : availableTemplates;
  const merge = useMergeCurrentVisitInput();
  function onNChange(changes) {
    if (changes.saveAs === 0) {
      changes.sendByEmail = false;
    }
    merge({finishVisitInfo: {...finishVisitInfo, ...changes}});
  }

  const dateValidation = useFoodInspection2013DateValidation();
  const finishVisit = useFinishVisit();

  const onFinishVisitClicked = () => {
    finishVisit(currentVisitId);
  };

  if (
    saveAsType === SaveAsType.FoodInspection &&
    (!foodQuestionValidation.hasAnyQuestionsAssessed ||
      foodQuestionValidation.notAssessedWithNote)
  ) {
    canFinish = false;
  }

  return (
    <div>
      {isAttachmentTotalSizeToLarge && (
        <Alert kind="warning" inline hideCloseButton contrast className="mt-3">
          {t("attachmentSizeToLarge")}
        </Alert>
      )}
      {hasFoodControlValidationErrors && (
        <Alert kind="warning" inline hideCloseButton contrast className="mt-3">
          {t("operativeGoalsErrorMessage")}
        </Alert>
      )}
      {!foodQuestionValidation.hasAnyQuestionsAssessed &&
        saveAsType === SaveAsType.FoodInspection && (
          <Alert
            kind="warning"
            inline
            hideCloseButton
            contrast
            className="mt-3"
          >
            {t("noQuestionSelectedForFoodFacility")}
          </Alert>
        )}
      {dateValidation.unallowed2024creation &&
        saveAsType === SaveAsType.FoodInspection && (
          <Alert
            kind="warning"
            inline
            hideCloseButton
            contrast
            className="mt-3"
          >
            {t("datesAfter2023NotAllowed")}
          </Alert>
        )}
      {foodQuestionValidation.notAssessedWithNote && (
        <Alert kind="warning" inline hideCloseButton contrast className="mt-3">
          {t("questionWithoutAssessmentWithNote")}
        </Alert>
      )}
      {(hasCompletingInfoValidationErrors ||
        templateValidationErrors.length > 0) && (
        <Alert kind="warning" inline hideCloseButton contrast className="mt-3">
          {t("mandatoryFieldsAreNotCompleted")}
        </Alert>
      )}
      <Panel className="p-3">
        <div className="d-flex justify-content-between">
          <h3 className="mb-3">{t("protocol")}</h3>
          <PreviewProtocolButton disabled={!canFinish} />
        </div>
        <Control
          labelText={t("template")}
          horizontal
          errors={getValidationErrorMessages(
            templateValidationErrors,
            "protocolTemplateId"
          )}
        >
          <Select
            className="mb-3"
            options={templateOptions}
            value={value.protocolTemplateId}
            onChange={(protocolTemplateId) =>
              onInspectionControlProtocolChange({protocolTemplateId})
            }
            getOptionKey={(x) => x.id}
            getOptionValue={(x) => x.id}
            getOptionText={(x) => x.title}
          />
        </Control>
        {casePartyRolesReduced.length > 0 && (
          <Control labelText={t("addressee")} horizontal>
            <Select
              className="mb-3"
              options={casePartyRolesReduced}
              value={value.casePartyAddressee}
              onChange={(casePartyAddressee) =>
                onInspectionControlProtocolChange({casePartyAddressee})
              }
              placeholder={t("addressee")}
              getOptionKey={(x) => x.partyId}
              getOptionValue={(x) => x.partyId}
              getOptionText={(x) => x.partyName}
            />
          </Control>
        )}
        <SaveAsSection
          value={finishVisitInfo.saveAs}
          onChange={onNChange}
          validationErrors={validationErrors}
        />
        {finishVisitInfo.saveAs === SaveDocumentAs.Completed && allowEmail && (
          <SendByEmailSwitch
            value={finishVisitInfo.sendByEmail}
            onChange={onNChange}
            validationErrors={validationErrors}
          />
        )}
        {finishVisitInfo.saveAs === SaveDocumentAs.Completed &&
          finishVisitInfo.sendByEmail && (
            <EmailSection
              onChange={onNChange}
              validationErrors={getValidationErrors(
                validationErrors,
                "emailInfo"
              )}
              pendingAdditionalEmailAddressIsInvalid={
                pendingAdditionalEmailAddressIsInvalid
              }
              noEmailAddressesSpecified={noEmailAddressesSpecified}
            />
          )}
      </Panel>
      <div className="d-flex justify-content-end my-3">
        <Button
          kind="primary"
          className="ml-2"
          icon={faCheck}
          disabled={!canFinish}
          onClick={onFinishVisitClicked}
        >
          {finishVisitInfo.sendByEmail
            ? t("finishVisitAndEmail")
            : t("finishVisit")}
        </Button>
      </div>
    </div>
  );
}
