import {
  faArrowRight,
  faDownload,
  faEllipsisV,
  faQuestion,
  faTrashAlt,
} from "@fortawesome/pro-regular-svg-icons";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {useIsOnline} from "@sokigo-sbwebb/serviceworker";
import {
  ActionsDropdown,
  ActionsDropdownItem,
  Button,
  Panel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableHeaderRow,
  TableRow,
  TableRowActionsCell,
} from "@sokigo/components-react-bootstrap";
import moment from "moment";
import {Fragment, useState} from "react";
import {useServerState} from "../../ServerStateProvider";
import {
  useCheckOutVisit,
  useDeleteServerVisit,
  useDeviceVisits,
} from "../../sw-comms/device-inspections-repo/useDeviceVisits";
import {useCurrentEcosUserId} from "../../useCurrentEcosUserId";
import {RefreshServerVisitsButton} from "./RefreshServerVisitsButton";
import {SaveToEcosStatus} from "../Common/SaveToEcosStatus";
import {ShowVisitIdModal} from "../Common/ShowVisitIdModal";

export function ServerVisits() {
  const t = useAppTranslation();
  const isOnline = useIsOnline();
  const deviceVisits = useDeviceVisits();
  const {serverVisits} = useServerState();
  const currentEcosUserId = useCurrentEcosUserId();
  const deleteServerVisit = useDeleteServerVisit();

  const serverVisitsList = Object.keys(serverVisits)
    .filter((x) => !deviceVisits[x]) // hide visits on device
    .filter(
      // only show non-finished visits
      (x) =>
        serverVisits[x].saveToEcosStatus ===
          SaveToEcosStatus.VisitNotFinished ||
        serverVisits[x].saveToEcosStatus ===
          SaveToEcosStatus.VisitWithValidationErrorsReopened
    )
    .filter((x) => serverVisits[x].ownerId === currentEcosUserId) // only show my visits
    .filter((x) => !serverVisits[x].checkoutId) // only show visits that are not checked out
    .map((x) => serverVisits[x]);

  const checkOutVisit = useCheckOutVisit();

  const [showingVisitId, setShowingVisitId] = useState(null);

  const columns = [
    {
      key: "visitTargetDescription",
      header: t("description"),
      renderCell: ({row}) => row.visitTargetDescription,
    },
    {
      key: "date",
      header: t("date"),
      renderCell: ({row}) => moment(row.createdDate).format("YYYY-MM-DD"),
    },
    {
      key: "lastSaved",
      header: t("lastSaved"),
      renderCell: ({row}) =>
        t("lastSavedDateAndDevice", {
          date: moment(row.lastSavedDate).format("YYYY-MM-DD"),
          device: row.lastSavedDevice,
        }),
    },
  ];

  return (
    <Panel className="my-3 p-3" highlightColor="#12c49e">
      <div className="d-flex justify-content-between">
        <h3 className="mb-3">{t("availableOnline")}</h3>
        <RefreshServerVisitsButton />
      </div>
      {!isOnline && <div>{t("deviceIsOffline")}</div>}
      {isOnline && (!serverVisitsList || serverVisitsList.length === 0) && (
        <div>{t("noVisitsAvailable")}</div>
      )}
      {isOnline && serverVisitsList?.length > 0 && (
        <Table
          rows={serverVisitsList}
          columns={columns}
          getRowKey={(x) => x.id}
        >
          <TableHead>
            <TableHeaderRow>
              {(columns) => (
                <>
                  {columns.map((x) => (
                    <TableHeader key={x.key} column={x}>
                      {x.header}
                    </TableHeader>
                  ))}
                  <TableHeader />
                </>
              )}
            </TableHeaderRow>
          </TableHead>
          <TableBody>
            {(rows) =>
              rows.map((r) => (
                <Fragment key={r.key}>
                  <TableRow key={r.key} row={r}>
                    {r.cells.map((c) => (
                      <TableCell key={c.key}>{c.value}</TableCell>
                    ))}
                    <TableRowActionsCell>
                      <ActionsDropdown
                        icon={faEllipsisV}
                        kind="ghost"
                        size="sm"
                      >
                        <ActionsDropdownItem
                          icon={faDownload}
                          onClick={() => checkOutVisit(r.row.id, false)}
                          itemText={t("downloadToDevice")}
                        />
                        <ActionsDropdownItem
                          icon={faTrashAlt}
                          kind="danger-hover-ghost"
                          onClick={() => deleteServerVisit(r.row.id)}
                          itemText={t("remove")}
                        />
                        <ActionsDropdownItem
                          onClick={() => setShowingVisitId(r.row.id)}
                          itemText={t("showVisitId")}
                          icon={faQuestion}
                        />
                      </ActionsDropdown>
                      <Button
                        size="sm"
                        kind="ghost"
                        onClick={() => checkOutVisit(r.row.id, true)}
                        icon={faArrowRight}
                      />
                    </TableRowActionsCell>
                  </TableRow>
                </Fragment>
              ))
            }
          </TableBody>
        </Table>
      )}
      <ShowVisitIdModal
        visitId={showingVisitId}
        onClose={() => setShowingVisitId(null)}
      />
    </Panel>
  );
}
