/* eslint-disable */
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {Icon, Panel} from "@sokigo/components-react-bootstrap";
import {useCurrentVisit} from "../../InspectionVisitRoute";
import {InfoRowSingleLine} from "./InfoRowSingleLine";
import {InfoRowOrientationType} from "./InfoRowOrientationType";
import {InfoRowSLVcode} from "./InfoRowSLVcode";
import {InfoRowEstate} from "./InfoRowEstate";
import {InfoRowToolTip} from "./InfoRowToolTip";
import {faTriangleExclamation} from "@fortawesome/pro-duotone-svg-icons";
import {Fragment} from "react";

function FacilityRows({facilities}) {
  const t = useAppTranslation();
  const {
    visit: {foodFacilityRiskClass24},
  } = useCurrentVisit();

  return (
    <>
      {facilities?.map(({estateName, address}) => (
        <Fragment key={estateName}>
          <h4 className="ml-2">
            {t("facilityType")}
            {foodFacilityRiskClass24.showWarningIsDecisionDateAffected && (
              <Icon
                style={{
                  marginLeft: "1em",
                  "--fa-primary-color": "#030303",
                  "--fa-secondary-color": "#f2c831",
                  "--fa-secondary-opacity": "1",
                }}
                fontAwesomeClassName="fa-duotone fa-triangle-exclamation"
                icon={faTriangleExclamation}
                tooltipText={
                  <div
                    style={{
                      maxWidth: "400px",
                      display: "block",
                      whiteSpace: "pre-wrap",
                      paddingLeft: "0.3em",
                    }}
                  >
                    {t("informationChanged")}
                  </div>
                }
              ></Icon>
            )}
          </h4>
          <InfoRowSingleLine
            array={[
              {
                title: t("mainOrientation"),
                value: foodFacilityRiskClass24.mainOrientation,
              },
            ]}
          />
          <InfoRowOrientationType
            array={[
              {
                title: t("foodFacilityTypesAndMainActivities"),
                value: foodFacilityRiskClass24.orientationTypes,
              },
            ]}
          />
          <InfoRowSLVcode
            array={[
              {
                title: t("otherActivities"),
                value: foodFacilityRiskClass24.otherActivities,
              },
            ]}
          />
          <InfoRowSLVcode
            array={[
              {
                title: t("productGroups"),
                value: foodFacilityRiskClass24.productGroups,
              },
            ]}
          />
          <InfoRowSingleLine
            array={[{title: t("extent"), value: foodFacilityRiskClass24.size}]}
          />
          <InfoRowToolTip
            array={[
              {
                title: t("seasonalFacility"),
                value: foodFacilityRiskClass24.seasonalFacility ? "Ja" : "Nej",
                tooltipValue: foodFacilityRiskClass24.seasonalFacilityNote,
              },
            ]}
          />
          <InfoRowToolTip
            array={[
              {
                title: t("mobileFacility"),
                value: foodFacilityRiskClass24.isMobileFacility ? "Ja" : "Nej",
                tooltipValue:
                  foodFacilityRiskClass24.mobilFacilityRegestrationNumber,
              },
            ]}
          />
          <InfoRowSingleLine
            array={[
              {
                title: t("goodCompliance"),
                value: foodFacilityRiskClass24.goodCompliance ? "Ja" : "Nej",
              },
            ]}
          />
          <InfoRowToolTip
            array={[
              {
                title: t("thirdPartyCertificatations"),
                value: foodFacilityRiskClass24.certifications ? "Ja" : "Nej",
                tooltipValue: foodFacilityRiskClass24.certifications
                  ? foodFacilityRiskClass24.certificates.map((cert, i) => (
                      <>
                        {i > 0 ? ", " : ""}
                        {cert}
                      </>
                    ))
                  : null,
              },
            ]}
          />
          <InfoRowSingleLine
            array={[
              {
                title: t("registeredApproved"),
                value: foodFacilityRiskClass24.registerOption,
              },
            ]}
          />
          <div>
            <>
              <InfoRowSingleLine
                array={[
                  {
                    title: t("riskClass"),
                    value: foodFacilityRiskClass24.decision
                      ? foodFacilityRiskClass24.decision
                          .adjustedRiskClassLevel ?? []
                      : "",
                  },
                ]}
              />
              <InfoRowSingleLine
                array={[
                  {
                    title: t("decidedControlFrequencyPerYear"),
                    value:
                      foodFacilityRiskClass24.decision &&
                      foodFacilityRiskClass24.decision.controlsPerYear
                        ? foodFacilityRiskClass24.decision.controlsPerYear.toLocaleString() ??
                          []
                        : "",
                  },
                ]}
              />
              <InfoRowSingleLine
                array={[
                  {
                    title: t("decidedControlFrequencyPer5Years"),
                    value:
                      foodFacilityRiskClass24.decision &&
                      foodFacilityRiskClass24.decision.controlsPer5Years
                        ? foodFacilityRiskClass24.decision.controlsPer5Years.toLocaleString() ??
                          []
                        : "",
                  },
                ]}
              />
              <InfoRowSingleLine
                array={[
                  {
                    title: t("riskClassAppliesFromDate"),
                    value:
                      foodFacilityRiskClass24.decision &&
                      foodFacilityRiskClass24.decision.effectiveDate
                        ? new Date(
                            foodFacilityRiskClass24.decision.effectiveDate
                          ).toLocaleDateString() ?? []
                        : "",
                  },
                ]}
              />
            </>
          </div>
          <br></br>
          {estateName && (
            <InfoRowEstate
              array={[
                {title: t("estate"), value: estateName},
                {title: t("address"), value: address},
              ]}
            />
          )}
        </Fragment>
      ))}
    </>
  );
}

function EstateRows({estates}) {
  const t = useAppTranslation();
  return (
    <>
      {estates?.map(({estateName, address}) => (
        <InfoRowEstate
          key={estateName + address}
          array={[
            {title: t("estate"), value: estateName},
            {title: t("address"), value: address},
          ]}
        />
      ))}
    </>
  );
}

export const VisitInformationHeader24 = () => {
  const {
    visit: {
      visitMetadata: {
        visitTargetDescription,
        visitTargetInformation: {
          caseNumber,
          caseTitle,
          caseSubtitle,
          estates,
          facilities,
        },
      },
    },
  } = useCurrentVisit();

  const t = useAppTranslation();
  return (
    <Panel className="container-fluid p-3 mt-3">
      <h3 className="mb-3">{visitTargetDescription}</h3>
      <EstateRows estates={estates} />
      <FacilityRows facilities={facilities} />
      {caseNumber && (
        <InfoRowEstate
          array={[
            {title: t("case"), value: caseNumber},
            {title: t("title"), value: caseTitle},
            {title: t("subtitle"), value: caseSubtitle},
          ]}
        />
      )}
      {!caseNumber && (
        <InfoRowEstate array={[{title: t("case"), value: t("newCase")}]} />
      )}
    </Panel>
  );
};
