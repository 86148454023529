import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {getValidationErrorMessages} from "@sokigo-sbwebb/validation";
import {
  ButtonSwitch,
  ButtonSwitchOption,
  Control,
  TextArea,
  TextBox,
} from "@sokigo/components-react-bootstrap";

const PredefinedEmail = ({emailDispatch, onChange, validationErrors}) => {
  const t = useAppTranslation();
  return (
    <>
      <Control labelText={t("allowSendEmail")}>
        <ButtonSwitch
          value={emailDispatch.allow}
          onChange={(allow) => onChange({...emailDispatch, allow})}
        >
          <ButtonSwitchOption value={true}>{t("yes")}</ButtonSwitchOption>
          <ButtonSwitchOption value={false}>{t("no")}</ButtonSwitchOption>
        </ButtonSwitch>
      </Control>
      {emailDispatch.allow && (
        <>
          <Control
            labelText={t("emailSubject")}
            errors={getValidationErrorMessages(validationErrors, "subject")}
          >
            <TextBox
              value={emailDispatch.subject}
              onChange={(subject) => onChange({...emailDispatch, subject})}
            />
          </Control>
          <Control
            labelText={t("emailBody")}
            errors={getValidationErrorMessages(validationErrors, "body")}
          >
            <TextArea
              cols="40"
              rows={5}
              value={emailDispatch.body}
              onChange={(body) => onChange({...emailDispatch, body})}
            />
          </Control>
        </>
      )}
    </>
  );
};

export default PredefinedEmail;
