import {Fragment, useEffect, useMemo, useState} from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderRow,
  TableHeader,
  TableRow,
  TableRowActionsCell,
  Switch,
} from "@sokigo/components-react-bootstrap";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {useIsApplicationBusy} from "@sokigo-sbwebb/default-core";
import {faCheck, faTimes} from "@fortawesome/pro-regular-svg-icons";
import {SaveAsType} from "../AdminRoutes/AdminVisitForms/EditVisitForm/VisitFormEditor/SaveAsType";
import useToggle from "./useToggle";
import {
  useApplicationBusy,
  useNotifications,
} from "@sokigo-sbwebb/default-core";
import {getJson} from "@sokigo-sbwebb/fetch";
import {useServerState} from "../../ServerStateProvider";

export function ExportVisitForm({
  show,
  setShow,
  visitForms,
  visitFormExportId,
}) {
  const t = useAppTranslation();
  const isBusy = useIsApplicationBusy();
  const applicationBusy = useApplicationBusy();
  const {addErrorNotification} = useNotifications();
  const {phraseGroups, controlAreaGroups, supportQuestions} = useServerState();
  const [applicationVersion, setApplicationVersion] = useState();
  const [selected, toggleSingle, toggleAll, clearSelection] = useToggle(
    visitForms.map((x) => x.id),
    []
  );

  const phraseGroupList = useMemo(
    () => phraseGroups && Object.keys(phraseGroups).map((x) => phraseGroups[x]),
    [phraseGroups]
  );

  const foodInspectionControlAreasWithSupportQuestions = useMemo(
    () =>
      supportQuestions &&
      Object.keys(supportQuestions)
        .map((x) => ({id: x, questions: supportQuestions[x]}))
        .filter((x) => x.questions)
        .map((x) => ({
          ...x,
          questions: Object.keys(x.questions).map((y) => ({
            id: y,
            supportQuestions: x.questions[y],
          })),
        })),
    [supportQuestions]
  );

  const controlAreaGroupList = useMemo(
    () =>
      controlAreaGroups &&
      Object.keys(controlAreaGroups).map((x) => controlAreaGroups[x]),
    [controlAreaGroups]
  );

  const allControlAreas = controlAreaGroupList
    ?.map((x) => x?.controlAreas)
    .flat();

  useEffect(() => {
    (async function () {
      applicationBusy(true);
      try {
        const result = await getJson("api/inspection/inspectionVersionInfo");
        setApplicationVersion(result);
      } catch (ex) {
        console.error(ex);
        addErrorNotification(t("couldNotLoadApplicationVersionInfo"));
      } finally {
        applicationBusy(false);
      }
    })();
  }, [addErrorNotification, applicationBusy, t]);

  const onSubmit = () => {
    downloadSelected();
    clearSelection();
  };

  const onClose = () => {
    setShow(false);
    clearSelection();
  };

  const getVisitFormExportData = (visitForm) => {
    return {
      id: visitForm.id,
      title: visitForm.title,
      subTitle: visitForm.subTitle,
      facilityTypeIds: visitForm.facilityTypeIds,
      type: visitForm.saveAsType,
      sections: visitForm.sections,
      controlResponseFormat: visitForm.controlResponseFormat,
      emailDispatch: {
        allow: visitForm.emailDispatch.allow,
        subject: visitForm.emailDispatch.allow
          ? visitForm.emailDispatch.subject
          : null,
        body: visitForm.emailDispatch.allow
          ? visitForm.emailDispatch.body
          : null,
      },
      protocolTexts: visitForm.sections.notes
        ? {
            allowDynamicParagraphs:
              visitForm.protocolTexts?.allowDynamicParagraphs ?? false,
            dynamicParagraphs: visitForm.protocolTexts?.allowDynamicParagraphs
              ? visitForm.protocolTexts?.dynamicParagraphs?.map((dp) => {
                  const phraseGroups = phraseGroupList.filter((pg) =>
                    dp.phraseGroupIds?.includes(pg.id)
                  );
                  return {
                    id: dp.id,
                    title: dp.title,
                    phraseGroups: phraseGroups,
                  };
                })
              : null,
            staticParagraphs: visitForm.protocolTexts?.staticParagraphs?.map(
              (sp) => {
                const phraseGroups = phraseGroupList.filter((pg) =>
                  sp.phraseGroupIds?.includes(pg.id)
                );
                return {
                  id: sp.id,
                  title: sp.title,
                  phraseGroups: phraseGroups,
                };
              }
            ),
          }
        : null,
      controlAreaGroups:
        visitForm.sections.controlAreas &&
        visitForm.saveAsType !== SaveAsType.FoodInspection
          ? controlAreaGroupList
              .filter((cag) => {
                const caIds = cag.controlAreas.map((x) => x.id);
                const savedCaIds = visitForm.controlAreas.map((x) => x.id);
                return savedCaIds.some((id) => caIds.includes(id));
              })
              .map((cag) => {
                return {
                  id: cag.id,
                  title: cag.title,
                  controlAreas: visitForm.controlAreas
                    ?.filter((x) => x.controlAreaGroupId === cag.id)
                    .map((ca) => {
                      const controlArea = allControlAreas.find(
                        (y) => y.id === ca.id
                      );
                      const phraseGroups = phraseGroupList.filter((pg) =>
                        controlArea.phraseGroups?.includes(pg.id)
                      );
                      return {
                        title: controlArea.title,
                        id: controlArea.id,
                        phraseGroups: phraseGroups,
                        controlPoints: ca.controlPoints?.map((cp) => {
                          const controlPoint = controlArea.controlPoints.find(
                            (y) => y.id === cp
                          );
                          const phraseGroups = phraseGroupList.filter((pg) =>
                            controlPoint.phraseGroups?.includes(pg.id)
                          );
                          return {
                            title: controlPoint.title,
                            id: controlPoint.id,
                            helpText: controlPoint.helpText,
                            phraseGroups: phraseGroups,
                          };
                        }),
                      };
                    }),
                };
              })
          : [],
      foodInspectionControlAreas: visitForm.foodInspectionControlAreas?.map(
        (fica) => {
          const controlArea =
            foodInspectionControlAreasWithSupportQuestions.find(
              (x) => x.id === fica.id
            );
          return {
            ...fica,
            questions: fica.questions.map((y) => {
              const question = controlArea?.questions.find(
                (que) => que.id === y
              );
              return {
                id: y,
                supportQuestions: question?.supportQuestions,
              };
            }),
          };
        }
      ),
    };
  };

  const downloadSelected = () => {
    const visitFormsToExport = visitForms.filter((x) =>
      selected.includes(x.id)
    );
    const dataForExport = {
      version: `${applicationVersion?.productMajorPart}.${applicationVersion?.productMinorPart}`,
      exportId: visitFormExportId,
      visitForms: visitFormsToExport.map((x) => getVisitFormExportData(x)),
    };
    const json = JSON.stringify(dataForExport);
    const encoded = "data:text/json;charset=utf-8," + encodeURIComponent(json);
    const el = document.createElement("a");
    el.setAttribute("href", encoded);
    el.setAttribute("download", `InspectionVisitForms.json`);
    el.click();
    onClose();
  };

  return (
    <Modal size="xl" show={show} onClose={onClose} scrollable={true} static>
      <fieldset disabled={isBusy}>
        <ModalHeader>{t("exportVisitForm")}</ModalHeader>
        <ModalBody>
          <VisitFormTable
            visitForms={visitForms}
            selected={selected}
            toggleAll={toggleAll}
            toggleSingle={toggleSingle}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            kind="secondary"
            onClick={onClose}
            className="mr-2"
            icon={faTimes}
          >
            {t("cancel")}
          </Button>
          <Button
            onClick={onSubmit}
            icon={faCheck}
            disabled={selected.length === 0}
            kind="primary"
          >
            {t("exportSelected")}
          </Button>
        </ModalFooter>
      </fieldset>
    </Modal>
  );
}

function VisitFormTable({visitForms, selected, toggleAll, toggleSingle}) {
  const t = useAppTranslation();

  function getTypeText(type) {
    if (type === SaveAsType.GenericInspection) {
      return t("genericInspection");
    } else if (type === SaveAsType.FoodInspection) {
      return t("foodInspection");
    } else {
      return t("visitOccurrence");
    }
  }

  const columns = [
    {
      key: "title",
      header: t("title"),
      renderCell: ({row}) => row.title,
    },
    {
      key: "subTitle",
      header: t("subTitle"),
      renderCell: ({row}) => row.subTitle,
    },
    {
      key: "type",
      header: t("type"),
      renderCell: ({row}) => {
        return getTypeText(row.saveAsType);
      },
    },
  ];

  const allSelected = selected.length === visitForms.length;

  return (
    <>
      <Table rows={visitForms} columns={columns} getRowKey={(x) => x.id}>
        <TableHead>
          <TableHeaderRow colSpan={columns.length + 1}>
            {(columns) => (
              <>
                {columns.map((x) => (
                  <TableHeader key={x.key} column={x}>
                    {x.header}
                  </TableHeader>
                ))}
                <TableHeader>
                  <Switch
                    checked={allSelected}
                    onChange={() => toggleAll()}
                    indeterminate={selected?.length > 0 && !allSelected}
                  />
                </TableHeader>
              </>
            )}
          </TableHeaderRow>
        </TableHead>
        <TableBody>
          {(rows) =>
            rows.map((r) => (
              <Fragment key={r.key}>
                <TableRow key={r.key} row={r} invalid={!r.row.title}>
                  {r.cells.map((c) => (
                    <TableCell key={c.key}>{c.value}</TableCell>
                  ))}
                  <TableRowActionsCell>
                    <Switch
                      checked={selected.includes(r.row.id)}
                      onChange={() => toggleSingle(r.row.id)}
                    />
                  </TableRowActionsCell>
                </TableRow>
              </Fragment>
            ))
          }
        </TableBody>
      </Table>
    </>
  );
}
