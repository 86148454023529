import {useEffect} from "react";
import {useMap} from "./MapProvider";

export const useMapEffect = (effect, dependencies, debug) => {
  const map = useMap();
  const deps = [map, ...(dependencies || [])];
  if (debug) {
    // eslint-disable-next-line no-debugger
    debugger;
  }
  useEffect(() => {
    if (!map) {
      return;
    }
    return effect(map);
  }, deps); // eslint-disable-line react-hooks/exhaustive-deps
};
