import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {getValidationErrorMessages} from "@sokigo-sbwebb/validation";
import {Control, TextBox, Select} from "@sokigo/components-react-bootstrap";
import {useState} from "react";
import {useServerState} from "../../../../../../ServerStateProvider";

export function StaticParagraphSettings({
  onChange,
  staticParagraph,
  validationErrors,
}) {
  const t = useAppTranslation();
  const {phraseGroups} = useServerState();
  const [initialTitle] = useState(staticParagraph?.title);

  const phraseOptions = Object.keys(phraseGroups).map(function (key) {
    return phraseGroups[key];
  });

  return (
    <div className="d-flex flex-column mt-2">
      <Control
        labelText={t("title")}
        horizontal
        helpText={
          initialTitle !== staticParagraph?.title ? t("tagMustBeUpdated") : null
        }
        errors={getValidationErrorMessages(validationErrors, "title")}
      >
        <TextBox
          value={staticParagraph?.title}
          onChange={(title) =>
            onChange({
              ...staticParagraph,
              title,
            })
          }
        />
      </Control>
      <Control labelText={t("tag")} horizontal>
        <div className="font-weight-bold">{`[Stycke:${staticParagraph?.title}]`}</div>
      </Control>
      <Control labelText={t("phrases")} horizontal>
        <Select
          multiple
          withCheckboxes
          options={phraseOptions}
          value={staticParagraph?.phraseGroupIds ?? []}
          getOptionValue={(x) => x.id}
          getOptionText={(x) => x.title}
          getOptionKey={(x) => x.id}
          onChange={(phraseGroupIds) =>
            onChange({...staticParagraph, phraseGroupIds})
          }
        />
      </Control>
    </div>
  );
}
