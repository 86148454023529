import {faCamera} from "@fortawesome/pro-regular-svg-icons";
import {useNotifications} from "@sokigo-sbwebb/default-core";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {useRefresh} from "@sokigo-sbwebb/react";
import {Button} from "@sokigo/components-react-bootstrap";
import {useCallback, useMemo, useRef, useState} from "react";
import {v4 as uuid} from "uuid";
import {AttachmentModal} from "../../attachments/AttachmentModal";
import {AttachmentsProvider} from "../../attachments/AttachmentsProvider";
import {
  useCurrentVisit,
  useCurrentVisitInput,
  useMergeCurrentVisitInput,
} from "../../InspectionVisitRoute";
import {useGetFilePositionAsync} from "./useGetFilePositionAsync";

function useDefaultDocumentType() {
  const {
    visit: {
      baseDataSnapshot: {documentTypes},
    },
  } = useCurrentVisit();
  return useMemo(
    // TODO this should only need the document type id!
    () => documentTypes.find((docType) => docType.name === "Foto"),
    [documentTypes]
  );
}

export function AddAttachmentsButton() {
  const t = useAppTranslation();
  const [newAttachments, setNewAttachments] = useState([]);
  const mergeCurrentVisitInput = useMergeCurrentVisitInput();
  const {addErrorNotification, addSuccessNotification} = useNotifications();

  const input = useCurrentVisitInput();
  const {
    visit: {visitFormSnapshot},
  } = useCurrentVisit();

  const attachments = useMemo(() => input.attachments ?? [], [input]);

  const deleteAttachment = (attachmentRow) => {
    const attachmentsWithoutRemoved = newAttachments.filter((attachment) => {
      return attachment.attachmentId !== attachmentRow?.attachmentId;
    });
    setNewAttachments(attachmentsWithoutRemoved);
  };

  const changeAttachment = useCallback(
    (changedAttachment) => {
      const attachmentsWithChangedOne = newAttachments.map((attachment) => {
        if (attachment.attachmentId === changedAttachment.attachmentId) {
          return changedAttachment;
        }
        return attachment;
      });
      setNewAttachments(attachmentsWithChangedOne);
    },
    [newAttachments]
  );

  const saveAttachments = useCallback(
    async (setDisableOk) => {
      try {
        const mergedAttachments = [
          ...attachments,
          ...newAttachments.map((x) => {
            // only metadata in the visit input
            // eslint-disable-next-line no-unused-vars
            const {file, ...metadata} = x;
            return metadata;
          }),
        ];
        mergeCurrentVisitInput(
          {attachments: mergedAttachments},
          newAttachments.map((a) => ({
            // data sent separately
            // file is already a blob, so can be passed directly
            id: a.attachmentId,
            data: a.file,
          }))
        );
        addSuccessNotification(t("attachmentAdded"));
      } catch (err) {
        addErrorNotification(t("attachmentAddingFailed"));
      }
      closeAttachmentsModal();
      enableAttachmentModalOkButton(setDisableOk);
    },
    [
      t,
      newAttachments,
      attachments,
      mergeCurrentVisitInput,
      addSuccessNotification,
      addErrorNotification,
    ]
  );

  const closeAttachmentsModal = () => {
    setNewAttachments([]);
  };

  const enableAttachmentModalOkButton = (setDisableOk) => {
    if (typeof setDisableOk === "function") {
      setDisableOk(false);
    }
  };
  const inputRef = useRef(null);
  const [refreshToken, refresh] = useRefresh();
  const defaultDocumentType = useDefaultDocumentType();
  const getFilePositionAsync = useGetFilePositionAsync();

  async function onFileInputChange(evt) {
    refresh(); // remount file input to allow selection of the same file again
    const attachments = await Promise.all(
      [...evt.target.files].map(async (file) => ({
        attachmentId: uuid(),
        documentType: defaultDocumentType,
        mimeType: file.type,
        note: "",
        fileName: file.name,
        position: await getFilePositionAsync(file),
        file,
        fileUrl: URL.createObjectURL(file),
        isNew: true,
        size: file.size,
      }))
    );
    setNewAttachments(attachments);
  }

  return (
    <>
      {newAttachments && (
        <AttachmentsProvider
          value={{
            newAttachments,
            deleteAttachment,
            changeAttachment,
            allAttachments: attachments,
          }}
        >
          <AttachmentModal
            show={newAttachments.length > 0}
            onClose={closeAttachmentsModal}
            onOk={saveAttachments}
          />
        </AttachmentsProvider>
      )}
      {visitFormSnapshot.sections.documents && (
        <Button
          kind="secondary"
          round
          tooltipText={t("attachmentsUploadButton")}
          onClick={() => inputRef.current.click()}
          icon={faCamera}
          className="btn-icon-only"
        >
          <input
            key={refreshToken}
            type="file"
            ref={inputRef}
            multiple
            onChange={onFileInputChange}
            hidden
          />
        </Button>
      )}
    </>
  );
}
