import {faSearch, faSpinnerThird} from "@fortawesome/pro-regular-svg-icons";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {Button} from "@sokigo/components-react-bootstrap";
import {useState} from "react";
import {CaseSearchForm} from "./CaseSearchForm";
import {CaseWorkerSelection} from "./CaseWorkerSelection";
import {CaseWorkerSelectionSwitch} from "./CaseWorkerSelectionSwitch";
import {EstateSearchForm} from "./EstateSearchForm";
import {SearchType} from "./SearchType";
import {SearchTypeSwitch} from "./SearchTypeSwitch";

export function SearchForm({disabled, searching, onSearch}) {
  const t = useAppTranslation();
  const [value, setValue] = useState({
    searchType: SearchType.Estate,
    caseWorkerSelection: CaseWorkerSelection.Me,
  });
  const canSearch = (() => {
    if (value.caseWorkerSelection === CaseWorkerSelection.Me) {
      return true;
    }
    if (value.caseWorkerSelection === CaseWorkerSelection.Any) {
      return value.searchType === SearchType.Estate
        ? value.estateSearch &&
            Object.values({
              ...value.estateSearch,
              estateOwnerSelection: null,
            }).some((x) => x)
        : value.caseSearch && Object.values(value.caseSearch).some((x) => x);
    }
    if (value.caseWorkerSelection === CaseWorkerSelection.Specific) {
      return !!value.specificCaseWorkerId;
    }
  })();

  async function onSubmit(evt) {
    evt.preventDefault();
    onSearch(value);
  }

  return (
    <form onSubmit={onSubmit}>
      <fieldset disabled={disabled}>
        <div className="row">
          <div className="col-md">
            <SearchTypeSwitch
              value={value.searchType}
              onChange={(searchType) => setValue((v) => ({...v, searchType}))}
            />
          </div>
          <div className="col-md">
            <CaseWorkerSelectionSwitch
              selection={value.caseWorkerSelection}
              onSelectionChange={(caseWorkerSelection) =>
                setValue((v) => ({...v, caseWorkerSelection}))
              }
              specificCaseWorkerId={value.specificCaseWorkerId}
              onSpecificCaseWorkerIdChange={(specificCaseWorkerId) =>
                setValue((v) => ({...v, specificCaseWorkerId}))
              }
              canSearch={canSearch}
            />
          </div>
        </div>
        {value.searchType === SearchType.Estate && (
          <EstateSearchForm
            value={value.estateSearch}
            onChange={(estateSearch) => setValue((v) => ({...v, estateSearch}))}
          />
        )}
        {value.searchType === SearchType.Case && (
          <CaseSearchForm
            value={value.caseSearch}
            onChange={(caseSearch) => setValue((v) => ({...v, caseSearch}))}
          />
        )}
        <hr />
        <Button
          style={{width: 200}}
          kind="primary"
          icon={searching ? faSpinnerThird : faSearch}
          iconSpin={searching}
          type="submit"
          disabled={!canSearch}
        >
          {t("search")}
        </Button>
      </fieldset>
    </form>
  );
}
