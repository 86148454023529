import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {faTrashCan} from "@fortawesome/pro-regular-svg-icons";
import {
  Button,
  ButtonSwitch,
  ButtonSwitchOption,
} from "@sokigo/components-react-bootstrap";
import {InspectionAssessment} from "../../Common/InspectionAssessment";

export function FoodControl24AssessmentSwitch({
  value,
  onChange,
  size,
  isMultipleAssessed,
  onRemove,
  assessmentId,
}) {
  const t = useAppTranslation();
  return (
    <>
      <ButtonSwitch
        value={value ?? InspectionAssessment.NotAssessed}
        onChange={(x) => onChange(x)}
        size={size}
      >
        {!isMultipleAssessed && (
          <ButtonSwitchOption
            value={InspectionAssessment.Acceptable}
            tooltipText={t("withoutRemarkLong")}
          >
            {t("withoutRemarkShort")}
          </ButtonSwitchOption>
        )}
        <ButtonSwitchOption
          value={InspectionAssessment.NotAcceptable}
          tooltipText={t("remarkLong")}
        >
          {t("remarkShort")}
        </ButtonSwitchOption>
        {!isMultipleAssessed && (
          <ButtonSwitchOption
            value={InspectionAssessment.NotAssessed}
            tooltipText={t("notAssessedLong")}
          >
            {t("notAssessed")}
          </ButtonSwitchOption>
        )}
      </ButtonSwitch>
      {isMultipleAssessed && (
        <Button
          kind="danger"
          icon={faTrashCan}
          onClick={() => onRemove(assessmentId)}
          style={{marginLeft: "5px", marginTop: "-4px"}}
        >
          {t("remove")}
        </Button>
      )}
    </>
  );
}
