import {FileDrop} from "@sokigo-sbwebb/default-components";
import {useCallback, useState} from "react";
import {v4 as uuid} from "uuid";
import {useServerState} from "../../../ServerStateProvider";
import {getBase64String} from "../../../services/getBase64String";
import {EditCommonDocumentsModal} from "./EditCommonDocumentsModal";
import CommonDocumentsTable from "./CommonDocumentsTable";
import {HelpLink} from "@sokigo-sbwebb/default-components";
import {Panel} from "@sokigo/components-react-bootstrap";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";

function CommonDocumentsDrop() {
  const [documents, setDocuments] = useState(null);

  const handleFiles = useCallback(async function handleFiles(files) {
    setDocuments(() => files.map((file) => ({id: uuid(), file, title: ""})));
  }, []);

  const {addCommonDocuments} = useServerState();
  async function onSave(savingFiles) {
    const filesWithData = await Promise.all(
      savingFiles.map(
        (f) =>
          new Promise((resolve) => {
            const rdr = new FileReader();
            rdr.onload = (evt) => {
              resolve({
                id: f.id,
                title: f.title,
                fileName: f.file.name,
                fileData: getBase64String(evt.target.result),
                documentCategories: f.documentCategories,
              });
            };
            rdr.readAsDataURL(f.file);
          })
      )
    );
    await addCommonDocuments(
      filesWithData.map((item) => ({id: item.id, item}))
    );
    setDocuments(null);
  }

  return (
    <div className="row mt-2">
      <div className="col">
        <FileDrop
          style={{height: 100, width: "100%"}}
          handleFiles={async (files) => {
            await handleFiles(files);
          }}
        />
        {documents && (
          <EditCommonDocumentsModal
            onClose={() => setDocuments(null)}
            documents={documents}
            onSave={onSave}
            isCreation
          />
        )}
      </div>
    </div>
  );
}

export function AdminDocumentsView() {
  const t = useAppTranslation();
  return (
    <Panel className="mx-3 py-3">
      <div className="d-flex flex-row justify-content-between align-items-center mb-1 px-3">
        <h4>{t("commonDocuments")}</h4>
        <div>
          <HelpLink section="commonDocuments" />
        </div>
      </div>
      <CommonDocumentsTable />
      <CommonDocumentsDrop />
    </Panel>
  );
}
