import {faInfoCircle} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {TextArea, Panel, Tooltip} from "@sokigo/components-react-bootstrap";
import {useLayoutEffect, useRef, useState} from "react";
import {useScratchPad} from "./useScratchPad";

export function ScratchPadPanel() {
  const t = useAppTranslation();
  const {scratchPadText, onChangeScratchPadText} = useScratchPad();
  const headerRef = useRef();
  const [headerSize, setHeaderSize] = useState(null);
  useLayoutEffect(() => {
    const h = headerRef.current?.offsetHeight;
    setHeaderSize(h);
  }, [setHeaderSize]);

  return (
    <Panel
      className="h-100 p-3 position-relative rounded-top-left rounded-bottom-left"
      style={{width: 350, boxShadow: "0 3px 7px rgb(0, 0, 0, 0.2)"}}
      square
    >
      <div ref={headerRef} className="pb-3 d-flex justify-content-between">
        <h4>{t("myScratchPad")}</h4>
        <Tooltip side="left" text={t("clearedAfterVisitIsFinished")}>
          <span className="align-self-start" tabIndex="0">
            <FontAwesomeIcon icon={faInfoCircle} />
          </span>
        </Tooltip>
      </div>
      <TextArea
        value={scratchPadText}
        onChange={(text) => onChangeScratchPadText(text)}
        style={{height: `calc(100% - ${headerSize}px)`}}
      />
    </Panel>
  );
}
