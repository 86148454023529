import {faTimes} from "@fortawesome/pro-regular-svg-icons";
import {Button} from "@sokigo/components-react-bootstrap";
import {useLayoutEffect, useReducer} from "react";
import {CommonDocumentsPanel} from "./CommonDocumentsPanel";
import styles from "./FooterPanel.module.scss";
import {ScratchPadPanel} from "./ScratchPadPanel";

export const FooterPanelChoice = {
  None: 0,
  CommonDocuments: 1,
  ScratchPad: 2,
};

function SlidingPanel({children, onTransitionEnd, animationState}) {
  return (
    <div
      className={[
        styles.footerPanel,
        animationState === AnimationState.FullyClosed && styles.closed,
        animationState === AnimationState.Opening && styles.opening,
        animationState === AnimationState.FullyOpen && styles.open,
        animationState === AnimationState.Closing && styles.closing,
      ]
        .filter((x) => x)
        .join(" ")}
      onTransitionEnd={(evt) => {
        if (evt.currentTarget === evt.target) {
          onTransitionEnd();
        }
      }}
    >
      {children}
    </div>
  );
}

const AnimationState = {
  FullyClosed: 0,
  Opening: 1,
  FullyOpen: 2,
  Closing: 3,
};
function reducer(prevState, {type, payload}) {
  switch (type) {
    case "transitionEnd": {
      return {
        ...prevState,
        animationState:
          prevState.animationState === AnimationState.Opening
            ? AnimationState.FullyOpen // stay opened
            : prevState.intendedPanel === FooterPanelChoice.None
            ? AnimationState.FullyClosed // intentionally closed => stay closed
            : AnimationState.Opening, // any other => re-open with intended content
        currentPanel: prevState.intendedPanel, //always safe to switch,
      };
    }
    case "setIntendedPanel": {
      const intendedPanel = payload;
      if (prevState.currentPanel === intendedPanel) {
        // already showing, keep on keeping on
        return {
          ...prevState,
          animationState:
            prevState.animationState === AnimationState.FullyOpen
              ? AnimationState.FullyOpen
              : AnimationState.Opening,
          intendedPanel,
        };
      }
      if (prevState.animationState === AnimationState.FullyClosed) {
        // fresh start
        return {
          animationState: AnimationState.Opening,
          intendedPanel,
          currentPanel: intendedPanel,
        };
      }
      return {
        ...prevState,
        intendedPanel,
        animationState: AnimationState.Closing,
      };
    }
  }
  return prevState;
}
function initializer(initialPanel) {
  return {
    currentPanel: initialPanel,
    intendedPanel: initialPanel,
    animationState:
      initialPanel !== FooterPanelChoice.None
        ? AnimationState.FullyOpen
        : AnimationState.FullyClosed,
  };
}
export function FooterPanel({panel, onClose}) {
  const [{intendedPanel, currentPanel, animationState}, dispatch] = useReducer(
    reducer,
    panel,
    initializer
  );
  useLayoutEffect(() => {
    if (panel !== intendedPanel) {
      dispatch({type: "setIntendedPanel", payload: panel});
    }
  }, [panel, intendedPanel]);
  return (
    <SlidingPanel
      onClose={onClose}
      onTransitionEnd={() => dispatch({type: "transitionEnd"})}
      animationState={animationState}
    >
      {currentPanel === FooterPanelChoice.CommonDocuments && (
        <CommonDocumentsPanel />
      )}
      {currentPanel === FooterPanelChoice.ScratchPad && <ScratchPadPanel />}
    </SlidingPanel>
  );
}

export function FooterPanelButton({
  title,
  icon,
  value,
  onSelect,
  selectedPanel,
}) {
  return (
    <Button
      kind={selectedPanel === value ? "selected" : "secondary"}
      round
      className="ml-2"
      icon={selectedPanel === value ? faTimes : icon}
      onClick={() =>
        onSelect((x) => (x === value ? FooterPanelChoice.None : value))
      }
      tooltipText={title}
    />
  );
}
