import {useApplicationPermissions} from "@sokigo-sbwebb/application-services";
import {AccessDenied} from "@sokigo-sbwebb/default-components";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {Tab, Tabs} from "@sokigo/components-react-bootstrap";
import {generatePath, Route, useHistory} from "react-router-dom";
import {AdminControlAreasMainView} from "./AdminControlAreas/AdminControlAreasMainView";
import {AdminDocumentsView} from "./AdminDocuments/AdminDocumentsView";
import {VisitFormsRoute} from "./AdminVisitForms/VisitFormsRoute";
import {AdminPhrasesView} from "./AdminPhrases/AdminPhrasesView";

export default function InspectionAdminMainRoutes({
  match: {
    url,
    path,
    params: {section = "visitForms", ...routeParams},
  },
}) {
  const t = useAppTranslation();
  const {hasPermission} = useApplicationPermissions();
  const canAccessAdminPage = hasPermission("admin");
  const {push} = useHistory();

  if (!canAccessAdminPage) {
    return <AccessDenied />;
  }

  return (
    <div className="d-flex flex-column overflow-auto" style={{maxWidth: 1440}}>
      <div className="flex-grow-0 flex-shrink-0 m-3">
        <Tabs
          selected={section}
          justified
          rounded
          tabsSquare
          onSelect={(newSection) =>
            section !== newSection &&
            push(
              generatePath(path, {
                ...routeParams,
                section: newSection,
              })
            )
          }
          className="bg-normal"
        >
          <Tab id="visitForms" headerText={t("visitForms")}></Tab>
          <Tab id="phrases" headerText={t("phrases")}></Tab>
          <Tab id="controlAreas" headerText={t("controlAreas")}></Tab>
          <Tab id="commonDocuments" headerText={t("commonDocuments")}></Tab>
        </Tabs>
      </div>
      <div className="flex-grow-0 overflow-auto">
        {section === "visitForms" && <Route component={VisitFormsRoute} />}
        {section === "commonDocuments" && (
          <Route component={AdminDocumentsView} />
        )}
        {section === "controlAreas" && (
          <Route
            path={url + "/:controlAreaSection?"}
            component={AdminControlAreasMainView}
          />
        )}
        {section === "phrases" && <Route component={AdminPhrasesView} />}
      </div>
    </div>
  );
}
