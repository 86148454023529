import {faPlus} from "@fortawesome/pro-regular-svg-icons";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {Button} from "@sokigo/components-react-bootstrap";
import {useState} from "react";
import {
  useCurrentVisitInput,
  useMergeCurrentVisitInput,
} from "../InspectionVisitRoute";
import {EditCustomPresentPartyModal} from "./EditCustomPresentPartyModal";

export function AddCustomPresentPartyButton() {
  const t = useAppTranslation();
  const [showing, setShowing] = useState(false);

  const {presentParties, finishVisitInfo} = useCurrentVisitInput();
  const merge = useMergeCurrentVisitInput();
  function onSave(addedParty) {
    merge({
      presentParties: [...(presentParties ?? []), addedParty],
      finishVisitInfo: {
        ...finishVisitInfo,
        emailInfo: {
          ...finishVisitInfo.emailInfo,
          // add to selectedPresentPartyIds if email address specified
          sendToPresentPartyIds:
            addedParty.emailAddressForDispatch ||
            addedParty.contactDetailIdForDispatch
              ? [
                  ...(finishVisitInfo.emailInfo.sendToPresentPartyIds ?? []),
                  addedParty.id,
                ]
              : finishVisitInfo.emailInfo.sendToPresentPartyIds,
        },
      },
    });
    setShowing(false);
  }

  return (
    <>
      <Button kind="ghost" onClick={() => setShowing(true)} icon={faPlus}>
        {t("addNewPresentParty")}
      </Button>
      {showing && (
        <EditCustomPresentPartyModal
          onSave={onSave}
          onClose={() => setShowing(false)}
          title={t("addNewPresentParty")}
        />
      )}
    </>
  );
}
