import {faInfoCircle} from "@fortawesome/pro-regular-svg-icons";
import {Icon} from "@sokigo/components-react-bootstrap";
import styles from "./VisitInformationHeader24.module.scss";
import {Fragment} from "react";

function Columns({array}) {
  return array
    .filter((x) => x.value)
    .map((object) => {
      return (
        <Fragment key={object.title}>
          <div className={styles.flexItemHeader}>{object.title}:</div>
          <div className={styles.flexItem}>
            {object.value}
            {object.tooltipValue && (
              <Icon
                icon={faInfoCircle}
                tooltipText={
                  <div
                    style={{
                      maxWidth: "400px",
                      display: "block",
                      whiteSpace: "pre-wrap",
                      paddingLeft: "0.3em",
                    }}
                  >
                    {object.tooltipValue}
                  </div>
                }
                style={{marginLeft: "1em"}}
              />
            )}
          </div>
        </Fragment>
      );
    });
}

export const InfoRowToolTip = ({array}) => {
  return (
    <div className={styles.flexContainer}>
      <Columns array={array} />
    </div>
  );
};
