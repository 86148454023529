import {
  useCurrentVisit,
  useCurrentVisitInput,
  useMergeCurrentVisitInput,
} from "../InspectionVisitRoute";
import {InspectionAssessment} from "../../Common/InspectionAssessment";

export function useSaveSelectedFoodControlQuestions() {
  const {
    foodControlQuestionsByFoodControlAreaId,
    foodControlSupportQuestionsByFoodControlQuestionId,
    foodControlQuestionNoteByQuestionId,
    foodControlQuestionAssessmentByQuestionId,
    foodControlSupportQuestionNoteBySupportQuestionId,
    foodControlSupportQuestionAssessmentBySupportQuestionId,
  } = useCurrentVisitInput();
  const {
    visit: {foodInspectionSupportQuestionsSnapshot},
  } = useCurrentVisit();

  const merge = useMergeCurrentVisitInput();
  return async function (selected) {
    const newFoodControlSupportQuestionsByFoodControlQuestionId = {
      ...(foodControlSupportQuestionsByFoodControlQuestionId ?? {}),
    };
    const newFoodControlQuestionNoteByQuestionId = {
      ...(foodControlQuestionNoteByQuestionId ?? {}),
    };
    let newFoodControlQuestionAssessmentByQuestionId = {
      ...(foodControlQuestionAssessmentByQuestionId ?? {}),
    };
    const newFoodControlSupportQuestionNoteBySupportQuestionId = {
      ...(foodControlSupportQuestionNoteBySupportQuestionId ?? {}),
    };
    const newFoodControlSupportQuestionAssessmentBySupportQuestionId = {
      ...(foodControlSupportQuestionAssessmentBySupportQuestionId ?? {}),
    };

    // Add support questions from library for added questions
    const selectedControlAreaIds = Object.keys(selected);

    for (const controlAreaId of selectedControlAreaIds) {
      const previousQuestionsForGroup =
        foodControlQuestionsByFoodControlAreaId?.[controlAreaId] ?? [];
      for (const questionId of selected[controlAreaId]) {
        if (!previousQuestionsForGroup.includes(questionId)) {
          newFoodControlQuestionAssessmentByQuestionId = {
            ...newFoodControlQuestionAssessmentByQuestionId,
            [questionId]: InspectionAssessment.NotAssessed,
          };
          newFoodControlSupportQuestionsByFoodControlQuestionId[questionId] =
            foodInspectionSupportQuestionsSnapshot.find(
              (x) => x.foodInspectionControlAreaId === controlAreaId
            )?.supportQuestionsByFoodInspectionControlAreaQuestionId[
              questionId
            ] ?? [];
        }
      }
    }

    // Delete data related to removed questions
    const previousControlAreaIds = Object.keys(
      foodControlQuestionsByFoodControlAreaId ?? {}
    );
    for (const controlAreaId of previousControlAreaIds) {
      for (const questionId of foodControlQuestionsByFoodControlAreaId[
        controlAreaId
      ]) {
        if (!selected[controlAreaId]?.includes(questionId)) {
          // question has been removed - remove assessment, note and all support questions
          delete newFoodControlQuestionNoteByQuestionId[questionId];
          delete newFoodControlQuestionAssessmentByQuestionId[questionId];

          // delete support question responses
          if (foodControlSupportQuestionsByFoodControlQuestionId[questionId]) {
            for (const sq of foodControlSupportQuestionsByFoodControlQuestionId[
              questionId
            ]) {
              delete newFoodControlSupportQuestionNoteBySupportQuestionId[
                sq.id
              ];
              delete newFoodControlSupportQuestionAssessmentBySupportQuestionId[
                sq.id
              ];
            }
          }

          // delete the support questions
          delete newFoodControlSupportQuestionsByFoodControlQuestionId[
            questionId
          ];
        }
      }
    }

    // Remove area from selection when the last question from the area has been removed
    const newFoodControlQuestionsByFoodControlAreaId = {...selected};
    for (const areaId of Object.keys(
      newFoodControlQuestionsByFoodControlAreaId
    )) {
      if (!(newFoodControlQuestionsByFoodControlAreaId[areaId].length > 0)) {
        delete newFoodControlQuestionsByFoodControlAreaId[areaId];
      }
    }

    merge({
      foodControlQuestionsByFoodControlAreaId:
        newFoodControlQuestionsByFoodControlAreaId,
      foodControlSupportQuestionsByFoodControlQuestionId:
        newFoodControlSupportQuestionsByFoodControlQuestionId,
      foodControlQuestionNoteByQuestionId:
        newFoodControlQuestionNoteByQuestionId,
      foodControlQuestionAssessmentByQuestionId:
        newFoodControlQuestionAssessmentByQuestionId,
      foodControlSupportQuestionNoteBySupportQuestionId:
        newFoodControlSupportQuestionNoteBySupportQuestionId,
      foodControlSupportQuestionAssessmentBySupportQuestionId:
        newFoodControlSupportQuestionAssessmentBySupportQuestionId,
    });
  };
}
