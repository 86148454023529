import {Button} from "@sokigo/components-react-bootstrap";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {useState} from "react";
import {v4 as uuid} from "uuid";
import {StaticParagraphsTable} from "./StaticParagraphsTable";
import {faPlus} from "@fortawesome/pro-regular-svg-icons";

export function StaticParagraphsSection({
  onChange,
  staticParagraphs = [],
  validationErrors,
}) {
  const t = useAppTranslation();

  const [expandedParagraphId, setExpandedParagraphId] = useState(null);

  const addStaticParagraph = () => {
    const newStaticParagraph = {
      id: uuid(),
      title: t("newStaticParagraph"),
      phrases: [],
    };
    onChange([...(staticParagraphs || []), newStaticParagraph]);
    setExpandedParagraphId(newStaticParagraph.id);
  };

  return (
    <>
      <div className="d-flex flex-column flex-sm-row justify-content-between align-items-md-center my-2">
        <h4>{t("staticParagraphs")}</h4>
        <Button icon={faPlus} onClick={addStaticParagraph} kind="primary">
          {t("addStaticParagraph")}
        </Button>
      </div>
      <StaticParagraphsTable
        onChange={(staticParagraphs) => onChange([...staticParagraphs])}
        staticParagraphs={staticParagraphs || []}
        validationErrors={validationErrors}
        expandedParagraphId={expandedParagraphId}
        onExpand={setExpandedParagraphId}
      />
    </>
  );
}
