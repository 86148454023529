import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {
  Table,
  TableBody,
  TableCell,
  TableExpandedRow,
  TableExpandRowCell,
  TableHead,
  TableHeader,
  TableHeaderRow,
  TableRow,
} from "@sokigo/components-react-bootstrap";
import moment from "moment";
import {Fragment} from "react";
import {FillTableCellWithTruncatedText} from "../../../../../../common-components/FillTableCellWithTruncatedText";
import {FoodInspectionInformation} from "./FoodInspectionInformation";
import {GenericInspectionInformation} from "./GenericInspectionInformation";
import {VisitOccurrenceInformation} from "./VisitOccurrenceInformation";

export function HistoricVisitsTable({historicVisits}) {
  const t = useAppTranslation();

  const getVisitTitle = (visit) => {
    if (visit.genericInspectionResult) {
      return t("genericInspection");
    } else if (visit.foodInspectionResult) {
      return t("foodInspection");
    } else {
      return t("visitOccurrence");
    }
  };

  const columns = [
    {
      key: "date",
      renderCell: (r) => moment(r.row.date).format("YYYY-MM-DD"),
      header: t("date"),
    },
    {
      key: "title",
      renderCell: (r) => getVisitTitle(r.row),
      header: t("title"),
    },
    {
      key: "performedBy",
      field: "performedBy",
      header: t("performedBy"),
    },
    {
      key: "note",
      renderCell: (r) => (
        <FillTableCellWithTruncatedText>
          {r.row.note}
        </FillTableCellWithTruncatedText>
      ),
      header: t("note"),
      fill: true,
    },
  ];

  return (
    <Table rows={historicVisits} columns={columns} getRowKey={(x) => x.rowId}>
      <TableHead>
        <TableHeaderRow>
          {(columns) => (
            <>
              <TableHeader />
              {columns.map((x) => (
                <TableHeader
                  key={x.key}
                  column={x}
                  className={x.fill ? "w-50" : undefined}
                >
                  {x.header}
                </TableHeader>
              ))}
            </>
          )}
        </TableHeaderRow>
      </TableHead>
      <TableBody>
        {(rows) =>
          rows.map((r) => (
            <Fragment key={r.key}>
              <TableRow row={r}>
                <TableExpandRowCell single={true} />
                {r.cells.map((c) => (
                  <TableCell key={c.key}>{c.value}</TableCell>
                ))}
              </TableRow>
              <TableExpandedRow row={r}>
                {r.row.genericInspectionResult ? (
                  <GenericInspectionInformation visit={r.row} />
                ) : r.row.foodInspectionResult ? (
                  <FoodInspectionInformation visit={r.row} />
                ) : (
                  <VisitOccurrenceInformation visit={r.row} />
                )}
              </TableExpandedRow>
            </Fragment>
          ))
        }
      </TableBody>
    </Table>
  );
}
