import {useMemo} from "react";
import {useCurrentVisit, useCurrentVisitInput} from "../InspectionVisitRoute";

export function useAvailableFoodControlAreas() {
  const {
    visit: {
      baseDataSnapshot: {foodInspectionControlAreas},
    },
  } = useCurrentVisit();
  const {
    foodControlQuestionsByFoodControlAreaId,
    foodControlSupportQuestionsByFoodControlQuestionId,
  } = useCurrentVisitInput();

  return useMemo(
    () =>
      foodInspectionControlAreas
        .filter(
          (x) => foodControlQuestionsByFoodControlAreaId?.[x.id]?.length > 0
        )
        .map((x) => ({
          ...x,
          questions: x.questions
            .filter((q) =>
              foodControlQuestionsByFoodControlAreaId?.[x.id].includes(q.id)
            )
            .map((q) => ({
              ...q,
              supportQuestions:
                foodControlSupportQuestionsByFoodControlQuestionId?.[q.id] ??
                [],
            })),
        })),
    [
      foodInspectionControlAreas,
      foodControlQuestionsByFoodControlAreaId,
      foodControlSupportQuestionsByFoodControlQuestionId,
    ]
  );
}
