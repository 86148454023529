import {faTrashAlt} from "@fortawesome/pro-regular-svg-icons";
import {useConfirmDialog} from "@sokigo-sbwebb/default-components";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {
  Button,
  Panel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableHeaderRow,
  TableRow,
  TableRowActionsCell,
} from "@sokigo/components-react-bootstrap";
import {InspectionAssessment} from "../../../../Common/InspectionAssessment";
import {useAvailableFoodControlAreas} from "../../../foodInspectionControl/useAvailableFoodControlAreas";
import {useCurrentVisit} from "../../../InspectionVisitRoute";
import {useCompletingInformationProvider} from "../CompletingInformationTab";
import {ActionsModalButton} from "./ActionsModalButton";

export function Actions() {
  const t = useAppTranslation();
  const {
    visit: {
      baseDataSnapshot: {availableFoodInspectionActions},
      input: {foodControlQuestionAssessmentByQuestionId},
    },
  } = useCurrentVisit();

  const [ConfirmModal, confirmDelete] = useConfirmDialog();

  const {onChange, value, penaltyTypes} = useCompletingInformationProvider();

  const availableAreas = useAvailableFoodControlAreas();

  const isAnyAssmentNotOk = foodControlQuestionAssessmentByQuestionId
    ? Object.keys(foodControlQuestionAssessmentByQuestionId).some(
        (key) =>
          foodControlQuestionAssessmentByQuestionId[key] ===
          InspectionAssessment.NotAcceptable
      )
    : false;

  //Actions section should be visible only for food visit form,
  //if there are any control points with assessment NotAcceptable
  if (!isAnyAssmentNotOk) {
    return null;
  }

  const removeAction = async (actionId) => {
    const confirmed = await confirmDelete();
    if (!confirmed) return;
    onChange({
      ...value,
      foodInspectionActionsModel: value.foodInspectionActionsModel.filter(
        (x) => actionId !== x.id
      ),
    });
  };

  return (
    <>
      <Panel className="mt-3 py-3">
        <div className="d-flex justify-content-between">
          <h4 className="px-3 mb-3">{t("actions")}</h4>
          <ActionsModalButton onChange={onChange} value={value} />
        </div>
        {value?.foodInspectionActionsModel?.length > 0 ? (
          <>
            <Table
              rows={value.foodInspectionActionsModel ?? []}
              columns={[
                {
                  key: "article",
                  field: "action",
                  renderCell: ({row}) =>
                    availableFoodInspectionActions.find(
                      (x) => x.id === row.actionId
                    )?.name,
                  header: t("article"),
                },
                {
                  key: "controlPoints",
                  field: "controlPoints",
                  renderCell: ({row}) =>
                    row.questionIds
                      ?.map(
                        (questionId) =>
                          availableAreas
                            .map((x) => x.questions)
                            .flat()
                            .find(
                              (inspectionItem) =>
                                inspectionItem.id === questionId
                            )?.number
                      )
                      .toString(),
                  header: t("controlPoints"),
                },
                {
                  key: "penalty",
                  renderCell: ({row}) =>
                    penaltyTypes.find((x) => x.id === row.penaltyType)?.title,
                  header: t("penalty"),
                },
                {
                  key: "amount",
                  field: "penaltyAmount",
                  header: t("amount"),
                },
                {
                  key: "decisionDate",
                  field: "decisionDate",
                  header: t("decisionDate"),
                },
              ]}
              getRowKey={(x) => x.id}
            >
              <TableHead>
                <TableHeaderRow>
                  {(columns) => (
                    <>
                      {columns.map((x) => (
                        <TableHeader key={x.key} column={x}>
                          {x.header}
                        </TableHeader>
                      ))}
                      <TableHeader />
                    </>
                  )}
                </TableHeaderRow>
              </TableHead>
              <TableBody>
                {(rows) =>
                  rows.map((r) => (
                    <TableRow key={r.key} row={r}>
                      {r.cells.map((c) => (
                        <TableCell key={c.key}>{c.value}</TableCell>
                      ))}
                      <TableRowActionsCell>
                        <ActionsModalButton
                          onChange={onChange}
                          value={value}
                          selectedId={r.row.id}
                        />
                        <Button
                          size="sm"
                          kind="danger-hover-ghost"
                          icon={faTrashAlt}
                          onClick={() => removeAction(r.row.id)}
                          tooltipText={t("remove")}
                        />
                      </TableRowActionsCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
            <ConfirmModal />
          </>
        ) : (
          <div className="px-3">{t("noActionsAdded")}</div>
        )}
      </Panel>
    </>
  );
}
