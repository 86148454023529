import {
  TableBody,
  TableCell,
  TableExpandedRow,
  TableExpandRowCell,
  TableRow,
  TableRowActionsCell,
} from "@sokigo/components-react-bootstrap";
import {useState} from "react";
import {AttachmentActionsDropdown} from "./AttachmentActionsDropdown";
import {AttachmentExpandedContent} from "./AttachmentExpandedContent";

function AttachmentRow({r, expandRow}) {
  const [isEditingPosition, setEditingPosition] = useState(false);
  return (
    <>
      <TableRow row={r}>
        <TableExpandRowCell />
        {r.cells.map((cell) => (
          <TableCell key={cell.key}>{cell.value}</TableCell>
        ))}
        <TableRowActionsCell>
          <AttachmentActionsDropdown
            row={r}
            onStartEditingPosition={() => {
              setEditingPosition(true);
              expandRow(r.key);
            }}
          />
        </TableRowActionsCell>
      </TableRow>
      <TableExpandedRow row={r} colSpan={r.cells.length + 2}>
        <AttachmentExpandedContent
          attachment={r.row}
          isEditingPosition={isEditingPosition}
          setEditingPosition={setEditingPosition}
        />
      </TableExpandedRow>
    </>
  );
}

export function AttachmentsTableBody({expandRow}) {
  return (
    <TableBody>
      {(rows) =>
        rows.map((r) => (
          <AttachmentRow expandRow={expandRow} r={r} key={r.key} />
        ))
      }
    </TableBody>
  );
}
