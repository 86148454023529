import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@sokigo/components-react-bootstrap";
import {faCheck} from "@fortawesome/pro-regular-svg-icons";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";

export function ShowVisitIdModal({visitId, onClose}) {
  const t = useAppTranslation();
  return (
    <Modal show={!!visitId} onClose={onClose}>
      <ModalHeader>{t("showVisitId")}</ModalHeader>
      <ModalBody>{visitId}</ModalBody>
      <ModalFooter>
        <Button onClick={onClose} icon={faCheck} kind="primary">
          {t("ok")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
