import {
  faEdit,
  faEllipsisV,
  faTrashAlt,
} from "@fortawesome/pro-regular-svg-icons";
import {useConfirmDialog} from "@sokigo-sbwebb/default-components";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {
  ActionsDropdown,
  ActionsDropdownDivider,
  ActionsDropdownItem,
  Control,
  Select,
  TableCell,
  TableExpandedRow,
  TableExpandRowCell,
  TableRow,
  TableRowActionsCell,
  TextArea,
} from "@sokigo/components-react-bootstrap";
import {useLayoutEffect, useRef, useState} from "react";
import {EditSupportQuestionModal} from "../../Common/EditSupportQuestionModal";
import {AssessmentSwitch} from "../AssessmentSwitch";
import {
  useCurrentVisitInput,
  useMergeCurrentVisitInput,
} from "../InspectionVisitRoute";
import {useAvailableNonFoodControlAreas} from "./useAvailableNonFoodControlAreas";

export default function ControlAreaPointForm({
  controlAreaPoint,
  row,
  colSpan,
  controlArea,
}) {
  const t = useAppTranslation();
  const [controlPointModalVisible, setControlPointModalVisible] = useState();
  const {
    nonFoodControlAreaPointAssessmentById,
    nonFoodControlAreaPointNoteById,
  } = useCurrentVisitInput();
  const merge = useMergeCurrentVisitInput();
  const note = nonFoodControlAreaPointNoteById?.[controlAreaPoint.id];
  const assessment =
    nonFoodControlAreaPointAssessmentById?.[controlAreaPoint.id];
  const {updateControlPoint, removeControlPoint} =
    useAvailableNonFoodControlAreas();
  const [ConfirmModal, confirmDelete] = useConfirmDialog();

  function updateControlAreaPoint(updatedControlAreaPoint) {
    updateControlPoint(controlArea, updatedControlAreaPoint);
    setControlPointModalVisible(false);
  }

  async function removeControlAreaPoint() {
    const r = await confirmDelete({title: t("confirmControlPointDelete")});
    if (r) {
      removeControlPoint(controlArea.id, controlAreaPoint.id);
    }
  }

  const noteRef = useRef();
  useLayoutEffect(() => {
    noteRef.current.scrollTop = noteRef.current.scrollHeight;
  }, [note]);

  function setControlAreaPointNote(note) {
    merge({
      nonFoodControlAreaPointNoteById: {
        ...(nonFoodControlAreaPointNoteById ?? {}),
        [controlAreaPoint.id]: note,
      },
    });
  }

  function setControlAreaPointAssessment(assessment) {
    merge({
      nonFoodControlAreaPointAssessmentById: {
        ...(nonFoodControlAreaPointAssessmentById ?? {}),
        [controlAreaPoint.id]: assessment,
      },
    });
  }

  return (
    <>
      <TableRow key={row.key} row={row}>
        <TableExpandRowCell single />
        {row.cells.map((c) => (
          <TableCell key={c.key}>{c.value}</TableCell>
        ))}
        <TableRowActionsCell>
          <div className="d-flex align-items-center">
            <AssessmentSwitch
              value={assessment}
              onChange={(assessment) =>
                setControlAreaPointAssessment(assessment)
              }
            />
            <ActionsDropdown
              icon={faEllipsisV}
              kind="ghost"
              className="ml-2"
              menuSize="sm"
            >
              <ActionsDropdownItem
                itemText={t("edit")}
                icon={faEdit}
                onClick={() => setControlPointModalVisible(true)}
              />
              <ActionsDropdownDivider />
              <ActionsDropdownItem
                kind="danger-hover-ghost"
                itemText={t("remove")}
                icon={faTrashAlt}
                onClick={() => removeControlAreaPoint()}
              />
            </ActionsDropdown>
          </div>
        </TableRowActionsCell>
      </TableRow>
      <TableExpandedRow row={row} colSpan={colSpan} className="py-2" indent>
        <div className="row p-3">
          <div className="col-6">
            {controlAreaPoint.phraseGroupOptions &&
              controlAreaPoint.phraseGroupOptions.length > 0 && (
                <Control labelText={t("phrases")} horizontal>
                  <Select
                    onChange={(value) => {
                      setControlAreaPointNote(
                        note ? note + "\n" + value : value
                      );
                    }}
                    options={controlAreaPoint.phraseGroupOptions}
                    placeholder={t("selectPhrase")}
                    getOptionKey={(x) => x.id}
                    getOptionValue={(x) => x.text}
                    getOptionText={(x) => x.code}
                  />
                </Control>
              )}
            <TextArea
              ref={noteRef}
              value={note}
              onChange={(text) => setControlAreaPointNote(text)}
              rows={5}
            />
          </div>
          {controlAreaPoint.helpText && (
            <div
              className="col-md-6 mt-2 mt-md-0"
              style={{whiteSpace: "pre-wrap"}}
            >
              {controlAreaPoint.helpText}
            </div>
          )}
        </div>
      </TableExpandedRow>
      {controlPointModalVisible && (
        <EditSupportQuestionModal
          defaultValue={controlAreaPoint}
          onCancel={() => {
            setControlPointModalVisible(false);
          }}
          onSave={updateControlAreaPoint}
        />
      )}
      <ConfirmModal />
    </>
  );
}
