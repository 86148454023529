import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {Control, Switch, Tooltip} from "@sokigo/components-react-bootstrap";

export function SelectFoodControlAreaQuestions({
  controlArea: {questions},
  selectedQuestions,
  onChange,
  assessedQuestionIds,
}) {
  const t = useAppTranslation();
  return (
    <>
      {questions.map((x) => (
        <Control
          horizontal
          labelText={
            <Tooltip text={x.title}>
              <span>
                {x.number}. {x.title}
              </span>
            </Tooltip>
          }
          labelWidths={{col: 9}}
          key={x.id}
          warningText={t("theControlQuestionHasBeenAssessed")}
        >
          <Switch
            className="float-right"
            id={x.id}
            checked={selectedQuestions.includes(x.id)}
            onChange={(selected) =>
              onChange(
                selected
                  ? [...selectedQuestions, x.id]
                  : selectedQuestions.filter((s) => s !== x.id)
              )
            }
            warning={
              !selectedQuestions.includes(x.id) &&
              assessedQuestionIds.includes(x.id)
            }
          />
        </Control>
      ))}
    </>
  );
}
