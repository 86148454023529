import {
  faCompass,
  faCompassSlash,
  faEllipsisV,
  faMapMarker,
  faMapMarkerSlash,
  faTrashAlt,
} from "@fortawesome/pro-regular-svg-icons";
import {useConfirmDialog} from "@sokigo-sbwebb/default-components";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {
  ActionsDropdown,
  ActionsDropdownDivider,
  ActionsDropdownItem,
} from "@sokigo/components-react-bootstrap";
import {useCallback} from "react";
import {useAttachments} from "./AttachmentsProvider";
import {useIsOnline} from "@sokigo-sbwebb/serviceworker";

export function AttachmentActionsDropdown({row, onStartEditingPosition}) {
  const t = useAppTranslation();
  const isOnline = useIsOnline();
  const [DeleteConfirmModal, confirmDelete] = useConfirmDialog();
  const {deleteAttachment, changeAttachment} = useAttachments();

  const removeAttachment = async (row) => {
    const answer = await confirmDelete();
    if (answer) {
      deleteAttachment(row);
    }
  };

  const removeHeading = useCallback(
    function removeHeading(attachment) {
      changeAttachment({...attachment, heading: null});
    },
    [changeAttachment]
  );

  return (
    <>
      <ActionsDropdown
        icon={faEllipsisV}
        kind="ghost"
        size="sm"
        onClick={(e) => e.stopPropagation()}
      >
        <ActionsDropdownItem
          onClick={() => onStartEditingPosition()}
          itemText={t("specifyPosition")}
          icon={faMapMarker}
          disabled={!isOnline}
        />
        <ActionsDropdownItem
          onClick={() =>
            changeAttachment({...row.row, position: null, heading: null})
          }
          itemText={t("removePosition")}
          icon={faMapMarkerSlash}
          danger
          disabled={!row.row?.position}
        />
        <ActionsDropdownDivider />
        <ActionsDropdownItem
          onClick={() => changeAttachment({...row.row, heading: 0})}
          itemText={t("setHeading")}
          icon={faCompass}
          hidden={!row.row.position || typeof row.row.heading === "number"}
          disabled={!isOnline}
        />
        <ActionsDropdownItem
          onClick={() => removeHeading(row.row)}
          itemText={t("removeHeading")}
          icon={faCompassSlash}
          hidden={!row.row.position || typeof row.row.heading !== "number"}
          danger
        />
        <ActionsDropdownDivider hidden={!row.row.position} />
        <ActionsDropdownItem
          onClick={() => removeAttachment(row.row)}
          itemText={t("deleteAttachment")}
          icon={faTrashAlt}
          danger
        />
      </ActionsDropdown>
      <DeleteConfirmModal />
    </>
  );
}
