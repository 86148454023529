import {useState} from "react";
import {
  useCurrentVisitInput,
  useMergeCurrentVisitInput,
} from "../InspectionVisitRoute";
import {EditSupportQuestionModal} from "../../Common/EditSupportQuestionModal";

export function useCreateSupportQuestion(foodControlQuestionId) {
  const {foodControlSupportQuestionsByFoodControlQuestionId} =
    useCurrentVisitInput();
  const merge = useMergeCurrentVisitInput();
  const [show, setShow] = useState(false);

  function save(createdQuestion) {
    let nv;
    if (foodControlSupportQuestionsByFoodControlQuestionId) {
      let existingSupportQuestions = [];
      if (
        foodControlQuestionId in
        foodControlSupportQuestionsByFoodControlQuestionId
      ) {
        existingSupportQuestions =
          foodControlSupportQuestionsByFoodControlQuestionId[
            foodControlQuestionId
          ];
      }
      nv = {
        foodControlSupportQuestionsByFoodControlQuestionId: {
          ...foodControlSupportQuestionsByFoodControlQuestionId,
          [foodControlQuestionId]: [
            ...existingSupportQuestions,
            createdQuestion,
          ],
        },
      };
    } else {
      nv = {
        foodControlSupportQuestionsByFoodControlQuestionId: {
          [foodControlQuestionId]: [createdQuestion],
        },
      };
    }
    merge(nv);
    setShow(false);
  }

  const ui = show ? (
    <EditSupportQuestionModal
      defaultValue={null}
      onCancel={() => setShow(false)}
      onSave={save}
    />
  ) : null;

  return [() => setShow(true), ui];
}
