export function FillTableCellWithTruncatedText({children}) {
  return (
    <div className="position-relative">
      &nbsp;
      <div
        style={{
          inset: 0,
        }}
        className="text-truncate position-absolute"
      >
        {children}
      </div>
    </div>
  );
}
