import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {Button} from "@sokigo/components-react-bootstrap";
import {Fragment} from "react";
import {HamburgerMenu} from "../../../../../common-components/HamburgerMenu";
import {useCurrentVisit} from "../InspectionVisitRoute";
import {SelectFoodControlAreasButton} from "./SelectFoodControlAreasButton";
import {useAvailableFoodControlAreas} from "./useAvailableFoodControlAreas";
import {useGetAssessmentIcon} from "../shared/useGetAssessmentIcon";

function SelectAreaAndQuestions({onSelect}) {
  const t = useAppTranslation();
  const availableAreas = useAvailableFoodControlAreas();
  const {
    visit: {
      input: {foodControlQuestionAssessmentByQuestionId},
    },
  } = useCurrentVisit();
  const getAssessmentIcon = useGetAssessmentIcon();

  return (
    <>
      {availableAreas.map((x) => (
        <Fragment key={x.id}>
          <div className="d-flex align-items-center justify-content-between mt-2">
            <h5>
              {x.number}: {x.title}
            </h5>
            <Button
              kind="ghost"
              size="sm"
              onClick={() =>
                onSelect({
                  foodControlAreaId: x.id,
                  questionIds: x.questions.map((q) => q.id),
                })
              }
            >
              {t("showAllFoodControlAreaQuestions")}
            </Button>
          </div>
          {x.questions.map((q) => (
            <div className="d-flex" key={q.id}>
              {getAssessmentIcon(
                q.id,
                foodControlQuestionAssessmentByQuestionId
              )}
              <Button
                kind="ghost"
                size="sm"
                className="font-weight-normal"
                onClick={() =>
                  onSelect({
                    foodControlAreaId: x.id,
                    questionIds: [q.id],
                  })
                }
              >
                {q.number}: {q.title}
              </Button>
            </div>
          ))}
        </Fragment>
      ))}
    </>
  );
}

export function FoodControlAreaNavigationMenuButton({onSelect: onSelectProp}) {
  const t = useAppTranslation();
  return (
    <HamburgerMenu tooltipText={t("selectFoodControlAreas")}>
      {(onClose) => (
        <>
          <h4>{t("selectedFoodControlAreas")}</h4>
          <div className="mb-2">
            <SelectAreaAndQuestions
              onSelect={(s) => {
                onSelectProp(s);
                onClose();
              }}
            />
          </div>
          <SelectFoodControlAreasButton />
        </>
      )}
    </HamburgerMenu>
  );
}
