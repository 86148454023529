import {createContext, useContext} from "react";
import {useServerState} from "../ServerStateProvider";

const VisitFormsContext = createContext();

export function VisitFormsProvider({children}) {
  const {visitForms} = useServerState();

  return (
    <VisitFormsContext.Provider value={visitForms || []}>
      {children}
    </VisitFormsContext.Provider>
  );
}

export function useVisitForms() {
  return useContext(VisitFormsContext);
}
