// Important! @sokigo/design-system-bootstrap must come before @sokigo-sbwebb/recommended!
import "@sokigo/design-system-bootstrap/index.css";
// ---------------------------------------------------------------------------------------
import App from "@sokigo-sbwebb/recommended";
import apps from "apps/apps";
import {translations} from "i18n/translations";
import {createRoot} from "react-dom/client";
import {register as serviceWorkerRegister} from "./setupServiceWorker";
import {DndProvider} from "react-dnd";
import {MultiBackend} from "react-dnd-multi-backend";
import {HTML5toTouch} from "rdndmb-html5-to-touch";

const root = createRoot(document.getElementById("app"));
root.render(
  <DndProvider backend={MultiBackend} options={HTML5toTouch}>
    <App
      applications={apps}
      params={{translations, transformTranslateZ: false}}
    />
  </DndProvider>
);

serviceWorkerRegister();
