import {Tooltip} from "@sokigo/components-react-bootstrap";
import {ControlAreaQuestion} from "./ControlAreaQuestion";
import {
  useCurrentVisitInput,
  useSelectedFoodAreaAndQuestions,
} from "../InspectionVisitRoute";
import {FoodControlAreaNavigationMenuButton} from "../foodInspectionControl/FoodControlAreaNavigationMenuButton";
import {useAvailableFoodControlAreas} from "../foodInspectionControl/useAvailableFoodControlAreas";

export function StandardControlForm({causeId}) {
  const {
    foodControlOccasion2024: {controls},
  } = useCurrentVisitInput();

  const availableAreas = useAvailableFoodControlAreas();

  const {
    selectedFoodControlAreaAndQuestions,
    setSelectedFoodControlAreaAndQuestions,
  } = useSelectedFoodAreaAndQuestions();

  let currentArea, currentQuestions;

  // If nothing selected, set default
  if (
    selectedFoodControlAreaAndQuestions.foodControlAreaId === undefined &&
    selectedFoodControlAreaAndQuestions.questionIds.length === 0
  ) {
    currentArea = availableAreas[0];
    currentQuestions = availableAreas[0] ? availableAreas[0].questions : [];
  } else {
    currentArea = availableAreas.find(
      (x) => x.id === selectedFoodControlAreaAndQuestions.foodControlAreaId
    );
    currentQuestions = currentArea?.questions.filter((q) =>
      selectedFoodControlAreaAndQuestions.questionIds.includes(q.id)
    );
  }

  const controlsCurrentCause = controls.filter((c) => c.cause === causeId);

  return (
    <div>
      <div className="d-flex flex-row align-items-center rounded bg-normal my-3">
        {
          <FoodControlAreaNavigationMenuButton
            onSelect={setSelectedFoodControlAreaAndQuestions}
            cause
          />
        }
        {currentArea && (
          <Tooltip text={currentArea.title} side="right">
            <h3 className="ml-2 text-truncate">
              {currentArea.number}: {currentArea.title}
            </h3>
          </Tooltip>
        )}
      </div>
      {/* {!isValid && (
        <Alert kind="warning" inline hideCloseButton contrast>
          {t("operativeGoalsErrorMessage")}
        </Alert>
      )} */}
      {currentQuestions?.map((q) => (
        <ControlAreaQuestion
          area={currentArea}
          key={q.id}
          question={q}
          controlsCurrentCause={controlsCurrentCause}
        />
      ))}
    </div>
  );
}
