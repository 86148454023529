import {BusyModal} from "./BusyModal";
import {BusyFailedModal} from "./BusyFailedModal";
import {BusyState} from "./useDeviceVisits";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";

export function Busy({busyState, onDismiss}) {
  const t = useAppTranslation();

  const fail = {
    [BusyState.CheckingInFailed]: [
      "visitCouldNotBeCheckedInHeader",
      "visitCouldNotBeCheckedInBody",
    ],
    [BusyState.FinishFailed]: [
      "visitCouldNotBeFinishedHeader",
      "visitCouldNotBeFinishedBody",
    ],
    [BusyState.CheckingOutFailed]: [
      "visitCouldNotBeCheckedOutHeader",
      "visitCouldNotBeCheckedOutBody",
    ],
    [BusyState.DeleteFailed]: [
      "visitCouldNotBeDeletedHeader",
      "visitCouldNotBeDeletedBody",
    ],
    [BusyState.RetryAbortedFailed]: [
      "visitCouldNotBeRetriedHeader",
      "visitCouldNotBeRetriedBody",
    ],
    [BusyState.ClearSuccessfullyFinishedFailed]: [
      "successfullyFinishedVisitsCouldNotBeClearedHeader",
      "successfullyFinishedVisitsCouldNotBeClearedBody",
    ],
    [BusyState.DeleteFinishedVisitFailed]: [
      "visitCouldNotBeDeletedHeader",
      "finishedVisitCouldNotBeDeletedBody",
    ],
    [BusyState.ReopeningVisitWithValidationErrorFailed]: [
      "reopeningValidationErrorFailedHeader",
      "reopeningValidationErrorFailedBody",
    ],
  };
  const busy = {
    [BusyState.CheckingIn]: "checkingInVisit",
    [BusyState.CheckingOut]: "checkingOutVisit",
    [BusyState.Finishing]: "finishingVisit",
    [BusyState.Deleting]: "deletingVisit",
    [BusyState.RetryingAborted]: "retryingAbortedVisit",
    [BusyState.ClearingSuccessfullyFinished]: "clearingSuccessfullySavedVisits",
    [BusyState.ReopeningVisitWithValidationError]:
      "reopeningVisitWithValidationError",
  };

  if (fail[busyState]) {
    const [header, body] = fail[busyState];
    return (
      <BusyFailedModal
        header={t(header)}
        body={t(body)}
        onDismiss={onDismiss}
      />
    );
  }
  if (busy[busyState]) {
    return <BusyModal text={t(busy[busyState])} />;
  }
  return null;
}
