import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {Button} from "@sokigo/components-react-bootstrap";
import {Fragment} from "react";
import {HamburgerMenu} from "../../../../../common-components/HamburgerMenu";
import {useCurrentVisitInput} from "../InspectionVisitRoute";
import {SelectControlAreasButton} from "./SelectControlAreasButton";
import {useAvailableNonFoodControlAreas} from "./useAvailableNonFoodControlAreas";
import {useGetAssessmentIcon} from "../shared/useGetAssessmentIcon";

function SelectControlArea({onSelect}) {
  const {controlAreas} = useAvailableNonFoodControlAreas();
  const {nonFoodControlAreaPointAssessmentById} = useCurrentVisitInput();
  const t = useAppTranslation();
  const getAssessmentIcon = useGetAssessmentIcon();

  return (
    <>
      {controlAreas.map((x) => (
        <Fragment key={x.id}>
          <div className="d-flex align-items-center justify-content-between mt-2">
            <h5>{x.title}</h5>
            <Button kind="ghost" size="sm" onClick={() => onSelect(x.id)}>
              {t("showAllFoodControlAreaQuestions")}
            </Button>
          </div>
          {x.controlPoints.map((q) => (
            <div className="d-flex" key={q.id}>
              {getAssessmentIcon(q.id, nonFoodControlAreaPointAssessmentById)}
              <Button
                kind="ghost"
                size="sm"
                className="font-weight-normal"
                onClick={() => onSelect(x.id)}
              >
                {q.title}
              </Button>
            </div>
          ))}
        </Fragment>
      ))}
    </>
  );
}

export function NonFoodControlAreaNavigationMenuButton({
  onSelect: onSelectProp,
}) {
  const t = useAppTranslation();
  return (
    <HamburgerMenu tooltipText={t("selectControlAreas")}>
      {(onClose) => (
        <>
          <h4>{t("selectedControlAreas")}</h4>
          <div className="mb-2">
            <SelectControlArea
              onSelect={(controlAreaId) => {
                onSelectProp(controlAreaId);
                onClose();
              }}
            />
          </div>
          <SelectControlAreasButton onClose={onClose} />
        </>
      )}
    </HamburgerMenu>
  );
}
