import {faTrashAlt} from "@fortawesome/pro-regular-svg-icons";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {Button} from "@sokigo/components-react-bootstrap";
import {
  useCurrentVisitInput,
  useMergeCurrentVisitInput,
} from "../InspectionVisitRoute";

export function RemovePresentPartyButton({id}) {
  const t = useAppTranslation();
  const {presentParties, finishVisitInfo} = useCurrentVisitInput();
  const merge = useMergeCurrentVisitInput();

  return (
    <Button
      kind="danger-hover-ghost"
      size="sm"
      icon={faTrashAlt}
      onClick={() => {
        merge({
          presentParties: presentParties.filter((x) => x.id !== id),
          finishVisitInfo: {
            ...finishVisitInfo,
            emailInfo: {
              ...finishVisitInfo.emailInfo,
              sendToPresentPartyIds:
                finishVisitInfo.emailInfo.sendToPresentPartyIds.filter(
                  (x) => x !== id
                ),
            },
          },
        });
      }}
      tooltipText={t("removePresentParty")}
    />
  );
}
