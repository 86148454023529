import {useNotifications} from "@sokigo-sbwebb/default-core";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableRowActionsCell,
} from "@sokigo/components-react-bootstrap";
import {useState} from "react";
import {useServerState} from "../../../ServerStateProvider";
import {ManageFoodControlAreaSupportQuestionsModal} from "./ManageFoodControlAreaSupportQuestionsModal";
import {HelpLink} from "@sokigo-sbwebb/default-components";
import {faEdit} from "@fortawesome/pro-regular-svg-icons";

function ManageFoodInspectionAreaButton({foodInspectionArea}) {
  const [show, setShow] = useState(false);
  const {addErrorNotification} = useNotifications();
  const {supportQuestions, updateSupportQuestions} = useServerState();
  const t = useAppTranslation();

  async function save(value) {
    try {
      await updateSupportQuestions(foodInspectionArea.id, value);
      setShow(false);
    } catch (err) {
      addErrorNotification(t("supportQuestionsCouldNotBeSaved"));
      throw err;
    }
  }

  return (
    <>
      <Button
        size="sm"
        kind="ghost"
        icon={faEdit}
        onClick={() => setShow(true)}
      />
      {show && (
        <ManageFoodControlAreaSupportQuestionsModal
          show={show}
          onClose={() => setShow(false)}
          onSave={save}
          foodInspectionArea={foodInspectionArea}
          defaultValue={supportQuestions[foodInspectionArea.id]}
        />
      )}
    </>
  );
}

export function FoodInspectionControlAreas() {
  const t = useAppTranslation();
  const {
    baseData: {foodInspectionControlAreas},
  } = useServerState();

  return (
    <>
      <div className="d-flex flex-row justify-content-between align-items-center mb-1 px-3">
        <h4>{t("foodInspectionControlAreas")}</h4>
        <HelpLink section="controlAreasFoodInspection" />
      </div>
      <div className="d-flex">
        {foodInspectionControlAreas &&
          foodInspectionControlAreas.length > 0 && (
            <Table
              className="mr-2"
              rows={foodInspectionControlAreas}
              columns={[
                {
                  key: "1",
                  renderCell: ({row}) => `${row.number}. ${row.title}`,
                },
              ]}
              getRowKey={(x) => x.id}
            >
              <TableBody>
                {(rows) =>
                  rows.map((r) => (
                    <TableRow key={r.key} row={r}>
                      {r.cells.map((c) => (
                        <TableCell key={c.key}>{c.value}</TableCell>
                      ))}
                      <TableRowActionsCell>
                        <ManageFoodInspectionAreaButton
                          foodInspectionArea={r.row}
                        />
                      </TableRowActionsCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
          )}
      </div>
    </>
  );
}
