import {faEdit, faTrashAlt} from "@fortawesome/pro-regular-svg-icons";
import {useConfirmDialog} from "@sokigo-sbwebb/default-components";
import {useNotifications} from "@sokigo-sbwebb/default-core";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableHeaderRow,
  TableRow,
  TableRowActionsCell,
} from "@sokigo/components-react-bootstrap";
import {Fragment, useMemo, useState} from "react";
import {useServerState} from "../../../ServerStateProvider";
import {EditCommonDocumentsModal} from "./EditCommonDocumentsModal";
import getPrettyFileSize from "./getPrettyFileSize";

function EditCommonDocumentButton({commonDocumentId}) {
  const [show, setShow] = useState(false);
  const {commonDocuments, updateCommonDocument} = useServerState();
  const document = commonDocuments[commonDocumentId];

  async function onSave(saveFiles) {
    const f = saveFiles[0];
    await updateCommonDocument(f.id, {
      id: f.id,
      title: f.title,
      documentCategories: f.documentCategories,
    });
    setShow(false);
  }

  return (
    <>
      <Button
        kind="ghost"
        icon={faEdit}
        tooltipText="Edit"
        tooltipSide="left"
        size="sm"
        onClick={() => setShow(true)}
      />
      {show && (
        <EditCommonDocumentsModal
          key={commonDocumentId}
          documents={[document]}
          onSave={onSave}
          onClose={() => setShow(false)}
        />
      )}
    </>
  );
}

function DeleteCommonDocumentButton({commonDocumentId}) {
  const t = useAppTranslation();
  const [ConfirmModal, confirmDelete] = useConfirmDialog();
  const {removeCommonDocument} = useServerState();
  const {addErrorNotification, addSuccessNotification} = useNotifications();

  async function remove() {
    const c = await confirmDelete();
    if (c) {
      try {
        await removeCommonDocument(commonDocumentId);
        addSuccessNotification(t("commonDocumentRemoved"));
      } catch (err) {
        addErrorNotification(t("commonDocumentCouldNotBeRemoved"));
        throw err;
      }
    }
  }
  return (
    <>
      <Button
        kind="danger-hover-ghost"
        icon={faTrashAlt}
        tooltipText={t("remove")}
        size="sm"
        onClick={remove}
      />
      <ConfirmModal />
    </>
  );
}

export default function CommonDocumentsTable() {
  const t = useAppTranslation();

  const {commonDocuments} = useServerState();

  const commonDocumentsList = useMemo(
    () =>
      commonDocuments &&
      Object.keys(commonDocuments)
        .map((x) => commonDocuments[x])
        .sort((a, b) => a.title.localeCompare(b.title, t("locale"))),
    [commonDocuments, t]
  );

  const columns = [
    {
      key: "documentCategories",
      header: t("documentCategories"),
      renderCell: ({row}) => row.documentCategories?.sort().toString(),
    },
    {
      key: "title",
      header: t("title"),
      renderCell: ({row}) => row.title,
    },
    {
      key: "fileName",
      header: t("fileName"),
      renderCell: ({row}) => row.fileName,
    },
    {
      key: "fileSize",
      header: t("fileSize"),
      renderCell: ({row}) => {
        return getPrettyFileSize(row.size);
      },
    },
  ];

  const [sorting] = useState({column: columns[0], direction: 1});

  return (
    <>
      {commonDocumentsList?.length > 0 && (
        <Table
          rows={commonDocumentsList}
          columns={columns}
          getRowKey={(x) => x.id}
          sortable
          sorting={sorting}
        >
          <TableHead>
            <TableHeaderRow colSpan={columns.length + 1}>
              {(columns) => (
                <>
                  {columns.map((x) => (
                    <TableHeader key={x.key} column={x}>
                      {x.header}
                    </TableHeader>
                  ))}
                  <TableHeader />
                </>
              )}
            </TableHeaderRow>
          </TableHead>
          <TableBody>
            {(rows) =>
              rows.map((r) => (
                <Fragment key={r.key}>
                  <TableRow key={r.key} row={r}>
                    {r.cells.map((c) => (
                      <TableCell key={c.key}>{c.value}</TableCell>
                    ))}
                    <TableRowActionsCell>
                      <EditCommonDocumentButton commonDocumentId={r.row.id} />
                      <DeleteCommonDocumentButton commonDocumentId={r.row.id} />
                    </TableRowActionsCell>
                  </TableRow>
                </Fragment>
              ))
            }
          </TableBody>
        </Table>
      )}
    </>
  );
}
