import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {useIsOnline} from "@sokigo-sbwebb/serviceworker";
import {
  ActionsDropdown,
  ActionsDropdownItem,
  Panel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableHeaderRow,
  TableRow,
  TableRowActionsCell,
} from "@sokigo/components-react-bootstrap";
import moment from "moment";
import {Fragment, useState} from "react";
import {useServerState} from "../../ServerStateProvider";
import {useCurrentEcosUserId} from "../../useCurrentEcosUserId";
import {
  EcosSaveOccurrenceStatusIndicator,
  EcosSaveProtocolStatusIndicator,
} from "./EcosSaveStatusIndicator";
import {RefreshServerVisitsButton} from "./RefreshServerVisitsButton";
import {SaveToEcosStatus} from "../Common/SaveToEcosStatus";
import {
  faBookCircleArrowUp,
  faDumpsterFire,
  faEllipsisV,
  faQuestion,
  faTrashAlt,
} from "@fortawesome/pro-regular-svg-icons";
import {
  useClearSuccessfullyFinishedVisits,
  useDeleteFinishedVisit,
  useReopenVisitWithValidationError,
  useRetryAbortedFinishedVisit,
} from "../../sw-comms/device-inspections-repo/useDeviceVisits";
import {ShowVisitIdModal} from "../Common/ShowVisitIdModal";

export function FinishedVisits() {
  const t = useAppTranslation();
  const isOnline = useIsOnline();
  const {serverVisits} = useServerState();

  const currentEcosUserId = useCurrentEcosUserId();

  const clearSuccessfullyFinishedVisits = useClearSuccessfullyFinishedVisits();
  const retryAbortedFinishedVisit = useRetryAbortedFinishedVisit();
  const deleteFinishedVisit = useDeleteFinishedVisit();
  const reopenVisitWithValidationError = useReopenVisitWithValidationError();

  const [showingVisitId, setShowingVisitId] = useState(null);

  const finishedVisits = Object.keys(serverVisits)
    .filter(
      // only show finished visits
      (x) =>
        serverVisits[x].saveToEcosStatus !==
          SaveToEcosStatus.VisitNotFinished &&
        serverVisits[x].saveToEcosStatus !==
          SaveToEcosStatus.VisitWithValidationErrorsReopened
    )
    .filter((x) => serverVisits[x].ownerId === currentEcosUserId) // only show my visits
    .map((x) => serverVisits[x]);

  const columns = [
    {
      key: "visitTargetDescription",
      header: t("description"),
      renderCell: ({row}) => row.visitTargetDescription,
    },
    {
      key: "date",
      header: t("date"),
      renderCell: ({row}) => moment(row.createdDate).format("YYYY-MM-DD"),
    },
    {
      key: "lastSaved",
      header: t("lastSaved"),
      renderCell: ({row}) =>
        t("lastSavedDateAndDevice", {
          date: moment(row.lastSavedDate).format("YYYY-MM-DD"),
          device: row.lastSavedDevice,
        }),
    },
    {
      key: "saveStatusOccurrence",
      header: t("saveStatusOccurrence"),
      renderCell: ({row}) => (
        <EcosSaveOccurrenceStatusIndicator
          saveToEcosStatus={row.saveToEcosStatus}
        />
      ),
    },
    {
      key: "saveStatusProtocol",
      header: t("saveStatusProtocol"),
      renderCell: ({row}) => (
        <EcosSaveProtocolStatusIndicator
          saveToEcosStatus={row.saveToEcosStatus}
        />
      ),
    },
  ];

  return (
    <>
      <Panel className="my-3 p-3" highlightColor="#12c445">
        <div className="d-flex justify-content-between">
          <h3 className="mb-3">{t("finishedVisits")}</h3>
          <div>
            <RefreshServerVisitsButton />
            <ActionsDropdown
              icon={faEllipsisV}
              kind="ghost"
              size="sm"
              className="ml-1"
            >
              <ActionsDropdownItem
                kind="danger-hover-ghost"
                onClick={() => clearSuccessfullyFinishedVisits()}
                itemText={t("clearSuccessfullySavedVisits")}
                icon={faDumpsterFire}
              />
            </ActionsDropdown>
          </div>
        </div>
        {!isOnline && <div>{t("deviceIsOffline")}</div>}
        {isOnline && (!finishedVisits || finishedVisits.length === 0) && (
          <div>{t("noVisitsAvailable")}</div>
        )}
        {isOnline && finishedVisits?.length > 0 && (
          <Table
            rows={finishedVisits}
            columns={columns}
            getRowKey={(x) => x.id}
          >
            <TableHead>
              <TableHeaderRow>
                {(columns) => (
                  <>
                    {columns.map((x) => (
                      <TableHeader key={x.key} column={x}>
                        {x.header}
                      </TableHeader>
                    ))}
                    <TableHeader />
                  </>
                )}
              </TableHeaderRow>
            </TableHead>
            <TableBody>
              {(rows) =>
                rows.map((r) => (
                  <Fragment key={r.key}>
                    <TableRow key={r.key} row={r}>
                      {r.cells.map((c) => (
                        <TableCell key={c.key}>{c.value}</TableCell>
                      ))}

                      <TableRowActionsCell>
                        <ActionsDropdown
                          icon={faEllipsisV}
                          kind="ghost"
                          size="sm"
                        >
                          {r.row.saveToEcosStatus !==
                            SaveToEcosStatus.Success && (
                            <>
                              <ActionsDropdownItem
                                kind="danger-hover-ghost"
                                onClick={() => deleteFinishedVisit(r.row.id)}
                                itemText={t("remove")}
                                icon={faTrashAlt}
                              />
                              {(r.row.saveToEcosStatus ===
                                SaveToEcosStatus.CreateOccurrenceAborted ||
                                r.row.saveToEcosStatus ===
                                  SaveToEcosStatus.SaveProtocolAborted) && (
                                <ActionsDropdownItem
                                  onClick={() =>
                                    retryAbortedFinishedVisit(
                                      r.row.id,
                                      r.row.saveToEcosStatus
                                    )
                                  }
                                  itemText={t("tryAgain")}
                                />
                              )}
                              {r.row.saveToEcosStatus ===
                                SaveToEcosStatus.CreateOccurrenceValidationError && (
                                <ActionsDropdownItem
                                  onClick={() =>
                                    reopenVisitWithValidationError(r.row.id)
                                  }
                                  itemText={t("reopenVisit")}
                                  icon={faBookCircleArrowUp}
                                />
                              )}
                            </>
                          )}
                          <ActionsDropdownItem
                            onClick={() => setShowingVisitId(r.row.id)}
                            itemText={t("showVisitId")}
                            icon={faQuestion}
                          />
                        </ActionsDropdown>
                      </TableRowActionsCell>
                    </TableRow>
                  </Fragment>
                ))
              }
            </TableBody>
          </Table>
        )}
      </Panel>
      <ShowVisitIdModal
        visitId={showingVisitId}
        onClose={() => setShowingVisitId(null)}
      />
    </>
  );
}
