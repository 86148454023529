import {faCheck, faTimes} from "@fortawesome/pro-regular-svg-icons";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {
  Button,
  Control,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  TextArea,
} from "@sokigo/components-react-bootstrap";
import {useState} from "react";
import {v4 as uuid} from "uuid";
import {TextBoxControl} from "../../../../common-components/TextBoxControl";

export function EditSupportQuestionModal({defaultValue, onSave, onCancel}) {
  const t = useAppTranslation();
  const [value, setValue] = useState(() => defaultValue ?? {id: uuid()});

  function save() {
    onSave(value);
  }

  return (
    <Modal onClose={onCancel} show static>
      <ModalHeader>
        {defaultValue ? t("editSupportQuestion") : t("addSupportQuestion")}
      </ModalHeader>
      <ModalBody>
        <TextBoxControl
          label={t("title")}
          value={value.title || ""}
          onChange={(title) => setValue((v) => ({...v, title}))}
        />
        <Control labelText={t("helpText")}>
          <TextArea
            value={value.helpText || ""}
            onChange={(helpText) => setValue((v) => ({...v, helpText}))}
            rows={4}
          />
        </Control>
      </ModalBody>
      <ModalFooter>
        <Button
          kind="secondary"
          onClick={onCancel}
          className="w-25 mr-2"
          icon={faTimes}
        >
          {t("cancel")}
        </Button>
        <Button
          onClick={save}
          icon={faCheck}
          disabled={!value.title}
          kind="primary"
          className="w-25"
        >
          {t("ok")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
