import {useState} from "react";
import TileWMS from "ol/source/TileWMS";
import {useMapEffect} from "./useMapEffect";
import TileLayer from "ol/layer/Tile";

export const TileWMSLayer = ({options, visible}) => {
  const [layer, setLayer] = useState(null);

  useMapEffect(
    (map) => {
      const source = new TileWMS(options);
      const layer = new TileLayer({
        source,
      });
      setLayer(layer);
      map.addLayer(layer);
      return () => {
        map.removeLayer(layer);
      };
    },
    [options]
  );

  if (layer && visible !== layer.getVisible()) {
    layer.setVisible(visible);
  }

  return null;
};
