import {useSetApplicationSubTitle} from "@sokigo-sbwebb/default-core";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {useEffect} from "react";
import {PdfViewer} from "../../../common-components/pdf-viewer/PdfViewer";

export function ShowProtocolPreviewRoute({
  match: {
    params: {visitId},
  },
}) {
  const t = useAppTranslation();
  const setApplicationSubtitle = useSetApplicationSubTitle();
  useEffect(() => {
    setApplicationSubtitle(t("previewProtocol"));
  }, [setApplicationSubtitle, t]);

  const url = document.baseURI + "api/inspection/protocol/preview/" + visitId;
  return <PdfViewer url={url} />;
}
