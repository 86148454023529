import {useCurrentVisit} from "../InspectionVisitRoute";
import {Sections} from "./sections/Sections";

export function FacilityDetailsTab() {
  const {
    visit: {
      visitMetadata: {
        visitTargetInformation: {infoSections},
      },
    },
  } = useCurrentVisit();

  return (
    <>
      <Sections infoSections={infoSections} />
    </>
  );
}
