import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {Panel} from "@sokigo/components-react-bootstrap";
import {useCurrentVisit} from "../../InspectionVisitRoute";
import {HistoricVisitsTable} from "./HistoricVisitsTable";

export function HistoricVisits() {
  const t = useAppTranslation();
  const {
    visit: {
      visitMetadata: {
        visitTargetInformation: {historicVisits},
      },
    },
  } = useCurrentVisit();

  return (
    <Panel className="mt-3 py-3">
      <h4 className="px-3 mb-3">{t("historicVisits")}</h4>
      {historicVisits?.length === 0 ? (
        <div className="px-3">{t("caseHasNoHistoricVisits")}</div>
      ) : (
        <HistoricVisitsTable historicVisits={historicVisits} />
      )}
    </Panel>
  );
}
