import {useCallback} from "react";
import {useCurrentVisitInput} from "../InspectionVisitRoute";
import {useSaveSelectedFoodControlQuestions} from "./useSaveSelectedFoodControlQuestions";

export function useRemoveFoodInspectionQuestion(foodControlAreaId, questionId) {
  const save = useSaveSelectedFoodControlQuestions();
  const {foodControlQuestionsByFoodControlAreaId} = useCurrentVisitInput();

  return useCallback(() => {
    save({
      ...foodControlQuestionsByFoodControlAreaId,
      [foodControlAreaId]: foodControlQuestionsByFoodControlAreaId[
        foodControlAreaId
      ].filter((id) => id !== questionId),
    });
  }, [
    foodControlAreaId,
    questionId,
    foodControlQuestionsByFoodControlAreaId,
    save,
  ]);
}
