import {
  faExternalLinkAlt,
  faFileDownload,
} from "@fortawesome/pro-regular-svg-icons";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {
  Accordion,
  AccordionSection,
  Button,
  Panel,
} from "@sokigo/components-react-bootstrap";
import {useMemo} from "react";
import {Link, useHistory} from "react-router-dom";
import {useServerState} from "../../../ServerStateProvider";
import {useCommonDocumentsCacheMetadata} from "../../../sw-comms/common-documents-cache/useCommonDocumentsCacheMetadata";
import {OfflineDocumentStatus} from "../../Common/OfflineDocumentStatus";
import {OfflineDocumentStatusIndicator} from "../../Common/OfflineDocumentStatusIndicator";

export function CommonDocumentsPanel() {
  const t = useAppTranslation();
  const {commonDocuments} = useServerState();

  const allCommonDocuments = useMemo(
    () => Object.values(commonDocuments),
    [commonDocuments]
  );
  const commonDocumentsWithoutCategories = useMemo(
    () =>
      allCommonDocuments.filter(
        (document) =>
          !document.documentCategories ||
          document.documentCategories?.length === 0
      ),
    [allCommonDocuments]
  );

  const cacheStatus = useCommonDocumentsCacheMetadata();
  const history = useHistory();

  function getCacheUrl(id) {
    return history.createHref({
      pathname: `/cache/commonDocument/${id}`,
    });
  }

  if (!cacheStatus) {
    return null;
  }

  const documentCategories = [
    ...new Set(
      allCommonDocuments
        .map((x) => x.documentCategories && x.documentCategories)
        .flat(1)
    ),
  ].filter((x) => x);

  function getRows(row) {
    return (
      <div
        key={row.id}
        className="d-flex justify-content-between align-items-center px-3 py-1"
      >
        <div style={{maxWidth: "100%"}} className="text-wrap text-break">
          {row.title}
        </div>
        {cacheStatus[row.id]?.status !== OfflineDocumentStatus.Available && (
          <OfflineDocumentStatusIndicator
            status={cacheStatus[row.id]?.status}
          />
        )}
        {cacheStatus[row.id]?.status === OfflineDocumentStatus.Available && (
          <div className="text-nowrap">
            {row.fileName.toLowerCase().endsWith(".pdf") ? (
              <Button
                kind="ghost"
                tag={Link}
                type={null}
                to={"/inspection/commonDocument/" + row.id}
                target="_blank"
                rel="noopener noreferrer"
                icon={faExternalLinkAlt}
                size="sm"
              />
            ) : (
              <Button
                tag="a"
                href={getCacheUrl(row.id)}
                target="_blank"
                rel="noreferrer"
                icon={faExternalLinkAlt}
                kind="ghost"
                size="sm"
                className="ml-1"
              />
            )}
            {
              <Button
                tag="a"
                href={getCacheUrl(row.id)}
                target="_blank"
                rel="noreferrer"
                icon={faFileDownload}
                kind="ghost"
                size="sm"
                className="ml-1"
                download={row.fileName}
              />
            }
          </div>
        )}
      </div>
    );
  }

  return (
    <Panel
      className="align-self-end pb-3 overflow-auto rounded-top-left rounded-bottom-left"
      square
      style={{width: 350, boxShadow: "0 3px 7px rgb(0, 0, 0, 0.2)"}}
    >
      <h4 className="p-3">{t("commonDocuments")}</h4>
      <div className="flex-shrink-1 overflow-auto">
        {documentCategories.length === 0 &&
        commonDocumentsWithoutCategories.length === 0 ? (
          t("noCommonDocumentsAdded")
        ) : (
          <Accordion border borderBetween square>
            {documentCategories.sort().map((x) => {
              const categoriesDocuments = allCommonDocuments.filter(
                (document) =>
                  document.documentCategories?.some(
                    (category) => category === x
                  )
              );
              return (
                <AccordionSection
                  key={x}
                  headerText={`${x} (${categoriesDocuments.length})`}
                >
                  {categoriesDocuments.map((row) => getRows(row))}
                </AccordionSection>
              );
            })}
            {commonDocumentsWithoutCategories.length > 0 && (
              <AccordionSection
                key={"others"}
                headerText={`${t("noCategory")} (${
                  commonDocumentsWithoutCategories.length
                })`}
              >
                {commonDocumentsWithoutCategories.map((row) => getRows(row))}
              </AccordionSection>
            )}
          </Accordion>
        )}
      </div>
    </Panel>
  );
}
