import {Panel, Tooltip} from "@sokigo/components-react-bootstrap";
import {
  useCurrentVisit,
  useCurrentVisitInput,
  useMergeCurrentVisitInput,
} from "../InspectionVisitRoute";
import {
  ActionsDropdown,
  ActionsDropdownItem,
  Table,
  TableBody,
  TableCell,
  TableExpandRowCell,
  TableExpandedRow,
  TableHead,
  TableHeader,
  TableHeaderRow,
  TableRow,
  TableRowActionsCell,
  TextArea,
} from "@sokigo/components-react-bootstrap";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faEllipsisV,
  faInfoCircle,
  faPlus,
  faTrashAlt,
} from "@fortawesome/pro-regular-svg-icons";
import {AssessmentSwitch} from "../AssessmentSwitch";
import {useEditSupportQuestion} from "../foodInspectionControl/useEditSupportQuestion";
import {useCreateSupportQuestion} from "../foodInspectionControl/useCreateSupportQuestion";
import {Fragment} from "react";
import {FillTableCellWithTruncatedText} from "src/common-components/FillTableCellWithTruncatedText";
import {Assessment} from "../information/historicVisits/Assessment";
import {useRemoveFoodControlSupportQuestion} from "../foodInspectionControl/useRemoveFoodControlSupportQuestion";
import {useRemoveFoodInspectionQuestion} from "../foodInspectionControl/useRemoveFoodInspectionQuestion";
import {ControlAssessments} from "./ControlAssessments";

function SupportQuestion({supportQuestion, note, onNoteChange}) {
  return (
    <div className="row p-3">
      <div className="col-6">
        <TextArea value={note ?? ""} onChange={(note) => onNoteChange(note)} />
      </div>
      {supportQuestion.helpText?.length && (
        <div className={"col-md-6 mt-2 mt-md-0"}>
          <p className={"mb-0 "} style={{whiteSpace: "pre-wrap"}}>
            {supportQuestion.helpText}
          </p>
        </div>
      )}
    </div>
  );
}

export function ControlAreaQuestion({area, question, controlsCurrentCause}) {
  const t = useAppTranslation();
  const removeSupportQuestion = useRemoveFoodControlSupportQuestion();
  const removeQuestion = useRemoveFoodInspectionQuestion(area.id, question.id);
  const [createSupportQuestion, supportQuestionCreation] =
    useCreateSupportQuestion(question.id);
  const [editSupportQuestion, supportQuestionEditing] = useEditSupportQuestion(
    question.id
  );
  const {
    visit: {
      input: {
        foodControlSupportQuestionNoteBySupportQuestionId,
        foodControlSupportQuestionAssessmentBySupportQuestionId,
      },
    },
  } = useCurrentVisit();

  const merge = useMergeCurrentVisitInput();
  const {foodControlOccasion2024} = useCurrentVisitInput();
  const controls = foodControlOccasion2024.controls ?? [];

  function setSupportQuestionNote(supportQuestionId, note) {
    merge({
      foodControlSupportQuestionNoteBySupportQuestionId: {
        ...(foodControlSupportQuestionNoteBySupportQuestionId ?? {}),
        [supportQuestionId]: note,
      },
    });
  }

  function setSupportQuestionAssessment(supportQuestionId, assessment) {
    merge({
      foodControlSupportQuestionAssessmentBySupportQuestionId: {
        ...(foodControlSupportQuestionAssessmentBySupportQuestionId ?? {}),
        [supportQuestionId]: assessment,
      },
    });
  }

  function onChangeControl(control) {
    merge({
      foodControlOccasion2024: {
        ...foodControlOccasion2024,
        controls: controls.map((x) => (x.id === control.id ? control : x)),
      },
    });
  }

  const columns = [
    {
      key: "title",
      header: t("title"),
      renderCell: ({row}) => (
        <FillTableCellWithTruncatedText>
          {row.title}
        </FillTableCellWithTruncatedText>
      ),
      className: "w-66",
    },
    {
      key: "note",
      header: t("note"),
      renderCell: ({row}) => (
        <FillTableCellWithTruncatedText>
          {foodControlSupportQuestionNoteBySupportQuestionId?.[row.id]}
        </FillTableCellWithTruncatedText>
      ),
      className: "w-33",
    },
  ];

  return (
    <>
      <Panel className="mb-4 p-3">
        <div className="d-flex flex-row justify-content-between align-items-center mb-2">
          <Tooltip text={question.title}>
            <h4 className="text-truncate">
              {question.number}: {question.title}
            </h4>
          </Tooltip>
          <ActionsDropdown
            icon={faEllipsisV}
            kind="ghost"
            className="ml-2"
            menuSize="sm"
          >
            <ActionsDropdownItem
              itemText={t("remove")}
              onClick={removeQuestion}
              icon={faTrashAlt}
              kind="danger-hover-ghost"
            />
            <ActionsDropdownItem
              itemText={t("addSupportQuestion")}
              onClick={createSupportQuestion}
              icon={faPlus}
            />
          </ActionsDropdown>
        </div>
        {controlsCurrentCause.map((control) => (
          <div key={control.id}>
            <ControlAssessments
              control={control}
              question={question}
              onChangeControl={onChangeControl}
            />
          </div>
        ))}

        {question.supportQuestions.length > 0 && (
          <div>
            <h4 className="mt-4 mb-2">
              {t("mySupportQuestionsForQ", {q: question.number})}{" "}
              <Tooltip
                side="left"
                text={t("supportQuestionsAreClearedAfterVisitFinished")}
              >
                <span tabIndex="0" className="align-start">
                  <FontAwesomeIcon icon={faInfoCircle} />
                </span>
              </Tooltip>
            </h4>
            <Table
              className={""}
              rows={question.supportQuestions}
              columns={columns}
              getRowKey={(x) => x.id}
            >
              <TableHead>
                <TableHeaderRow colSpan={columns.length + 2}>
                  {(columns) => (
                    <>
                      <TableHeader className="" />
                      {columns.map((x) => (
                        <TableHeader
                          key={x.key}
                          column={x}
                          className={x.className}
                        >
                          {x.header}
                        </TableHeader>
                      ))}
                      <TableHeader />
                    </>
                  )}
                </TableHeaderRow>
              </TableHead>
              <TableBody>
                {(rows) =>
                  rows.map((r) => (
                    <Fragment key={r.key}>
                      <TableRow key={r.key} row={r}>
                        <TableExpandRowCell row={r} />
                        {r.cells.map((c) => (
                          <TableCell key={c.key}>{c.value}</TableCell>
                        ))}
                        <TableRowActionsCell>
                          <div className="d-flex align-items-center">
                            <AssessmentSwitch
                              size="sm"
                              value={
                                foodControlSupportQuestionAssessmentBySupportQuestionId?.[
                                  r.row.id
                                ] ?? Assessment.NotAssessed
                              }
                              onChange={(assessment) =>
                                setSupportQuestionAssessment(
                                  r.row.id,
                                  assessment
                                )
                              }
                            />
                            <ActionsDropdown
                              size="sm"
                              icon={faEllipsisV}
                              kind="ghost"
                              className="ml-2"
                            >
                              <ActionsDropdownItem
                                itemText={t("remove")}
                                onClick={() =>
                                  removeSupportQuestion(question.id, r.row.id)
                                }
                                icon={faTrashAlt}
                                kind="danger-hover-ghost"
                              />

                              <ActionsDropdownItem
                                itemText={t("edit")}
                                onClick={() => editSupportQuestion(r.row.id)}
                                icon={faEdit}
                              />
                            </ActionsDropdown>
                          </div>
                        </TableRowActionsCell>
                      </TableRow>
                      <TableExpandedRow
                        row={r}
                        colSpan={columns.length + 2}
                        indent
                      >
                        <SupportQuestion
                          supportQuestion={r.row}
                          note={
                            foodControlSupportQuestionNoteBySupportQuestionId?.[
                              r.row.id
                            ]
                          }
                          onNoteChange={(note) =>
                            setSupportQuestionNote(r.row.id, note)
                          }
                        />
                      </TableExpandedRow>
                    </Fragment>
                  ))
                }
              </TableBody>
            </Table>
          </div>
        )}
        {supportQuestionEditing}
        {supportQuestionCreation}
      </Panel>
    </>
  );
}
