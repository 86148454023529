import {useEffect} from "react";
import {useCurrentEcosUserId} from "../apps/sokigo-sbwebb-inspection/useCurrentEcosUserId";

function startPeriodicPoll(messageType, signal, currentEcosUserId) {
  async function poll() {
    const {active} = await navigator.serviceWorker.ready;

    if (signal.aborted) {
      return;
    }

    const mc = new MessageChannel();
    mc.port1.onmessage = async (event) => {
      mc.port1.close();
      mc.port2.close();
      if (signal.aborted) {
        return;
      }
      const response = event.data;
      const next = response.next;
      setTimeout(poll, next);
    };
    active.postMessage(
      {
        type: messageType,
        currentEcosUserId,
      },
      [mc.port2]
    );
  }

  // noinspection JSIgnoredPromiseFromCall
  poll();
}

export function TabJobTimers({children}) {
  const currentEcosUserId = useCurrentEcosUserId();
  useEffect(() => {
    const ac = new AbortController();

    startPeriodicPoll(
      "INSPECTION_POLL_SERVER_VISITS",
      ac.signal,
      currentEcosUserId
    );
    startPeriodicPoll(
      "INSPECTION_POLL_INSPECTION_BASE_DATA",
      ac.signal,
      currentEcosUserId
    );
    startPeriodicPoll(
      "INSPECTION_POLL_ECOS_BASE_DATA",
      ac.signal,
      currentEcosUserId
    );
    startPeriodicPoll("INSPECTION_POLL_SYNC", ac.signal, currentEcosUserId);

    return () => ac.abort();
  }, [currentEcosUserId]);

  return children;
}
