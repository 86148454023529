import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {
  Table,
  TableBody,
  TableCell,
  TableExpandedRow,
  TableExpandRowCell,
  TableHead,
  TableHeader,
  TableHeaderRow,
  TableRow,
} from "@sokigo/components-react-bootstrap";
import {Fragment, useMemo, useState} from "react";
import useWindowDimensions from "../../../../../common-components/useWindowDimensions";

import {InspectionAssessment} from "../../Common/InspectionAssessment";
import {useCurrentVisit, useCurrentVisitInput} from "../InspectionVisitRoute";
import {SelectFoodControlAreaQuestions} from "./SelectFoodControlAreaQuestions";
import {SelectFoodControlAreasSwitch} from "./SelectFoodControlAreasSwitch";

export function SelectFoodControlAreas({onChange, value}) {
  const {windowWidth} = useWindowDimensions();
  const expandedRowMaxWidth =
    windowWidth < 576
      ? `${windowWidth - 100}px` //xs
      : windowWidth < 769
      ? `${windowWidth - 350}px` //sm
      : windowWidth < 992
      ? `${windowWidth - 420}px` //md
      : `${windowWidth - 305}px`; //lg , xl

  const t = useAppTranslation();
  const {
    visit: {
      baseDataSnapshot: {foodInspectionControlAreas},
      visitMetadata: {availableFoodInspectionControlAreas},
    },
  } = useCurrentVisit();

  // If we're in a visit, get available control areas for that facility type
  const availableInspectionControlAreas = useMemo(
    () =>
      availableFoodInspectionControlAreas
        ? foodInspectionControlAreas.filter((x) =>
            availableFoodInspectionControlAreas.includes(x.id)
          )
        : foodInspectionControlAreas,
    [foodInspectionControlAreas, availableFoodInspectionControlAreas]
  );

  const {
    foodControlQuestionNoteByQuestionId,
    foodControlQuestionAssessmentByQuestionId,
    foodControlSupportQuestionNoteBySupportQuestionId,
    foodControlSupportQuestionAssessmentBySupportQuestionId,
    foodControlSupportQuestionsByFoodControlQuestionId,
  } = useCurrentVisitInput();

  const [controlAreasSwitchIsChanged, setControlAreasSwitchIsChanged] =
    useState(false);

  // Get IDs of all questions that already have assessments or notes
  const assessedQuestionIds = useMemo(
    () =>
      availableInspectionControlAreas
        .map((x) => x.questions.map((q) => q.id))
        .flat() // all question ids in flat list
        .filter(
          // only get questions with some kind of input
          (x) =>
            // has note
            foodControlQuestionNoteByQuestionId?.[x] ||
            // has assessment
            foodControlQuestionAssessmentByQuestionId?.[x] ===
              InspectionAssessment.Acceptable ||
            // has assessment
            foodControlQuestionAssessmentByQuestionId?.[x] ===
              InspectionAssessment.NotAcceptable ||
            // has support question input
            foodControlSupportQuestionsByFoodControlQuestionId?.[x]?.some(
              (sq) =>
                // has support question note
                foodControlSupportQuestionNoteBySupportQuestionId?.[sq.id] ||
                // has support question assessment
                foodControlSupportQuestionAssessmentBySupportQuestionId?.[
                  sq.id
                ] === InspectionAssessment.Acceptable ||
                // has support question assessment
                foodControlSupportQuestionAssessmentBySupportQuestionId?.[
                  sq.id
                ] === InspectionAssessment.NotAcceptable
            )
        ),
    [
      availableInspectionControlAreas,
      foodControlQuestionNoteByQuestionId,
      foodControlQuestionAssessmentByQuestionId,
      foodControlSupportQuestionsByFoodControlQuestionId,
      foodControlSupportQuestionAssessmentBySupportQuestionId,
      foodControlSupportQuestionNoteBySupportQuestionId,
    ]
  );

  const columns = [
    {
      key: "foodControlArea",
      header: t("foodControlArea"),
      renderCell: ({row}) => (
        <>
          {row.number}: {row.title}
        </>
      ),
    },
  ];

  return (
    <>
      <Table
        rows={availableInspectionControlAreas}
        columns={columns}
        getRowKey={(x) => x.id}
      >
        <TableHead>
          <TableHeaderRow>
            {(columns) => (
              <>
                <TableHeader></TableHeader>
                {columns.map((x) => (
                  <TableHeader key={x.key} column={x} className="w-100">
                    {x.header}
                  </TableHeader>
                ))}
                <TableHeader></TableHeader>
              </>
            )}
          </TableHeaderRow>
        </TableHead>
        <TableBody>
          {(rows) =>
            rows.map((r) => (
              <Fragment key={r.key}>
                <TableRow key={r.key} row={r}>
                  <TableExpandRowCell row={r} />
                  {r.cells.map((c) => (
                    <TableCell key={c.key}>{c.value}</TableCell>
                  ))}
                  <TableCell align="right">
                    <SelectFoodControlAreasSwitch
                      controlAreasSwitchIsChanged={controlAreasSwitchIsChanged}
                      controlAreaId={r.row.id}
                      foodInspectionControlAreas={
                        availableInspectionControlAreas
                      }
                      assessedQuestionIds={assessedQuestionIds}
                      selectedQuestions={value[r.row.id] ?? []}
                      onChange={(qs, add) => {
                        //add
                        if (add) {
                          onChange({
                            ...value,
                            [r.row.id]: qs,
                          });
                        }
                        //remove all
                        else {
                          const v = {...value};
                          delete v[r.row.id];
                          onChange(v);
                        }

                        setControlAreasSwitchIsChanged(true);
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableExpandedRow row={r} colSpan={3} indent>
                  <div className="px-3" style={{maxWidth: expandedRowMaxWidth}}>
                    <SelectFoodControlAreaQuestions
                      controlArea={r.row}
                      assessedQuestionIds={assessedQuestionIds}
                      selectedQuestions={value[r.row.id] ?? []}
                      onChange={(qs) => {
                        if (qs.length > 0) {
                          onChange({
                            ...value,
                            [r.row.id]: qs,
                          });
                        } else {
                          const v = {...value};
                          delete v[r.row.id];
                          onChange(v);
                        }
                        setControlAreasSwitchIsChanged(false);
                      }}
                    />
                  </div>
                </TableExpandedRow>
              </Fragment>
            ))
          }
        </TableBody>
      </Table>
    </>
  );
}
