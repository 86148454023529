import {TextBoxControl} from "../common-components/TextBoxControl";
import {Password} from "@sokigo-sbwebb/default-components";
import {Alert, Tab, Tabs} from "@sokigo/components-react-bootstrap";
import {CustomClaimTypes} from "./sokigo-sbwebb-ecos/ecos-settings-service/transformations/CustomClaimTypes";
import {ClaimTransformations} from "./sokigo-sbwebb-ecos/ecos-settings-service/transformations/ClaimTransformations";

const claimTypes = [
  {
    type: "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name",
    description: "Användarnamn",
  },
  {
    type: "http://schemas.microsoft.com/ws/2008/06/identity/claims/role",
    description: "Roll",
  },
  {
    type: "sc://tekis.se/customclaim",
    description: "sc://tekis.se/customclaim",
  },
];

function EcosIntegrationSettingsView({settings, onChange}) {
  return (
    <Tabs justified tabsSquare>
      <Tab id="ecos" headerText="Ecos" className="p-3 w-75">
        <TextBoxControl
          value={settings.publicServicesBaseUrl}
          label={"URL"}
          placeholder={"https://ecos.server/EcosPublicServices/"}
          onChange={(publicServicesBaseUrl) =>
            onChange({...settings, publicServicesBaseUrl})
          }
        />
        <TextBoxControl
          value={settings.username}
          label={"Username"}
          onChange={(username) => onChange({...settings, username})}
        />
        <Password
          vertical
          labelText={"Password"}
          value={settings.password}
          onChange={(password) => onChange({...settings, password})}
        />
        <TextBoxControl
          value={settings.domain}
          label={"Domain"}
          onChange={(domain) => onChange({...settings, domain})}
        />
      </Tab>
      <Tab id="customClaims" headerText="Custom claims" className="p-3 w-75">
        <CustomClaimTypes
          value={settings.customClaimTypes ?? []}
          onChange={(customClaimTypes) =>
            onChange({...settings, customClaimTypes})
          }
        />
      </Tab>
      <Tab
        id="claimTransforms"
        headerText="Claim transformations"
        className="p-3 w-75"
      >
        <Alert className="mb-4" hideCloseButton>
          Om någon transformation är angiven här kommer endast de claims som
          skapats av transformationerna skickas till Ecos. Input till
          transformationerna är de claims som kommer från inloggningsmetoden.
        </Alert>
        <ClaimTransformations
          value={settings.claimTransformations ?? []}
          onChange={(claimTransformations) =>
            onChange({...settings, claimTransformations})
          }
          claimTypes={[...claimTypes, ...(settings.customClaimTypes ?? [])]}
        />
      </Tab>
    </Tabs>
  );
}

export const EcosIntegrationServiceApplication = {
  name: "sokigo-ecos-webapps-ecos-integration-service",
  title: "Ecos integration",
  settings: {
    group: "Ecos",
    component: EcosIntegrationSettingsView,
  },
};
