import {faPlus, faTrashAlt} from "@fortawesome/pro-regular-svg-icons";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableHeaderRow,
  TableRow,
  TextBox,
} from "@sokigo/components-react-bootstrap";

export function CustomClaimTypes({value, onChange}) {
  return (
    <>
      <Button
        className="mb-3"
        kind="primary"
        icon={faPlus}
        onClick={() => onChange([...value, {}])}
      >
        Lägg till
      </Button>
      <Table
        rows={value || []}
        columns={[
          {
            label: "Type",
            renderCell: ({row, onRowChange}) => (
              <TextBox
                placeholder="Type"
                value={row.type}
                onChange={(type) => onRowChange({...row, type})}
              />
            ),
          },
          {
            label: "Description",
            renderCell: ({row, onRowChange}) => (
              <TextBox
                placeholder="Description"
                value={row.description}
                onChange={(description) => onRowChange({...row, description})}
              />
            ),
          },
        ]}
        getRowKey={(x) => (value || []).findIndex((v) => v === x)}
        onChange={onChange}
        borderless
        size="lg"
      >
        <TableHead>
          <TableHeaderRow>
            {(columns) => (
              <>
                {columns.map((c) => (
                  <TableHeader key={c.label}>{c.label}</TableHeader>
                ))}
                <TableHeader />
              </>
            )}
          </TableHeaderRow>
        </TableHead>
        <TableBody>
          {(rows) =>
            rows.map((r, i) => (
              <TableRow key={r.key} row={r}>
                {r.cells.map((c) => (
                  <TableCell className="pl-0" key={c.key}>
                    {c.value}
                  </TableCell>
                ))}
                <TableCell className="px-0 w-1px">
                  <Button
                    kind="danger-hover-ghost"
                    icon={faTrashAlt}
                    onClick={(e) => {
                      e.stopPropagation();
                      onChange(value.filter((_, vi) => vi !== i));
                    }}
                  />
                </TableCell>
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
    </>
  );
}
