import ExifReader from "exifreader";
import {useCallback} from "react";
import {useGeoLocation} from "../../../../services/useGeoLocation";
import {useMapSettings} from "../../../../services/useMapSettings";
import {getWgs84PositionInCoordinateSystemWithCoordinateOrderXY} from "../../../mapping/Mapping/GetWgs84PositionInCoordinateSystemWithCoordinateOrderXY";

export function useGetFilePositionAsync() {
  const geoLocation = useGeoLocation();
  const {mapSettings} = useMapSettings();

  const getFilePositionAsync = useCallback(
    (file) =>
      new Promise((resolve, reject) => {
        if (!file.type.startsWith("image/")) {
          resolve(null);
        }

        // try to get geolocation from image metadata
        // exifreader wants arraybuffer, easiest and safest way is to read the file again
        // (converting between base64 and arraybuffer in js can lead to performance issues with large files)
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          let wgs84position = null;
          try {
            const tags = new ExifReader.load(reader.result, {expanded: true});
            const lat = tags?.gps?.Latitude;
            const lon = tags?.gps?.Longitude;
            if (mapSettings?.projection && !isNaN(lon) && !isNaN(lat)) {
              wgs84position = [lon, lat];
            }
          } catch (err) {
            // ignore
            wgs84position = null;
          }
          if (
            !wgs84position &&
            geoLocation?.latitude &&
            geoLocation?.longitude &&
            mapSettings?.projection
          ) {
            // if no position in image metadata, get current position if available
            wgs84position = [geoLocation?.longitude, geoLocation?.latitude];
          }

          if (!wgs84position) {
            resolve(null);
          }

          try {
            const position = {
              type: "Point",
              coordinates:
                getWgs84PositionInCoordinateSystemWithCoordinateOrderXY(
                  wgs84position,
                  mapSettings?.projection
                ),
            };
            resolve(position);
          } catch (err) {
            reject();
          }
        });
        reader.readAsArrayBuffer(file);
      }),
    [geoLocation, mapSettings]
  );

  return getFilePositionAsync;
}
