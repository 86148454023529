import {useMemo} from "react";
import {useServiceWorkerRepository} from "../useServiceWorkerRepository";

export function useFoodControlAreaSupportQuestionsRepository() {
  const {items, updateItem, createItem} = useServiceWorkerRepository(
    "control-areas-support-questions"
  );

  const v = useMemo(
    () => ({
      supportQuestions: items,
      updateSupportQuestions: updateItem,
      addSupportQuestions: createItem,
    }),
    [items, updateItem, createItem]
  );

  return v;
}
