import {
  faArrowRight,
  faCheck,
  faSpinnerThird,
  faTimes,
} from "@fortawesome/pro-regular-svg-icons";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {Fragment, useCallback, useMemo, useState} from "react";
import {
  Button,
  Control,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableRowActionsCell,
} from "@sokigo/components-react-bootstrap";
import {useServerState} from "../../../../ServerStateProvider";
import {NewCaseParameters} from "./NewCaseParameters";
import NewCaseParametersValidationSchema from "./NewCaseParametersValidationSchema.json";
import {
  getValidationErrorMessages,
  Validation,
} from "@sokigo-sbwebb/validation";
import {SaveAsType} from "../../../AdminRoutes/AdminVisitForms/EditVisitForm/VisitFormEditor/SaveAsType";
import {useStartVisit} from "../../../../sw-comms/device-inspections-repo/useDeviceVisits";

export function CreateNewVisitModal({
  isNewCase,
  caseNumber,
  possibleVisits,
  onClose: onCloseProp,
}) {
  const t = useAppTranslation();
  const [size, setSize] = useState("md");
  function getTypeText(type) {
    if (type === SaveAsType.GenericInspection) {
      return t("genericInspection");
    } else if (type === SaveAsType.FoodInspection) {
      return t("foodInspection");
    } else if (type === SaveAsType.FoodInspection24) {
      return t("foodInspection24");
    } else {
      return t("visitOccurrence");
    }
  }
  function getHeader() {
    return isNewCase
      ? t("newVisitHeader")
      : t("newVisitInCaseHeader", {
          case: caseNumber,
        });
  }
  function getHeaderWithSaveAsType(saveAsType) {
    const typeText = getTypeText(saveAsType);
    return isNewCase
      ? t("newVisitWithTypeHeader", {
          visitType: typeText,
        })
      : t("newVisitWithCaseNoAndTypeHeader", {
          visitType: typeText,
          case: caseNumber,
        });
  }
  const [header, setHeader] = useState(getHeader());
  const [showStartVisitButton, setShowStartVisitButton] = useState(false);
  const [showSelectVisit, setShowSelectVisit] = useState(true);
  const [starting, setStarting] = useState(false);
  const [selectedVisit, setSelectedVisit] = useState();
  const [selectedVisitFormId, setSelectedVisitFormId] = useState();
  const [newCaseParameters, setNewCaseParameters] = useState(null);
  const {visitForms} = useServerState();
  const visitFormsList = Object.keys(visitForms).map((x) => visitForms[x]);
  const [selectedDiary, setSelectedDiary] = useState([]);
  const {baseData: {diaryPlanItems} = {}} = useServerState();
  const startVisit = useStartVisit();

  const availableVisitFormsList = useMemo(
    () =>
      visitFormsList
        // can only select visit form after visit has been selected
        .filter(() => selectedVisit)
        // can only select visit form of the same type as the selected visit
        .filter((x) => selectedVisit.saveAsType === x.saveAsType)
        // can only select visit form without facility filter or of the same facility type
        .filter(
          (x) =>
            x.saveAsType === SaveAsType.FoodInspection ||
            x.facilityTypeIds.length === 0 ||
            (selectedVisit.facilityTypeId &&
              x.facilityTypeIds.includes(selectedVisit.facilityTypeId))
        ),
    [visitFormsList, selectedVisit]
  );

  const validation = useMemo(
    () => new Validation(NewCaseParametersValidationSchema),
    []
  );
  const validationErrors = useMemo(
    () =>
      validation.validate(
        {
          selectedVisitFormId: selectedVisitFormId,
          processId: newCaseParameters?.processId,
          diaryPlanItemId: newCaseParameters?.diaryPlanItemId,
          municipalityCode: newCaseParameters?.municipalityCode,
          caseBillingModelId: newCaseParameters?.caseBillingModelId,
        },
        t
      ),
    [selectedVisitFormId, newCaseParameters, validation, t]
  );

  const diaryPlanOptions = (() => {
    return diaryPlanItems.map(function mapLevel(x) {
      if (x.children?.length > 0) {
        return {
          key: x.id,
          label: x.text,
          children: x.children.map((y) => mapLevel(y)),
        };
      } else {
        return {
          key: x.id,
          label: x.text,
          children: [],
        };
      }
    });
  })();

  const selectedDiaryValidationErrors = useMemo(() => {
    const key = getSelectedDiaryId(selectedDiary);
    if (!key) {
      return [t("obligatory")];
    }

    let errors = [];
    traverse(diaryPlanOptions);

    function traverse(nodes) {
      nodes.forEach((x) => {
        if (x.key === key) {
          if (x.children.length > 0) {
            errors = [t("obligatory")];
          }
        } else {
          traverse(x.children);
        }
      });
    }

    if (selectedDiary.length === 0) {
      errors = [t("obligatory")];
    }

    return errors;
  }, [diaryPlanOptions, selectedDiary, t]);

  const selectedVisitForm =
    selectedVisitFormId && visitForms[selectedVisitFormId];

  const canStartVisit = isNewCase
    ? selectedVisit &&
      selectedVisitForm &&
      !showSelectVisit &&
      !!newCaseParameters &&
      validationErrors.length === 0 &&
      selectedDiaryValidationErrors.length === 0
    : selectedVisit && selectedVisitForm && !showSelectVisit;

  const start = useCallback(
    async function start() {
      setStarting(true);
      try {
        await startVisit({
          newCaseParameters: isNewCase ? newCaseParameters : null,
          visitFormId: selectedVisitForm.id,
          visitTargetId: selectedVisit.visitTargetId,
        });
      } catch (ex) {
        // addErrorNotification in useStartVisit. Just enable buttons here, so it's possible to close modal.
        setStarting(false);
      } finally {
        setStarting(false);
      }
    },
    [startVisit, isNewCase, newCaseParameters, selectedVisitForm, selectedVisit]
    /*  VS code quick fix for eslint error on missing or unnessecary dependency adds id to selectedVisitForm and visitTargetId to selectedVisit.
        As selectedVisitForm is undefined when component first loads, it will break the page.  */
  );

  function onClose() {
    if (starting) {
      return;
    }
    setSelectedVisit(null);
    onCloseProp();
  }

  function getSelectedDiaryId(diaryTree) {
    let ret = null;
    parseDiaryTree(diaryTree);

    function parseDiaryTree(treeNode) {
      if (treeNode.some((x) => x.children?.length > 0)) {
        parseDiaryTree(treeNode[0].children);
      } else {
        ret = treeNode[0]?.key;
      }
    }

    return ret;
  }

  const genericInspectionVisitForms = visitFormsList.filter(
    (x) => SaveAsType.GenericInspection === x.saveAsType
  );

  const foodInspectionVisitForms = visitFormsList.filter(
    (x) => SaveAsType.FoodInspection === x.saveAsType
  );

  const visitOccurrenceVisitForms = visitFormsList.filter(
    (x) => SaveAsType.VisitOccurrence === x.saveAsType
  );

  const foodInspection24VisitForms = visitFormsList.filter(
    (x) => SaveAsType.FoodInspection24 === x.saveAsType
  );

  const possibleVisitsThatHaveExistingVisitForms = possibleVisits.filter(
    (x) =>
      (genericInspectionVisitForms.length > 0 &&
        x.saveAsType === SaveAsType.GenericInspection) ||
      (foodInspectionVisitForms.length > 0 &&
        x.saveAsType === SaveAsType.FoodInspection) ||
      (visitOccurrenceVisitForms.length > 0 &&
        x.saveAsType === SaveAsType.VisitOccurrence) ||
      (foodInspection24VisitForms.length > 0 &&
        x.saveAsType === SaveAsType.FoodInspection24)
  );

  return (
    <Modal
      static
      show
      size={size}
      onClose={onClose}
      disabledClose={starting}
      scrollable={true}
    >
      <ModalHeader onClose={onClose}>{header}</ModalHeader>
      <ModalBody>
        {possibleVisitsThatHaveExistingVisitForms.length === 0 &&
          t("noVisitFormsAvailable")}
        {showSelectVisit &&
          possibleVisitsThatHaveExistingVisitForms.length > 0 && (
            <Table
              rows={possibleVisitsThatHaveExistingVisitForms}
              columns={[
                {
                  renderCell: ({row}) => getTypeText(row.saveAsType),
                },
                {
                  key: "arrow",
                  renderCell: ({row}) => (
                    <Button
                      kind="ghost"
                      size="sm"
                      icon={faArrowRight}
                      onClick={() => {
                        const visit = possibleVisits.find(
                          (x) => x.key === row.key
                        );
                        setSelectedVisit(visit);
                        setHeader(getHeaderWithSaveAsType(row.saveAsType));
                        setSize(isNewCase ? "lg" : "md");
                        setShowSelectVisit(false);
                        setShowStartVisitButton(true);
                      }}
                    />
                  ),
                },
              ]}
              getRowKey={(x) => x.key}
            >
              <TableBody>
                {(rows) => (
                  <>
                    {rows.map((r) => (
                      <Fragment key={r.key}>
                        <TableRow key={r.key} row={r}>
                          <TableCell>{r.cells[0].value}</TableCell>
                          <TableRowActionsCell>
                            {r.cells[1].value}
                          </TableRowActionsCell>
                        </TableRow>
                      </Fragment>
                    ))}
                  </>
                )}
              </TableBody>
            </Table>
          )}
        {!showSelectVisit && selectedVisit && (
          <fieldset disabled={starting}>
            {selectedVisit && (
              <>
                <div className="row">
                  <div className="col">
                    <Control
                      labelText={t("visitForm")}
                      errors={getValidationErrorMessages(
                        validationErrors,
                        "selectedVisitFormId"
                      )}
                    >
                      <Select
                        onChange={(selectedVisitFormId) =>
                          setSelectedVisitFormId(selectedVisitFormId)
                        }
                        options={availableVisitFormsList}
                        value={selectedVisitFormId}
                        placeholder={t("selectVisitForm")}
                        getOptionKey={(x) => x.id}
                        getOptionValue={(x) => x.id}
                        getOptionText={(x) => x.title}
                      />
                    </Control>
                  </div>
                </div>
                {isNewCase && (
                  <NewCaseParameters
                    value={newCaseParameters ?? {}}
                    onChange={(newCaseInfo) =>
                      setNewCaseParameters(newCaseInfo)
                    }
                    validationErrors={validationErrors}
                    selectedDiary={selectedDiary}
                    setSelectedDiary={setSelectedDiary}
                    selectedDiaryValidationErrors={
                      selectedDiaryValidationErrors
                    }
                    diaryPlanOptions={diaryPlanOptions}
                    getSelectedDiaryId={getSelectedDiaryId}
                  />
                )}
              </>
            )}
          </fieldset>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          kind="secondary"
          onClick={onClose}
          icon={faTimes}
          disabled={starting}
        >
          {t("cancel")}
        </Button>
        {showStartVisitButton && (
          <Button
            kind="primary"
            onClick={start}
            icon={starting ? faSpinnerThird : faCheck}
            disabled={!canStartVisit || starting}
            iconSpin={starting}
            className="ml-2"
          >
            {t("startVisit")}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
}

export function CreateNewVisitButton({
  isNewCase,
  caseNumber,
  possibleVisits,
  onClick,
}) {
  const t = useAppTranslation();
  const [showing, setShowing] = useState(false);

  return (
    <>
      <Button
        kind="ghost"
        icon={faArrowRight}
        tooltipText={t("startVisit")}
        size="sm"
        onClick={() => {
          if (onClick) {
            onClick();
          }
          setShowing(true);
        }}
      />
      {showing && (
        <CreateNewVisitModal
          isNewCase={isNewCase}
          caseNumber={caseNumber}
          possibleVisits={possibleVisits}
          onClose={() => setShowing(false)}
        />
      )}
    </>
  );
}
