import {
  faCheck,
  faEdit,
  faPlus,
  faTimes,
} from "@fortawesome/pro-regular-svg-icons";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {
  getValidationErrorMessages,
  getValidationErrors,
  Validation,
} from "@sokigo-sbwebb/validation";
import {
  Button,
  Control,
  DatePicker,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Select,
  TextBox,
} from "@sokigo/components-react-bootstrap";
import moment from "moment";
import {useMemo, useState} from "react";
import {v4 as uuid} from "uuid";
import {InspectionAssessment} from "../../../../Common/InspectionAssessment";
import {useAvailableFoodControlAreas} from "../../../foodInspectionControl/useAvailableFoodControlAreas";
import {useCurrentVisit} from "../../../InspectionVisitRoute";
import {useCompletingInformationProvider} from "../CompletingInformationTab";
import {ActionPenaltyTypes} from "./ActionPenaltyTypes";
import validationSchema from "./ActionSectionSchema.json";

function ActionsModal({onClose, onChange, value, selectedId}) {
  const t = useAppTranslation();
  const isEditMode = selectedId ? true : false;
  const [localValue, setLocalValue] = useState(
    selectedId
      ? {...value.foodInspectionActionsModel.find((x) => x.id === selectedId)}
      : {
          id: uuid(),
          penaltyType: ActionPenaltyTypes.NoPenalty,
          decisionDate: moment().format("YYYY-MM-DD"),
        }
  );
  const {
    visit: {
      createdDate,
      baseDataSnapshot: {availableFoodInspectionActions},
      input: {foodControlQuestionAssessmentByQuestionId},
    },
  } = useCurrentVisit();
  const availableAreas = useAvailableFoodControlAreas();

  const notOkFoodControlQuestionAssessments = Object.keys(
    foodControlQuestionAssessmentByQuestionId
  ).filter((key) => {
    if (
      foodControlQuestionAssessmentByQuestionId[key] ===
      InspectionAssessment.NotAcceptable
    )
      return key;
  });

  // Only for food inspections, if there are any control points with assessment = Ej godtagbar
  const questionIds = availableAreas
    .map((area) => area.questions)
    .flat()
    .filter((question) =>
      notOkFoodControlQuestionAssessments?.some((x) => x === question.id)
    );

  const isValidControlPoints = useMemo(() => {
    if (questionIds.length === 0) {
      return true;
    } else {
      if (localValue.questionIds?.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  }, [questionIds, localValue]);

  const validation = useMemo(() => new Validation(validationSchema), []);
  const validationErrors = validation.validate(localValue, t);

  const {penaltyTypes} = useCompletingInformationProvider();

  const isValidDate = (current) => {
    // Date must be >= date of inspection and <= today's date.
    return (
      moment(createdDate).isSameOrBefore(current, "day") &&
      moment(current).isSameOrBefore(moment(), "day")
    );
  };

  return (
    <Modal size="xl" static show={true} onClose={onClose}>
      <ModalHeader>{t("addAction")}</ModalHeader>
      <ModalBody>
        <div className="row">
          <div className="col-md-6">
            <Control
              labelText={t("action")}
              errors={getValidationErrorMessages(validationErrors, "actionId")}
            >
              <Select
                className="mb-3"
                onChange={(actionId) =>
                  setLocalValue({...localValue, actionId})
                }
                options={availableFoodInspectionActions}
                value={localValue?.actionId}
                getOptionKey={(x) => x.id}
                getOptionValue={(x) => x.id}
                getOptionText={(x) => x.name}
              />
            </Control>
          </div>
          <div className="col-md-6">
            <Control
              labelText={t("controlPoints")}
              errors={!isValidControlPoints ? ["Must select at least one"] : []}
              vertical
            >
              <Select
                options={questionIds}
                value={localValue?.questionIds}
                getOptionValue={(x) => x.id}
                getOptionText={(x) => x.title}
                getOptionKey={(x) => x.id}
                onChange={(questionIds) =>
                  setLocalValue({...localValue, questionIds})
                }
                multiple
                withCheckboxes
              />
            </Control>
          </div>
          <div className="col-md-6">
            <Control labelText={t("connectedToPenalty")}>
              <Select
                className="mb-3"
                onChange={(penaltyType) =>
                  setLocalValue({...localValue, penaltyType})
                }
                options={penaltyTypes}
                value={localValue?.penaltyType}
                getOptionKey={(x) => x.id}
                getOptionValue={(x) => x.id}
                getOptionText={(x) => x.title}
              />
            </Control>
          </div>
          <div className="col-md-6">
            {localValue.penaltyType &&
              localValue.penaltyType !== ActionPenaltyTypes.NoPenalty && (
                <Control
                  labelText={t("amount")}
                  errors={getValidationErrorMessages(
                    validationErrors,
                    "amount"
                  )}
                >
                  <TextBox
                    value={localValue?.penaltyAmount}
                    required
                    type="number"
                    name="amount"
                    min={0}
                    onChange={(penaltyAmount) =>
                      setLocalValue({...localValue, penaltyAmount})
                    }
                  />
                </Control>
              )}
          </div>
          <div className="col-md-3">
            <Control labelText={t("decisionDate")}>
              <DatePicker
                format="yyyy-MM-dd"
                invalid={!isValidDate}
                value={localValue?.decisionDate}
                onChange={(decisionDate) =>
                  setLocalValue({...localValue, decisionDate})
                }
                locale={t("locale")}
              />
            </Control>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button kind="secondary" onClick={onClose} icon={faTimes}>
          {t("cancel")}
        </Button>
        <Button
          onClick={() => {
            const actions = isEditMode
              ? value?.foodInspectionActionsModel?.map((x) =>
                  x.id === selectedId ? {...localValue} : x
                )
              : value?.foodInspectionActionsModel
              ? [...value.foodInspectionActionsModel, localValue]
              : [localValue];

            onChange({
              ...value,
              foodInspectionActionsModel: actions,
            });
            onClose();
          }}
          icon={faCheck}
          kind="primary"
          className="ml-2"
          disabled={
            getValidationErrors(validationErrors).length > 0 ||
            !isValidControlPoints
          }
        >
          {t("ok")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export function ActionsModalButton({onChange, value, selectedId}) {
  const [isOpen, setIsOpen] = useState(false);
  const t = useAppTranslation();

  return (
    <>
      {selectedId ? (
        <Button
          size="sm"
          kind="danger-hover-ghost"
          icon={faEdit}
          onClick={() => setIsOpen(true)}
          tooltipText={t("edit")}
        />
      ) : (
        <div className="mr-3">
          <Button icon={faPlus} onClick={() => setIsOpen(true)} kind="primary">
            {t("addAction")}
          </Button>
        </div>
      )}
      {isOpen && (
        <ActionsModal
          onChange={onChange}
          onClose={() => setIsOpen(false)}
          value={value}
          selectedId={selectedId}
        />
      )}
    </>
  );
}
