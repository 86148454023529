// import icon from "./diamond-solid.png";
import settings from "./EcosSettingsSchema.json";
import settingsi18n from "./settingsi18n.json";

export default {
  name: "sokigo-ecos-webapps-settings-service",
  title: "Ecos Settings",
  // icon,
  settings: {
    schema: settings,
    group: "Ecos",
    translations: settingsi18n,
  },
};
