import {faCheck, faTimes} from "@fortawesome/pro-regular-svg-icons";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@sokigo/components-react-bootstrap";
import {useState} from "react";
import {v4 as uuid} from "uuid";
import {TextBoxControl} from "../../../../../common-components/TextBoxControl";
import {isValidEmailAddress} from "./isValidEmailAddress";
import {RolePicker} from "./RolePicker";

export function EditCustomPresentPartyModal({
  onClose,
  onSave,
  defaultValue,
  title,
}) {
  const t = useAppTranslation();
  const [saving, setSaving] = useState(false);

  const [value, setValue] = useState(
    () =>
      defaultValue ?? {
        id: uuid(),
        protocolRoleId: null,
        protocolName: "",
        emailAddressForDispatch: "",
      }
  );

  async function save() {
    setSaving(true);
    await onSave(value);
    setSaving(false);
  }

  const {protocolRoleId, nameForProtocol, emailAddressForDispatch} = value;
  const emailAddressValid =
    !emailAddressForDispatch || isValidEmailAddress(emailAddressForDispatch);
  const canSave = protocolRoleId && emailAddressValid;
  return (
    <Modal show onClose={onClose} static>
      <fieldset disabled={saving}>
        <ModalHeader onClose={onClose}>{title}</ModalHeader>
        <ModalBody>
          <TextBoxControl
            label={t("nameForProtocol")}
            value={nameForProtocol}
            onChange={(nameForProtocol) =>
              setValue((v) => ({...v, nameForProtocol}))
            }
            placeholder={t("nameForProtocol")}
          />
          <TextBoxControl
            label={t("emailAddressForDispatch")}
            value={emailAddressForDispatch}
            onChange={(emailAddressForDispatch) =>
              setValue((v) => ({...v, emailAddressForDispatch}))
            }
            placeholder={t("emailAddressForDispatch")}
            errors={!emailAddressValid ? [t("invalidEmailAddress")] : []}
          />
          <RolePicker
            label={t("roleForProtocol")}
            value={protocolRoleId}
            onChange={(protocolRoleId) =>
              setValue((v) => ({...v, protocolRoleId}))
            }
            errors={protocolRoleId ? [] : [t("mandatory")]}
          />
        </ModalBody>
        <ModalFooter>
          <Button icon={faTimes} onClick={onClose} className="w-25">
            {t("cancel")}
          </Button>
          <Button
            kind="primary"
            icon={faCheck}
            disabled={!canSave}
            onClick={save}
            className="ml-2 w-25"
          >
            {t("ok")}
          </Button>
        </ModalFooter>
      </fieldset>
    </Modal>
  );
}
