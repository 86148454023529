import {useCallback} from "react";
import {
  useCurrentVisitInput,
  useMergeCurrentVisitInput,
} from "../InspectionVisitRoute";

export function useRemoveFoodControlSupportQuestion() {
  const {foodControlSupportQuestionsByFoodControlQuestionId} =
    useCurrentVisitInput();
  const merge = useMergeCurrentVisitInput();

  return useCallback(
    (questionId, supportQuestionId) => {
      merge({
        foodControlSupportQuestionsByFoodControlQuestionId: {
          ...(foodControlSupportQuestionsByFoodControlQuestionId ?? {}),
          [questionId]: foodControlSupportQuestionsByFoodControlQuestionId?.[
            questionId
          ].filter((x) => x.id !== supportQuestionId),
        },
      });
    },
    [foodControlSupportQuestionsByFoodControlQuestionId, merge]
  );
}
