import {useMemo} from "react";
import {useServiceWorkerRepository} from "../useServiceWorkerRepository";

export function useControlAreaGroupsRepository() {
  const {items, updateItem, deleteItem, createItem} =
    useServiceWorkerRepository("control-area-groups");

  const v = useMemo(
    () => ({
      controlAreaGroups: items,
      updateControlAreaGroup: updateItem,
      removeControlAreaGroup: deleteItem,
      addControlAreaGroup: createItem,
    }),
    [items, updateItem, deleteItem, createItem]
  );

  return v;
}
