import {
  faCheck,
  faPause,
  faSignalSlash,
} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Spinner, Tooltip, Error} from "@sokigo/components-react-bootstrap";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {useIsOnline} from "@sokigo-sbwebb/serviceworker";

export function SyncStatusIndicator({visitWithStatus}) {
  const t = useAppTranslation();
  const online = useIsOnline();

  if (!online) {
    return (
      <Tooltip text={t("waitingForConnection")}>
        <div className="d-inline-block">
          <FontAwesomeIcon icon={faSignalSlash} fixedWidth />
        </div>
      </Tooltip>
    );
  }

  if (visitWithStatus.uploadInProgress) {
    return (
      <Tooltip text={t("syncing")}>
        <div className="d-inline-block">
          <Spinner small inline />
        </div>
      </Tooltip>
    );
  }

  if (visitWithStatus.previousUploadFailed) {
    return (
      <Tooltip text={t("couldNotSync")}>
        <div className="d-inline-block">
          <Error warning className="ml-1" />
        </div>
      </Tooltip>
    );
  }

  if (visitWithStatus.previousUploadStartTime < visitWithStatus.changeDate) {
    return (
      <Tooltip text={t("queued")}>
        <div className="d-inline-block">
          <FontAwesomeIcon icon={faPause} className="text-info" fixedWidth />
        </div>
      </Tooltip>
    );
  }

  return (
    <Tooltip text={t("finished")}>
      <div className="d-inline-block">
        <FontAwesomeIcon
          icon={faCheck}
          className="text-success-alt"
          fixedWidth
        />
      </div>
    </Tooltip>
  );
}
