import {CompletingInformationProvider} from "../finish/CompletingInformation/CompletingInformationTab";
import {TimeRegistration} from "../finish/CompletingInformation/Sections/TimeRegistration";
import {ControlOccasionHeader} from "./ControlOccasionHeader";

export function ControlOccasionTab() {
  return (
    <>
      <CompletingInformationProvider>
        <ControlOccasionHeader />
        <TimeRegistration />
      </CompletingInformationProvider>
    </>
  );
}
