import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {useMemo} from "react";
import {
  ButtonSwitch,
  ButtonSwitchOption,
  Control,
  Select,
  TextArea,
  TextBox,
} from "@sokigo/components-react-bootstrap";
import {getValidationErrorMessages} from "@sokigo-sbwebb/validation";
import {
  useCurrentVisit,
  useCurrentVisitInput,
} from "../../../InspectionVisitRoute";
import {InputWithTags} from "../InputWithTags/InputWithTags";
import {uniqBy} from "lodash-es";

function PresentPartiesSelect({label, onChange}) {
  const {
    presentParties,
    finishVisitInfo: {emailInfo},
  } = useCurrentVisitInput();
  const {
    visit: {
      visitMetadata: {
        visitTargetInformation: {casePartyRoles},
      },
    },
  } = useCurrentVisit();

  const partiesWithEmailForDispatch =
    presentParties?.filter(
      (party) =>
        party.emailAddressForDispatch || party.contactDetailIdForDispatch
    ) ?? [];

  return (
    <Control labelText={label} horizontal>
      <Select
        multiple
        withCheckboxes
        options={partiesWithEmailForDispatch}
        value={emailInfo.sendToPresentPartyIds}
        getOptionValue={(x) => x.id}
        getOptionText={(x) =>
          x.emailAddressForDispatch ??
          casePartyRoles
            .find(
              (cpr) =>
                cpr.partyId === x.partyId && cpr.contactId === x.contactId
            )
            .emailAddresses.find(
              (ea) => ea.contactDetailId === x.contactDetailIdForDispatch
            ).emailAddress
        }
        getOptionKey={(x) => x.id}
        onChange={(sendToPresentPartyIds) =>
          onChange({
            emailInfo: {...emailInfo, sendToPresentPartyIds},
          })
        }
      />
    </Control>
  );
}

function getCompoundId({partyId, contactId, contactDetailId}) {
  return [partyId, contactId ?? "null", contactDetailId].join("_");
}
function RecipientsSelect({label, onChange}) {
  const {
    visit: {
      visitMetadata: {
        visitTargetInformation: {casePartyRoles = []},
      },
      input: {
        finishVisitInfo: {emailInfo},
      },
    },
  } = useCurrentVisit();

  const options = uniqBy(
    casePartyRoles
      .map(({partyId, contactId, emailAddresses, partyName, contactName}) =>
        emailAddresses?.map(({emailAddress, contactDetailId}) => {
          return {
            compoundId: getCompoundId({
              partyId,
              contactId,
              contactDetailId,
            }),
            partyId,
            contactId,
            contactDetailId,
            label: `${partyName} - ${
              contactName ? contactName + " - " : ""
            } ${emailAddress}`,
          };
        })
      )
      .flat(),
    (x) => x.compoundId
  );

  const {sendToCasePartyContactDetails} = emailInfo;
  const value = useMemo(() => {
    return sendToCasePartyContactDetails.map(getCompoundId);
  }, [sendToCasePartyContactDetails]);

  const changeSelectedRecipients = (compoundIds) => {
    const sendToCasePartyContactDetails = compoundIds.map((compoundId) => {
      const {partyId, contactId, contactDetailId} = options.find(
        (o) => o.compoundId === compoundId
      );
      return {partyId, contactId, contactDetailId};
    });
    onChange({emailInfo: {...emailInfo, sendToCasePartyContactDetails}});
  };

  return (
    <Control labelText={label} horizontal>
      <Select
        multiple
        withCheckboxes
        options={options}
        value={value}
        getOptionValue={(x) => x.compoundId}
        getOptionText={(x) => x.label}
        getOptionKey={(x) => x.compoundId}
        onChange={changeSelectedRecipients}
      />
    </Control>
  );
}

function AdditionalEmailsTextBox({pendingAdditionalEmailAddressIsInvalid}) {
  const t = useAppTranslation();

  return (
    <Control
      errors={
        pendingAdditionalEmailAddressIsInvalid
          ? [t("inputContainsInvalidEmailAddress")]
          : []
      }
      labelText={t("additionalEmailAddresses")}
      horizontal
    >
      <div className="d-flex flex-column">
        <InputWithTags />
        <div
          style={{
            fontSize: "0.75rem",
            color: "var(--sds-text-04)",
            marginTop: "0.25rem",
          }}
        >
          {t("emailInfoText")}
        </div>
      </div>
    </Control>
  );
}

function EmailContent({
  subjectLabel,
  messageLabel,
  onChange,
  validationErrors,
}) {
  const {
    finishVisitInfo: {emailInfo},
  } = useCurrentVisitInput();

  return (
    <>
      <Control
        horizontal
        labelText={subjectLabel}
        errors={getValidationErrorMessages(validationErrors, "subject")}
      >
        <TextBox
          value={emailInfo.subject}
          onChange={(value) => {
            onChange({emailInfo: {...emailInfo, subject: value}});
          }}
        />
      </Control>
      <Control
        horizontal
        labelText={messageLabel}
        errors={getValidationErrorMessages(validationErrors, "body")}
      >
        <TextArea
          value={emailInfo.body}
          onChange={(value) =>
            onChange({emailInfo: {...emailInfo, body: value}})
          }
          rows={7}
        />
      </Control>
    </>
  );
}

function IncludeAttachmentsSwitch({
  label,
  optionLabels,
  onChange,
  validationErrors,
}) {
  const {
    finishVisitInfo: {emailInfo},
  } = useCurrentVisitInput();
  return (
    <Control
      horizontal
      labelText={label}
      error={getValidationErrorMessages(validationErrors, "includeAttachments")}
    >
      <ButtonSwitch
        value={emailInfo.includeAttachments}
        onChange={(value) =>
          onChange({emailInfo: {...emailInfo, includeAttachments: value}})
        }
      >
        <ButtonSwitchOption value={true}>{optionLabels.yes}</ButtonSwitchOption>
        <ButtonSwitchOption value={false}>{optionLabels.no}</ButtonSwitchOption>
      </ButtonSwitch>
    </Control>
  );
}

function SendFromSelect({label, onChange, validationErrors}) {
  const {
    visit: {
      baseDataSnapshot: {emailAccounts = []},
      input: {
        finishVisitInfo: {emailInfo},
      },
    },
  } = useCurrentVisit();

  return (
    <Control
      horizontal
      labelText={label}
      errors={getValidationErrorMessages(
        validationErrors,
        "sendFromEmailAccountId"
      )}
    >
      <Select
        options={emailAccounts}
        value={emailInfo.sendFromEmailAccountId}
        getOptionValue={(x) => x.id}
        getOptionText={(x) => x.title}
        onChange={(sendFromEmailAccountId) =>
          onChange({
            emailInfo: {
              ...emailInfo,
              sendFromEmailAccountId,
            },
          })
        }
      />
    </Control>
  );
}

function RecipientsValidationWrapper({children, noEmailAddressesSpecified}) {
  const t = useAppTranslation();
  // TODO TODOBAS
  const wrapperClassNames = noEmailAddressesSpecified
    ? "p-3  border border-danger rounded"
    : "mb-7";

  return (
    <>
      <div className={wrapperClassNames}>{children}</div>
      {noEmailAddressesSpecified && (
        <div className="mb-7">
          <strong className="text-danger">{t("oneOfRecipientRequired")}</strong>
        </div>
      )}
    </>
  );
}

export function EmailSection({
  onChange,
  validationErrors,
  noEmailAddressesSpecified,
  pendingAdditionalEmailAddressIsInvalid,
}) {
  const t = useAppTranslation();
  return (
    <div>
      <h3 className="mb-3">{t("email")}</h3>
      <RecipientsValidationWrapper
        noEmailAddressesSpecified={noEmailAddressesSpecified}
      >
        <PresentPartiesSelect label={t("presentParties")} onChange={onChange} />
        <RecipientsSelect label={t("recipients")} onChange={onChange} />
        <AdditionalEmailsTextBox
          pendingAdditionalEmailAddressIsInvalid={
            pendingAdditionalEmailAddressIsInvalid
          }
        />
      </RecipientsValidationWrapper>
      <EmailContent
        subjectLabel={t("emailSubject")}
        messageLabel={t("message")}
        onChange={onChange}
        validationErrors={validationErrors}
      />
      <IncludeAttachmentsSwitch
        label={t("includeAttachments")}
        optionLabels={{yes: t("yes"), no: t("no")}}
        onChange={onChange}
        validationErrors={validationErrors}
      />
      <SendFromSelect
        label={t("sendFrom")}
        onChange={onChange}
        validationErrors={validationErrors}
      />
    </div>
  );
}
