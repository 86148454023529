import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {
  Table,
  TableBody,
  TableCell,
  TableExpandedRow,
  TableExpandRowCell,
  TableHead,
  TableHeader,
  TableHeaderRow,
  TableRow,
} from "@sokigo/components-react-bootstrap";
import moment from "moment";
import {Fragment} from "react";
import {FillTableCellWithTruncatedText} from "../../../../../../common-components/FillTableCellWithTruncatedText";
import {DocumentsTable} from "../documents/DocumentsTable";
import {Assessment, getAssessment} from "./Assessment";
import styles from "./HistoricVisit.module.scss";

export function FoodInspectionInformation({visit}) {
  const t = useAppTranslation();
  return (
    <div className="d-flex flex-column p-4">
      <h5>{t("documents")}</h5>
      {visit.documents.length === 0 && <div>{t("noDocuments")}</div>}
      {visit.documents.length > 0 && (
        <DocumentsTable documents={visit.documents} size="sm" />
      )}
      <FoodInspectionResult
        result={visit.foodInspectionResult}
        note={visit.note}
      />
    </div>
  );
}

function FoodInspectionResult({
  result: {inspectionItems, actionRequirements, totalAssessment},
  note,
}) {
  const t = useAppTranslation();

  return (
    <>
      <h5 className="pt-4">{t("inspectionResult")}</h5>
      {inspectionItems.length === 0 && <div>{t("noControlAreas")}</div>}
      {inspectionItems.length > 0 && (
        <FoodInspectionTable inspectionItems={inspectionItems} />
      )}
      <h5 className="pt-4">{t("assessment")}</h5>
      <div className="d-flex flex-column">
        <Assessment assessment={totalAssessment} />
        <div style={{marginTop: "8px", whiteSpace: "pre-wrap"}}>{note}</div>
      </div>
      <h5 className="pt-4">{t("actions")}</h5>
      {actionRequirements.length === 0 && <div>{t("noActions")}</div>}
      {actionRequirements.length > 0 && (
        <FoodInspectionActionsTable inspectionActions={actionRequirements} />
      )}
    </>
  );
}

function FoodInspectionTable({inspectionItems}) {
  const t = useAppTranslation();

  const columns = [
    {
      key: "title",
      renderCell: ({row}) => (
        <FillTableCellWithTruncatedText>{`${row.areaNumber}. ${row.areaTitle}`}</FillTableCellWithTruncatedText>
      ),
      header: t("legislativeArea"),
    },
    {
      key: "question",
      renderCell: ({row}) => (
        <FillTableCellWithTruncatedText>{`${row.questionNumber} ${row.questionTitle}`}</FillTableCellWithTruncatedText>
      ),
      header: t("controlPointQuestion"),
    },
    {
      key: "assessment",
      renderCell: ({row}) => getAssessment(row.assessment, t),
      header: t("assessment"),
    },
    {
      key: "note",
      renderCell: ({row}) => (
        <FillTableCellWithTruncatedText>
          {row.note}
        </FillTableCellWithTruncatedText>
      ),
      header: t("note"),
    },
  ];

  return (
    <Table
      rows={inspectionItems}
      columns={columns}
      getRowKey={(x) => x.rowId}
      size="sm"
    >
      <TableHead>
        <TableHeaderRow>
          {(columns) => (
            <>
              <TableHeader />
              {columns.map((x) => (
                <TableHeader key={x.key} column={x}>
                  {x.header}
                </TableHeader>
              ))}
            </>
          )}
        </TableHeaderRow>
      </TableHead>
      <TableBody>
        {(rows) =>
          rows.map((r) => (
            <Fragment key={r.key}>
              <TableRow row={r}>
                <TableExpandRowCell single={true} disabled={!r.row.note} />
                {r.cells.map((c) => (
                  <TableCell key={c.key}>{c.value}</TableCell>
                ))}
              </TableRow>
              <TableExpandedRow row={r}>
                <div className={"p-2 " + styles.note}>{r.row.note}</div>
              </TableExpandedRow>
            </Fragment>
          ))
        }
      </TableBody>
    </Table>
  );
}

function FoodInspectionActionsTable({inspectionActions}) {
  const t = useAppTranslation();

  const columns = [
    {
      key: "action",
      field: "action",
      header: t("action"),
    },
    {
      key: "controlPoints",
      renderCell: ({row}) => row.questionNumbers.join(", "),
      header: t("controlPointQuestion"),
    },
    {
      key: "penalty",
      field: "penalty",
      header: t("penalty"),
    },
    {
      key: "decisionDate",
      renderCell: ({row}) =>
        row.decisionDate && moment(row.decisionDate).format("YYYY-MM-DD"),
      header: t("decisionDate"),
    },
  ];

  return (
    <Table
      rows={inspectionActions}
      columns={columns}
      getRowKey={(x) => x.rowId}
      size="sm"
    >
      <TableHead>
        <TableHeaderRow>
          {(columns) => (
            <>
              {columns.map((x) => (
                <TableHeader key={x.key} column={x}>
                  {x.header}
                </TableHeader>
              ))}
            </>
          )}
        </TableHeaderRow>
      </TableHead>
      <TableBody>
        {(rows) =>
          rows.map((r) => (
            <Fragment key={r.key}>
              <TableRow row={r}>
                {r.cells.map((c) => (
                  <TableCell key={c.key}>{c.value}</TableCell>
                ))}
              </TableRow>
            </Fragment>
          ))
        }
      </TableBody>
    </Table>
  );
}
