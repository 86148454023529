import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {
  Table,
  TableBody,
  TableCell,
  TableExpandedRow,
  TableExpandHeader,
  TableExpandRowCell,
  TableHead,
  TableHeader,
  TableHeaderRow,
  TableRow,
} from "@sokigo/components-react-bootstrap";
import {Fragment} from "react";
import {ContactDetailsTable} from "./ContactDetailsTable";

export function CasePartiesTable({casePartyRoles, tableExpandHeader, single}) {
  const t = useAppTranslation();
  const columns = [
    {
      key: "partyName",
      field: "partyName",
      header: t("name"),
    },
    {
      key: "nationalIdentityNumber",
      field: "nationalIdentityNumber",
      header: t("nationalIdentityNumberShort"),
    },
    {
      key: "roleName",
      field: "roleName",
      header: t("role"),
    },
    {
      key: "contactName",
      field: "contactName",
      header: t("contact"),
    },
  ];

  return (
    <Table
      rows={casePartyRoles}
      columns={columns}
      getRowKey={(x) => [x.partyId, x.contactId ?? "null", x.roleId].join("_")}
    >
      <TableHead>
        <TableHeaderRow>
          {(columns) => (
            <>
              {tableExpandHeader && !single ? (
                <TableExpandHeader />
              ) : (
                <TableHeader />
              )}
              {columns.map((x) => (
                <TableHeader key={x.key} column={x}>
                  {x.header}
                </TableHeader>
              ))}
            </>
          )}
        </TableHeaderRow>
      </TableHead>
      <TableBody>
        {(rows) =>
          rows.map((r) => (
            <Fragment key={r.key}>
              <TableRow row={r}>
                <TableExpandRowCell single={single} />
                {r.cells.map((c) => (
                  <TableCell key={c.key}>{c.value}</TableCell>
                ))}
              </TableRow>
              <TableExpandedRow row={r}>
                <ContactDetailsTable
                  emailAddresses={r.row.emailAddresses}
                  phoneNumbers={r.row.phoneNumbers}
                />
              </TableExpandedRow>
            </Fragment>
          ))
        }
      </TableBody>
    </Table>
  );
}
