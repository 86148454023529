import {useState} from "react";

// given a list of options, provides utility methods for toggling selection of one or all options
// TODO might want to use useEffect to keep track of the list of options maybe ‍
const useToggle = (options, defaultValue) => {
  const [selected, setSelected] = useState(defaultValue || []);
  const toggle = (id) =>
    setSelected((cur) =>
      cur.includes(id) ? cur.filter((x) => x !== id) : [...cur, id]
    );
  const toggleAll = () =>
    setSelected((cur) =>
      cur.length === options.length ? [] : options.slice(0)
    );
  const clear = () => setSelected([]);
  return [selected, toggle, toggleAll, clear];
};

export default useToggle;
