import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {Panel} from "@sokigo/components-react-bootstrap";
import {useCurrentVisit} from "../../InspectionVisitRoute";
import {DocumentsTable} from "./DocumentsTable";

export function Documents() {
  const t = useAppTranslation();
  const {
    visit: {
      visitMetadata: {
        visitTargetInformation: {caseDocuments},
      },
    },
  } = useCurrentVisit();

  return (
    <Panel className="mt-3 py-3">
      <h4 className="px-3 mb-3">{t("documents")}</h4>
      {caseDocuments?.length === 0 && (
        <div className="px-3">{t("caseHasNoDocuments")}</div>
      )}
      {caseDocuments?.length > 0 && (
        <DocumentsTable documents={caseDocuments} />
      )}
    </Panel>
  );
}
