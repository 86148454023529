import {faEdit} from "@fortawesome/pro-regular-svg-icons";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {Button} from "@sokigo/components-react-bootstrap";
import {useState} from "react";
import {
  useCurrentVisit,
  useCurrentVisitInput,
  useMergeCurrentVisitInput,
} from "../InspectionVisitRoute";
import {EditCasePartyRolePresentPartyModal} from "./EditCasePartyRolePresentPartyModal";
import {EditCustomPresentPartyModal} from "./EditCustomPresentPartyModal";

export function EditPresentPartyButton({presentParty}) {
  const t = useAppTranslation();
  const {
    visit: {
      visitMetadata: {
        visitTargetInformation: {casePartyRoles},
      },
    },
  } = useCurrentVisit();
  const [showing, setShowing] = useState(false);

  const {presentParties, finishVisitInfo} = useCurrentVisitInput();
  const merge = useMergeCurrentVisitInput();
  const casePartyRole = casePartyRoles.find(
    (x) =>
      x.partyId === presentParty.partyId &&
      x.contactId === presentParty.contactId
  );

  function onSave(editedParty) {
    merge({
      presentParties: presentParties.map((x) =>
        x.id === presentParty.id ? editedParty : x
      ),
      finishVisitInfo:
        editedParty.emailAddressForDispatch ?? editedParty.contactDetailId
          ? finishVisitInfo
          : {
              ...finishVisitInfo,
              emailInfo: {
                ...finishVisitInfo.emailInfo,
                // if email was removed from party, remove party from list of recipients as well
                sendToPresentPartyIds:
                  finishVisitInfo.emailInfo.sendToPresentPartyIds?.filter(
                    (x) => x !== editedParty.id
                  ) ?? [],
              },
            },
    });
    setShowing(false);
  }

  return (
    <>
      {showing && (
        <>
          {casePartyRole ? (
            <EditCasePartyRolePresentPartyModal
              onSave={onSave}
              onClose={() => setShowing(false)}
              title={t("editPresentParty")}
              defaultValue={presentParty}
              casePartyRole={casePartyRole}
            />
          ) : (
            <EditCustomPresentPartyModal
              onSave={onSave}
              onClose={() => setShowing(false)}
              title={t("editPresentParty")}
              defaultValue={presentParty}
            />
          )}
        </>
      )}
      <Button
        size="sm"
        kind="ghost"
        icon={faEdit}
        onClick={() => setShowing(true)}
        tooltipText={t("edit")}
      />
    </>
  );
}
