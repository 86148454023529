import {faPlus} from "@fortawesome/pro-regular-svg-icons";
import {useNotifications} from "@sokigo-sbwebb/default-core";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {postJson} from "@sokigo-sbwebb/fetch";
import {Panel, Button} from "@sokigo/components-react-bootstrap";
import {useState} from "react";
import {SearchForm} from "./Form/SearchForm";
import {SearchType} from "./Form/SearchType";
import {SearchResults} from "./Results/SearchResults";

export function HomeSearch() {
  const t = useAppTranslation();
  const {addErrorNotification} = useNotifications();
  const [searching, setSearching] = useState(false);
  const [results, setResults] = useState(null);
  async function search(value) {
    setResults(null);
    setSearching(true);
    try {
      let r;
      switch (value.searchType) {
        case SearchType.Estate:
          r = await postJson("api/inspection/search/estate", {
            caseWorkerSelection: value.caseWorkerSelection,
            specificCaseWorkerId: value.specificCaseWorkerId,
            ...value.estateSearch,
          });
          break;
        case SearchType.Case:
          r = await postJson("api/inspection/search/case", {
            caseWorkerSelection: value.caseWorkerSelection,
            specificCaseWorkerId: value.specificCaseWorkerId,
            ...value.caseSearch,
          });
          break;
        default:
          throw new Error("Unknown search type: " + value.searchType);
      }
      setResults({searchType: value.searchType, results: r});
    } catch (err) {
      addErrorNotification(t("searchFailed"));
      throw err;
    } finally {
      setSearching(false);
    }
  }

  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <Panel className="mb-3 p-3" highlightColor="#1292c4">
        <div className="d-flex align-items-center">
          <h3 className="flex-grow-1">{t("newVisit")}</h3>
          {!expanded && (
            <div>
              <Button
                kind="primary"
                icon={faPlus}
                onClick={() => setExpanded(true)}
              >
                {t("prepareNewVisit")}
              </Button>
            </div>
          )}
        </div>
        {expanded && (
          <div className="mt-3">
            <SearchForm
              disabled={searching}
              searching={searching}
              onSearch={search}
            />
            {results && (
              <SearchResults
                searchType={results.searchType}
                results={results.results}
              />
            )}
          </div>
        )}
      </Panel>
      <hr />
    </>
  );
}
