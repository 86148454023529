import {createContext, useEffect, useState, useContext} from "react";
import {useUniqueId} from "../Misc/useUniqueId";
import "ol/ol.css";
import Map from "ol/Map";
import View from "ol/View";

function useTrackExtent(map, extent) {
  // track the extent values, not the array
  const minX = extent?.[0];
  const minY = extent?.[1];
  const maxX = extent?.[2];
  const maxY = extent?.[3];
  useEffect(() => {
    if (minX === null || minX === undefined) return;
    if (!map) return;
    map.getView().fit([minX, minY, maxX, maxY]);
  }, [map, minX, minY, maxX, maxY]);
}

const MapContext = createContext();
const MapContainerIdContext = createContext();
export const MapProvider = ({
  children,
  center,
  initialCenter,
  initialExtent,
  initialZoom,
  viewProjection,
  extent,
}) => {
  const [map, setMap] = useState(null);
  const id = useUniqueId("olMap");

  const [savedInitialCenter] = useState(initialCenter);
  const [savedInitialZoom] = useState(initialZoom);
  const [savedInitialExtent] = useState(initialExtent);

  useEffect(() => {
    if (!map || !center) {
      return;
    }
    map.getView().setCenter(center);
  }, [center, map, initialZoom]);

  useEffect(() => {
    const map = new Map({
      layers: [],
      target: id,
      view: new View({
        projection: viewProjection,
        center: savedInitialCenter || [0, 0],
        zoom: savedInitialZoom || 10,
      }),
    });

    if (savedInitialExtent) {
      map.getView().fit(savedInitialExtent);
      map.getView().setZoom(savedInitialZoom);
    }

    setMap(map);

    return () => {
      map.setTarget(null); // cleanup ol map
      map.dispose();
      setMap(null);
    };
  }, [
    id,
    savedInitialCenter,
    savedInitialExtent,
    savedInitialZoom,
    viewProjection,
  ]);

  useTrackExtent(map, extent);

  return (
    <MapContext.Provider value={map}>
      <MapContainerIdContext.Provider value={id}>
        {children}
      </MapContainerIdContext.Provider>
    </MapContext.Provider>
  );
};

export const useMap = () => {
  return useContext(MapContext);
};

export const useMapContainerId = () => {
  return useContext(MapContainerIdContext);
};
