import {useCurrentVisitInput} from "../InspectionVisitRoute";
import {FoodInspectionControlForm} from "./FoodInspectionControlForm";
import {SelectControlAreasWithoutModal} from "./SelectControlAreasWithoutModal";

export function FoodInspectionControlTab() {
  const {foodControlQuestionsByFoodControlAreaId} = useCurrentVisitInput();
  if (
    !(Object.keys(foodControlQuestionsByFoodControlAreaId ?? {}).length > 0)
  ) {
    return <SelectControlAreasWithoutModal />;
  }

  return <FoodInspectionControlForm />;
}
