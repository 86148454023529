import {faEllipsisV, faTrashAlt} from "@fortawesome/pro-regular-svg-icons";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {getValidationErrors} from "@sokigo-sbwebb/validation";
import {
  ActionsDropdown,
  ActionsDropdownItem,
  Table,
  TableBody,
  TableCell,
  TableExpandedRow,
  TableExpandRowCell,
  TableReorderableRowCell,
  TableRow,
  TableRowActionsCell,
} from "@sokigo/components-react-bootstrap";
import {Fragment} from "react";
import {StaticParagraphSettings} from "./StaticParagraphSettings";

export function StaticParagraphsTable({
  onChange,
  staticParagraphs,
  validationErrors,
  expandedParagraphId,
  onExpand,
}) {
  const t = useAppTranslation();
  const columns = [
    {
      key: "title",
      field: "title",
    },
  ];

  const removeStaticParagraph = (id) => {
    onChange(staticParagraphs.filter((x) => x.id !== id));
  };

  return (
    <Table
      rows={staticParagraphs ?? []}
      columns={columns}
      getRowKey={(x) => x.id}
      onChange={onChange}
      className="mt-2"
      expanded={expandedParagraphId ? [expandedParagraphId] : []}
      onExpand={(ids) => onExpand(ids[0])}
    >
      <TableBody>
        {(rows) =>
          rows.map((r) => (
            <Fragment key={r.key}>
              <TableRow row={r}>
                <TableExpandRowCell single />
                <TableReorderableRowCell />
                {r.cells.map((c) => (
                  <TableCell key={c.key}>{c.value}</TableCell>
                ))}
                <TableRowActionsCell>
                  <ActionsDropdown
                    icon={faEllipsisV}
                    kind="ghost"
                    size="sm"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <ActionsDropdownItem
                      onClick={() => removeStaticParagraph(r.key)}
                      itemText={t("remove")}
                      icon={faTrashAlt}
                      danger
                    />
                  </ActionsDropdown>
                </TableRowActionsCell>
              </TableRow>
              <TableExpandedRow row={r} colSpan={columns.length + 2}>
                <StaticParagraphSettings
                  staticParagraph={r.row}
                  onChange={(updatedRow) =>
                    /* TODO: use r.onRowChange if it becomes available? */
                    onChange(
                      (staticParagraphs ?? []).map((x) =>
                        x.id === updatedRow.id ? updatedRow : x
                      )
                    )
                  }
                  validationErrors={getValidationErrors(
                    validationErrors,
                    r.rowIndex
                  )}
                />
              </TableExpandedRow>
            </Fragment>
          ))
        }
      </TableBody>
    </Table>
  );
}
