import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {
  Table,
  TableBody,
  TableCell,
  TableExpandedRow,
  TableExpandRowCell,
  TableHead,
  TableHeader,
  TableHeaderRow,
  TableRow,
} from "@sokigo/components-react-bootstrap";
import {Fragment} from "react";
import {ConnectedCases} from "./ConnectedCases";

export function ConnectedFacilities({connectedFacilities, estateRowId}) {
  const t = useAppTranslation();
  const columns = [
    {
      key: "facilityType",
      header: t("facilityType"),
      renderCell: ({row}) => row.facilityType,
    },
    {
      key: "facilityCollectionNameOrFacilityName",
      header: t("facilityCollectionNameOrFacilityName"),
      renderCell: ({row}) =>
        row.facilityCollectionName ?? row.facilityName ?? "",
    },
    {
      key: "operator",
      header: t("operator"),
      renderCell: ({row}) => row.operatorName,
    },
    {
      key: "address",
      header: t("address"),
      renderCell: ({row}) => row.address,
    },
  ];
  const connectedFacilitiesRows = connectedFacilities.map((x, i) => ({
    ...x,
    rowId: i,
  }));
  return (
    <>
      <Table
        className="my-2"
        rows={connectedFacilitiesRows}
        columns={columns}
        getRowKey={(x) => x.rowId}
        size="sm"
        borderless
      >
        <TableHead>
          <TableHeaderRow colSpan={columns.length + 1}>
            {(columns) => (
              <>
                <TableHeader />
                {columns.map((x) => (
                  <TableHeader key={x.key} column={x}>
                    {x.header}
                  </TableHeader>
                ))}
              </>
            )}
          </TableHeaderRow>
        </TableHead>
        <TableBody>
          {(rows) =>
            rows.map((r) => (
              <Fragment key={r.key}>
                <TableRow key={r.key} row={r}>
                  <TableExpandRowCell
                    disabled={
                      r.row.connectedCases.length === 0 &&
                      r.row.newCasePossibleVisits.length === 0
                    }
                    className="pl-1"
                  />
                  {r.cells.map((c) => (
                    <TableCell key={c.key}>{c.value}</TableCell>
                  ))}
                </TableRow>
                <TableExpandedRow row={r} indent colSpan={columns.length + 1}>
                  <ConnectedCases
                    connectedCases={r.row.connectedCases}
                    estateRowId={estateRowId}
                    facilityRowId={r.row.rowId}
                  />
                </TableExpandedRow>
              </Fragment>
            ))
          }
        </TableBody>
      </Table>
    </>
  );
}
