import {useEffect, useRef} from "react";
import {useMapContainerId} from "./MapProvider";
import {useMap} from "./MapProvider";

export const MapContainer = ({className, style, children}) => {
  const id = useMapContainerId();
  const containerRef = useRef(null);
  const map = useMap();

  useEffect(() => {
    const container = containerRef.current;
    if (!container || !ResizeObserver || !map) return;

    const resizeObserver = new ResizeObserver(() => {
      map.updateSize();
    });
    resizeObserver.observe(container);

    return () => {
      resizeObserver.unobserve(container);
    };
  }, [containerRef, map]);

  return (
    <div
      className={className}
      id={id}
      style={{
        ...style,
        position: "relative",
      }}
      ref={containerRef}
    >
      {children}
    </div>
  );
};
