import {Control, TextBox} from "@sokigo/components-react-bootstrap";

export function TextBoxControl({
  label,
  value,
  onChange,
  placeholder,
  errors,
  readOnly,
  name,
  ...props
}) {
  return (
    <Control labelText={label} errors={errors ?? []} {...props}>
      <TextBox
        placeholder={placeholder}
        value={value ?? ""}
        onChange={(value) => onChange(value)}
        readOnly={readOnly}
        name={name}
      />
    </Control>
  );
}
