import {useMemo} from "react";
import {useCurrentVisitInput, useCurrentVisit} from "../InspectionVisitRoute";
import {SaveAsType} from "../../AdminRoutes/AdminVisitForms/EditVisitForm/VisitFormEditor/SaveAsType";
import {InspectionAssessment} from "../../Common/InspectionAssessment";
import {useAvailableFoodControlAreas} from "../selectControls/useAvailableFoodControlAreas";
import moment from "moment";
import {FollowUpAssessment} from "../../Common/FollowUpAssessment";
import {ControlCause} from "../../Common/ControlCause";
import {Validation} from "@sokigo-sbwebb/validation";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import CompletingInformationSchema from "../finish/CompletingInformation/CompletingInformationSchema.json";
import FinishVisitSchema from "../finish/FinishVisit/FinishVisitSchema.json";
import DocumentTemplateSchema from "../finish/FinishVisit/DocumentTemplateSchema.json";

export function useFoodAssessmentValidation(controlId) {
  const {foodControlOccasion2024} = useCurrentVisitInput();

  return useMemo(() => {
    let controls = foodControlOccasion2024.controls ?? [];
    if (controlId !== undefined) {
      controls = controls.filter((c) => c.id === controlId);
    }
    const assessmentsByControl = controls.map((x) => ({
      controlId: x.id,
      assessedNumber: x.assessments
        ? x.assessments.filter(
            (y) => y.valid !== InspectionAssessment.NotAssessed
          ).length
        : x.followUps
        ? x.followUps.filter((y) => y.value !== FollowUpAssessment.NotAssessed)
            .length
        : 0,
    }));

    const withAssesments = assessmentsByControl.filter(
      (x) => x.assessedNumber !== 0
    );

    const isValid = withAssesments.length >= controls.length;

    return isValid;
  }, [foodControlOccasion2024, controlId]);
}

export function useFoodControlAreas24Validation(controlId) {
  const availableAreas = useAvailableFoodControlAreas();
  const {foodControlOccasion2024} = useCurrentVisitInput();

  return useMemo(() => {
    let controlsToValidate = foodControlOccasion2024.controls ?? [];
    if (controlId !== undefined) {
      controlsToValidate = controlsToValidate.filter((c) => c.id === controlId);
    }

    if (
      controlsToValidate.length > 0 &&
      controlsToValidate.every((c) => c.cause === ControlCause.FollowUp)
    ) {
      return true;
    }

    const operativeGoalsControlAreaId = "09ebf132-4ee0-4446-8cb5-0f61f5a84307";

    const controls = controlsToValidate.map((x) => ({
      controlId: x.id,
      assessedNumber: !x.assessments
        ? 0
        : x.assessments.filter(
            (y) => y.valid !== InspectionAssessment.NotAssessed
          ).length,
      assesmentByQuestionId:
        x.assessments &&
        x.assessments.map((y) => ({
          questionId: y.questionId,
          assessment: y.valid,
        })),
    }));

    const hasAnyQuestionsAssessed =
      controls && controls.filter((x) => x.assessedNumber > 0).length > 0;

    // if visit that have one or more assessed control points under area "P: Operativa mål"
    if (hasAnyQuestionsAssessed) {
      const nonOperativeGoalsQuestionIds = availableAreas
        .filter((x) => x.id !== operativeGoalsControlAreaId)
        .map((x) => x.questions)
        .flat()
        .map((x) => x.id);

      let isValid = false;
      //must have at least one assessed control point belonging to another control area.
      //("Assessed" = having an assessment value other than "Not assessed  ".)
      for (let i = 0; i < controls.length; i++) {
        for (let j = 0; j < nonOperativeGoalsQuestionIds.length; j++) {
          const assessmentValue = controls[i].assesmentByQuestionId?.filter(
            (x) => x.questionId === nonOperativeGoalsQuestionIds[j]
          );
          if (
            assessmentValue?.length > 0 &&
            assessmentValue.filter(
              (x) =>
                x.assessment.length &&
                x.assessment !== InspectionAssessment.NotAssessed
            )
          ) {
            isValid = true;
            break;
          }
        }
      }

      return isValid;
    }
    return true;
  }, [foodControlOccasion2024, availableAreas, controlId]);
}

export function useFoodObligatoryValuesValidation(controlId) {
  const {foodControlOccasion2024} = useCurrentVisitInput();

  return useMemo(() => {
    let controls = foodControlOccasion2024.controls ?? [];
    if (controlId !== undefined) {
      controls = controls.filter((c) => c.id === controlId);

      if (controls.length === 0) {
        return true;
      }
    }

    return (
      controls.length > 0 &&
      controls.every(
        (c) => c.cause && c.announced !== undefined && c.announced !== null
      )
    );
  }, [foodControlOccasion2024.controls, controlId]);
}

export function useInspectionDateValidation() {
  const {completingInformation} = useCurrentVisitInput();
  const {
    visit: {
      visitMetadata: {saveAsType},
      createdDate,
      baseDataSnapshot: {allowDatesBefore2024},
    },
  } = useCurrentVisit();

  return useMemo(() => {
    let isValid = true;
    let unallowed2023cretaion = false;

    if (completingInformation.occurrenceDate) {
      const inspectionDate = new Date(completingInformation.occurrenceDate);
      const finalEndDate = Date.now();
      const earliestAllowedDate = new Date(createdDate.slice(0, 10));
      isValid =
        saveAsType === SaveAsType.FoodInspection24
          ? moment(inspectionDate).isBetween(
              earliestAllowedDate,
              finalEndDate,
              "days",
              "[]"
            )
          : true;

      if (!allowDatesBefore2024 && inspectionDate.getFullYear() === 2023) {
        unallowed2023cretaion = true;
      }
    }
    return {minMax: isValid, unallowed2023cretaion};
  }, [saveAsType, createdDate, completingInformation, allowDatesBefore2024]);
}

export function useFoodInspection2013DateValidation() {
  const {completingInformation} = useCurrentVisitInput();
  const {
    visit: {
      visitMetadata: {saveAsType},
    },
  } = useCurrentVisit();

  return useMemo(() => {
    let unallowed2024creation = false;

    if (completingInformation.occurrenceDate) {
      const inspectionDate = new Date(completingInformation.occurrenceDate);

      if (saveAsType === SaveAsType.FoodInspection) {
        unallowed2024creation = !moment(inspectionDate).isSameOrBefore(
          "2023-12-31",
          "day"
        );
      }
    }
    return {unallowed2024creation};
  }, [saveAsType, completingInformation]);
}

export function useObligatoryBevaWhen92ControlValidation(controlId) {
  const {foodControlOccasion2024} = useCurrentVisitInput();
  const {
    visit: {
      visitMetadata: {saveAsType},
    },
  } = useCurrentVisit();

  return useMemo(() => {
    if (saveAsType !== SaveAsType.FoodInspection24) {
      return true;
    }

    let controls92 = foodControlOccasion2024?.controls?.filter(
      (x) => x.control92 === true
    );

    if (controlId) {
      controls92 = controls92?.filter((x) => x.id === controlId);
    }

    let isValid = true;
    controls92?.forEach((control) => {
      if (!control.assessments?.some((a) => a.beva)) {
        isValid = false;
      }
    });

    return isValid;
  }, [saveAsType, foodControlOccasion2024, controlId]);
}

export function useFoodFacilityTypeValidation(controlId) {
  const {
    foodControlOccasion2024,
    completingInformation: {occurrenceDate},
  } = useCurrentVisitInput();
  const {
    visit: {
      visitMetadata: {saveAsType},
      foodInspection24Options: {foodFacilityTypes},
    },
  } = useCurrentVisit();

  return useMemo(() => {
    if (saveAsType !== SaveAsType.FoodInspection24) {
      return true;
    }

    const invalidFoodFacilityTypes = foodFacilityTypes
      .filter(
        (fft) =>
          (fft.endDate && new Date(fft.endDate) < new Date(occurrenceDate)) ||
          fft.deleted
      )
      .map((f) => f.id);

    let controls = foodControlOccasion2024?.controls;
    if (controlId) {
      controls = controls?.filter((x) => x.id === controlId);
    }

    const hasInvalidFoodFacilityTypes = controls?.some((x) =>
      invalidFoodFacilityTypes.includes(x.foodFacilityTypeId)
    );

    const invalidIrregularities = foodFacilityTypes
      .flatMap((fft) => fft.irregularities)
      .filter((i) => i.notAvailable)
      .map((i) => i.id);

    const hasInvalidIrregularities = controls
      ?.flatMap((c) => c.followUps)
      .some(
        (x) =>
          x &&
          x.value !== 0 &&
          invalidIrregularities?.includes(x.irregularityId)
      );

    return !hasInvalidFoodFacilityTypes && !hasInvalidIrregularities;
  }, [
    saveAsType,
    foodControlOccasion2024,
    controlId,
    foodFacilityTypes,
    occurrenceDate,
  ]);
}

export function useControlOccasionTabHasObligatoryValuesMissing() {
  const t = useAppTranslation();
  const visitWithStatus = useCurrentVisit();
  const visitValidation = useMemo(
    () => new Validation(CompletingInformationSchema),
    []
  );
  const visitValidationErrors = visitValidation.validate(
    visitWithStatus.visit,
    t
  );
  const errors = visitValidationErrors
    .flatMap((err) => err.errors)
    .flatMap((err) => err.errors)
    .filter(
      (x) =>
        x.property === "occurrenceDate" || x.property === "municipalityCode"
    );
  return errors.length > 0;
}

export function useFinish24TabHasObligatoryValuesMissing() {
  const t = useAppTranslation();
  const {
    visit: {input},
  } = useCurrentVisit();
  const {finishVisitInfo, completingInformation} = input;
  const finishVisitInfoValidation = useMemo(
    () => new Validation(FinishVisitSchema),
    []
  );
  const templateValidation = useMemo(
    () => new Validation(DocumentTemplateSchema),
    []
  );
  const validationErrors1 = useMemo(
    () => finishVisitInfoValidation.validate(finishVisitInfo, t),
    [finishVisitInfoValidation, finishVisitInfo, t]
  );
  const validationErrors2 = useMemo(
    () => templateValidation.validate(completingInformation, t),
    [templateValidation, completingInformation, t]
  );
  return validationErrors1.length > 0 || validationErrors2.length > 0;
}
