import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {
  ButtonSwitch,
  ButtonSwitchOption,
  Control,
} from "@sokigo/components-react-bootstrap";
import {TextBoxControl} from "../../../../../../common-components/TextBoxControl";
import {FacilityTypePicker} from "./FacilityTypePicker";

const EstateOwnerSelections = {
  Any: 0,
  Organization: 1,
  Person: 2,
};

function EstateOwnerSelectionSwitch({value, onChange}) {
  const t = useAppTranslation();
  return (
    <Control labelText={t("estateOwner")}>
      <ButtonSwitch
        value={value ?? EstateOwnerSelections.Any}
        onChange={onChange}
      >
        <ButtonSwitchOption value={EstateOwnerSelections.Any}>
          {t("estateOwnerSelectionAny")}
        </ButtonSwitchOption>
        <ButtonSwitchOption value={EstateOwnerSelections.Organization}>
          {t("estateOwnerSelectionOrganization")}
        </ButtonSwitchOption>
        <ButtonSwitchOption value={EstateOwnerSelections.Person}>
          {t("estateOwnerSelectionPerson")}
        </ButtonSwitchOption>
      </ButtonSwitch>
    </Control>
  );
}

export function EstateSearchForm({value = {}, onChange}) {
  const t = useAppTranslation();
  const c = (changes) => onChange({...value, ...changes});
  return (
    <>
      <div className="row">
        <div className="col-md">
          <TextBoxControl
            label={t("estate")}
            value={value.estate}
            onChange={(estate) => c({estate})}
            placeholder={t("estate")}
            name="estatesearch-estatename"
          />
        </div>
        <div className="col-md">
          <EstateOwnerSelectionSwitch
            value={value.estateOwnerSelection}
            onChange={(estateOwnerSelection) => c({estateOwnerSelection})}
          />
        </div>
      </div>
      {value.estateOwnerSelection === EstateOwnerSelections.Person && (
        <div className="row">
          <div className="col-md">
            <TextBoxControl
              label={t("estateOwnerFirstName")}
              value={value.estateOwnerFirstName}
              onChange={(estateOwnerFirstName) => c({estateOwnerFirstName})}
              placeholder={t("estateOwnerFirstName")}
              name="estatesearch-estateowner-firstname"
            />
          </div>
          <div className="col-md">
            <TextBoxControl
              label={t("estateOwnerLastName")}
              value={value.estateOwnerLastName}
              onChange={(estateOwnerLastName) => c({estateOwnerLastName})}
              placeholder={t("estateOwnerLastName")}
              name="estatesearch-estateowner-lastname"
            />
          </div>
        </div>
      )}
      {value.estateOwnerSelection === EstateOwnerSelections.Organization && (
        <div className="row">
          <div className="col-md">
            <TextBoxControl
              label={t("estateOwnerOrganization")}
              value={value.estateOwnerOrganization}
              onChange={(estateOwnerOrganization) =>
                c({estateOwnerOrganization})
              }
              placeholder={t("estateOwnerOrganization")}
              name="estatesearch-estateowner-organization"
            />
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-md">
          <TextBoxControl
            label={t("addressStreet")}
            value={value.addressStreetName}
            onChange={(addressStreetName) => c({addressStreetName})}
            placeholder={t("addressStreet")}
            name="estatesearch-address-street"
          />
        </div>
        <div className="col-md">
          <TextBoxControl
            label={t("addressNumber")}
            value={value.addressStreetNumber}
            onChange={(addressStreetNumber) => c({addressStreetNumber})}
            placeholder={t("addressNumber")}
            name="estatesearch-address-number"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md">
          <TextBoxControl
            label={t("facilityCollectionName")}
            value={value.facilityCollectionName}
            onChange={(facilityCollectionName) => c({facilityCollectionName})}
            placeholder={t("facilityCollectionName")}
            name="estatesearch-facility-collectionname"
          />
        </div>
        <div className="col-md">
          <TextBoxControl
            label={t("facilityName")}
            value={value.facilityName}
            onChange={(facilityName) => c({facilityName})}
            placeholder={t("facilityName")}
            name="estatesearch-facility-name"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md">
          <FacilityTypePicker
            value={value.facilityTypeId}
            onChange={(facilityTypeId) => c({facilityTypeId})}
            className="mb-0"
          />
        </div>
        <div className="col-md"></div>
      </div>
    </>
  );
}
