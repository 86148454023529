import {faSync} from "@fortawesome/pro-regular-svg-icons";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {useIsOnline} from "@sokigo-sbwebb/serviceworker";
import {Button} from "@sokigo/components-react-bootstrap";
import {useState} from "react";
import {useServerState} from "../../ServerStateProvider";

export function RefreshServerVisitsButton() {
  const t = useAppTranslation();
  const {refreshServerVisits} = useServerState();
  const isOnline = useIsOnline();
  const [refreshing, setRefreshing] = useState(false);

  async function refresh() {
    setRefreshing(true);
    await refreshServerVisits();
    setRefreshing(false);
  }

  if (!isOnline) {
    return null;
  }

  return (
    <Button
      tooltipText={t("refresh")}
      icon={faSync}
      kind="ghost"
      size="sm"
      onClick={refresh}
      disabled={refreshing}
      className="ml-1"
    />
  );
}
