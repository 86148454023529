import {useSetApplicationSubTitle} from "@sokigo-sbwebb/default-core";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {useEffect} from "react";
import {useOfflineDocumentsCacheMetadata} from "../sw-comms/offline-documents-cache/useOfflineDocumentsCacheMetadata";
import {PdfViewer} from "../../../common-components/pdf-viewer/PdfViewer";

export function ShowDocumentRoute({
  match: {
    params: {documentId},
  },
}) {
  const t = useAppTranslation();
  const setApplicationSubtitle = useSetApplicationSubTitle();
  const offlineDocumentsMetadata = useOfflineDocumentsCacheMetadata();
  const doc = offlineDocumentsMetadata?.[documentId];

  const subtitle = doc
    ? t("caseDocumentApplicationSubtitle", {title: doc.filename})
    : null;
  useEffect(() => {
    setApplicationSubtitle(subtitle);
  }, [setApplicationSubtitle, subtitle]);

  if (!doc) {
    return null;
  }

  const url = document.baseURI + "cache/caseDocument/" + documentId;
  return <PdfViewer url={url} />;
}
