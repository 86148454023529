import {Panel} from "@sokigo/components-react-bootstrap";
import {ControlItemTable} from "./ControlItemTable";
import {useCurrentVisit} from "../InspectionVisitRoute";
import useWindowDimensions from "src/common-components/useWindowDimensions";
import {ControlList} from "./ControlList";

export function SelectControlsTab() {
  const {windowWidth} = useWindowDimensions();
  const {
    visit: {
      foodInspection24Options: {foodFacilityTypes},
    },
  } = useCurrentVisit();

  return (
    <>
      <Panel className="container-fluid p-3 mt-3">
        {windowWidth > 1500 ? (
          <ControlItemTable
            foodFacilityTypes={foodFacilityTypes}
          ></ControlItemTable>
        ) : (
          <ControlList foodFacilityTypes={foodFacilityTypes}></ControlList>
        )}
      </Panel>
    </>
  );
}
