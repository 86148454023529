import {useApplicationPermissions} from "@sokigo-sbwebb/application-services";
import {useProvideNavbarSubItems} from "@sokigo-sbwebb/default-core";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {useMemo} from "react";
import {Route, Switch} from "react-router-dom";
import InspectionAdminMainRoutes from "./AdminRoutes/InspectionAdminMainRoutes";
import {Home} from "./Home/Home";
import {ShowCommonDocumentRoute} from "./ShowCommonDocumentRoute";
import {InspectionManageAllVisitsRoute} from "./ManageVisits/InspectionManageAllVisitsRoute";
import {ShowDocumentRoute} from "./ShowDocumentRoute";
import {InspectionVisitRoute} from "./Visit/InspectionVisitRoute";
import {ShowProtocolPreviewRoute} from "./ShowProtocolPreviewRoute";

export function InspectionMainRoute({match: {url}}) {
  const t = useAppTranslation();
  const {hasPermission} = useApplicationPermissions();
  const hasAdminPermission = hasPermission("admin");
  const hasManageAllVisitsPermission = hasPermission("manageAllVisits");
  if (url.endsWith("/")) {
    url = url.slice(0, url.length - 1);
  }

  const navbarSubItems = useMemo(
    () =>
      [
        hasAdminPermission && {label: t("admin"), to: url + "/admin"},
        hasManageAllVisitsPermission && {
          label: t("manageAllVisits"),
          to: url + "/visits",
        },
      ].filter((x) => x),
    [hasAdminPermission, hasManageAllVisitsPermission, url, t]
  );
  useProvideNavbarSubItems(navbarSubItems);

  return (
    <>
      <Switch>
        <Route
          path={url + "/admin/:section?"}
          component={InspectionAdminMainRoutes}
        />
        <Route
          path={url + "/visit/:visitId/:section?"}
          component={InspectionVisitRoute}
        />
        <Route
          path={url + "/commonDocument/:commonDocumentId"}
          component={ShowCommonDocumentRoute}
        />
        <Route
          path={url + "/document/:documentId"}
          component={ShowDocumentRoute}
        />
        <Route
          path={url + "/protocolPreview/:visitId"}
          component={ShowProtocolPreviewRoute}
        />
        <Route
          path={url + "/visits"}
          component={InspectionManageAllVisitsRoute}
        />
        <Route path={url + "/"} component={Home} />
      </Switch>
    </>
  );
}
