import {createContext, useContext, useMemo} from "react";
import {useBaseDataRepository} from "./sw-comms/base-data-repo/useBaseDataRepository";
import {useCommonDocumentsMetadataRepository} from "./sw-comms/common-documents-repo/useCommonDocuments";
import {useControlAreaGroupsRepository} from "./sw-comms/control-areas-repo/useControlAreaGroupsRepository";
import {useFoodControlAreaSupportQuestionsRepository} from "./sw-comms/control-areas-support-questions-repo/useFoodControlAreaSupportQuestionsRepository";
import {useVisitFormsRepository} from "./sw-comms/visit-forms-repo/useVisitFormsRepository";
import {usePhraseGroupsRepository} from "./sw-comms/phrase-repo/usePhraseGroupsRepository";
import {useServerVisitRepository} from "./sw-comms/server-visit-repo/useServerVisitRepository";

const ServerStateContext = createContext();
export function ServerStateProvider({children}) {
  const baseData = useBaseDataRepository();
  const {phraseGroups, addPhraseGroup, removePhraseGroup, updatePhraseGroup} =
    usePhraseGroupsRepository();
  const {
    visitForms,
    addVisitForm,
    removeVisitForm,
    updateVisitForm,
    setVisitForms,
    refreshVisitForms,
  } = useVisitFormsRepository();
  const {
    commonDocuments,
    addCommonDocument,
    addCommonDocuments,
    removeCommonDocument,
    updateCommonDocument,
  } = useCommonDocumentsMetadataRepository();
  const {serverVisits, refreshServerVisits} = useServerVisitRepository();
  const {
    controlAreaGroups,
    updateControlAreaGroup,
    removeControlAreaGroup,
    addControlAreaGroup,
  } = useControlAreaGroupsRepository();
  const {supportQuestions, updateSupportQuestions, addSupportQuestions} =
    useFoodControlAreaSupportQuestionsRepository();

  const v = useMemo(
    () => ({
      baseData,
      phraseGroups,
      addPhraseGroup,
      removePhraseGroup,
      updatePhraseGroup,
      visitForms,
      addVisitForm,
      removeVisitForm,
      updateVisitForm,
      setVisitForms,
      refreshVisitForms,
      commonDocuments,
      addCommonDocument,
      addCommonDocuments,
      removeCommonDocument,
      updateCommonDocument,
      serverVisits,
      refreshServerVisits,
      supportQuestions,
      addSupportQuestions,
      updateSupportQuestions,
      controlAreaGroups,
      addControlAreaGroup,
      updateControlAreaGroup,
      removeControlAreaGroup,
    }),
    [
      baseData,
      phraseGroups,
      addPhraseGroup,
      removePhraseGroup,
      updatePhraseGroup,
      visitForms,
      addVisitForm,
      removeVisitForm,
      updateVisitForm,
      setVisitForms,
      refreshVisitForms,
      commonDocuments,
      addCommonDocument,
      addCommonDocuments,
      removeCommonDocument,
      updateCommonDocument,
      serverVisits,
      refreshServerVisits,
      supportQuestions,
      addSupportQuestions,
      updateSupportQuestions,
      controlAreaGroups,
      addControlAreaGroup,
      updateControlAreaGroup,
      removeControlAreaGroup,
    ]
  );

  if (
    !serverVisits ||
    !visitForms ||
    !baseData ||
    !commonDocuments ||
    !supportQuestions
  ) {
    //TODO: "waiting for necessary data to load"
    //TODO: if offline => show message??
    return null;
  }

  return (
    <ServerStateContext.Provider value={v}>
      {children}
    </ServerStateContext.Provider>
  );
}

export function useServerState() {
  return useContext(ServerStateContext);
}
