import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {InspectionAssessment} from "../../../Common/InspectionAssessment";

export function Assessment({assessment}) {
  const t = useAppTranslation();
  return <div>{getAssessment(assessment, t)}</div>;
}

export const getAssessment = (assessment, t) => {
  if (assessment === InspectionAssessment.Acceptable) {
    return t("acceptable");
  } else if (assessment === InspectionAssessment.NotAcceptable) {
    return t("notAcceptable");
  } else {
    return t("notAssessed");
  }
};
