import {Fragment, useCallback, useEffect, useMemo, useState} from "react";
import {
  Alert,
  Button,
  Control,
  Error,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableHeaderRow,
  TableRow,
  TableRowActionsCell,
  Tooltip,
} from "@sokigo/components-react-bootstrap";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {
  useApplicationBusy,
  useIsApplicationBusy,
} from "@sokigo-sbwebb/default-core";
import {
  faCheck,
  faFileImport,
  faTimes,
} from "@fortawesome/pro-regular-svg-icons";
import useToggle from "./useToggle";
import {SaveAsType} from "../AdminRoutes/AdminVisitForms/EditVisitForm/VisitFormEditor/SaveAsType";
import {useServerState} from "../../ServerStateProvider";
import {v4 as uuid} from "uuid";
import {getUniqueTitle} from "./getUniqueTitle";

// Shamelessly stolen from https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding#Solution_4_%E2%80%93_escaping_the_string_before_encoding_it
function b64DecodeUnicode(str) {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(
    atob(str)
      .split("")
      .map((c) => {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
}

export function ImportVisitForm({
  show,
  setShow,
  visitFormExportId,
  visitForms,
  onImported,
}) {
  const t = useAppTranslation();
  const isBusy = useIsApplicationBusy();
  const applicationBusy = useApplicationBusy();
  const {
    addVisitForm,
    updatePhraseGroup,
    addControlAreaGroup,
    phraseGroups,
    controlAreaGroups,
    addSupportQuestions,
    supportQuestions,
    baseData: {facilityTypes},
  } = useServerState();
  const [pendingVisitForms, setPendingVisitForms] = useState();
  const [invalidFileMessage, setInvalidFileMessage] = useState();
  const [updatedPendingVisitForms, setUpdatedPendingVisitForms] = useState();
  const [
    includeFoodInspectionSupportQuestions,
    setIncludeFoodInspectionSupportQuestions,
  ] = useState();
  const [selected, toggleSingle, toggleAll, clearSelection] = useToggle(
    pendingVisitForms?.map((x) => x.id),
    []
  );

  const isValidVisitFormJsonFile = (incomingJson) => {
    return incomingJson?.exportId === visitFormExportId;
  };

  const updateIdReferencesInVisitForm = useCallback((item, idList = []) => {
    if (Object.prototype.hasOwnProperty.call(item, "id")) {
      const newId = uuid();
      const savedItem = idList.find((x) => x.oldId === item.id);
      if (savedItem) {
        item.id = savedItem.newId;
      } else {
        idList.push({newId, oldId: item.id});
        item.id = newId;
      }
    }
    Object.keys(item).forEach((key) => {
      if (Array.isArray(item[key])) {
        if (item[key].length > 0) {
          if (
            key === "facilityTypeIds" ||
            key === "foodInspectionControlAreas"
          ) {
            return item[key];
          } else if (
            typeof item[key][0] === "string" ||
            item[key][0] instanceof String
          ) {
            item[key] = item[key].map((guid) => {
              const savedItem = idList.find((x) => x.oldId === guid);
              const newId = uuid();
              if (savedItem) {
                return savedItem.newId;
              } else {
                idList.push({newId, oldId: guid});
                return newId;
              }
            });
          } else {
            item[key] = item[key].map((arrItem) => {
              if (typeof arrItem === "object" && arrItem !== null) {
                return updateIdReferencesInVisitForm(arrItem, idList);
              }
            });
          }
        }
      } else if (typeof item[key] === "object" && item[key] !== null) {
        item[key] = updateIdReferencesInVisitForm(item[key], idList);
      }
    });
    return item;
  }, []);

  const updateIdReferencesForImportedVisitForms = useCallback(() => {
    const idList = [];
    const visitFormsWithOldReferences = [...pendingVisitForms];
    const visitFormsWithUpdatedReferences = visitFormsWithOldReferences.map(
      (x) => updateIdReferencesInVisitForm(x, idList)
    );
    setUpdatedPendingVisitForms([...visitFormsWithUpdatedReferences]);
  }, [pendingVisitForms, updateIdReferencesInVisitForm]);

  useEffect(() => {
    if (pendingVisitForms) {
      updateIdReferencesForImportedVisitForms();
    }
  }, [pendingVisitForms, updateIdReferencesForImportedVisitForms]);

  const phraseGroupList = useMemo(
    () => phraseGroups && Object.keys(phraseGroups).map((x) => phraseGroups[x]),
    [phraseGroups]
  );

  const controlAreaGroupList = useMemo(
    () =>
      controlAreaGroups &&
      Object.keys(controlAreaGroups).map((x) => controlAreaGroups[x]),
    [controlAreaGroups]
  );

  const allControlAreas = useMemo(
    () => controlAreaGroupList?.map((x) => x.controlAreas).flat(),
    [controlAreaGroupList]
  );

  const allControlPoints = useMemo(
    () => allControlAreas?.map((x) => x.controlPoints).flat(),
    [allControlAreas]
  );

  const allFoodInspectionControlAreasWithSupportQuestions = useMemo(
    () =>
      supportQuestions &&
      Object.keys(supportQuestions)
        .map((x) => ({id: x, questions: supportQuestions[x]}))
        .filter((x) => x.questions)
        .map((x) => ({
          ...x,
          questions: Object.keys(x.questions).map((y) => ({
            id: y,
            supportQuestions: x.questions[y],
          })),
        }))
        .map((x) => x.questions)
        .flat(),
    [supportQuestions]
  );

  const selectFile = () => {
    setIncludeFoodInspectionSupportQuestions(false);
    setInvalidFileMessage(null);
    const el = document.createElement("input");
    el.type = "file";
    el.onchange = (e) => {
      if (e.target.value) {
        try {
          const file = e.target.files[0];
          const fileReader = new FileReader();
          fileReader.onload = (e) => {
            try {
              const base64jsonStringOfData =
                e.target.result.split("base64,")[1];
              const fileData = b64DecodeUnicode(base64jsonStringOfData);
              const jsonData = JSON.parse(fileData);
              const isValid = isValidVisitFormJsonFile(jsonData);
              if (!isValid) {
                setInvalidFileMessage(t("openFileError"));
                return;
              }
              setPendingVisitForms(jsonData.visitForms);
              clearSelection();
            } catch (ex) {
              console.error(ex);
              setInvalidFileMessage(t("openFileError"));
            }
          };
          fileReader.readAsDataURL(file);
        } catch (ex) {
          console.error(ex);
          setInvalidFileMessage(t("openFileError"));
        }
      }
    };
    el.click();
  };

  const importControlAreaSupportQuestions = async (
    controlAreaSupportQuestions
  ) => {
    for (const x of controlAreaSupportQuestions) {
      await addSupportQuestions(x.controlAreaId, {
        [x.questionId]: x.supportQuestions,
      });
    }
  };

  const getSupportQuestionsToImport = (visitForm) => {
    if (
      includeFoodInspectionSupportQuestions &&
      visitForm.type === SaveAsType.FoodInspection
    ) {
      const controlAreaSupportQuestions = [];
      visitForm.foodInspectionControlAreas?.forEach((fica) => {
        const controlAreaId = fica.id;
        fica.questions.forEach((q) => {
          if (q.supportQuestions?.length > 0) {
            const existingSupportQuestionTitles =
              allFoodInspectionControlAreasWithSupportQuestions
                .find((x) => x.id === q.id)
                ?.supportQuestions.map((x) => x.title);
            const supportQuestions = q.supportQuestions.map((sq) => ({
              ...sq,
              id: uuid(),
              title: getUniqueTitle(sq.title, existingSupportQuestionTitles),
            }));
            controlAreaSupportQuestions.push({
              controlAreaId,
              questionId: q.id,
              supportQuestions,
            });
          }
        });
      });
      return controlAreaSupportQuestions;
    }
  };

  const importPhraseGroupsFromControlAreaGroups = async (visitForm) => {
    if (visitForm.type !== SaveAsType.FoodInspection) {
      //Copy over phrasegroups from control area groups
      for (let i = 0; i < visitForm.controlAreaGroups?.length; i++) {
        const cag = visitForm.controlAreaGroups[i];
        for (let j = 0; j < cag.controlAreas.length; j++) {
          const ca = cag.controlAreas[j];
          if (ca.phraseGroups.length > 0) {
            for (let k = 0; k < ca.phraseGroups.length; k++) {
              const pg = ca.phraseGroups[k];
              await updatePhraseGroup(pg.id, {
                ...pg,
                title: getUniqueTitle(
                  pg.title,
                  phraseGroupList.map((x) => x.title)
                ),
              });
            }
          }
          for (let l = 0; l < ca.controlPoints.length; l++) {
            const cp = ca.controlPoints[l];
            if (cp.phraseGroups.length > 0) {
              for (let m = 0; m < cp.phraseGroups.length; m++) {
                const pg = cp.phraseGroups[m];
                await updatePhraseGroup(pg.id, {
                  ...pg,
                  title: getUniqueTitle(
                    pg.title,
                    phraseGroupList.map((x) => x.title)
                  ),
                });
              }
            }
          }
        }
      }
    }
  };

  const importPhraseGroupsFromProtocolTexts = async (visitForm) => {
    //Copy over phrasegroups from dynamic paragraphs
    for (
      let i = 0;
      i < visitForm.protocolTexts?.dynamicParagraphs?.length;
      i++
    ) {
      const dp = visitForm.protocolTexts.dynamicParagraphs[i];
      for (let j = 0; j < dp.phraseGroups?.length; j++) {
        const pg = dp.phraseGroups[j];
        await updatePhraseGroup(pg.id, {
          ...pg,
          title: getUniqueTitle(
            pg.title,
            phraseGroupList.map((x) => x.title)
          ),
        });
      }
    }
    //Copy over phrasegroups from static paragraphs
    for (
      let i = 0;
      i < visitForm.protocolTexts?.staticParagraphs?.length;
      i++
    ) {
      const sp = visitForm.protocolTexts.staticParagraphs[i];
      for (let j = 0; j < sp.phraseGroups?.length; j++) {
        const pg = sp.phraseGroups[j];
        await updatePhraseGroup(pg.id, {
          ...pg,
          title: getUniqueTitle(
            pg.title,
            phraseGroupList.map((x) => x.title)
          ),
        });
      }
    }
  };

  const importControlAreaGroups = async (controlAreaGroups) => {
    for (let i = 0; i < controlAreaGroups?.length; i++) {
      const cag = controlAreaGroups[i];
      await addControlAreaGroup(cag.id, cag);
    }
  };

  const getControlAreaGroupsToImport = (visitForm) => {
    if (visitForm.type !== SaveAsType.FoodInspection) {
      return visitForm.controlAreaGroups?.map((cag) => {
        return {
          ...cag,
          title: getUniqueTitle(
            cag.title,
            controlAreaGroupList.map((x) => x.title)
          ),
          controlAreas: cag.controlAreas.map((ca) => {
            return {
              ...ca,
              title: getUniqueTitle(
                ca.title,
                allControlAreas.map((x) => x.title)
              ),
              phraseGroups: ca.phraseGroups?.map((x) => x.id),
              controlPoints: ca.controlPoints.map((cp) => {
                return {
                  ...cp,
                  title: getUniqueTitle(
                    cp.title,
                    allControlPoints.map((x) => x.title)
                  ),
                  phraseGroups: cp.phraseGroups?.map((x) => x.id),
                };
              }),
            };
          }),
        };
      });
    }
  };

  const importVisitForm = async (visitForm) => {
    const flatControlAreas = visitForm.controlAreaGroups
      ?.map((cag) => cag.controlAreas)
      .flat();
    const uniqueControlAreas = flatControlAreas?.filter(
      (ca, i, arr) => arr.findIndex((t) => t.id === ca.id) === i
    );
    await addVisitForm({
      id: visitForm.id,
      title: getUniqueTitle(
        visitForm.title,
        visitForms.map((x) => x.title)
      ),
      subtitle: visitForm.subTitle,
      disabled: true,
      sections: visitForm.sections,
      emailDispatch: visitForm.emailDispatch,
      controlAreas:
        visitForm.type !== SaveAsType.FoodInspection
          ? uniqueControlAreas?.map((ca) => {
              return {
                id: ca.id,
                controlPoints: ca.controlPoints?.map((cp) => cp.id),
              };
            })
          : null,
      foodInspectionControlAreas:
        visitForm.type === SaveAsType.FoodInspection
          ? visitForm.foodInspectionControlAreas?.map((fica) => ({
              id: fica.id,
              questions: fica.questions.map((q) => q.id),
            }))
          : null,
      protocolTexts: {
        allowDynamicParagraphs: visitForm.protocolTexts?.allowDynamicParagraphs,
        dynamicParagraphs: visitForm.protocolTexts?.dynamicParagraphs?.map(
          (dp) => ({
            id: dp.id,
            title: dp.title,
            phraseGroupIds: dp.phraseGroups?.map((pg) => pg.id),
          })
        ),
        staticParagraphs: visitForm.protocolTexts?.staticParagraphs?.map(
          (sp) => ({
            id: sp.id,
            title: sp.title,
            phraseGroupIds: sp.phraseGroups?.map((pg) => pg.id),
          })
        ),
      },
      facilityTypeIds: visitForm.facilityTypeIds.filter(
        (id) => !!facilityTypes.find((ft) => ft.id === id)
      ),
      saveAsType: visitForm.type,
      controlResponseFormat: visitForm.controlResponseFormat,
    });
  };

  const importVisitFormAndLibraryItems = async (visitForm) => {
    await importPhraseGroupsFromProtocolTexts(visitForm);
    await importPhraseGroupsFromControlAreaGroups(visitForm);
    await importVisitForm(visitForm);
  };

  const onSubmit = async () => {
    applicationBusy(true);
    const visitForms = updatedPendingVisitForms.filter((x) =>
      selected.includes(x.id)
    );
    let controlAreaGroupList = [];
    const supportQuestionList = [];
    for (let i = 0; i < visitForms.length; i++) {
      const visitForm = visitForms[i];
      const controlAreaGroups = await getControlAreaGroupsToImport(visitForm);
      //Non food inspection control area groups
      controlAreaGroups?.forEach((cag) => {
        let group = controlAreaGroupList.find((x) => x.id === cag.id);
        if (group) {
          const newControlAreas = [...group.controlAreas];
          cag.controlAreas.forEach((x) => {
            const controlArea = group.controlAreas.find((y) => x.id === y.id);
            if (!controlArea) {
              newControlAreas.push(x);
            }
          });
          group = {
            ...group,
            controlAreas: newControlAreas,
          };
          controlAreaGroupList = controlAreaGroupList.map((x) =>
            x.id === group.id ? group : x
          );
        } else {
          controlAreaGroupList.push(cag);
        }
      });
      //Food inspection control area support questions
      const supportQuestions = getSupportQuestionsToImport(visitForm);
      supportQuestions?.forEach((sq) => {
        const supportQuestion = supportQuestionList.find(
          (x) => x.questionId === sq.questionId
        );
        if (!supportQuestion) {
          supportQuestionList.push(sq);
        }
      });
      await importVisitFormAndLibraryItems(visitForm);
    }
    await importControlAreaSupportQuestions(supportQuestionList);
    await importControlAreaGroups(controlAreaGroupList);
    setShow(false);
    setPendingVisitForms(null);
    setUpdatedPendingVisitForms(null);
    setIncludeFoodInspectionSupportQuestions(false);
    onImported();
    applicationBusy(false);
  };

  const onClose = () => {
    setShow(false);
    setPendingVisitForms(null);
    setUpdatedPendingVisitForms(null);
    setIncludeFoodInspectionSupportQuestions(false);
  };

  const hasPendingFoodInspectionImport = !!pendingVisitForms?.find(
    (x) => x.type === SaveAsType.FoodInspection
  );

  return (
    <Modal size="xl" show={show} onClose={onClose} scrollable={true} static>
      <fieldset disabled={isBusy}>
        <ModalHeader>{t("importVisitForm")}</ModalHeader>
        <ModalBody>
          {invalidFileMessage && (
            <Alert kind="danger" inline className="mb-3">
              {invalidFileMessage}
            </Alert>
          )}
          {(!pendingVisitForms || pendingVisitForms?.length === 0) && (
            <SelectFileButton selectFile={selectFile} />
          )}
          {pendingVisitForms && pendingVisitForms?.length > 0 && (
            <>
              <ImportVisitFormTable
                pendingVisitForms={pendingVisitForms}
                selected={selected}
                toggleSingle={toggleSingle}
                toggleAll={toggleAll}
                visitForms={visitForms}
              />
              {hasPendingFoodInspectionImport && (
                <Control
                  horizontal
                  className="mt-3"
                  labelText={t("includeFoodInspectionSupportQuestionsMessage")}
                  labelWidths={{sm: 10, md: 8, lg: 5, xl: 4}}
                >
                  <Switch
                    checked={includeFoodInspectionSupportQuestions}
                    onChange={() =>
                      setIncludeFoodInspectionSupportQuestions(
                        !includeFoodInspectionSupportQuestions
                      )
                    }
                  />
                </Control>
              )}
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            kind="secondary"
            onClick={onClose}
            className="mr-2"
            icon={faTimes}
          >
            {t("cancel")}
          </Button>
          <Button
            onClick={onSubmit}
            icon={faCheck}
            disabled={selected.length === 0}
            kind="primary"
          >
            {t("importSelected")}
          </Button>
        </ModalFooter>
      </fieldset>
    </Modal>
  );
}

function SelectFileButton({selectFile}) {
  const t = useAppTranslation();

  return (
    <Button onClick={selectFile} icon={faFileImport}>
      {t("selectFile")}
    </Button>
  );
}

function ImportVisitFormTable({
  pendingVisitForms,
  selected,
  toggleAll,
  toggleSingle,
  visitForms,
}) {
  const t = useAppTranslation();

  function getTypeText(type) {
    if (type === SaveAsType.GenericInspection) {
      return t("genericInspection");
    } else if (type === SaveAsType.FoodInspection) {
      return t("foodInspection");
    } else {
      return t("visitOccurrence");
    }
  }

  const columns = [
    {
      key: "title",
      header: t("title"),
      renderCell: ({row}) => row.title,
    },
    {
      key: "subTitle",
      header: t("subTitle"),
      renderCell: ({row}) => row.subTitle,
    },
    {
      key: "type",
      header: t("type"),
      renderCell: ({row}) => {
        return getTypeText(row.type);
      },
    },
  ];

  const allSelected = selected.length === pendingVisitForms.length;

  return (
    <>
      <Table rows={pendingVisitForms} columns={columns} getRowKey={(x) => x.id}>
        <TableHead>
          <TableHeaderRow colSpan={columns.length + 1}>
            {(columns) => (
              <>
                {columns.map((x) => (
                  <TableHeader key={x.key} column={x}>
                    {x.header}
                  </TableHeader>
                ))}
                <TableHeader>
                  <Switch
                    checked={allSelected}
                    onChange={() => toggleAll()}
                    indeterminate={selected?.length > 0 && !allSelected}
                  />
                </TableHeader>
              </>
            )}
          </TableHeaderRow>
        </TableHead>
        <TableBody>
          {(rows) =>
            rows.map((r) => {
              const hasDuplicateVisitFormValues = !!visitForms.find(
                (x) =>
                  x.title === r.row.title &&
                  x.subTitle === r.row.subTitle &&
                  x.saveAsType === r.row.type
              );
              return (
                <Fragment key={r.key}>
                  <TableRow key={r.key} row={r} invalid={!r.row.title}>
                    {r.cells.map((c, i) => (
                      <TableCell key={c.key}>
                        {i === 0 && hasDuplicateVisitFormValues ? (
                          <Tooltip text={t("duplicateVisitFormWarning")}>
                            <div className="d-flex flex-row align-items-center justify-content-between">
                              <div>{c.value}</div>
                              <Error warning solid />
                            </div>
                          </Tooltip>
                        ) : (
                          c.value
                        )}
                      </TableCell>
                    ))}
                    <TableRowActionsCell>
                      <Switch
                        checked={selected.includes(r.row.id)}
                        onChange={() => toggleSingle(r.row.id)}
                      />
                    </TableRowActionsCell>
                  </TableRow>
                </Fragment>
              );
            })
          }
        </TableBody>
      </Table>
    </>
  );
}
