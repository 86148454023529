import {
  Control,
  DatePicker,
  Label,
  Panel,
  Select,
  Switch,
  TextArea,
} from "@sokigo/components-react-bootstrap";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {useCompletingInformationProvider} from "../finish/CompletingInformation/CompletingInformationTab";
import moment from "moment";
import {useServerState} from "src/apps/sokigo-sbwebb-inspection/ServerStateProvider";
import {useEffect} from "react";
import {useCurrentVisit} from "../InspectionVisitRoute";
import {useInspectionDateValidation} from "../foodInspection24Validator/useFoodInspection24Validation";
import {SaveToEcosStatus} from "../../Common/SaveToEcosStatus";

export function ControlOccasionHeader() {
  const t = useAppTranslation();
  const {
    visit: {
      saveToEcosStatus,
      visitMetadata: {
        visitTargetInformation: {facilities},
      },
    },
  } = useCurrentVisit();

  const {onChange, value} = useCompletingInformationProvider();

  const onControlInformationChange = (change) => {
    onChange({...value, ...change});
  };

  const facilityinfo = facilities[0];

  const {baseData: {selectableMunicipalities, allMunicipalities} = {}} =
    useServerState();

  const selectedMunicipalityCode = selectableMunicipalities.map(
    (m) => m.municipalityCode
  );
  const cmp = (a, b) => (a > b) - (a < b);
  const sortedMunicipalities = allMunicipalities
    .map((x) => ({
      ...x,
      isAssigned: selectedMunicipalityCode.includes(x.municipalityCode)
        ? true
        : false,
    }))
    .sort(function (a, b) {
      return cmp(b.isAssigned, a.isAssigned) || cmp(a.name, b.name);
    });

  const dateValidation = useInspectionDateValidation();
  const dateError = [];
  if (!dateValidation.minMax) {
    dateError.push(t("minMaxInspectionDateValidationMessage"));
  }
  if (dateValidation.unallowed2023cretaion) {
    dateError.push(t("datesBefore2024notAllowed"));
  }

  useEffect(() => {
    if (
      !facilityinfo.isMobileFacility &&
      value.municipalityCode === null &&
      facilityinfo.municipalityCode !== null
    ) {
      onChange({
        ...value,
        municipalityCode: facilities[0].municipalityCode,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Panel className="my-3 p-3">
      <div style={{marginBottom: "20px"}}>
        <h4 className="px-3 mb-3">{t("controlOccasion")}</h4>
        <div style={{display: "flex", flexWrap: "wrap"}}>
          <Control
            className="col-sm-6 col-md-4 col-lg-3"
            labelText={t("date")}
            errors={!value?.occurrenceDate ? [t("mandatory")] : dateError}
          >
            <DatePicker
              format="yyyy-MM-dd"
              clearable
              value={
                value?.occurrenceDate || ""
                  ? moment(value?.occurrenceDate).format("YYYY-MM-DD")
                  : ""
              }
              onChange={(occurrenceDate) =>
                onControlInformationChange({
                  occurrenceDate,
                })
              }
              //min={"2024-01-01"} (Do we want to limit selection date??)
              inline
              locale={t("locale")}
              disabled={
                saveToEcosStatus ===
                SaveToEcosStatus.VisitWithValidationErrorsReopened
                  ? true
                  : false
              }
            />
          </Control>
          <div className="form-group col-xs-12 col-sm-6 col-md-5">
            <Switch
              className="mt-3 mr-2"
              size="lg"
              kind="success"
              checked={value?.saveScratchPadNote || false}
              onChange={(saveScratchPadNote) =>
                onControlInformationChange({saveScratchPadNote})
              }
            />
            <Label className="mt-5">{t("saveScratchPadNote")}</Label>
          </div>
        </div>
        <Control
          labelText={t("performingAuthority")}
          className="col-md-6"
          errors={value.municipalityCode ? [] : [t("obligatory")]}
        >
          <Select
            value={value.municipalityCode}
            onChange={(municipalityCode) =>
              onChange({...value, municipalityCode})
            }
            clearable
            options={sortedMunicipalities ?? []}
            getOptionValue={(x) => x.municipalityCode}
            getOptionKey={(x) => x.municipalityCode}
            getOptionText={(x) => x.name}
            getOptionGroup={(x) => x.isAssigned}
          />
        </Control>
        <div>
          <div className="col-md-6">
            <Label className="mt-5">{t("note")}</Label>

            <TextArea
              value={value?.occurrenceNote ?? ""}
              noResize
              style={{height: "20em"}}
              onChange={(occurrenceNote) =>
                onChange({...value, occurrenceNote})
              }
            />
          </div>
        </div>
      </div>
    </Panel>
  );
}
