import {faEllipsisV, faPlus} from "@fortawesome/pro-regular-svg-icons";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {
  ActionsDropdown,
  ActionsDropdownItem,
  Panel,
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableHeaderRow,
} from "@sokigo/components-react-bootstrap";
import {useState} from "react";
import {FillTableCellWithTruncatedText} from "../../../../../common-components/FillTableCellWithTruncatedText";
import {useServerState} from "../../../ServerStateProvider";
import {EditSupportQuestionModal} from "../../Common/EditSupportQuestionModal";
import {useCurrentVisitInput} from "../InspectionVisitRoute";
import ControlAreaPointForm from "./ControlAreaPointForm";
import {useAvailableNonFoodControlAreas} from "./useAvailableNonFoodControlAreas";

export default function ControlAreaPoints({controlArea}) {
  const t = useAppTranslation();
  const {phraseGroups} = useServerState();
  const [controlPointModalVisible, setControlPointModalVisible] = useState();
  const {nonFoodControlAreaPointNoteById} = useCurrentVisitInput();
  const {addNewControlPoint} = useAvailableNonFoodControlAreas();

  const controlPointsWithPhaseGroupOptions = (() => {
    return controlArea.controlPoints.map(function (x) {
      const controlItemPhraseGroupsIds = x.phraseGroups
        ? x.phraseGroups.flatMap(function (y) {
            return y;
          })
        : [];
      const actualPhraseGroupIds = controlArea.phraseGroups
        ? [
            ...new Set(
              controlArea.phraseGroups.concat(controlItemPhraseGroupsIds)
            ),
          ]
        : controlItemPhraseGroupsIds;

      return {
        ...x,
        phraseGroupOptions: actualPhraseGroupIds
          ? actualPhraseGroupIds.flatMap(function (y) {
              return phraseGroups[y]?.phrases.map(function (z) {
                return z;
              });
            })
          : [],
      };
    });
  })();

  function addControlAreaPoint(newControlAreaPoint) {
    addNewControlPoint(controlArea, newControlAreaPoint);
    setControlPointModalVisible(false);
  }

  const columns = [
    {
      key: "title",
      header: t("title"),
      renderCell: ({row}) => row.title,
      className: "w-66",
    },
    {
      key: "note",
      header: t("note"),
      renderCell: ({row}) => (
        <FillTableCellWithTruncatedText>
          {nonFoodControlAreaPointNoteById?.[row.id]}
        </FillTableCellWithTruncatedText>
      ),
      className: "w-33",
    },
  ];

  return (
    <Panel className="py-3">
      <div className="mx-3 mb-3 d-flex align-items-center justify-content-between">
        <h4>{t("controlPoints")}</h4>
        <ActionsDropdown
          icon={faEllipsisV}
          kind="ghost"
          className="ml-2"
          menuSize="sm"
        >
          <ActionsDropdownItem
            itemText={t("addControlPoint")}
            onClick={() => setControlPointModalVisible(true)}
            icon={faPlus}
          />
        </ActionsDropdown>
      </div>
      <Table
        rows={controlPointsWithPhaseGroupOptions}
        columns={columns}
        getRowKey={(x) => x.id}
        size="lg"
      >
        <TableHead>
          <TableHeaderRow>
            {(columns) => (
              <>
                <TableHeader />
                {columns.map((x) => (
                  <TableHeader key={x.key} column={x} className={x.className}>
                    {x.header}
                  </TableHeader>
                ))}
                <TableHeader />
              </>
            )}
          </TableHeaderRow>
        </TableHead>
        <TableBody>
          {(rows) =>
            rows.map((r) => (
              <ControlAreaPointForm
                key={r.key}
                controlAreaPoint={r.row}
                row={r}
                colSpan={columns.length + 2}
                controlArea={controlArea}
              />
            ))
          }
        </TableBody>
      </Table>
      {controlPointModalVisible && (
        <EditSupportQuestionModal
          onCancel={() => {
            setControlPointModalVisible(false);
          }}
          onSave={addControlAreaPoint}
        />
      )}
    </Panel>
  );
}
