import {useSetApplicationSubTitle} from "@sokigo-sbwebb/default-core";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {useEffect} from "react";
import {useServerState} from "../ServerStateProvider";
import {PdfViewer} from "../../../common-components/pdf-viewer/PdfViewer";

export function ShowCommonDocumentRoute({
  match: {
    params: {commonDocumentId},
  },
}) {
  const t = useAppTranslation();
  const setApplicationSubtitle = useSetApplicationSubTitle();
  const {commonDocuments} = useServerState();
  const doc = commonDocuments?.[commonDocumentId];

  const subtitle = doc
    ? t("commonDocumentApplicationSubtitle", {title: doc.title})
    : null;
  useEffect(() => {
    setApplicationSubtitle(subtitle);
  }, [setApplicationSubtitle, subtitle]);

  if (!doc) {
    return null;
  }

  const url = document.baseURI + "cache/commonDocument/" + commonDocumentId;
  return <PdfViewer url={url} />;
}
