import {
  faArrowDown,
  faArrowUp,
  faDownload,
  faRedo,
  faUndo,
} from "@fortawesome/pro-regular-svg-icons";
import {useApplicationBusy} from "@sokigo-sbwebb/default-core";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {useRefresh} from "@sokigo-sbwebb/react";
import {Button, Select} from "@sokigo/components-react-bootstrap";
import {useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import {Document, Page} from "react-pdf/dist/esm/entry.webpack";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import styles from "./PdfViewer.module.scss";

const ScaleOption = {
  Original: 0,
  FitPage: 1,
  FitWidth: 2,
  Scale075: 3,
  Scale125: 4,
  Scale150: 5,
  Scale200: 6,
  Scale300: 7,
};

export function PdfViewer({url}) {
  const t = useAppTranslation();
  const applicationBusy = useApplicationBusy();

  const [token, refresh] = useRefresh();
  const [numPages, setNumPages] = useState(null);
  const [rotation, setRotation] = useState(0);
  const [page, setPage] = useState(1);
  const [scaleOption, setScaleOption] = useState(ScaleOption.Original);
  const [pageDimensions, setPageDimensions] = useState(null);
  const [containerDimensions, setContainerDimensions] = useState(null);

  useEffect(() => {
    applicationBusy(isNaN(numPages));
  }, [numPages, applicationBusy]);

  useEffect(() => {
    // reset on file change
    setNumPages(null);
    setPageDimensions(null);
    setPage(1);
    setRotation(0);
    refresh();
  }, [url, refresh]);

  function onDocumentLoadSuccess({numPages}) {
    setNumPages(numPages);
  }

  const pageOptions = !numPages
    ? []
    : [...new Array(numPages)].map((_, i) => i + 1);

  const ref = useRef();

  const scale = useMemo(() => {
    const originalScale = 1.25 * (96 / 72);
    if (!pageDimensions || !containerDimensions) {
      return originalScale;
    }
    const containerWidth = containerDimensions.width;
    const containerHeight = containerDimensions.height;
    const pageWidth =
      rotation % 180 === 0 ? pageDimensions.width : pageDimensions.height;
    const pageHeight =
      rotation % 180 === 0 ? pageDimensions.height : pageDimensions.width;

    const s = {
      [ScaleOption.Original]: () => originalScale,
      [ScaleOption.FitPage]: () =>
        Math.min(containerWidth / pageWidth, containerHeight / pageHeight),
      [ScaleOption.FitWidth]: () => containerWidth / pageWidth,
      [ScaleOption.Scale075]: () => originalScale * 0.75,
      [ScaleOption.Scale125]: () => originalScale * 1.25,
      [ScaleOption.Scale150]: () => originalScale * 1.5,
      [ScaleOption.Scale200]: () => originalScale * 2.0,
      [ScaleOption.Scale300]: () => originalScale * 3.0,
    }[scaleOption]();

    return s;
  }, [pageDimensions, containerDimensions, rotation, scaleOption]);

  useLayoutEffect(() => {
    if (!ResizeObserver || !ref.current) {
      return;
    }
    const container = ref.current;

    const resizeObserver = new ResizeObserver(() => {
      const style = window.getComputedStyle(container);
      const px = parseFloat(style.paddingLeft) + parseFloat(style.paddingRight);
      const py = parseFloat(style.paddingTop) + parseFloat(style.paddingBottom);

      setContainerDimensions({
        height: container.clientHeight - py - 30,
        width: container.clientWidth - px - 30,
      });
    });
    resizeObserver.observe(container);

    return () => {
      resizeObserver.unobserve(container);
    };
  }, []);

  function onPageLoadSuccess({originalHeight, originalWidth}) {
    setPageDimensions({height: originalHeight, width: originalWidth});
  }

  function onDocumentItemClick({pageNumber}) {
    setPage(pageNumber);
  }

  const scaleOptions = [
    {value: ScaleOption.Original, label: t("pdfScaleOriginal")},
    {value: ScaleOption.FitPage, label: t("pdfScaleFitPage")},
    {value: ScaleOption.FitWidth, label: t("pdfScaleFitWidth")},
    {value: ScaleOption.Scale075, label: "75%"},
    {value: ScaleOption.Scale125, label: "125%"},
    {value: ScaleOption.Scale150, label: "150%"},
    {value: ScaleOption.Scale200, label: "200%"},
    {value: ScaleOption.Scale300, label: "300%"},
  ];

  return (
    <div>
      {/* toolbar */}
      <div className={styles.toolbarContainer}>
        <div className={styles.toolbar}>
          <div>
            <Button
              kind="ghost"
              tooltipText={t("pdfPreviousPage")}
              onClick={() => setPage((p) => (p === 1 ? 1 : p - 1))}
              disabled={page === 1}
              icon={faArrowUp}
              className="mr-1"
            />
            <Button
              kind="ghost"
              tooltipText={t("pdfNextPage")}
              onClick={() =>
                setPage((p) => (p === numPages ? numPages : p + 1))
              }
              disabled={page === numPages}
              icon={faArrowDown}
              className="mr-1"
            />
            <Select
              searchable={false}
              onChange={(n) => setPage(n)}
              value={page}
              options={pageOptions}
              getOptionKey={(n) => n}
              getOptionText={(n) => n.toString()}
              className={"w-auto"}
            />
          </div>

          <div>
            <div className="flex-grow-0">
              <Select
                searchable={false}
                options={scaleOptions}
                value={scaleOption}
                getOptionKey={(x) => x.value}
                getOptionText={(x) => x.label}
                getOptionValue={(x) => x.value}
                onChange={(x) => setScaleOption(x)}
              />
            </div>
          </div>

          <div>
            <Button
              kind="ghost"
              tooltipText={t("pdfRotateLeft")}
              icon={faUndo}
              onClick={() => setRotation((r) => (r === 0 ? 270 : r - 90))}
            />
            <Button
              kind="ghost"
              tooltipText={t("pdfRotateRight")}
              icon={faRedo}
              onClick={() => setRotation((r) => (r === 270 ? 0 : r + 90))}
              className="ml-1"
            />
            <Button
              kind="ghost"
              tag="a"
              tooltipText={t("download")}
              icon={faDownload}
              href={url}
              className="ml-1"
              target="_blank"
            />
          </div>
        </div>
      </div>

      {/* main content */}
      <div className="flex-fill p-4 overflow-auto" ref={ref}>
        <div className="mt-6">
          <Document
            options={{
              cMapUrl: "cmaps/",
              cMapPacked: true,
            }}
            key={token}
            file={url}
            onLoadSuccess={onDocumentLoadSuccess}
            onItemClick={onDocumentItemClick}
            className="d-flex justify-content-center"
          >
            <Page
              key={token}
              pageNumber={page}
              scale={scale}
              rotate={rotation}
              onLoadSuccess={onPageLoadSuccess}
              className="shadow-lg"
            />
          </Document>
        </div>
      </div>
    </div>
  );
}
