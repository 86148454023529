import {
  Button,
  Tooltip,
  Control,
  Select,
  Switch,
} from "@sokigo/components-react-bootstrap";
import {
  faTrashCan,
  faExclamationCircle,
} from "@fortawesome/pro-regular-svg-icons";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {ControlCause} from "../../Common/ControlCause";
import {
  useFoodAssessmentValidation,
  useFoodFacilityTypeValidation,
  useObligatoryBevaWhen92ControlValidation,
  useFoodControlAreas24Validation,
} from "../foodInspection24Validator/useFoodInspection24Validation";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export function ControlListItem({
  control,
  selectedControlReasons,
  controlReason,
  onChange,
  onRemove,
}) {
  const t = useAppTranslation();

  const hasObligatoryBevaWhen92ControlValid =
    useObligatoryBevaWhen92ControlValidation(control.id);
  const foodFacilityTypesValid = useFoodFacilityTypeValidation(control.id);
  const hasAnyAssessmentsValid = useFoodAssessmentValidation(control.id);
  const controlAreasValid = useFoodControlAreas24Validation(control.id);
  const errToolTip =
    !hasObligatoryBevaWhen92ControlValid ||
    !foodFacilityTypesValid ||
    !hasAnyAssessmentsValid ||
    !controlAreasValid;

  function onControlChange(v) {
    const updated = {...control, ...v};
    if (updated.cause !== ControlCause.Planned) {
      delete updated.control92;
    }
    onChange(updated);
  }

  function getControlReasons() {
    const selectedReasonsId =
      selectedControlReasons && selectedControlReasons.map((x) => x.id);
    const selectedCause =
      selectedControlReasons &&
      selectedControlReasons.find((x) => x.id === control.cause);
    //removing already selected reasons from list and adding currently selected one so it can be showed in ddl
    //when follow up is chosen you're not allowed to change it to a standard control and vice versa
    const availableControlReasons = selectedCause ? [selectedCause] : [];
    if (!selectedCause || selectedCause.id !== ControlCause.FollowUp) {
      availableControlReasons.push(
        ...controlReason.filter(
          (x) =>
            !selectedReasonsId.includes(x.id) &&
            (!selectedCause ||
              (selectedCause.id !== ControlCause.FollowUp &&
                x.id !== ControlCause.FollowUp))
        )
      );
    }

    const any =
      controlReason
        .filter((x) => !selectedReasonsId.includes(x.id))
        .concat([selectedCause])
        .filter((x) => x !== undefined).length > 1;

    return {
      availableControlReasons,
      any: any,
    };
  }

  function getAnnouncedValue() {
    if (control.announced === undefined || control.announced === null)
      return undefined;
    else return control.announced ? 1 : 2;
  }

  return (
    <div className="d-flex flex-row align-items-center" key={control.id}>
      {errToolTip && (
        <Tooltip
          text={
            <>
              {!hasObligatoryBevaWhen92ControlValid &&
                t("missingObligatoryBevaWhen92Control")}
              {!foodFacilityTypesValid && (
                <>
                  <br />
                  {t("foodFacilityTypeNotValidForInspection")}
                </>
              )}
              {!hasAnyAssessmentsValid && (
                <>
                  <br />
                  {t("missingAssessmentErrorMessage")}
                </>
              )}
              {!controlAreasValid && (
                <>
                  <br />
                  {t("missingLawSection")}
                </>
              )}
            </>
          }
        >
          <FontAwesomeIcon icon={faExclamationCircle} className="text-error" />
        </Tooltip>
      )}

      <div className="my-1 ml-1 py-2 px-3 border rounded d-flex">
        <div>
          <Control
            errors={control.cause ? [] : [t("mandatory")]}
            labelText={t("controlCause")}
          >
            <Select
              placeholder={t("selectControlCause")}
              options={getControlReasons().availableControlReasons}
              value={control.cause}
              getOptionValue={(x) => x.id}
              searchable={false}
              getOptionKey={(x) => x.id}
              getOptionText={(x) => x.text}
              onChange={(v) => {
                onControlChange({cause: v});
              }}
            ></Select>
          </Control>
          {control.cause && (
            <Control
              errors={getAnnouncedValue() ? [] : [t("mandatory")]}
              labelText={t("controlType")}
            >
              <Select
                placeholder={t("selectControlType")}
                options={[
                  {id: 1, text: "Föranmäld"},
                  {id: 2, text: "Oanmäld"},
                ]}
                value={getAnnouncedValue()}
                getOptionValue={(x) => x.id}
                getOptionKey={(x) => x.id}
                getOptionText={(x) => x.text}
                searchable={false}
                onChange={(v) => {
                  onControlChange({announced: v === 1 ? true : false});
                }}
              ></Select>
            </Control>
          )}
        </div>
        <div className="mx-3">
          {control.cause && (
            <>
              <Control
                labelText={t("92control")}
                style={
                  control.cause !== ControlCause.Planned
                    ? {visibility: "hidden"}
                    : {}
                }
              >
                <Switch
                  checked={control.control92}
                  kind="success"
                  onChange={(v) => {
                    onControlChange({control92: v});
                  }}
                />
              </Control>
              <Control labelText={t("controlOnSite")}>
                <Switch
                  checked={control.onSite}
                  kind="success"
                  onChange={(v) => {
                    onControlChange({onSite: v});
                  }}
                />
              </Control>
            </>
          )}
        </div>
        <div>
          {control.cause && (
            <>
              <Control labelText={t("controlSampling")}>
                <Switch
                  checked={control.sample}
                  kind="success"
                  onChange={(v) => {
                    onControlChange({sample: v});
                  }}
                />
              </Control>
              <Control labelText={t("controlRevision")}>
                <Switch
                  checked={control.revision}
                  kind="success"
                  onChange={(v) => {
                    onControlChange({revision: v});
                  }}
                />
              </Control>
            </>
          )}
        </div>
      </div>
      <Button
        kind="ghost"
        icon={faTrashCan}
        value={control.id}
        onClick={(e) => {
          onRemove(e.currentTarget.value);
        }}
      />
    </div>
  );
}
