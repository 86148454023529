import {
  faCopy,
  faEdit,
  faEllipsisV,
  faFileExport,
  faFileImport,
  faPlus,
  faPowerOff,
  faTrashAlt,
} from "@fortawesome/pro-regular-svg-icons";
import {HelpLink, useConfirmDialog} from "@sokigo-sbwebb/default-components";
import {useApplicationBusy} from "@sokigo-sbwebb/default-core";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {getJson} from "@sokigo-sbwebb/fetch";
import {useRefresh} from "@sokigo-sbwebb/react";
import {Validation} from "@sokigo-sbwebb/validation";
import {
  ActionsDropdown,
  ActionsDropdownItem,
  Badge,
  Button,
  Error,
  Panel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableHeaderRow,
  TableRow,
  TableRowActionsCell,
} from "@sokigo/components-react-bootstrap";
import {Fragment, useEffect, useMemo, useState} from "react";
import {v4 as uuid} from "uuid";
import {useServerState} from "../../../ServerStateProvider";
import {ExportVisitForm} from "../../ImportAndExportVisitForm/ExportVisitForm";
import {ImportVisitForm} from "../../ImportAndExportVisitForm/ImportVisitForm";
import {EditVisitFormModal} from "./EditVisitForm/EditVisitFormModal";
import {SaveAsType} from "./EditVisitForm/VisitFormEditor/SaveAsType";
import VisitFormSchema from "./VisitFormSchema.json";

function CreateVisitFormButton({onClose}) {
  const t = useAppTranslation();
  const [creatingInspection, setCreatingInspection] = useState(false);

  return (
    <>
      <Button
        className="mb-1 ml-1"
        kind="primary"
        icon={faPlus}
        onClick={() => setCreatingInspection(true)}
      >
        {t("addVisitForm")}
      </Button>
      <EditVisitFormModal
        show={creatingInspection}
        onClose={() => {
          setCreatingInspection(false);
          onClose();
        }}
        visitFormId={null}
      />
    </>
  );
}

function VisitFormTable({
  visitFormsList,
  setEditingVisitFormId,
  refreshVisitForms,
}) {
  const t = useAppTranslation();
  const {removeVisitForm, addVisitForm, updateVisitForm} = useServerState();
  const applicationBusy = useApplicationBusy();
  const [ConfirmRemoveVisitForm, confirmRemoveVisitForm] = useConfirmDialog();
  const [formTitle, setFormTitle] = useState("");
  const [formSubTitle, setFormSubTitle] = useState("");
  const validation = useMemo(() => new Validation(VisitFormSchema), []);
  const visitFormsValidation = useMemo(
    () =>
      visitFormsList.reduce((acc, cur) => {
        acc[cur.id] = validation.validate(cur, t);
        return acc;
      }, {}),
    [t, validation, visitFormsList]
  );

  async function duplicate(visitForm) {
    const newVisitForm = {...visitForm, id: uuid(), disabled: true};
    await addVisitForm(newVisitForm);
  }

  async function remove(id, title, subTitle) {
    setFormTitle(title);
    setFormSubTitle(subTitle);
    const confirmed = await confirmRemoveVisitForm();
    if (!confirmed) return;
    applicationBusy(true);
    await removeVisitForm(id);
    refreshVisitForms();
    applicationBusy(false);
  }

  async function activate(visitForm) {
    applicationBusy(true);
    await updateVisitForm(visitForm.id, {
      ...visitForm,
      disabled: !visitForm.disabled,
    });
    applicationBusy(false);
  }

  function getTypeText(type) {
    return type === SaveAsType.GenericInspection
      ? t("genericInspection")
      : type === SaveAsType.FoodInspection
      ? t("foodInspection")
      : type === SaveAsType.VisitOccurrence
      ? t("visitOccurrence")
      : type === SaveAsType.FoodInspection24
      ? t("foodInspection24")
      : t("unknownVisitForm");
  }

  const columns = [
    {
      key: "title",
      header: t("title"),
      field: "title",
      renderCell: ({row}) => (
        <>
          {row.title}
          {visitFormsValidation[row.id]?.length > 0 ? (
            <Error warning className="ml-1" />
          ) : null}
        </>
      ),
    },
    {
      key: "active",
      header: "",
      className: "w-1px",
      renderCell: ({row}) =>
        row.disabled ? <Badge kind="warning">{t("inactive")}</Badge> : null,
    },
    {
      key: "subTitle",
      header: t("subTitle"),
      field: "subTitle",
    },
    {
      key: "type",
      header: t("type"),
      field: "type",
    },
  ];

  const visitFormListWithParsedValues = visitFormsList.map((x) => ({
    ...x,
    type: getTypeText(x.saveAsType),
  }));

  return (
    <>
      <Table
        rows={visitFormListWithParsedValues}
        columns={columns}
        getRowKey={(x) => x.id}
        sortable
      >
        <TableHead>
          <TableHeaderRow colSpan={columns.length + 1}>
            {(columns) => (
              <>
                {columns.map((x) => (
                  <TableHeader key={x.key} column={x} className={x.className}>
                    {x.header}
                  </TableHeader>
                ))}
                <TableHeader />
              </>
            )}
          </TableHeaderRow>
        </TableHead>
        <TableBody>
          {(rows) =>
            rows.map((r) => (
              <Fragment key={r.key}>
                <TableRow key={r.key} row={r} invalid={!r.row.title}>
                  {r.cells.map((c) => (
                    <TableCell key={c.key}>{c.value}</TableCell>
                  ))}
                  <TableRowActionsCell>
                    <Button
                      size="sm"
                      kind="ghost"
                      icon={faEdit}
                      onClick={() => setEditingVisitFormId(r.row.id)}
                    />
                    <ActionsDropdown
                      icon={faEllipsisV}
                      kind="ghost"
                      size="sm"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <ActionsDropdownItem
                        itemText={
                          r.row.disabled ? t("activate") : t("deactivate")
                        }
                        icon={faPowerOff}
                        disabled={
                          r.row.disabled &&
                          visitFormsValidation[r.row.id]?.length > 0
                        }
                        onClick={async () => {
                          await activate(r.row);
                          refreshVisitForms();
                        }}
                      />
                      <ActionsDropdownItem
                        itemText={t("copy")}
                        icon={faCopy}
                        onClick={async () => {
                          await duplicate(r.row);
                          refreshVisitForms();
                        }}
                      />
                      <ActionsDropdownItem
                        onClick={() =>
                          remove(r.row.id, r.row.title, r.row.subTitle)
                        }
                        itemText={t("remove")}
                        icon={faTrashAlt}
                        kind="danger-hover-ghost"
                      />
                    </ActionsDropdown>
                  </TableRowActionsCell>
                </TableRow>
              </Fragment>
            ))
          }
        </TableBody>
      </Table>
      <ConfirmRemoveVisitForm>
        {t("removeVisitFormWarning", {
          title: formTitle,
          subTitle: formSubTitle ? ` - ${formSubTitle}` : "",
        })}
      </ConfirmRemoveVisitForm>
    </>
  );
}

const VISITFORM_EXPORT_ID = "66e9fa35-d2d9-41e5-8f77-c13eb10e891a";

export function VisitFormsRoute() {
  const t = useAppTranslation();
  const [editingVisitFormId, setEditingVisitFormId] = useState();
  const [importVisitForm, setImportVisitForm] = useState();
  const [exportVisitForm, setExportVisitForm] = useState();

  const [visitForms, setVisitForms] = useState([]);
  const [refreshVisitFormsToken, refreshVisitForms] = useRefresh();
  useEffect(() => {
    async function getAll() {
      const forms = await getJson("api/inspection/visitforms/all");
      forms.sort((a, b) => a.title.localeCompare(b.title, t("locale")));
      setVisitForms(forms);
    }
    getAll();
  }, [refreshVisitFormsToken, t]);

  return (
    <Panel className="py-3 mx-3">
      <div className="d-flex flex-row float-right mr-3">
        <ActionsDropdown
          className="mb-1 mr-1"
          icon={faEllipsisV}
          kind="ghost"
          onClick={(e) => e.stopPropagation()}
          menuSize="sm"
        >
          <ActionsDropdownItem
            itemText={t("exportVisitForm")}
            icon={faFileExport}
            onClick={() => setExportVisitForm(true)}
          />
          <ActionsDropdownItem
            itemText={t("importVisitForm")}
            onClick={() => setImportVisitForm(true)}
            icon={faFileImport}
          />
        </ActionsDropdown>
        <HelpLink section="visitForms" />
        <CreateVisitFormButton onClose={refreshVisitForms} />
      </div>
      {visitForms && visitForms.length === 0 && t("noVisitForms")}
      {visitForms && visitForms.length > 0 && (
        <>
          <VisitFormTable
            visitFormsList={visitForms}
            setEditingVisitFormId={setEditingVisitFormId}
            refreshVisitForms={refreshVisitForms}
          />
          {editingVisitFormId && (
            <EditVisitFormModal
              show={!!editingVisitFormId}
              onClose={() => {
                setEditingVisitFormId(null);
                refreshVisitForms();
              }}
              visitFormId={editingVisitFormId}
              visitForms={visitForms}
            />
          )}
        </>
      )}
      <ImportVisitForm
        setShow={setImportVisitForm}
        show={importVisitForm}
        visitFormExportId={VISITFORM_EXPORT_ID}
        visitForms={visitForms}
        onImported={refreshVisitForms}
      />
      <ExportVisitForm
        setShow={setExportVisitForm}
        show={exportVisitForm}
        visitForms={visitForms}
        visitFormExportId={VISITFORM_EXPORT_ID}
      />
    </Panel>
  );
}
