import {CaseParties} from "./caseParties/CaseParties";
import {Documents} from "./documents/Documents";
import {VisitInformationHeader24} from "./header/VisitInformationHeader24";
import {HistoricVisits24} from "./historicVisits/HistoricVisits24";

export function InformationTab24() {
  return (
    <>
      <VisitInformationHeader24 />
      <CaseParties />
      <Documents />
      <HistoricVisits24 />
    </>
  );
}
