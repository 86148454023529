import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {Panel} from "@sokigo/components-react-bootstrap";
import {useCallback} from "react";
import {useHistory} from "react-router-dom";
import {
  useCurrentVisitInput,
  useMergeCurrentVisitInput,
} from "../../Visit/InspectionVisitRoute";
import {AttachmentsProvider} from "./AttachmentsProvider";
import {Attachments} from "./AttachmentsTable";
import {useServerState} from "src/apps/sokigo-sbwebb-inspection/ServerStateProvider";

export function AttachmentsTab() {
  const t = useAppTranslation();
  const {attachments} = useCurrentVisitInput();
  const {baseData: {attachmentSizeLimit} = {}} = useServerState();
  const mergeCurrentVisitInput = useMergeCurrentVisitInput();

  const sizecollection = attachments.map((x) => x.size);
  const totalsize = sizecollection.reduce((a, b) => (a = a + b), 0);
  const totalSizeInMb = totalsize / 1048576;
  const totalSizeFormatted = totalSizeInMb.toLocaleString("sv-SE", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const changeAttachment = useCallback(
    (changedAttachment) => {
      const attachmentsWithChangedOne = attachments.map((attachment) => {
        if (attachment.attachmentId === changedAttachment.attachmentId) {
          return changedAttachment;
        }
        return attachment;
      });
      mergeCurrentVisitInput({attachments: attachmentsWithChangedOne});
    },
    [attachments, mergeCurrentVisitInput]
  );

  const deleteAttachment = useCallback(
    (attachmentRow) => {
      const attachmentsWithoutRemoved = attachments.filter((attachment) => {
        return attachment.attachmentId !== attachmentRow.attachmentId;
      });
      mergeCurrentVisitInput({attachments: attachmentsWithoutRemoved});
    },
    [mergeCurrentVisitInput, attachments]
  );

  const history = useHistory();

  const attachmentsWithFileUrl = attachments.map((a) => ({
    ...a,
    fileUrl: history.createHref({
      pathname: `/cache/attachment/${a.attachmentId}`,
    }),
  }));

  return (
    <Panel className="mt-3 py-3">
      {attachments && (
        <AttachmentsProvider
          value={{
            allAttachments: attachmentsWithFileUrl,
            changeAttachment,
            deleteAttachment,
            newAttachments: [],
          }}
        >
          <div>
            <h4 className="px-3 mb-2" style={{float: "left"}}>
              {attachments.length === 1 ? t("attachment") : t("attachments")}
            </h4>
            <div
              style={
                totalSizeInMb >= attachmentSizeLimit
                  ? {float: "right", marginRight: "1em", color: "red"}
                  : {float: "right", marginRight: "1em"}
              }
            >
              {t("attachmenttotalFileSize")}:
              {` ${totalSizeFormatted}/${attachmentSizeLimit} MB`}
            </div>
          </div>

          {attachments.length === 0 ? (
            <div className="px-3">{t("noAttachmentsHaveBeenAdded")}</div>
          ) : (
            <Attachments />
          )}
        </AttachmentsProvider>
      )}
    </Panel>
  );
}
