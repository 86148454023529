import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {useEffect, useState} from "react";
import {ControlAreasTable} from "./ControlAreasTable";
import {ControlPointsTable} from "./ControlPointsTable";
import {ControlsModal} from "./ControlsModal";

export function NonFoodInspectionControlAreas({visitForm, onChange}) {
  const [savedControlAreas, setSavedControlAreas] = useState([]);
  const [selectedControlAreaId, setSelectedControlAreaId] = useState();
  const selectedControlArea = savedControlAreas.find(
    (x) => x.id === selectedControlAreaId
  );
  const t = useAppTranslation();

  useEffect(() => {
    setSavedControlAreas(visitForm.controlAreas);
  }, [visitForm.controlAreas]);

  const onChangeSavedControlAreas = (controlAreas) => {
    onChange(controlAreas);
  };

  const removeControlArea = (id) => {
    onChangeSavedControlAreas(savedControlAreas.filter((x) => x.id !== id));
  };

  const removeControlPoint = (id) => {
    const updatedControlAreas = savedControlAreas
      .map((x) => {
        const updatedControlPoints = x.controlPoints.filter(
          (pointId) => pointId !== id
        );
        if (updatedControlPoints.length > 0) {
          return {
            ...x,
            controlPoints: updatedControlPoints,
          };
        }
      })
      .filter((x) => x);
    onChangeSavedControlAreas(updatedControlAreas);
  };

  const onChangeSavedControlPoints = (controlPoints) => {
    onChangeSavedControlAreas(
      savedControlAreas.map((ca) => {
        if (ca.id === selectedControlAreaId) {
          return {...ca, controlPoints: controlPoints.map((x) => x.id)};
        } else {
          return ca;
        }
      })
    );
  };

  const saveControlAreaGroups = (visitFormControlAreas) => {
    onChangeSavedControlAreas(visitFormControlAreas);
  };

  return (
    <>
      <div className="row">
        <h4 className="col-sm-9 col-xs-12">{t("selectedControlAreas")}</h4>
        <ControlsModal
          onChange={saveControlAreaGroups}
          defaultValue={visitForm.controlAreas}
        />
      </div>
      <div className="row">
        <div className="col-12">
          {Array.isArray(savedControlAreas) && savedControlAreas.length > 0 && (
            <>
              <h5>{t("controlAreas")}</h5>
              <ControlAreasTable
                onChange={(x) => onChangeSavedControlAreas([...x])}
                savedControlAreas={savedControlAreas}
                setSelectedControlAreaId={setSelectedControlAreaId}
                removeControlArea={removeControlArea}
              />
            </>
          )}
        </div>
        <div className="col">
          {selectedControlArea && (
            <>
              <h5>{t("controlPointsInSelectedControlArea")}</h5>
              <ControlPointsTable
                onChange={onChangeSavedControlPoints}
                selectedControlArea={selectedControlArea}
                removeControlPoint={removeControlPoint}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
}
