import {
  faBriefcase,
  faCar,
  faTrashAlt,
} from "@fortawesome/pro-regular-svg-icons";
import {useConfirmDialog} from "@sokigo-sbwebb/default-components";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {
  Button,
  Panel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableHeaderRow,
  TableRow,
  TableRowActionsCell,
} from "@sokigo/components-react-bootstrap";
import {useCurrentVisit} from "../../../InspectionVisitRoute";
import {useCompletingInformationProvider} from "../CompletingInformationTab";
import {TimeRegistrationModalButon} from "./TimeRegistrationModalButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export function TimeRegistration() {
  const t = useAppTranslation();

  const {
    visit: {
      visitMetadata: {billableArticles},
      baseDataSnapshot: {caseWorkers},
    },
  } = useCurrentVisit();

  const {onChange, value, notBillableArticle} =
    useCompletingInformationProvider();
  const [ConfirmModal, confirmDelete] = useConfirmDialog();

  const allBillableArticles = [notBillableArticle, ...billableArticles];

  const removeTimeRegistration = async (timeRegId) => {
    const confirmed = await confirmDelete();
    if (!confirmed) return;
    onChange({
      ...value,
      spentTimeRegistration: value.spentTimeRegistration.filter(
        (x) => timeRegId !== x.id
      ),
    });
  };

  return (
    <>
      <Panel className="mt-3 py-3">
        <div className="d-flex justify-content-between">
          <h4 className="px-3 mb-3">{t("timeRegistration")}</h4>
          <TimeRegistrationModalButon onChange={onChange} value={value} />
        </div>

        {value?.spentTimeRegistration?.length > 0 ? (
          <Table
            rows={value?.spentTimeRegistration ?? []}
            columns={[
              {
                key: "1",
                field: "articleId",
                renderCell: ({row}) =>
                  allBillableArticles.find((x) => x.articleId === row.articleId)
                    ?.title,
                header: t("article"),
              },
              {
                key: "2",
                field: "timeSpent",
                renderCell: ({row}) => (
                  <>
                    {row.amount}{" "}
                    {row.travelTime ? (
                      <FontAwesomeIcon icon={faCar} />
                    ) : (
                      <FontAwesomeIcon icon={faBriefcase} />
                    )}
                  </>
                ),
                header: t("time"),
              },
              {
                key: "3",
                renderCell: ({row}) =>
                  caseWorkers.find((x) => x.id === row.performedByCaseWorkerId)
                    ?.name,
                header: t("performedBy"),
              },
              {
                key: "4",
                field: "performedBy",
              },
            ]}
            getRowKey={(x) => x.id}
          >
            <TableHead>
              <TableHeaderRow>
                {(columns) => (
                  <>
                    {columns.map((x) => (
                      <TableHeader key={x.key} column={x}>
                        {x.header}
                      </TableHeader>
                    ))}
                    <TableHeader />
                  </>
                )}
              </TableHeaderRow>
            </TableHead>
            <TableBody>
              {(rows) =>
                rows.map((r) => (
                  <TableRow key={r.key} row={r}>
                    {r.cells.map((c) => (
                      <TableCell key={c.key}>{c.value}</TableCell>
                    ))}
                    <TableRowActionsCell>
                      <TimeRegistrationModalButon
                        onChange={onChange}
                        value={value}
                        selectedId={r.row.id}
                      />
                      <Button
                        size="sm"
                        kind="danger-hover-ghost"
                        icon={faTrashAlt}
                        onClick={() => removeTimeRegistration(r.row.id)}
                        tooltipText={t("remove")}
                      />
                    </TableRowActionsCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        ) : (
          <div className="px-3">{t("noTimeRegistration")}</div>
        )}
      </Panel>
      <ConfirmModal />
    </>
  );
}
