import {faCheck, faTimes} from "@fortawesome/pro-regular-svg-icons";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@sokigo/components-react-bootstrap";

export const ConfirmAsssessmentActionModal = ({
  show,
  header,
  text,
  onOkay,
  onCancel,
}) => {
  const t = useAppTranslation();
  const onCancelButton = () => {
    onCancel();
  };

  return (
    <Modal show={show} static onClose={onCancel} size="md">
      <ModalHeader>{header}</ModalHeader>
      <ModalBody>
        {text.map((x) => (
          <p key={x} className={"mb-3"}>
            {x}
          </p>
        ))}
      </ModalBody>
      <ModalFooter>
        <Button kind="primary" onClick={onOkay} icon={faCheck} className="mr-2">
          {t("ok")}
        </Button>
        <Button kind="secondary" onClick={onCancelButton} icon={faTimes}>
          {t("cancel")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
