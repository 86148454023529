import {useCallback, useMemo} from "react";
import {
  useCurrentVisitInput,
  useMergeCurrentVisitInput,
} from "../InspectionVisitRoute";

export function useScratchPad() {
  const input = useCurrentVisitInput();

  const scratchPadText = useMemo(() => input.scratchPadText ?? [], [input]);

  const mergeCurrentVisitInput = useMergeCurrentVisitInput();
  const onChangeScratchPadText = useCallback(
    (scratchPadText) => mergeCurrentVisitInput({scratchPadText}),
    [mergeCurrentVisitInput]
  );

  return {
    scratchPadText,
    onChangeScratchPadText,
  };
}
