import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {Panel} from "@sokigo/components-react-bootstrap";
import {useCurrentVisit} from "../../InspectionVisitRoute";
import {CasePartiesTable} from "./CasePartiesTable";

export function CaseParties() {
  const t = useAppTranslation();
  const {
    visit: {visitMetadata},
  } = useCurrentVisit();

  const {casePartyRoles} = visitMetadata.visitTargetInformation;

  return (
    <Panel className="mt-3 py-3">
      <h4 className="px-3 mb-3">{t("caseParties")}</h4>
      {(!casePartyRoles || casePartyRoles?.length === 0) && (
        <div className="px-3">{t("caseHasNoParties")}</div>
      )}
      {casePartyRoles?.length > 0 && (
        <CasePartiesTable
          casePartyRoles={casePartyRoles}
          tableExpandHeader={true}
          single={true}
        />
      )}
    </Panel>
  );
}
