import {InspectionGui} from "./components/InspectionGui";
import {CurrentEcosUserIdProvider} from "./useCurrentEcosUserId";

export default {
  name: "sokigo-sbwebb-inspection",
  title: "Tillsyn", // TODO - use translated title when supported everywhere
  route: "inspection",
  view: InspectionGui,
  provider: CurrentEcosUserIdProvider,
  offline: true,
  translations: {
    "en-US": {
      inspectionTitle: "Inspection",
    },
    "sv-SE": {
      inspectionTitle: "Tillsyn",
    },
  },
};
