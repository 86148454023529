import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {
  ActionsDropdown,
  ActionsDropdownHeader,
  ActionsDropdownItem,
} from "@sokigo/components-react-bootstrap";
import {Fragment, useMemo} from "react";
import {useCurrentVisit} from "../apps/sokigo-sbwebb-inspection/components/Visit/InspectionVisitRoute";
import styles from "./PhrasePicker.module.scss";

export function PhrasePicker({value, onChange, availablePhraseGroupIds}) {
  const t = useAppTranslation();
  const {
    visit: {phraseGroupsSnapshot},
  } = useCurrentVisit();

  const phraseGroups = useMemo(
    () =>
      phraseGroupsSnapshot.filter((pg) =>
        availablePhraseGroupIds?.includes(pg.id)
      ),
    [availablePhraseGroupIds, phraseGroupsSnapshot]
  );

  return (
    <>
      {phraseGroups?.length > 0 && (
        <ActionsDropdown
          dropdownText={t("selectPhrase")}
          kind="ghost"
          className="border mb-2"
        >
          {phraseGroups.map((pg) => (
            <Fragment key={pg.id}>
              <ActionsDropdownHeader headerText={pg.title} />
              {pg.phrases.map((p) => (
                <ActionsDropdownItem
                  key={p.id}
                  onClick={() =>
                    onChange((value ?? "") + (value ? "\n" : "") + p.text)
                  }
                  itemText={
                    <div className={styles.phrase + " text-truncate"}>
                      {p.code}: {p.text}
                    </div>
                  }
                />
              ))}
            </Fragment>
          ))}
        </ActionsDropdown>
      )}
    </>
  );
}
