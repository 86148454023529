import proj4 from "proj4";

export function getWgs84PositionInCoordinateSystemWithCoordinateOrderXY(
  point,
  crs
) {
  const wgs84 = proj4.defs("EPSG:4326");
  const target = proj4.defs(crs);
  if (!target) {
    throw new Error("Unknown coordinate system: " + crs);
  }
  const transformer = proj4(wgs84, target);
  const result = transformer.forward(point, true);
  // ensure xy coordinate order, because that's what openlayers expects
  return [result[target.axis.indexOf("e")], result[target.axis.indexOf("n")]];
}
