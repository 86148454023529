import {
  faCheck,
  faExclamationCircle,
  faPause,
} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {Spinner, Tooltip, Error} from "@sokigo/components-react-bootstrap";
import {SaveToEcosStatus} from "../Common/SaveToEcosStatus";

export function EcosSaveOccurrenceStatusIndicator({saveToEcosStatus}) {
  const t = useAppTranslation();

  switch (saveToEcosStatus) {
    case SaveToEcosStatus.VisitNotFinished:
    case SaveToEcosStatus.VisitWithValidationErrorsReopened:
      throw new Error("Visit is not finished");
    case SaveToEcosStatus.VisitFinishedSaveToEcosQueued:
      return (
        <Tooltip text={t("queued")}>
          <div className="d-inline-block">
            <FontAwesomeIcon icon={faPause} className="text-info" />
          </div>
        </Tooltip>
      );
    case SaveToEcosStatus.CreateOccurrenceReadyToStart:
    case SaveToEcosStatus.CreateOccurrenceInProgress:
      return (
        <Tooltip text={t("saveStatusOccurrenceStarted")}>
          <div className="d-inline-block">
            <Spinner small inline />
          </div>
        </Tooltip>
      );
    case SaveToEcosStatus.CreateOccurrenceValidationError:
      return (
        <Tooltip text={t("saveStatusValidationError")}>
          <div className="d-inline-block">
            <FontAwesomeIcon
              icon={faExclamationCircle}
              className="text-error"
            />
          </div>
        </Tooltip>
      );
    case SaveToEcosStatus.CreateOccurrenceError:
      return (
        <Tooltip text={t("saveVisitError")}>
          <div className="d-inline-block">
            <FontAwesomeIcon
              icon={faExclamationCircle}
              className="text-error"
            />
          </div>
        </Tooltip>
      );
    case SaveToEcosStatus.CreateOccurrenceFailed:
      return (
        <Tooltip text={t("saveStatusOccurrenceFailed")}>
          <div className="d-inline-block">
            <Error warning className="ml-1" />
          </div>
        </Tooltip>
      );
    case SaveToEcosStatus.CreateOccurrenceAborted:
      return (
        <Tooltip text={t("saveStatusOccurrenceAborted")}>
          <div className="d-inline-block">
            <FontAwesomeIcon
              icon={faExclamationCircle}
              className="text-error"
            />
          </div>
        </Tooltip>
      );
    case SaveToEcosStatus.SaveProtocolReadyToStart:
    case SaveToEcosStatus.SaveProtocolInProgress:
    case SaveToEcosStatus.SaveProtocolFailed:
    case SaveToEcosStatus.SaveProtocolAborted:
    case SaveToEcosStatus.Success:
      return (
        <Tooltip text={t("saveStatusOccurrenceSuccess")}>
          <div className="d-inline-block">
            <FontAwesomeIcon icon={faCheck} className="text-success-alt" />
          </div>
        </Tooltip>
      );
    default:
      throw new Error("Unknown status: " + saveToEcosStatus);
  }
}
export function EcosSaveProtocolStatusIndicator({saveToEcosStatus}) {
  const t = useAppTranslation();

  switch (saveToEcosStatus) {
    case SaveToEcosStatus.VisitNotFinished:
    case SaveToEcosStatus.VisitWithValidationErrorsReopened:
      throw new Error("Visit is not finished");
    case SaveToEcosStatus.VisitFinishedSaveToEcosQueued:
    case SaveToEcosStatus.CreateOccurrenceReadyToStart:
    case SaveToEcosStatus.CreateOccurrenceInProgress:
    case SaveToEcosStatus.CreateOccurrenceFailed:
    case SaveToEcosStatus.CreateOccurrenceValidationError:
    case SaveToEcosStatus.CreateOccurrenceError:
    case SaveToEcosStatus.CreateOccurrenceAborted:
      return null; // not yet started
    case SaveToEcosStatus.SaveProtocolReadyToStart:
    case SaveToEcosStatus.SaveProtocolInProgress:
      return (
        <Tooltip text={t("saveStatusProtocolStarted")}>
          <div className="d-inline-block">
            <Spinner small inline />
          </div>
        </Tooltip>
      );
    case SaveToEcosStatus.SaveProtocolFailed:
      return (
        <Tooltip text={t("saveStatusProtocolFailed")}>
          <div className="d-inline-block">
            <Error warning className="ml-1" />
          </div>
        </Tooltip>
      );
    case SaveToEcosStatus.SaveProtocolAborted:
      return (
        <Tooltip text={t("saveStatusProtocolAborted")}>
          <div className="d-inline-block">
            <FontAwesomeIcon
              icon={faExclamationCircle}
              className="text-error"
            />
          </div>
        </Tooltip>
      );
    case SaveToEcosStatus.Success:
      return (
        <Tooltip text={t("finished")}>
          <div className="d-inline-block">
            <FontAwesomeIcon icon={faCheck} className="text-success-alt" />
          </div>
        </Tooltip>
      );
    default:
      throw new Error("Unknown status: " + saveToEcosStatus);
  }
}
