import {SearchType} from "../Form/SearchType";
import {CaseSearchResults} from "./CaseSearchResults";
import {EstateSearchResults} from "./EstateSearchResults";

export function SearchResults({searchType, results}) {
  return (
    <>
      {searchType === SearchType.Case && (
        <CaseSearchResults results={results} />
      )}
      {searchType === SearchType.Estate && (
        <EstateSearchResults results={results} />
      )}
    </>
  );
}
