export function getUniqueTitle(incomingTitle, existingTitles = []) {
  const baseName = incomingTitle.replace(/ \(\d+\)$/, "");
  const regExp = new RegExp(`^${baseName}( \\((\\d+)\\))?$`);
  const newNr = existingTitles.reduce((acc, cur) => {
    const match = regExp.exec(cur);
    if (!match) return acc;
    const nr = (match[2] && parseInt(match[2], 10) + 1) || 1;
    return nr > acc ? nr : acc;
  }, 0);
  const newTitle = `${baseName}${newNr > 0 ? ` (${newNr})` : ""}`;
  return newTitle;
}
