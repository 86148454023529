import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {Panel} from "@sokigo/components-react-bootstrap";
import {useCurrentVisit} from "../../InspectionVisitRoute";
import {InfoRow} from "./InfoRow";

function FacilityRows({facilities}) {
  const t = useAppTranslation();
  return (
    <>
      {facilities?.map(
        ({
          facilityTitle,
          estateName,
          address,
          invoiceRecipientName,
          operatorName,
        }) => (
          <>
            <InfoRow
              array={[
                {title: t("facilityType"), value: facilityTitle},
                {title: t("operator"), value: operatorName},
                {title: t("invoiceRecipient"), value: invoiceRecipientName},
              ]}
            />
            {estateName && (
              <InfoRow
                array={[
                  {title: t("estate"), value: estateName},
                  {title: t("address"), value: address},
                ]}
              />
            )}
          </>
        )
      )}
    </>
  );
}

function EstateRows({estates}) {
  const t = useAppTranslation();
  return (
    <>
      {estates?.map(({estateName, address}) => (
        <InfoRow
          key={estateName + address}
          array={[
            {title: t("estate"), value: estateName},
            {title: t("address"), value: address},
          ]}
        />
      ))}
    </>
  );
}

export const VisitInformationHeader = () => {
  const {
    visit: {
      visitMetadata: {
        visitTargetDescription,
        visitTargetInformation: {
          caseNumber,
          caseTitle,
          caseSubtitle,
          estates,
          facilities,
        },
      },
    },
  } = useCurrentVisit();

  const t = useAppTranslation();
  return (
    <Panel className="container-fluid p-3 mt-3">
      <h3 className="mb-3">{visitTargetDescription}</h3>
      <EstateRows estates={estates} />
      <FacilityRows facilities={facilities} />
      {caseNumber && (
        <InfoRow
          array={[
            {title: t("case"), value: caseNumber},
            {title: t("title"), value: caseTitle},
            {title: t("subtitle"), value: caseSubtitle},
          ]}
        />
      )}
      {!caseNumber && (
        <InfoRow array={[{title: t("case"), value: t("newCase")}]} />
      )}
    </Panel>
  );
};
