import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {useCurrentVisit} from "../InspectionVisitRoute";
import {DynamicParagraphs} from "./DynamicParagraphs";
import {StaticParagraphs} from "./StaticParagraphs";
import {Panel} from "@sokigo/components-react-bootstrap";

export function ProtocolTextsTab() {
  const t = useAppTranslation();
  const {
    visit: {
      visitFormSnapshot: {protocolTexts},
    },
  } = useCurrentVisit();

  return (
    <Panel className="mt-3 py-3">
      {!protocolTexts || protocolTexts?.length === 0 ? (
        <div className="px-3">{t("noProtocolTexts")}</div>
      ) : (
        <div className="px-3">
          <StaticParagraphs />
          <DynamicParagraphs />
        </div>
      )}
    </Panel>
  );
}
