import {createContext, useContext} from "react";
import {useServiceWorkerRepository} from "./sw-comms/useServiceWorkerRepository";

const CurrentEcosUserIdContext = createContext();
const CurrentEcosUserIdFetchedContext = createContext();

export function CurrentEcosUserIdProvider({children}) {
  const {items} = useServiceWorkerRepository("current-ecos-user");

  const item = items?.["_"];
  const ecosUserId = item?.ecosUserId;
  const ecosUserIdFetched = !!item;

  return (
    <CurrentEcosUserIdContext.Provider value={ecosUserId}>
      <CurrentEcosUserIdFetchedContext.Provider value={ecosUserIdFetched}>
        {children}
      </CurrentEcosUserIdFetchedContext.Provider>
    </CurrentEcosUserIdContext.Provider>
  );
}

export function useCurrentEcosUserIdFetched() {
  return useContext(CurrentEcosUserIdFetchedContext);
}
export function useCurrentEcosUserId() {
  return useContext(CurrentEcosUserIdContext);
}
