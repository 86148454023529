import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {Tab, Tabs, Panel} from "@sokigo/components-react-bootstrap";
import {generatePath, useHistory} from "react-router-dom";
import {FoodInspectionControlAreas} from "./FoodInspectionControlAreas";
import {GenericControlAreas} from "./GenericControlAreas";

export function AdminControlAreasMainView({
  match: {
    path,
    params: {controlAreaSection = "foodInspection", ...routeParams},
  },
}) {
  const {push} = useHistory();
  const t = useAppTranslation();

  return (
    <Panel className="mx-3 pb-3">
      <div className="row">
        <div className="col-md-4 col-xl-2">
          <Tabs
            vertical
            selected={controlAreaSection}
            onSelect={(newControlAreaSection) =>
              newControlAreaSection !== controlAreaSection &&
              push(
                generatePath(path, {
                  ...routeParams,
                  controlAreaSection: newControlAreaSection,
                })
              )
            }
            tabsSquare
            className="text-uppercase"
          >
            <Tab id="foodInspection" headerText={t("foodInspection")} />
            <Tab
              id="generic"
              headerText={t("visitOccurrenceAndGenericInspection")}
            />
          </Tabs>
        </div>
        <div className="col-md-8 col-xl-10 pt-3">
          {controlAreaSection === "foodInspection" && (
            <FoodInspectionControlAreas />
          )}
          {controlAreaSection === "generic" && <GenericControlAreas />}
        </div>
      </div>
    </Panel>
  );
}
