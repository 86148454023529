import {useAppTranslation} from "@sokigo-sbwebb/default-i18n";
import {
  Table,
  TableBody,
  TableCell,
  TableExpandedRow,
  TableExpandRowCell,
  TableHead,
  TableHeader,
  TableHeaderRow,
  TableRow,
} from "@sokigo/components-react-bootstrap";
import {createContext, Fragment, useMemo, useState} from "react";
import {ConnectedCases} from "./ConnectedCases";
import {ConnectedFacilities} from "./ConnectedFacilities";

export const SelectedCaseContext = createContext(null);
export const SetSelectedCaseContext = createContext(null);

function EstateSearchResultExpandedRow({result, estateRowId}) {
  return (
    <>
      {result.connectedFacilities.length > 0 && (
        <ConnectedFacilities
          connectedFacilities={result.connectedFacilities}
          estateRowId={estateRowId}
        />
      )}
      {(result.connectedCases.length > 0 ||
        result.newCasePossibleVisits.length > 0) && (
        <ConnectedCases
          connectedCases={result.connectedCases}
          estateRowId={estateRowId}
          facilityRowId={null}
        />
      )}
    </>
  );
}

export function EstateSearchResults({results}) {
  const t = useAppTranslation();
  const [selectedCase, setSelectedCase] = useState(null);

  const mappedResults = useMemo(
    () =>
      results.map((x) => ({
        ...x,
        connectedFacilities: x.connectedFacilities.map((cf) => ({
          ...cf,
          connectedCases: [
            ...cf.connectedCases.map((cc) => ({...cc})),
            {
              rowId: x.rowId,
              isNewCaseRow: true,
              possibleVisits: cf.newCasePossibleVisits,
            },
          ],
        })),
        connectedCases: [
          ...x.connectedCases.map((cc) => ({...cc})),
          {
            rowId: x.rowId,
            isNewCaseRow: true,
            possibleVisits: x.newCasePossibleVisits,
          },
        ],
      })),
    [results]
  );

  const columns = [
    {
      key: "estate",
      header: t("estate"),
      renderCell: ({row}) => row.estate,
    },
    {
      key: "numberOfFacilities",
      header: t("numberOfFacilities"),
      renderCell: ({row}) => row.connectedFacilities.length,
    },
    {
      key: "numberOfCases",
      header: t("numberOfCases"),
      renderCell: ({row}) =>
        row.connectedCases.filter((x) => !x.isNewCaseRow).length,
    },
  ];

  return (
    <SelectedCaseContext.Provider value={selectedCase}>
      <SetSelectedCaseContext.Provider value={setSelectedCase}>
        <div className="mt-4">
          <Table
            rows={mappedResults}
            columns={columns}
            getRowKey={(x) => x.rowId}
            sorting={{column: columns[0], direction: 1}}
          >
            <TableHead>
              <TableHeaderRow colSpan={columns.length + 1}>
                {(columns) => (
                  <>
                    <TableHeader />
                    {columns.map((x) => (
                      <TableHeader key={x.key} column={x}>
                        {x.header}
                      </TableHeader>
                    ))}
                  </>
                )}
              </TableHeaderRow>
            </TableHead>
            <TableBody>
              {(rows) =>
                rows.map((r) => (
                  <Fragment key={r.key}>
                    <TableRow key={r.key} row={r}>
                      <TableExpandRowCell
                        disabled={
                          // if this happens, the row should not have been returned at all?
                          r.row.connectedCases.length === 0 &&
                          r.row.connectedFacilities.length === 0 &&
                          r.row.newCasePossibleVisits.length === 0
                        }
                      />
                      {r.cells.map((c) => (
                        <TableCell key={c.key}>{c.value}</TableCell>
                      ))}
                    </TableRow>
                    <TableExpandedRow
                      row={r}
                      indent
                      colSpan={columns.length + 1}
                    >
                      <EstateSearchResultExpandedRow
                        result={r.row}
                        estateRowId={r.row.rowId}
                      />
                    </TableExpandedRow>
                  </Fragment>
                ))
              }
            </TableBody>
          </Table>
        </div>
      </SetSelectedCaseContext.Provider>
    </SelectedCaseContext.Provider>
  );
}
