import {useCallback, useEffect, useMemo, useState} from "react";
import {
  RepoCreateItemRequest,
  RepoCreateItemsRequest,
  RepoDeleteItemRequest,
  RepoGetItemsRequest,
  RepoGetItemsResponse,
  RepoItemsBroadcastMessage,
  RepoMessage,
  RepoMessageTypes,
  RepoRefreshRequest,
  RepoResponseMessage,
  RepoUpdateItemRequest,
  RepoUpdateItemsRequest,
} from "./RepoMessage";

export function useServiceWorkerRepository<TValue>(repoName: string): {
  items: {[key: string]: TValue};
  createItem: (string, TValue) => void;
  updateItem: (string, TValue) => void;
  deleteItem: (string) => void;
  createItems: (items: {id: string; item: TValue}[]) => void;
  updateItems: (items: {id: string; item: TValue}[]) => void;
  refresh: () => void;
} {
  const [name] = useState(repoName);
  if (repoName !== name) {
    throw new Error("Cannot switch repository name");
  }

  const sendMessage = useCallback(async function sendMessage<TResponse>(
    msg: RepoMessage,
    getResponse?: (RepoResponseMessage) => TResponse
  ) {
    const {active} = await navigator.serviceWorker.ready;
    return await new Promise<TResponse>((resolve, reject) => {
      const mc = new MessageChannel();
      mc.port1.onmessage = async (event) => {
        const response = <RepoResponseMessage>event.data;
        if (response.success) {
          resolve(getResponse?.(response));
        } else {
          reject();
        }
      };
      active.postMessage(msg, [mc.port2]);
    });
  },
  []);

  const [items, setItems] = useState(null);

  useEffect(() => {
    const broadcastHandler = (
      event: MessageEvent<RepoItemsBroadcastMessage<TValue>>
    ) => {
      if (!event.data.isRepoMessage) {
        return;
      }
      const m = <RepoItemsBroadcastMessage<TValue>>event.data;
      if (m.repoName !== name || m.type !== RepoMessageTypes.Items) {
        return;
      }
      setItems(m.items);
    };
    navigator.serviceWorker.addEventListener("message", broadcastHandler);

    (async function () {
      // get initial items from SW on startup
      const items = await sendMessage<{[key: string]: TValue}>(
        new RepoGetItemsRequest(name),
        (msg) => {
          const itemsResponse = <RepoGetItemsResponse<TValue>>msg;
          return itemsResponse.items;
        }
      );
      setItems(items);
    })();

    return () => {
      navigator.serviceWorker.removeEventListener("message", broadcastHandler);
    };
  }, [name, sendMessage]);

  const refresh = useCallback(async () => {
    await sendMessage<void>(new RepoRefreshRequest(name));
  }, [name, sendMessage]);

  const createItem = useCallback(
    async (id: string, item: TValue) => {
      const msg = new RepoCreateItemRequest(id, item, name);
      await sendMessage<void>(msg);
    },
    [name, sendMessage]
  );
  const createItems = useCallback(
    async (items: {id: string; item: TValue}[]) => {
      const msg = new RepoCreateItemsRequest(items, name);
      await sendMessage<void>(msg);
    },
    [name, sendMessage]
  );

  const updateItem = useCallback(
    async (id: string, value: TValue) => {
      await sendMessage<void>(new RepoUpdateItemRequest(id, value, name));
    },
    [name, sendMessage]
  );

  const updateItems = useCallback(
    async (items: {id: string; item: TValue}[]) => {
      const msg = new RepoUpdateItemsRequest(items, name);
      await sendMessage<void>(msg);
    },
    [name, sendMessage]
  );

  const deleteItem = useCallback(
    async (id: string) => {
      await sendMessage<void>(new RepoDeleteItemRequest(id, name));
    },
    [name, sendMessage]
  );

  const v = useMemo(
    () => ({
      items,
      createItem,
      createItems,
      deleteItem,
      updateItem,
      updateItems,
      refresh,
    }),
    [
      items,
      createItem,
      createItems,
      deleteItem,
      updateItem,
      updateItems,
      refresh,
    ]
  );

  return v;
}
