import {useMemo} from "react";
import {InspectionAssessment} from "../../Common/InspectionAssessment";
import {useCurrentVisitInput, useCurrentVisit} from "../InspectionVisitRoute";
import {useAvailableFoodControlAreas} from "./useAvailableFoodControlAreas";
import {SaveAsType} from "../../AdminRoutes/AdminVisitForms/EditVisitForm/VisitFormEditor/SaveAsType";

export function useFoodControlAreasValidation() {
  const availableAreas = useAvailableFoodControlAreas();
  const {foodControlQuestionAssessmentByQuestionId} = useCurrentVisitInput();

  const {
    visit: {
      visitMetadata: {saveAsType},
    },
  } = useCurrentVisit();

  return useMemo(() => {
    const operativeGoalsControlAreaId = "09ebf132-4ee0-4446-8cb5-0f61f5a84307";

    const foodControlQuestionAssessmentByQuestionIdArray =
      foodControlQuestionAssessmentByQuestionId
        ? Object.entries(foodControlQuestionAssessmentByQuestionId)
        : [];

    const hasAnyQuestionsAssessed =
      saveAsType === SaveAsType.FoodInspection ||
      saveAsType === SaveAsType.FoodInspection24
        ? foodControlQuestionAssessmentByQuestionIdArray.filter(
            (x) => x[1] !== InspectionAssessment.NotAssessed
          ).length > 0
        : true;

    // if visit that have one or more assessed control points under area "P: Operativa mål"
    if (
      (saveAsType === SaveAsType.FoodInspection ||
        saveAsType === SaveAsType.FoodInspection24) &&
      hasAnyQuestionsAssessed
    ) {
      const nonOperativeGoalsQuestionIds = availableAreas
        .filter((x) => x.id !== operativeGoalsControlAreaId)
        .map((x) => x.questions)
        .flat()
        .map((x) => x.id);

      let isValid = false;
      //must have at least one assessed control point belonging to another control area.
      //("Assessed" = having an assessment value other than "Not assessed  ".)
      for (let i = 0; i < nonOperativeGoalsQuestionIds.length; i++) {
        const assessmentValue =
          foodControlQuestionAssessmentByQuestionId[
            nonOperativeGoalsQuestionIds[i]
          ];
        if (
          assessmentValue &&
          assessmentValue !== InspectionAssessment.NotAssessed
        ) {
          isValid = true;
          break;
        }
      }
      return isValid;
    }
    return true;
  }, [foodControlQuestionAssessmentByQuestionId, saveAsType, availableAreas]);
}

export function useFoodControlQuestionsValidation() {
  const {
    foodControlQuestionAssessmentByQuestionId,
    foodControlQuestionNoteByQuestionId,
  } = useCurrentVisitInput();

  const {
    visit: {
      visitMetadata: {saveAsType},
    },
  } = useCurrentVisit();

  return useMemo(() => {
    let result = {};
    const foodControlQuestionAssessmentByQuestionIdArray =
      foodControlQuestionAssessmentByQuestionId
        ? Object.entries(foodControlQuestionAssessmentByQuestionId)
        : [];

    const foodControlQuestionNoteByQuestionIdArray =
      foodControlQuestionNoteByQuestionId
        ? Object.entries(foodControlQuestionNoteByQuestionId)
        : [];

    const hasAnyQuestionsAssessed =
      saveAsType === SaveAsType.FoodInspection
        ? foodControlQuestionAssessmentByQuestionIdArray.filter(
            (x) => x[1] !== InspectionAssessment.NotAssessed
          ).length > 0
        : true;

    const notAssessed = foodControlQuestionAssessmentByQuestionIdArray
      .filter((x) => x[1] === InspectionAssessment.NotAssessed)
      .map((x) => x[0]);

    const withNote = foodControlQuestionNoteByQuestionIdArray
      .filter((x) => x[1] !== "")
      .map((x) => x[0]);

    const notAssessedWithNote =
      notAssessed.filter((value) => withNote.includes(value)).length > 0;

    result = {hasAnyQuestionsAssessed, notAssessedWithNote};

    return result;
  }, [
    foodControlQuestionAssessmentByQuestionId,
    foodControlQuestionNoteByQuestionId,
    saveAsType,
  ]);
}
